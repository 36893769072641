import * as React from 'react'
import * as RFAPI from '@api/api'
import { useOnboardingTasks } from '@app/api/hooks'
import { EVENTS_ID, type ONBOARDING_TOUR_CONTEXT } from '@app/constants'
import { Text, TutorialTour, type TutorialTourStepsProps } from '@components/tutorial/tutorial-tour'
import { OnboardingTaskType } from '@app/api/openapi'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

interface ValidationTourProps {
  onboardingTourContext: ONBOARDING_TOUR_CONTEXT
}

export const ValidationTour: React.FC<ValidationTourProps> = ({ onboardingTourContext }) => {
  const { mutate: mutateOnboardingTasks, data: onboardingTasks } = useOnboardingTasks(onboardingTourContext)

  const onInstallationInstructionRead = React.useCallback(async (): Promise<void> => {
    await RFAPI.completeUserOnboardingTask(OnboardingTaskType.PublicIrreversibleUserHasReadPluginInstallationInstructions)
    await mutateOnboardingTasks()
  }, [mutateOnboardingTasks])

  React.useEffect(() => {
    window.addEventListener(EVENTS_ID.USER_HAS_READ_PLUGIN_INSTALLATION_INSTRUCTIONS, onInstallationInstructionRead, {})
    return () => {
      window.addEventListener(EVENTS_ID.USER_HAS_READ_PLUGIN_INSTALLATION_INSTRUCTIONS, onInstallationInstructionRead, {})
    }
  }, [onInstallationInstructionRead])

  const validationSteps: TutorialTourStepsProps[] = React.useMemo(() => {
    if (onboardingTasks === undefined) {
      return []
    }
    return [
      {
        keyIsCompleted: 'validationPageUserHasReadValidateIntroduction',
        onboardingTaskCompletion: OnboardingTaskType.PublicValidationPageUserHasReadValidateIntroduction,
        step: {
          title: <FormattedMessage id='tutorial.validate-editor.validate.title' defaultMessage='Welcome to Validation' />,
          description: <Text>
            <FormattedMessage id='tutorial.validate-editor.validate.content' defaultMessage='It is standard practice to validate that a trained artificial intelligence system will perform well on images that were not used for training.' />
          </Text>,
        },
      },
      {
        keyIsCompleted: 'validationPageUserHasReadUploadImage',
        onboardingTaskCompletion: OnboardingTaskType.PublicValidationPageUserHasReadUploadImage,
        step: {
          title: <FormattedMessage id='tutorial.validate-editor.upload.title' defaultMessage='Upload images' />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial.validate-editor.upload-image.content-1' defaultMessage='Upload images that are representative of the objects and structures you want to detect but that were not used in the annotate and train step. For most problems, 5 to 10 images are sufficient.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial.validate-editor.upload-image.content-2' defaultMessage='To do this, drag and drop an image in the left section.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial.validate-editor.upload-image.alternative.content' defaultMessage='Or, click the + icon above' />
            </Text>
          </>,
          target: () => document.getElementById('upload-image-section') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'validationPageUserHasReadVisualizeResultsClassHide',
        onboardingTaskCompletion: OnboardingTaskType.PublicValidationPageUserHasReadVisualizeResultsClassHide,
        step: {
          title: <FormattedMessage id='tutorial.validate-editor.visualize-results-hide.title' defaultMessage='Vizualize Results Classes' />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial.validate-editor.visualize-results.content-1' defaultMessage='Now that your images are uploaded, Studio will run class predictions on each of those images.  See the result and make sure that the Studio makes prediction that looks right to you' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial.validate-editor.visualize-results-hide.content-1' defaultMessage='You can hide specific classes using the associated button' />
            </Text>
          </>,
          target: () => document.getElementById('prediction-class-show-hide') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'validationPageUserHasReadSendImageAnnotate',
        onboardingTaskCompletion: OnboardingTaskType.PublicValidationPageUserHasReadSendImageAnnotate,
        step: {
          title: <FormattedMessage id='tutorial.validate-editor.image-annotation.title' defaultMessage='Send Image To Annotation' />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial.validate-editor.image-annotation.content-1' defaultMessage="The prediction on the image is not what you expected? You see that Studio is not recognizing a structure that you would like it to find?" />
            </Text>
            <Text>
              <FormattedMessage id='tutorial.validate-editor.image-annotation.content-2' defaultMessage='Then right click on the image available here and send it to annotation. This will enable you to make additional annotations on images where Studio has not learned properly what you expect from it.' />
            </Text>
          </>,
          target: () => document.getElementById('image-list') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'validationPageUserHasReadVisualizeResultsOpacity',
        onboardingTaskCompletion: OnboardingTaskType.PublicValidationPageUserHasReadVisualizeResultsOpacity,
        step: {
          title: <FormattedMessage id='tutorial.validate-editor.visualize-results-opacity.title' defaultMessage='Vizualize Results Opacity' />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial.validate-editor.visualize-results-opacity.content-1' defaultMessage='Use the opacity slider and show/hide button to switch between the image and prediction.' />
            </Text>
          </>,
          target: () => document.getElementById('prediction-opacity') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'validationPageUserHasReadDownloadPlugin',
        onboardingTaskCompletion: OnboardingTaskType.PublicValidationPageUserHasReadDownloadPlugin,
        step: {
          title: <FormattedMessage id='tutorial.validate-editor.plugin.title' defaultMessage='Download' />,
          description: <Text>
            <FormattedMessage
              id='tutorial.validate-editor.plugin.content-1'
              defaultMessage="When you're satisfied with the results, click on the download button to grab your {visionDescriptionUrl} plugin. Use Vision paired with the plugin to produce in depth analysis."
              values={{
                visionDescriptionUrl: <Link to={'https://clemex.com/image-analysis-solutions/image-analysis/'} target='_blank'>
                  <FormattedMessage id='tutorial-tour.project-list.welcome.content-5.url' defaultMessage={'Clemex Vision'} />
                </Link>,
              }} />,
          </Text>,
          target: () => document.getElementById('download-button') as unknown as HTMLElement,
        },
      },
    ]
  }, [onboardingTasks])

  return <TutorialTour
    steps={validationSteps}
    onboardingTourContext={onboardingTourContext}
  />
}
