import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style, { type StyleFunction } from 'ol/style/Style'
import { type LineString, Point } from 'ol/geom'
import Text from 'ol/style/Text'
import { measureTextWidth } from 'ol/render/canvas'
import { TextPositionToShapeValues, type ModifyInteractionStyle, buildModifyInteractionStyle, FeatureType, type ShapeStyle, DEFAULT_SHAPE_STYLE, FEATURE_TYPE } from './common'

export interface LineGeometryProperties {
  geometryType: 'LINE'
  pinnedResolution: number
}

export const buildPerimeterStyle = (
  selection: Set<string>,
  pixelSizeCalibration: number | undefined,
  style: ShapeStyle = DEFAULT_SHAPE_STYLE,
): StyleFunction => {
  return (feature, resolution: number) => {
    const geometry = feature.getGeometry()
    if (geometry === undefined) {
      return []
    }
    const type = geometry.getType()
    if (type !== 'LineString') {
      return []
    }

    const { pinnedResolution } = geometry.getProperties() as LineGeometryProperties

    const lineStringGeom = geometry as LineString
    const perimeters = lineStringGeom.getLength()

    const coordinates = lineStringGeom.getCoordinates()
    let top = coordinates[0]
    let left = coordinates[0]
    let bottom = coordinates[0]
    let right = coordinates[0]
    for (const point of coordinates) {
      if (point[0] < left[0]) {
        left = point
      }
      if (point[0] > right[0]) {
        right = point
      }
      if (point[1] < bottom[1]) {
        bottom = point
      }
      if (point[1] > top[1]) {
        top = point
      }
    }
    const font = `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`
    const perimeterMeasurementText = pixelSizeCalibration === undefined
      ? `${perimeters.toFixed(1)} px`
      : `${(perimeters * pixelSizeCalibration).toFixed(2)} µm`
    const measurementTexts = [
      perimeterMeasurementText,
    ]
    const measurementTextsWidth = Math.max(...measurementTexts.map((measurementText) => measureTextWidth(font, measurementText))) + style.measurementTextFontStrokeWidth * 2
    const measurementTextHeight = style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2
    const measurementTextsHeight = (measurementTextHeight * measurementTexts.length) + (style.measurementTextFontLineHeight * (measurementTexts.length - 1))

    const measurementTextsBoxWidth = measurementTextsWidth + style.measurementTextBackgroundPadding * 2
    const measurementTextsBoxHeight = measurementTextsHeight +
      style.measurementTextFontStrokeWidth * 2 +
      style.measurementTextBackgroundPadding * 2

    const textOffsetX = {
      [TextPositionToShapeValues.TOP]: 0,
      [TextPositionToShapeValues.BOTTOM]: 0,
      [TextPositionToShapeValues.LEFT]: -((measurementTextsBoxWidth / 2) + style.measurementTextBackgroundDistanceShape),
      [TextPositionToShapeValues.RIGHT]: (measurementTextsBoxWidth / 2) + style.measurementTextBackgroundDistanceShape,
    }[style.textPositionToShape]

    const textOffsetY = {
      [TextPositionToShapeValues.TOP]: -((measurementTextsBoxHeight / 2) + style.measurementTextBackgroundDistanceShape),
      [TextPositionToShapeValues.BOTTOM]: (measurementTextsBoxHeight / 2) + style.measurementTextBackgroundDistanceShape,
      [TextPositionToShapeValues.LEFT]: 0,
      [TextPositionToShapeValues.RIGHT]: 0,
    }[style.textPositionToShape]

    const displaySide = {
      [TextPositionToShapeValues.TOP]: top,
      [TextPositionToShapeValues.BOTTOM]: bottom,
      [TextPositionToShapeValues.LEFT]: left,
      [TextPositionToShapeValues.RIGHT]: right,
    }[style.textPositionToShape]
    const selected = selection.has(feature.getProperties().id as string)

    const styles = []
    styles.push(
      new Style({
        stroke: new Stroke({
          color: selected ? style.mainSelectedColor : style.mainColor,
          width: style.strokeWidth,
        }),
        zIndex: style.zIndexOffSet + (selected ? style.zIndexSelectionOffset : 0),
      }),
    )

    if (feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_PERIMETER) {
      styles.push(
        new Style({
          text: new Text({
            text: measurementTexts.join('\n'),
            font,
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
            backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
            fill: new Fill({
              color: style.measurementTextFontFillColor,
            }),
            stroke: new Stroke({
              color: style.measurementTextFontStrokeColor,
              width: style.measurementTextFontStrokeWidth,
            }),
            offsetX: textOffsetX * pinnedResolution / resolution,
            offsetY: textOffsetY * pinnedResolution / resolution,
            scale: pinnedResolution / resolution,
          }),
          geometry: () => {
            return new Point(displaySide)
          },
          zIndex: style.zIndexOffSet + 2 + (selected ? style.zIndexSelectionOffset : 0),
        }),
      )
    }

    return styles
  }
}

export const buildPerimeterInteractionStyle: (style: ModifyInteractionStyle) => StyleFunction = buildModifyInteractionStyle([FeatureType.DIRECT_MEASURE_PERIMETER])
