import { Flex, Typography } from 'antd'
import type { PropsWithChildren } from 'react'
import commonStyles from './styles/common.module.scss'
import clsx from 'clsx'

type SettingRowProps = PropsWithChildren<{
  title?: React.ReactNode
  className?: string
}>

const { Title } = Typography

export const SettingRow: React.FC<SettingRowProps> = (props) => {
  return <>
    <Flex vertical flex={1} className={clsx(commonStyles.settingRowContainer, props.className)}>
      <Flex justify='space-between' align='center' gap={16} >
        { props.title !== undefined && <Title level={4} className={commonStyles.settingsItemTitle}>
          {props.title}
        </Title>
        }
        {props.children}
      </Flex>
    </Flex>
  </>
}

type SettingSectionProps = PropsWithChildren<{
  title?: React.ReactNode
  className?: string
}>

export const SettingsSection: React.FC<SettingSectionProps> = (props) => {
  return <Flex className={clsx(commonStyles.settingsSection, props.className)} vertical>
    {props.title !== undefined &&
      // Do not remove the right-panel-setting-section-hidden class, it is used to hide the title in the right panel
      // It is styled in the right panel scss file (I know it's not the best, but it would require a lot of changes to do it properly)
      // Since this component is shared between the right panel and the algo setting modal and the modal needs the title.
      <Title level={3} className={clsx(commonStyles.settingsSectionTitle, 'right-panel-setting-section-hidden')}>
        {props.title}
      </Title>
    }
    {props.children}
  </Flex>
}
