/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bbox } from './Bbox';
import {
    BboxFromJSON,
    BboxFromJSONTyped,
    BboxToJSON,
} from './Bbox';
import type { CoordinatesInner } from './CoordinatesInner';
import {
    CoordinatesInnerFromJSON,
    CoordinatesInnerFromJSONTyped,
    CoordinatesInnerToJSON,
} from './CoordinatesInner';

/**
 * Polygon Model
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {Bbox}
     * @memberof Polygon
     */
    bbox?: Bbox | null;
    /**
     * 
     * @type {string}
     * @memberof Polygon
     */
    type: PolygonTypeEnum;
    /**
     * 
     * @type {Array<Array<CoordinatesInner>>}
     * @memberof Polygon
     */
    coordinates: Array<Array<CoordinatesInner>>;
}


/**
 * @export
 */
export const PolygonTypeEnum = {
    Polygon: 'Polygon'
} as const;
export type PolygonTypeEnum = typeof PolygonTypeEnum[keyof typeof PolygonTypeEnum];


/**
 * Check if a given object implements the Polygon interface.
 */
export function instanceOfPolygon(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "coordinates" in value;

    return isInstance;
}

export function PolygonFromJSON(json: any): Polygon {
    return PolygonFromJSONTyped(json, false);
}

export function PolygonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Polygon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bbox': !exists(json, 'bbox') ? undefined : BboxFromJSON(json['bbox']),
        'type': json['type'],
        'coordinates': json['coordinates'],
    };
}

export function PolygonToJSON(value?: Polygon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bbox': BboxToJSON(value.bbox),
        'type': value.type,
        'coordinates': value.coordinates,
    };
}

