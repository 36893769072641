import { LineString, type Point, Polygon } from 'ol/geom'
import Style, { type StyleFunction } from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import type Feature from 'ol/Feature'
import { type FeatureLike } from 'ol/Feature'
import Fill from 'ol/style/Fill'
import { type Collection } from 'ol'
import { DEFAULT_SHAPE_STYLE, type ShapeStyle } from './common'

export interface ArrowGeometryProperties {
  geometryType: 'ARROW'
  showLeftArrow: boolean
  showRightArrow: boolean
  pinnedResolution: number
}
export const DEFAULT_ARROW_GEOMETRY_PROPERTIES: ArrowGeometryProperties = {
  geometryType: 'ARROW',
  showLeftArrow: false,
  showRightArrow: true,
  pinnedResolution: 1,
}

export const buildArrowStyle = (
  selection: Set<string>,
  style: ShapeStyle = DEFAULT_SHAPE_STYLE,
): StyleFunction => {
  return (feature: FeatureLike) => {
    const geom = feature.getGeometry()

    if (geom?.getType() !== 'LineString') {
      console.error(`Unexpected geometry type ${geom?.getType()}`)
      return []
    }

    const { showLeftArrow, showRightArrow, pinnedResolution } = geom.getProperties() as ArrowGeometryProperties

    const lineStringCoordinates = (geom as LineString).getCoordinates()
    if (lineStringCoordinates.length !== 2) {
      return []
    }
    const start = lineStringCoordinates[0] as [number, number]
    const end = lineStringCoordinates[1] as [number, number]

    const [x1, y1] = start
    const [x2, y2] = end
    const mainLineLenght = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
    const arrow = new Polygon([[[0, 0], [style.arrowWidth * pinnedResolution, -style.arrowLength * pinnedResolution], [-style.arrowWidth * pinnedResolution, -style.arrowLength * pinnedResolution], [0, 0]]])
    // If the distance between the two points is too small, we scale down the arrows
    // The space between the two arrows is 0.8 times the arrow length
    // The total length (arrows + spacing) should be at least 2.8 times the arrow length
    if (style.arrowLength * 2.8 > mainLineLenght * pinnedResolution) {
      const scale = (mainLineLenght * pinnedResolution) / (2.8 * style.arrowLength)
      arrow.scale(scale, scale, [0, 0])
    }
    const mainLine: LineString = new LineString([start, end])
    const selected = selection.has(feature.getProperties().id as string)
    const mainStroke = new Stroke({
      color: selected ? style.mainSelectedColor : style.mainColor,
      width: style.measuringLineThickness,
    })
    const fill = new Fill({
      color: selected ? style.mainSelectedColor : style.mainColor,
    })

    const styles = []
    styles.push(
      // main line
      new Style({
        stroke: mainStroke,
        fill,
        geometry: mainLine,
        zIndex: style.zIndexOffSet + (selected ? style.zIndexSelectionOffset : 0),
      }),
    )

    if (showLeftArrow) {
      const leftArrow = arrow.clone()
      leftArrow.rotate(Math.atan2(y2 - y1, x2 - x1), [0, 0])
      leftArrow.rotate(Math.PI / 2, [0, 0])
      leftArrow.translate(x1, y1)
      styles.push(
        // Left arrow
        new Style({
          fill,
          geometry: leftArrow,
          zIndex: style.zIndexOffSet + 1 + (selected ? style.zIndexSelectionOffset : 0),
        }),
      )
    }
    if (showRightArrow) {
      const rightArrow = arrow.clone()
      rightArrow.rotate(Math.atan2(y1 - y2, x1 - x2), [0, 0])
      rightArrow.rotate(Math.PI / 2, [0, 0])
      rightArrow.translate(x2, y2)
      styles.push(
        // Right arrow
        new Style({
          fill,
          geometry: rightArrow,
          zIndex: style.zIndexOffSet + 1 + (selected ? style.zIndexSelectionOffset : 0),
        }),
      )
    }
    return styles
  }
}

export const buildArrowInteractionStyle = (selection: Collection<Feature>, style: ShapeStyle): StyleFunction => {
  return (_feature: FeatureLike, resolution: number) => {
    const hitPoint = _feature.getGeometry() as Point
    const targetFeature = _feature.get('features')[0] as Feature | undefined

    if (targetFeature === undefined) {
      return undefined
    }
    if (!selection.getArray().some((selectedFeature) => selectedFeature.getProperties().id === targetFeature.getProperties().id)) {
      return undefined
    }

    const geom = targetFeature.getGeometry()
    if (geom !== undefined) {
      if (geom.getType() === 'LineString') {
        const targetLineCoordinates = (geom as LineString).getCoordinates() as [[number, number], [number, number]]
        const hitPointCoordinates = hitPoint.getCoordinates() as [number, number]

        const [start, end] = targetLineCoordinates
        const [x1, y1] = start
        const [x2, y2] = end
        const arrow = new Polygon([[[0, 0], [style.arrowWidth * resolution, -style.arrowLength * resolution], [-style.arrowWidth * resolution, -style.arrowLength * resolution], [0, 0]]])

        const leftArrow = arrow.clone()
        leftArrow.rotate(Math.atan2(y2 - y1, x2 - x1), [0, 0])
        leftArrow.rotate(Math.PI / 2, [0, 0])
        leftArrow.translate(x1, y1)
        const rightArrow = arrow.clone()
        rightArrow.rotate(Math.atan2(y1 - y2, x1 - x2), [0, 0])
        rightArrow.rotate(Math.PI / 2, [0, 0])
        rightArrow.translate(x2, y2)

        if (
          (start[0] === hitPointCoordinates[0] &&
          start[1] === hitPointCoordinates[1]) ||
          (end[0] === hitPointCoordinates[0] &&
          end[1] === hitPointCoordinates[1])
        ) {
          return [
            new Style({
              fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
              }),
              geometry: () => {
                if (start[0] === hitPointCoordinates[0]) {
                  return leftArrow
                } else {
                  return rightArrow
                }
              },
              zIndex: style.zIndexOffSet + 4,
            }),
          ]
        }
      }
    }
  }
}
