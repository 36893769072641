/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PredictionStatus } from './PredictionStatus';
import {
    PredictionStatusFromJSON,
    PredictionStatusFromJSONTyped,
    PredictionStatusToJSON,
} from './PredictionStatus';

/**
 * Project Training Snapshot Predictions Status
 * @export
 * @interface ProjectTrainingSnapshotPredictionsStatus
 */
export interface ProjectTrainingSnapshotPredictionsStatus {
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingSnapshotPredictionsStatus
     */
    projectTrainingSnapshotId: string;
    /**
     * 
     * @type {{ [key: string]: PredictionStatus; }}
     * @memberof ProjectTrainingSnapshotPredictionsStatus
     */
    images: { [key: string]: PredictionStatus; };
}

/**
 * Check if a given object implements the ProjectTrainingSnapshotPredictionsStatus interface.
 */
export function instanceOfProjectTrainingSnapshotPredictionsStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectTrainingSnapshotId" in value;
    isInstance = isInstance && "images" in value;

    return isInstance;
}

export function ProjectTrainingSnapshotPredictionsStatusFromJSON(json: any): ProjectTrainingSnapshotPredictionsStatus {
    return ProjectTrainingSnapshotPredictionsStatusFromJSONTyped(json, false);
}

export function ProjectTrainingSnapshotPredictionsStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingSnapshotPredictionsStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectTrainingSnapshotId': json['project_training_snapshot_id'],
        'images': (mapValues(json['images'], PredictionStatusFromJSON)),
    };
}

export function ProjectTrainingSnapshotPredictionsStatusToJSON(value?: ProjectTrainingSnapshotPredictionsStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_training_snapshot_id': value.projectTrainingSnapshotId,
        'images': (mapValues(value.images, PredictionStatusToJSON)),
    };
}

