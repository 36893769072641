import type * as React from 'react'
import styles from './styles/uploading-progression.module.scss'
import { type RcFile } from 'rc-upload/lib/interface'
import { FileImageOutlined } from '@ant-design/icons'
import { Progress, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'

const { Text } = Typography

interface UploadingProgressionComponentProps {
  uploadingData: [string, number, RcFile][]
}
export const UploadingProgressionComponent: React.FC<UploadingProgressionComponentProps> = (props) => {
  const { uploadingData } = props
  if (uploadingData.length === 0) {
    return null
  }

  return <div className={styles.uploading}>
    <div>
      <FileImageOutlined className={styles.placeholder} />
      <Text className={styles.uploadingText}>
        <FormattedMessage id="editor.uploading.label" defaultMessage={'{nbImage} image(s) are uploading ...'} values={{ nbImage: uploadingData.length }}/>
      </Text>
      <div className={styles.uploadsProgressionContainer}>
        {
          uploadingData.map(([, progress, file], index) => {
            return <div key={index} className={styles.uploadProgressionContainer}>
              <div className={styles.uploadFileName}>
                {file.name}
              </div>
              <Progress percent={Math.round(progress)} />
            </div>
          })
        }
      </div>
    </div>
  </div>
}
