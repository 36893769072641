import { Modal } from 'antd'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import type { DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable'
import Draggable from 'react-draggable'

interface DraggableModalProps {
  isOpen?: boolean
  onClose?: () => void
  children?: React.ReactNode
  className?: string
  title: React.ReactNode
}
export const DraggableModal: React.FC<DraggableModalProps> = ({
  children,
  className,
  title,
  isOpen: _isOpen,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(_isOpen ?? false)

  useEffect(() => {
    if (_isOpen !== undefined) {
      setIsOpen(_isOpen)
    }
  }, [_isOpen])

  const closeModal = (): void => {
    setIsOpen(false)
    onClose?.()
  }

  const draggleRef = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)
  const onStart: DraggableEventHandler = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (targetRect === undefined) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  return <Modal
    className={className}
    open={isOpen}
    title={
      <div
        className="grow flex cursor-move"
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false)
          }
        }}
        onMouseOut={() => {
          setDisabled(true)
        }}
      >
        { title }
      </div>
    }
    closable={true}
    onCancel={closeModal}
    footer={null}
    onOk={closeModal}
    mask={false}
    maskClosable={false}
    wrapClassName="pointer-events-none"

    modalRender={(modal) => (
      <Draggable
        disabled={disabled}
        bounds={bounds}
        nodeRef={draggleRef}
        onStart={(event, uiData) => { onStart(event, uiData) }}
      >
        <div ref={draggleRef}>{modal}</div>
      </Draggable>
    )}
  >
    { children }
  </Modal>
}
