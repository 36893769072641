/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ServerEnvironment } from './ServerEnvironment';
import {
    ServerEnvironmentFromJSON,
    ServerEnvironmentFromJSONTyped,
    ServerEnvironmentToJSON,
} from './ServerEnvironment';

/**
 * 
 * @export
 * @interface EnvironmentConfigurationResponse
 */
export interface EnvironmentConfigurationResponse {
    /**
     * 
     * @type {ServerEnvironment}
     * @memberof EnvironmentConfigurationResponse
     */
    environment: ServerEnvironment;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentConfigurationResponse
     */
    logrocketAppId: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentConfigurationResponse
     */
    thumbnailCacheInvalidationKey: string | null;
}

/**
 * Check if a given object implements the EnvironmentConfigurationResponse interface.
 */
export function instanceOfEnvironmentConfigurationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "environment" in value;
    isInstance = isInstance && "logrocketAppId" in value;
    isInstance = isInstance && "thumbnailCacheInvalidationKey" in value;

    return isInstance;
}

export function EnvironmentConfigurationResponseFromJSON(json: any): EnvironmentConfigurationResponse {
    return EnvironmentConfigurationResponseFromJSONTyped(json, false);
}

export function EnvironmentConfigurationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentConfigurationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'environment': ServerEnvironmentFromJSON(json['environment']),
        'logrocketAppId': json['logrocket_app_id'],
        'thumbnailCacheInvalidationKey': json['thumbnail_cache_invalidation_key'],
    };
}

export function EnvironmentConfigurationResponseToJSON(value?: EnvironmentConfigurationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'environment': ServerEnvironmentToJSON(value.environment),
        'logrocket_app_id': value.logrocketAppId,
        'thumbnail_cache_invalidation_key': value.thumbnailCacheInvalidationKey,
    };
}

