/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PosterImageResponse } from './PosterImageResponse';
import {
    PosterImageResponseFromJSON,
    PosterImageResponseFromJSONTyped,
    PosterImageResponseToJSON,
} from './PosterImageResponse';

/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectResponse
     */
    creationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectResponse
     */
    lastModifiedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    description: string;
    /**
     * 
     * @type {PosterImageResponse}
     * @memberof ProjectResponse
     */
    posterImage?: PosterImageResponse | null;
}

/**
 * Check if a given object implements the ProjectResponse interface.
 */
export function instanceOfProjectResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "lastModifiedDate" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function ProjectResponseFromJSON(json: any): ProjectResponse {
    return ProjectResponseFromJSONTyped(json, false);
}

export function ProjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'creationDate': (new Date(json['creation_date'])),
        'lastModifiedDate': (new Date(json['last_modified_date'])),
        'name': json['name'],
        'slug': json['slug'],
        'description': json['description'],
        'posterImage': !exists(json, 'poster_image') ? undefined : PosterImageResponseFromJSON(json['poster_image']),
    };
}

export function ProjectResponseToJSON(value?: ProjectResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creation_date': (value.creationDate.toISOString()),
        'last_modified_date': (value.lastModifiedDate.toISOString()),
        'name': value.name,
        'slug': value.slug,
        'description': value.description,
        'poster_image': PosterImageResponseToJSON(value.posterImage),
    };
}

