/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeepLearningTrainingParametersValueObject } from './DeepLearningTrainingParametersValueObject';
import {
    DeepLearningTrainingParametersValueObjectFromJSON,
    DeepLearningTrainingParametersValueObjectFromJSONTyped,
    DeepLearningTrainingParametersValueObjectToJSON,
} from './DeepLearningTrainingParametersValueObject';
import type { RfAlgorithmTrainingParametersValueObject } from './RfAlgorithmTrainingParametersValueObject';
import {
    RfAlgorithmTrainingParametersValueObjectFromJSON,
    RfAlgorithmTrainingParametersValueObjectFromJSONTyped,
    RfAlgorithmTrainingParametersValueObjectToJSON,
} from './RfAlgorithmTrainingParametersValueObject';

/**
 * 
 * @export
 * @interface TrainingParameters
 */
export interface TrainingParameters {
    /**
     * 
     * @type {number}
     * @memberof TrainingParameters
     */
    sigmaMin: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingParameters
     */
    sigmaMax: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingParameters
     */
    intensity: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingParameters
     */
    texture: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingParameters
     */
    edges: boolean;
    /**
     * The number of epochs to train the model.
     * @type {number}
     * @memberof TrainingParameters
     */
    epochs: number;
}

/**
 * Check if a given object implements the TrainingParameters interface.
 */
export function instanceOfTrainingParameters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sigmaMin" in value;
    isInstance = isInstance && "sigmaMax" in value;
    isInstance = isInstance && "intensity" in value;
    isInstance = isInstance && "texture" in value;
    isInstance = isInstance && "edges" in value;
    isInstance = isInstance && "epochs" in value;

    return isInstance;
}

export function TrainingParametersFromJSON(json: any): TrainingParameters {
    return TrainingParametersFromJSONTyped(json, false);
}

export function TrainingParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sigmaMin': json['sigma_min'],
        'sigmaMax': json['sigma_max'],
        'intensity': json['intensity'],
        'texture': json['texture'],
        'edges': json['edges'],
        'epochs': json['epochs'],
    };
}

export function TrainingParametersToJSON(value?: TrainingParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sigma_min': value.sigmaMin,
        'sigma_max': value.sigmaMax,
        'intensity': value.intensity,
        'texture': value.texture,
        'edges': value.edges,
        'epochs': value.epochs,
    };
}

