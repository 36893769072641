/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TrainingProgressionProgression } from './TrainingProgressionProgression';
import {
    TrainingProgressionProgressionFromJSON,
    TrainingProgressionProgressionFromJSONTyped,
    TrainingProgressionProgressionToJSON,
} from './TrainingProgressionProgression';

/**
 * Training progression.
 * @export
 * @interface TrainingProgression
 */
export interface TrainingProgression {
    /**
     * 
     * @type {TrainingProgressionProgression}
     * @memberof TrainingProgression
     */
    progression: TrainingProgressionProgression | null;
}

/**
 * Check if a given object implements the TrainingProgression interface.
 */
export function instanceOfTrainingProgression(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "progression" in value;

    return isInstance;
}

export function TrainingProgressionFromJSON(json: any): TrainingProgression {
    return TrainingProgressionFromJSONTyped(json, false);
}

export function TrainingProgressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingProgression {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'progression': TrainingProgressionProgressionFromJSON(json['progression']),
    };
}

export function TrainingProgressionToJSON(value?: TrainingProgression | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'progression': TrainingProgressionProgressionToJSON(value.progression),
    };
}

