/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum describing the status of a plugin for a project for an user
 * @export
 */
export const PluginStatus = {
    Available: 'AVAILABLE',
    NotTrainedYet: 'NOT_TRAINED_YET',
    MissingDownloadPermission: 'MISSING_DOWNLOAD_PERMISSION',
    AlgorithmIsNotAvailable: 'ALGORITHM_IS_NOT_AVAILABLE'
} as const;
export type PluginStatus = typeof PluginStatus[keyof typeof PluginStatus];


export function PluginStatusFromJSON(json: any): PluginStatus {
    return PluginStatusFromJSONTyped(json, false);
}

export function PluginStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PluginStatus {
    return json as PluginStatus;
}

export function PluginStatusToJSON(value?: PluginStatus | null): any {
    return value as any;
}

