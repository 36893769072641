import * as React from 'react'
import styles from './styles/rf-page.module.scss'
import { Layout } from 'antd'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

interface RfPageProps {
  title?: string
  background?: boolean
  backgroundOnTopFooter?: boolean
  children: React.ReactNode
  className?: string
}

export const RFPage: React.FC<RfPageProps> = (props) => {
  const intl = useIntl()
  const mainTitle = intl.formatMessage({
    id: 'page.title.default',
    defaultMessage: 'Clemex Studio',
  })
  React.useEffect(() => {
    document.title = props.title === undefined ? mainTitle : `${props.title} - ${mainTitle}`
  }, [mainTitle, props.title])

  const [backgroundImg, setBackgroundImg] = React.useState(styles.deltaFerrite)

  React.useEffect(() => {
    const availableImages = [styles.deltaFerrite, styles.austeniticFerritic]
    const randomIndex = Math.floor(Math.random() * availableImages.length)
    setBackgroundImg(
      availableImages[randomIndex],
    )
  }, [])

  return (
    <Layout className={clsx(styles.layout, backgroundImg, { [styles.background]: props.background }, { [styles.backgroundOnTopFooter]: props.backgroundOnTopFooter }, props.className)}>
      {props.children}
    </Layout>
  )
}
