import type * as React from 'react'
import { RFPage, AuthenticationHeader, RFContent } from '@components/layout'
import { Button, Result } from 'antd'
import { WebUIRoutes } from '@app/routes'

import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router'

export const ErrorPage: React.FC = () => {
  const navigate = useNavigate()
  const onClick = (): void => {
    navigate(WebUIRoutes.projectList().path)
  }

  return <RFPage>
    <AuthenticationHeader/>
    <RFContent>
      <Result
        status="500"
        title="500"
        subTitle={<FormattedMessage id="500.title" defaultMessage={'We\'re sorry!  Something went wrong.'} />}
        extra={<Button type="primary" onClick={onClick}><FormattedMessage id="500.button" defaultMessage={'Back Home'} /></Button>}
      />
    </RFContent>
  </RFPage>
}
