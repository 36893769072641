export const swapPointCoordinates = (coordinates: number[]): number[] => {
  // Swap the y coordinates because OpenLayers use the bottom left corner as origin and Clemex Studio use the top left corner as origin.
  return coordinates.map((x, index) => (index === 1) ? -x : x)
}
export const swapLineStringCoordinates = (coordinates: number[][]): number[][] => {
  return coordinates.map(swapPointCoordinates)
}
export const swapPolygonCoordinates = (coordinates: number[][][]): number[][][] => {
  return coordinates.map(swapLineStringCoordinates)
}
