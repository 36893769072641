import * as React from 'react'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/editor-tools.module.scss'
import { IconMenu } from '@components/common/icon-menu'
import { EVENTS_ID, Tool, ctrlModifier } from '@app/constants'
import { useStageZoom } from '@app/pages/editor-page/hooks/stage'
import { ProjectDatasetContext } from '@app/api/openapi'
import { faEraser as EraseIcon } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type IconName } from '@fortawesome/fontawesome-svg-core'
import DrawIcon from '@material-design-icons/svg/filled/brush.svg'
import PanIcon from '@material-design-icons/svg/filled/pan_tool.svg'
import RedoIcon from '@material-design-icons/svg/filled/redo.svg'
import UndoIcon from '@material-design-icons/svg/filled/undo.svg'
import ZoomInIcon from '@material-design-icons/svg/filled/zoom_in.svg'
import ZoomOutIcon from '@material-design-icons/svg/filled/zoom_out.svg'
import ZoomResetIcon from '@material-design-icons/svg/filled/crop_free.svg'
import SmartAnnotationOn from '@material-design-icons/svg/filled/auto_fix_high.svg'
import ImageFilter from '@material-design-icons/svg/filled/tune.svg'
import PixelGridIcon from '@material-design-icons/svg/filled/grid_4x4.svg'
import SelectIcon from '@material-design-icons/svg/outlined/ads_click.svg'
import { useEditorStore } from '@app/stores/editor'
import { useImageFilterStore } from '@app/stores/image-filter'
import { useDatasetContext, useSelectedImage } from '@app/pages/editor-page/hooks/editor-page'

const { Title } = Typography

export const EditorTools: React.FC = () => {
  const stageZoom = useStageZoom()
  const datasetContext = useDatasetContext()
  const { data: selectedImage } = useSelectedImage()
  const selectedTool = useEditorStore((store) => store.selectedTool)
  const disabledTools = useEditorStore((store) => store.disabledTools)
  const [isImageFilterOpen, setImageFilterOpen] = useImageFilterStore((store) => [store.isOpen, store.setIsOpen])
  const [isPixelGridVisible, setPixelGridVisibility] = useEditorStore((store) => [store.isPixelGridVisible, store.setPixelGridVisibility])

  const controlZoomCanvasToolItems = React.useMemo(() => {
    const zoomIcons = [
      {
        icon: <ZoomInIcon />,
        selected: false,
        disabled: !(selectedImage !== undefined && stageZoom.canZoomIn()),
        action: () => window.dispatchEvent(new CustomEvent(EVENTS_ID.ZOOM_IN)),
        helpTitle: <FormattedMessage id="tools.view.ZoomIn" defaultMessage='Zoom in ({ctrlModifier}+=)' values={{ ctrlModifier }} />,
      },
      {
        icon: <ZoomResetIcon />,
        selected: false,
        disabled: !(stageZoom.canZoomReset()),
        action: () => window.dispatchEvent(new CustomEvent(EVENTS_ID.ZOOM_TO_FIT)),
        helpTitle: <FormattedMessage id="tools.view.zoomReset" defaultMessage='Zoom reset ({ctrlModifier}+0)' values={{ ctrlModifier }} />,
      },
      {
        icon: <ZoomOutIcon />,
        selected: false,
        disabled: !(selectedImage !== undefined && stageZoom.canZoomOut()),
        action: () => window.dispatchEvent(new CustomEvent(EVENTS_ID.ZOOM_OUT)),
        helpTitle: <FormattedMessage id="tools.view.zoomOut" defaultMessage='Zoom out ({ctrlModifier}+-)' values={{ ctrlModifier }} />,
      },
    ]

    return zoomIcons
  }, [selectedImage, stageZoom])

  const controlPanCanvasToolItems = React.useMemo(() => {
    const panTool = [{
      icon: <PanIcon />,
      selected: selectedTool === Tool.PAN,
      disabled: disabledTools.includes(Tool.PAN),
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_PAN_TOOL)) },
      helpTitle: <FormattedMessage id="tools.view.pan" defaultMessage={'Pan (Hold space and drag) or (P)'} />,
    }]

    const selectTool = [{
      icon: <SelectIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.SELECT,
      disabled: disabledTools.includes(Tool.SELECT),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_SELECTION_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.select" defaultMessage={'Selection tools'} />,
    }]
    panTool.push(...selectTool)

    // XXX: Add the image filter tool after the pan tool
    //      It might not be the best location and make the tool semantic inacurate
    //      But it is a quick way to add the tool without reorganizing the whole tool system
    const imageFilterTool = [{
      icon: <ImageFilter />,
      selected: isImageFilterOpen,
      disabled: false,
      action: () => { setImageFilterOpen(!isImageFilterOpen) },
      helpTitle: <FormattedMessage id="tools.view.image-filter" defaultMessage={'Image Filter'} />,
    }]
    panTool.push(...imageFilterTool)

    // XXX: Add a tool to toggle the pixel grid
    //      It might not be the best location and make the tool semantic inacurate
    //      But it is a quick way to add the tool without reorganizing the whole tool system
    const toggleGridTool = [{
      icon: <PixelGridIcon />,
      selected: isPixelGridVisible,
      disabled: false,
      action: () => { setPixelGridVisibility(!isPixelGridVisible) },
      helpTitle: isPixelGridVisible
        ? <FormattedMessage id="tools.view.pixel-grid-toggle.show" defaultMessage={'Show Pixel grid'} />
        : <FormattedMessage id="tools.view.pixel-grid-toggle.hide" defaultMessage={'Hide Pixel grid'} />,
    }]
    panTool.push(...toggleGridTool)

    return panTool
  }, [selectedTool, disabledTools, isImageFilterOpen, isPixelGridVisible, setImageFilterOpen, setPixelGridVisibility])

  const annotationsToolItems = React.useMemo(() => {
    const annotationOnlyTools = [{
      icon: <DrawIcon />,
      selected: selectedTool === Tool.BRUSH,
      disabled: disabledTools.includes(Tool.BRUSH),
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_BRUSH_TOOL)) },
      helpTitle: <FormattedMessage id="tools.annotations.brush" defaultMessage={'Brush (B)'} />,
    },
    {
      icon: <FontAwesomeIcon icon={EraseIcon as unknown as IconName} size="lg" />,
      selected: selectedTool === Tool.ERASER,
      disabled: disabledTools.includes(Tool.ERASER),
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ERASER_TOOL)) },
      helpTitle: <FormattedMessage id="tools.annotations.eraser" defaultMessage={'Eraser (E)'} />,
    },
    ]

    annotationOnlyTools.push({
      icon: <SmartAnnotationOn />,
      selected: selectedTool === Tool.SMART_ANNOTATION_ON,
      disabled: disabledTools.includes(Tool.SMART_ANNOTATION_ON),
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_SMART_ANNOTATION_ON)) },
      helpTitle: <FormattedMessage id="tools.annotations.smart-annotation.magic-wand.on" defaultMessage={'Magic Wand (w)'} />,
    })

    return annotationOnlyTools
  }, [selectedTool, disabledTools])

  const undoRedoToolItems = React.useMemo(() => {
    const undoRedoIcons = [{
      icon: <UndoIcon />,
      selected: false,
      disabled: false,
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UNDO)) },
      helpTitle: <FormattedMessage id="tools.annotations.undo" defaultMessage='Undo ({ctrlModifier}+Z)' values={{ ctrlModifier }} />,
    },
    {
      icon: <RedoIcon />,
      selected: false,
      disabled: false,
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_REDO)) },
      helpTitle: <FormattedMessage id="tools.annotations.redo" defaultMessage='Redo ({ctrlModifier}+Shift+Z)' values={{ ctrlModifier }} />,
    }]

    return undoRedoIcons
  }, [])

  return <div id='tools-editor' className={styles.editorTools}>
    <Title level={5} className={styles.titleSection}>
      { datasetContext === ProjectDatasetContext.Training &&
        <div>
          <FormattedMessage id="tools.annotation.title" defaultMessage={'Annotation'} />
        </div>
      }
      <div>
        <FormattedMessage id="tools.annotation.control.title" defaultMessage={'Control'} />
      </div>
    </Title>
    <div className={styles.container}>
      { datasetContext === ProjectDatasetContext.Training &&
        <div className={styles.groupIcons}>
          <IconMenu
            items={annotationsToolItems}
          />
          <IconMenu
            items={undoRedoToolItems}
            vertical={false}
          />
        </div>
      }
      <div className={styles.groupIcons}>
        <IconMenu
          items={controlZoomCanvasToolItems}
        />
        <IconMenu
          items={controlPanCanvasToolItems}
        />
      </div>
    </div>
  </div >
}

export default EditorTools
