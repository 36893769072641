import { LineString } from 'ol/geom'
import { Draw, Modify } from 'ol/interaction'
import { type MapBrowserEvent, type Feature, type Collection } from 'ol'
import EventType from 'ol/events/EventType'
import { type DrawEvent } from 'ol/interaction/Draw'
import type BaseEvent from 'ol/events/Event'
import { FeatureType, FEATURE_TYPE, type LineFeatureType } from '../shapes/common'
import { type ChangePatch } from './common'
import { v4 as uuidV4 } from 'uuid'
import { type ModifyEvent } from 'ol/interaction/Modify'
import { type StyleFunction } from 'ol/style/Style'
import { primaryAction } from 'ol/events/condition'
import { type Layer } from 'ol/layer'
import { type LineGeometryProperties } from '../shapes/perimeter'

export class DrawLineInteraction extends Draw {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private _isFreehand: boolean
  private _isInteracting = false
  private readonly _drawingFeatureType: LineFeatureType

  constructor(
    destinationCollection: Collection<Feature<LineString>>,
    style: StyleFunction,
    onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void,
    drawingFeatureType: LineFeatureType,
    freehand = false,
  ) {
    super({
      type: 'LineString',
      minPoints: 3,
      condition: primaryAction,
      freehandCondition: (e) => {
        if (!this._isFreehand) {
          return false
        }
        if (e.originalEvent instanceof MouseEvent) {
          return e.originalEvent.button === -1 || e.originalEvent.button === 0
        }
        return true
      },
      style,
      geometryFunction: (coordinates_, geom) => {
        const coordinates = coordinates_ as [number, number][]
        if (geom === undefined) {
          geom = new LineString(coordinates)
        } else {
          geom.setCoordinates(coordinates)
        }
        geom.setProperties({
          geometryType: 'LINE',
          pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
        } satisfies LineGeometryProperties)
        return geom
      },
    })
    this._onEndInteraction = onEndInteraction
    this._drawingFeatureType = drawingFeatureType
    this._isFreehand = freehand

    this.addEventListener('drawstart', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      const id = uuidV4()
      drawEndEvent.feature.setProperties({
        [FEATURE_TYPE]: this._drawingFeatureType,
        id,
      })
      this._isInteracting = true
    })
    this.addEventListener('drawabort', () => {
      this._isInteracting = false
    })
    this.addEventListener('drawend', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      this._isInteracting = false
      const feature = drawEndEvent.feature as Feature<LineString>
      // Note: The simplify method sometimes removes the properties of the geometry
      const geometryProperties = feature.getGeometry()?.getProperties()
      feature.setGeometry(feature.getGeometry()?.simplify(1) as LineString)
      if (geometryProperties) {
        feature.getGeometry()?.setProperties(geometryProperties)
      }
      destinationCollection.push(feature)
      this._onEndInteraction({
        add: [{
          id: feature.getProperties().id,
          data: feature,
        }],
      })
    })
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortDrawing()
        return false
      }
      if (key === 'Enter') {
        this.finishDrawing()
        return false
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }

  public readonly setFreehand = (isFreehand: boolean): void => {
    this._isFreehand = isFreehand
  }
}

interface ModifyLineInteractionOptions {
  modifiableFeatures: Collection<Feature>
  layers: Layer[]
  style: StyleFunction
  onStartInteraction: (targetFeatures: Feature<LineString>[]) => void
  onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  pixelTolerance?: number
}
export class ModifyLineInteraction extends Modify {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<LineString>>) => void
  private readonly _layers: Layer[]
  private readonly _pixelTolerance: number

  constructor({
    modifiableFeatures,
    layers,
    style,
    onEndInteraction,
    onStartInteraction,
    pixelTolerance = 8,
  }: ModifyLineInteractionOptions) {
    super({
      features: modifiableFeatures,
      pixelTolerance,
      condition: (e: MapBrowserEvent<UIEvent>) => {
        return primaryAction(e) && this.isCursorAbleToModify(e)
      },
      insertVertexCondition: () => {
        return modifiableFeatures.getArray().some((feature) => {
          return feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_PERIMETER || feature.get(FEATURE_TYPE) === FeatureType.METADATA_ANNOTATION_LINE
        })
      },
      style,
    })
    this._onEndInteraction = onEndInteraction
    this._layers = layers
    this._pixelTolerance = pixelTolerance

    this.on('modifystart', (modifyStartEvent: ModifyEvent) => {
      onStartInteraction(modifyStartEvent.features.getArray() as Feature<LineString>[])
    })

    this.on('modifyend', (modifyEndEvent: ModifyEvent) => {
      this._onEndInteraction({
        update: modifyEndEvent.features.getArray().map((feature) => {
          return {
            id: feature.getProperties().id,
            data: feature as Feature<LineString>,
          }
        }),
      })
    })
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }
    return evt.map.hasFeatureAtPixel(evt.pixel, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layers.includes(layer),
    })
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }
}
