/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RectangleGeometryProperties
 */
export interface RectangleGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof RectangleGeometryProperties
     */
    geometryType: RectangleGeometryPropertiesGeometryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RectangleGeometryProperties
     */
    pinnedResolution: number;
}


/**
 * @export
 */
export const RectangleGeometryPropertiesGeometryTypeEnum = {
    Rectangle: 'RECTANGLE'
} as const;
export type RectangleGeometryPropertiesGeometryTypeEnum = typeof RectangleGeometryPropertiesGeometryTypeEnum[keyof typeof RectangleGeometryPropertiesGeometryTypeEnum];


/**
 * Check if a given object implements the RectangleGeometryProperties interface.
 */
export function instanceOfRectangleGeometryProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "geometryType" in value;
    isInstance = isInstance && "pinnedResolution" in value;

    return isInstance;
}

export function RectangleGeometryPropertiesFromJSON(json: any): RectangleGeometryProperties {
    return RectangleGeometryPropertiesFromJSONTyped(json, false);
}

export function RectangleGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RectangleGeometryProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function RectangleGeometryPropertiesToJSON(value?: RectangleGeometryProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry_type': value.geometryType,
        'pinned_resolution': value.pinnedResolution,
    };
}

