/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectShareDetailsResponse
 */
export interface ProjectShareDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectShareDetailsResponse
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectShareDetailsResponse
     */
    sharedBy: string;
}

/**
 * Check if a given object implements the ProjectShareDetailsResponse interface.
 */
export function instanceOfProjectShareDetailsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectName" in value;
    isInstance = isInstance && "sharedBy" in value;

    return isInstance;
}

export function ProjectShareDetailsResponseFromJSON(json: any): ProjectShareDetailsResponse {
    return ProjectShareDetailsResponseFromJSONTyped(json, false);
}

export function ProjectShareDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectShareDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectName': json['project_name'],
        'sharedBy': json['shared_by'],
    };
}

export function ProjectShareDetailsResponseToJSON(value?: ProjectShareDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_name': value.projectName,
        'shared_by': value.sharedBy,
    };
}

