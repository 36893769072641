import * as React from 'react'
import { EVENTS_ID } from '@app/constants'
import { useProjectImages } from '@app/api/hooks'
import { type ProjectDatasetContext } from '@app/api/openapi'
import { useSelectedImageId, useSetSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'

export const useImageNavigationEventHandler = (projectId?: string, context?: ProjectDatasetContext): void => {
  const selectedImageId = useSelectedImageId()
  const setSelectedImageId = useSetSelectedImageId()

  const { data: images } = useProjectImages(projectId, context)

  React.useEffect(() => {
    const onPrevious = (): void => {
      if (images !== undefined) {
        const selectedImageIndex = images.findIndex(image => image.id === selectedImageId)
        const previousImage = images[selectedImageIndex - 1] ?? images[0]
        setSelectedImageId(previousImage.id)
      }
    }
    const onNext = (): void => {
      if (images !== undefined) {
        const selectedImageIndex = images.findIndex(image => image.id === selectedImageId)
        const nextImage = images[selectedImageIndex + 1] ?? images[images.length - 1]
        setSelectedImageId(nextImage.id)
      }
    }
    window.addEventListener(EVENTS_ID.NAVIGATE_NEXT_IMAGE, onNext, false)
    window.addEventListener(EVENTS_ID.NAVIGATE_PREVIOUS_IMAGE, onPrevious, false)
    return () => {
      window.removeEventListener(EVENTS_ID.NAVIGATE_NEXT_IMAGE, onNext, false)
      window.removeEventListener(EVENTS_ID.NAVIGATE_PREVIOUS_IMAGE, onPrevious, false)
    }
  }, [projectId, context, images, selectedImageId, setSelectedImageId])
}
