import type * as React from 'react'
import { AuthenticationHeader, RFContent, RFPage } from '@components/layout'
import * as RFAPI from '@api/api'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import * as Sentry from '@sentry/react'
import { WelcomeTitle } from '@components/authentication/welcome-title'
import { useIntl } from 'react-intl'
import styles from './styles/register.module.scss'
import { RegisterForm, type RegisterFormFormResult } from '@components/authentication/register-form'
import { useBaseNotificationStore } from '@components/notifications/use-notifications'

export const RegisterPage: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addNotification } = useBaseNotificationStore()

  const handleRegisterSubmit = async (formData: RegisterFormFormResult): Promise<void> => {
    try {
      const response = await RFAPI.register(formData.email, formData.password, formData.firstName, formData.lastName)
      if (response.status === 'FAIL') {
        throw new Error(intl.formatMessage({
          id: 'register.notification.register.failed',
          defaultMessage: 'Failed to register',
        }))
      }
      addNotification({
        type: 'success',
        message: intl.formatMessage({
          id: 'register.notification.register.success.title',
          defaultMessage: 'Successfully registered your account!',
        }),
        description: intl.formatMessage({
          id: 'register.notification.register.success.body',
          defaultMessage: 'Please check your email to activate your account',
        }),
      })
      navigate(WebUIRoutes.basePath().path)
    } catch (error) {
      addNotification({
        type: 'error',
        message: intl.formatMessage({
          id: 'register.notification.register.error.title',
          defaultMessage: 'Failed to register!',
        }),
        description: intl.formatMessage({
          id: 'register.notification.register.error.body',
          defaultMessage: 'Please try again soon.',
        }),
      })
      Sentry.captureException(error)
    }
  }

  return <RFPage background backgroundOnTopFooter className={styles.page}>
    <AuthenticationHeader >
      <WelcomeTitle />
    </AuthenticationHeader>
    <RFContent className={styles.content}>
      <section className={styles.sectionContainer}>
        <div className={styles.formsContainer}>
          <RegisterForm onSubmit={handleRegisterSubmit} />
        </div>
        <div className={styles.imageContainer}>
        </div>
      </section>
    </RFContent>
  </RFPage>
}
