/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureFlagResponse
 */
export interface FeatureFlagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagResponse
     */
    enableLocalUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagResponse
     */
    enableMaintenance: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagResponse
     */
    enableImportProjectExperimentation: boolean;
}

/**
 * Check if a given object implements the FeatureFlagResponse interface.
 */
export function instanceOfFeatureFlagResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enableLocalUser" in value;
    isInstance = isInstance && "enableMaintenance" in value;
    isInstance = isInstance && "enableImportProjectExperimentation" in value;

    return isInstance;
}

export function FeatureFlagResponseFromJSON(json: any): FeatureFlagResponse {
    return FeatureFlagResponseFromJSONTyped(json, false);
}

export function FeatureFlagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureFlagResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enableLocalUser': json['enable_local_user'],
        'enableMaintenance': json['enable_maintenance'],
        'enableImportProjectExperimentation': json['enable_import_project_experimentation'],
    };
}

export function FeatureFlagResponseToJSON(value?: FeatureFlagResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enable_local_user': value.enableLocalUser,
        'enable_maintenance': value.enableMaintenance,
        'enable_import_project_experimentation': value.enableImportProjectExperimentation,
    };
}

