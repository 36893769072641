/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * AlgorithmId represent the type of an algorithm
 * @export
 */
export const AlgorithmId = {
    RfAlgorithmSklearn: 'RF_ALGORITHM_SKLEARN',
    RfAlgorithmCuml: 'RF_ALGORITHM_CUML',
    ClemexNetLiteV1: 'CLEMEX_NET_LITE_V1',
    ClemexNetLiteV2: 'CLEMEX_NET_LITE_V2',
    ClemexGrainsegV3: 'CLEMEX_GRAINSEG_V3',
    ClemexGrainsegV3Aluminum: 'CLEMEX_GRAINSEG_V3_ALUMINUM',
    ClemexMedcleanV1: 'CLEMEX_MEDCLEAN_V1'
} as const;
export type AlgorithmId = typeof AlgorithmId[keyof typeof AlgorithmId];


export function AlgorithmIdFromJSON(json: any): AlgorithmId {
    return AlgorithmIdFromJSONTyped(json, false);
}

export function AlgorithmIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlgorithmId {
    return json as AlgorithmId;
}

export function AlgorithmIdToJSON(value?: AlgorithmId | null): any {
    return value as any;
}

