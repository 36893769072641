import { type ClemexStudioMosaicCanvas, type StudioExtraClassAnnotationProperties } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import { useEffect, useMemo, useState } from 'react'
import { useImageFilterStore } from '@app/stores/image-filter'
import { useEditorStore } from '@app/stores/editor'
import { useGlobalAnnotationOpacity, useGlobalPredictionOpacity, useGlobalPredictionVisibility, useHiddenAnnotationClassIndexes, useHiddenPredictionClassIndexes, useDatasetContext, useProjectId, useSelectedImage, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'
import { useDebouncedCallback } from 'use-debounce'
import { type ClemexMosaicCanvas } from '@clemex/mosaic-canvas'
import { ProjectDatasetContext } from '@app/api/openapi'
import { MAX_CLASS_COUNT } from '@app/constants'

import { useProjectTrainingSnapshot } from '@app/api/hooks'
import { TaskStatus } from '@app/api/openapi/models/TaskStatus'
import { IN_PROGRESS_STATUSES, useImageTilingStatus, usePredictionMaskTilingStatus, useProjectImageStatus } from '@app/api/resource-status'
import { ClemexMosaicImage, ClemexMosaicMask } from '@clemex/mosaic-canvas'
import { uniq } from 'lodash'

class StudioClemexMosaicImage extends ClemexMosaicImage {
  public override getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/images/${this.mosaicId}/tile/${z}/${x}/${y}`
  }
}
interface ImageMetadata {
  mosaicId: string
  x: number
  y: number
  width: number
  height: number
  tileSize?: number
  rotation?: number
}
type StudioClemexMosaicMaskProps = ImageMetadata & { baseURL: string, projectTrainingSnapshotId: string, maskIndex: number }
class StudioClemexMosaicMask extends ClemexMosaicMask {
  private readonly projectTrainingSnapshotId: string
  constructor ({ projectTrainingSnapshotId, ...options }: StudioClemexMosaicMaskProps) {
    super(options)
    this.projectTrainingSnapshotId = projectTrainingSnapshotId
  }

  public override getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/images/${this.mosaicId}/prediction/${this.projectTrainingSnapshotId}/mask/${this.maskIndex}/tile/${z}/${x}/${y}`
  }
}

export interface MosaicConfiguration {
  mosaicImage: StudioClemexMosaicImage
  mosaicMasks: StudioClemexMosaicMask[]
}
interface useMosaicImageType {
  data: StudioClemexMosaicImage | undefined
  isLoading: boolean
  isValidating: boolean
  error: unknown
}
export const useMosaicImage = (projectId: string | undefined): useMosaicImageType => {
  const { data: selectedImage, ...rest } = useSelectedImage()
  const { data: imageMaskTilingStatus, isValidating: isValidatingImageMaskTiling } = useImageTilingStatus(selectedImage?.id, projectId)

  // Use cache to avoid revalidation transient state
  const [mosaicImageCache, setMosaicImageCache] = useState<Record<string, StudioClemexMosaicImage>>({})

  const cacheKey = selectedImage === undefined ? undefined : selectedImage.id

  // Memoize ClemexMosaicImageData instance per imageId
  const mosaicImage = useMemo((): StudioClemexMosaicImage | undefined => {
    if (selectedImage?.id === undefined || cacheKey === undefined) {
      return undefined
    }
    if (mosaicImageCache[cacheKey] !== undefined) {
      return mosaicImageCache[cacheKey]
    }
    const _mosaicImage = (selectedImage?.id === undefined || imageMaskTilingStatus !== TaskStatus.Completed || isValidatingImageMaskTiling)
      ? undefined
      : new StudioClemexMosaicImage({
        mosaicId: selectedImage.id,
        height: selectedImage.height,
        width: selectedImage.width,
        x: 0,
        y: 0,
        baseURL: window.location.origin,
      })
    return _mosaicImage
  }, [selectedImage?.id, selectedImage?.height, selectedImage?.width, cacheKey, mosaicImageCache, imageMaskTilingStatus, isValidatingImageMaskTiling])

  useEffect(() => {
    if (mosaicImage === undefined || cacheKey === undefined) {
      return
    }
    if (mosaicImageCache[cacheKey] === undefined) {
      setMosaicImageCache({
        ...mosaicImageCache,
        [cacheKey]: mosaicImage,
      })
    }
  }, [cacheKey, mosaicImage, mosaicImageCache])

  return {
    ...rest,
    data: mosaicImage,
  }
}

interface useMosaicMasksType {
  data: StudioClemexMosaicMask[] | undefined
  isLoading: boolean
  isValidating: boolean
  error: unknown
}
export const useMosaicMasks = (projectId: string | undefined): useMosaicMasksType => {
  const { data: projectImageInfo, isLoading, isValidating, error } = useSelectedImage()
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: predictionMaskTilingStatus, isValidating: isValidatingPredictionMaskTilingStatus } = usePredictionMaskTilingStatus(projectImageInfo?.id, projectTrainingSnapshot?.projectTrainingSnapshotId)

  const [mosaicMaskCache, setMosaicMaskCache] = useState<Record<string, StudioClemexMosaicMask[]>>({})

  const cacheKey = useMemo(() => {
    if (projectImageInfo === undefined || projectTrainingSnapshot === undefined) {
      return undefined
    }
    return `${projectImageInfo.id}-${projectTrainingSnapshot.projectTrainingSnapshotId}`
  }, [projectImageInfo, projectTrainingSnapshot])

  const mosaicMasks = useMemo((): StudioClemexMosaicMask[] | undefined => {
    if (projectImageInfo?.id === undefined || projectTrainingSnapshot === undefined || cacheKey === undefined) {
      return undefined
    }

    if (mosaicMaskCache[cacheKey] !== undefined) {
      return mosaicMaskCache[cacheKey]
    }

    if (predictionMaskTilingStatus !== TaskStatus.Completed || isValidatingPredictionMaskTilingStatus) {
      return undefined
    }

    const trainingSnapshotColorIndexes = uniq(projectTrainingSnapshot.classAnnotations.map((classAnnotation) => classAnnotation.colorIndex))
    const masks = trainingSnapshotColorIndexes.map((trainingSnapshotColorIndex) => {
      return new StudioClemexMosaicMask({
        mosaicId: projectImageInfo.id,
        height: projectImageInfo.height,
        width: projectImageInfo.width,
        x: 0,
        y: 0,
        maskIndex: trainingSnapshotColorIndex,
        baseURL: window.location.origin,
        projectTrainingSnapshotId: projectTrainingSnapshot.projectTrainingSnapshotId,
      })
    })
    return masks
  }, [cacheKey, isValidatingPredictionMaskTilingStatus, mosaicMaskCache, predictionMaskTilingStatus, projectImageInfo?.height, projectImageInfo?.id, projectImageInfo?.width, projectTrainingSnapshot])

  useEffect(() => {
    if (mosaicMasks === undefined || mosaicMasks.length === 0 || cacheKey === undefined) {
      return
    }
    if (mosaicMaskCache[cacheKey] === undefined) {
      setMosaicMaskCache({
        ...mosaicMaskCache,
        [cacheKey]: mosaicMasks,
      })
    }
  }, [cacheKey, mosaicMasks, mosaicMaskCache])

  return {
    data: mosaicMasks,
    isLoading,
    isValidating,
    error,
  }
}

interface UseCanvasImageResult {
  isIsSelectedImageLoading: boolean
}
export const useCanvasImage = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): UseCanvasImageResult => {
  const projectId = useProjectId()
  const datasetContext = useDatasetContext()
  const selectedImageId = useSelectedImageId()
  const { data: mosaicImage } = useMosaicImage(projectId)
  const { data: mosaicMasks } = useMosaicMasks(projectId)
  const { data: projectImageStatus } = useProjectImageStatus(projectId)
  const [hiddenPredictionClassIndexes] = useHiddenPredictionClassIndexes()
  const [globalPredictionVisibility] = useGlobalPredictionVisibility()
  const [globalPredictionOpacity] = useGlobalPredictionOpacity()
  const [globalAnnotationOpacity] = useGlobalAnnotationOpacity()
  const [hiddenAnnotationClassIndexes] = useHiddenAnnotationClassIndexes()
  const directMeasureAndMetadataAnnotationVisibility = useEditorStore((state) => state.directMeasureAndMetadataAnnotationVisibility)

  const [
    contrast,
    saturation,
    gamma,
    exposure,
  ] = useImageFilterStore((store) => [
    store.contrast,
    store.saturation,
    store.gamma,
    store.exposure,
  ])

  // When mosaicImage is loaded or changed, update the ClemexMosaicCanvas state.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    // Remove image content in case of image is undefined when switching from a valid image
    if (mosaicImage === undefined) {
      clemexMosaicCanvas.resetSources()
      clemexMosaicCanvas.removeImage()
      clemexMosaicCanvas.resetView()
      return
    }
    clemexMosaicCanvas.resetSources()
    clemexMosaicCanvas.setImage(mosaicImage)
    clemexMosaicCanvas.resetView()
  }, [clemexMosaicCanvas, mosaicImage])

  // When mosaicMasks are loaded or changed, update the ClemexMosaicCanvas state.
  useEffect(() => {
    if (mosaicMasks === undefined || mosaicImage === undefined) {
      clemexMosaicCanvas.removeAllMasks()
    } else {
      clemexMosaicCanvas.setMasks(mosaicMasks)
      mosaicMasks?.forEach((mask) => {
        const isColorIndexVisible = !hiddenPredictionClassIndexes.includes(mask.maskIndex)
        clemexMosaicCanvas.setMaskLayerVisibility(mask.maskIndex, isColorIndexVisible)
      })
    }

  }, [clemexMosaicCanvas, globalPredictionVisibility, hiddenPredictionClassIndexes, mosaicImage, mosaicMasks])

  useEffect(() => {
    clemexMosaicCanvas.setImageFilterStyle({
      contrast,
      saturation,
      gamma,
      exposure,
    })
  }, [clemexMosaicCanvas, contrast, exposure, gamma, saturation])

  // When globalClassOpacity change, update the ClemexMosaicCanvas state.
  useEffect(() => {
    clemexMosaicCanvas.setAnnotationsLayerOpacity(globalAnnotationOpacity)
  }, [clemexMosaicCanvas, globalAnnotationOpacity])

  // XXX: Debounce as OpenLayers is not able to quickly change layer opacity.
  const debouncedUpdateMaskLayerGroupOpacityAndVisibility = useDebouncedCallback((_clemexMosaicCanvas: ClemexMosaicCanvas<StudioExtraClassAnnotationProperties>, _globalPredictionVisibility: boolean, _globalPredictionOpacity: number) => {
    _clemexMosaicCanvas.setMaskLayerGroupOpacity(_globalPredictionVisibility ? _globalPredictionOpacity : 0)
  }, 500, { leading: true, trailing: true, maxWait: 300 })

  useEffect(() => {
    // Wait the masks to be loaded before updating the mask layer group opacity and visibility.
    if (mosaicMasks === undefined) {
      return
    }
    debouncedUpdateMaskLayerGroupOpacityAndVisibility(clemexMosaicCanvas, globalPredictionVisibility, globalPredictionOpacity)
  }, [clemexMosaicCanvas, globalPredictionVisibility, globalPredictionOpacity, debouncedUpdateMaskLayerGroupOpacityAndVisibility, mosaicMasks])

  useEffect(() => {
    if (datasetContext === ProjectDatasetContext.Training) {
      for (let colorIndex = 0; colorIndex < MAX_CLASS_COUNT; colorIndex++) {
        const isColorIndexVisible = !hiddenAnnotationClassIndexes.includes(colorIndex)
        clemexMosaicCanvas.setAnnotationsColorIndexVisibility(colorIndex, isColorIndexVisible)
      }
    }
    mosaicMasks?.forEach((mask) => {
      const isColorIndexVisible = !hiddenPredictionClassIndexes.includes(mask.maskIndex)
      clemexMosaicCanvas.setMaskLayerVisibility(mask.maskIndex, isColorIndexVisible)
    })
    clemexMosaicCanvas.render()
  }, [datasetContext, clemexMosaicCanvas, hiddenAnnotationClassIndexes, hiddenPredictionClassIndexes, mosaicMasks])

  // When the directMeasureAndMetadataAnnotationVisibility change, update the ClemexMosaicCanvas state.
  useEffect(() => {
    clemexMosaicCanvas.setDirectMeasureAndMetadataAnnotationLayerVisibility(directMeasureAndMetadataAnnotationVisibility)
  }, [clemexMosaicCanvas, directMeasureAndMetadataAnnotationVisibility])

  const isIsSelectedImageLoading = useMemo(() => {
    if (projectImageStatus?.images === undefined) {
      return false
    }
    if (selectedImageId === undefined) {
      return false
    }
    const imageStatus = projectImageStatus.images[selectedImageId]
    if (imageStatus === undefined) {
      return false
    }
    return IN_PROGRESS_STATUSES.includes(imageStatus.imageTilingStatus)
  }, [projectImageStatus?.images, selectedImageId])

  return { isIsSelectedImageLoading }
}
