/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRegistrationRequest
 */
export interface UserRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    email: string;
}

/**
 * Check if a given object implements the UserRegistrationRequest interface.
 */
export function instanceOfUserRegistrationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function UserRegistrationRequestFromJSON(json: any): UserRegistrationRequest {
    return UserRegistrationRequestFromJSONTyped(json, false);
}

export function UserRegistrationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegistrationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'password': json['password'],
        'email': json['email'],
    };
}

export function UserRegistrationRequestToJSON(value?: UserRegistrationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'password': value.password,
        'email': value.email,
    };
}

