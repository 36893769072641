/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ThicknessMeasureVisualization } from './ThicknessMeasureVisualization';
import {
    ThicknessMeasureVisualizationFromJSON,
    ThicknessMeasureVisualizationFromJSONTyped,
    ThicknessMeasureVisualizationToJSON,
} from './ThicknessMeasureVisualization';

/**
 * 
 * @export
 * @interface ThicknessMeasureImageObject
 */
export interface ThicknessMeasureImageObject {
    /**
     * 
     * @type {number}
     * @memberof ThicknessMeasureImageObject
     */
    thicknessMin: number;
    /**
     * 
     * @type {number}
     * @memberof ThicknessMeasureImageObject
     */
    thicknessMax: number;
    /**
     * 
     * @type {number}
     * @memberof ThicknessMeasureImageObject
     */
    thicknessMedian: number;
    /**
     * 
     * @type {number}
     * @memberof ThicknessMeasureImageObject
     */
    thicknessMean: number;
    /**
     * 
     * @type {ThicknessMeasureVisualization}
     * @memberof ThicknessMeasureImageObject
     */
    visualization: ThicknessMeasureVisualization;
}

/**
 * Check if a given object implements the ThicknessMeasureImageObject interface.
 */
export function instanceOfThicknessMeasureImageObject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "thicknessMin" in value;
    isInstance = isInstance && "thicknessMax" in value;
    isInstance = isInstance && "thicknessMedian" in value;
    isInstance = isInstance && "thicknessMean" in value;
    isInstance = isInstance && "visualization" in value;

    return isInstance;
}

export function ThicknessMeasureImageObjectFromJSON(json: any): ThicknessMeasureImageObject {
    return ThicknessMeasureImageObjectFromJSONTyped(json, false);
}

export function ThicknessMeasureImageObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThicknessMeasureImageObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'thicknessMin': json['thickness_min'],
        'thicknessMax': json['thickness_max'],
        'thicknessMedian': json['thickness_median'],
        'thicknessMean': json['thickness_mean'],
        'visualization': ThicknessMeasureVisualizationFromJSON(json['visualization']),
    };
}

export function ThicknessMeasureImageObjectToJSON(value?: ThicknessMeasureImageObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'thickness_min': value.thicknessMin,
        'thickness_max': value.thicknessMax,
        'thickness_median': value.thicknessMedian,
        'thickness_mean': value.thicknessMean,
        'visualization': ThicknessMeasureVisualizationToJSON(value.visualization),
    };
}

