/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bbox } from './Bbox';
import {
    BboxFromJSON,
    BboxFromJSONTyped,
    BboxToJSON,
} from './Bbox';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * Point Model
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {Bbox}
     * @memberof Point
     */
    bbox?: Bbox | null;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    type: PointTypeEnum;
    /**
     * 
     * @type {Coordinates}
     * @memberof Point
     */
    coordinates: Coordinates;
}


/**
 * @export
 */
export const PointTypeEnum = {
    Point: 'Point'
} as const;
export type PointTypeEnum = typeof PointTypeEnum[keyof typeof PointTypeEnum];


/**
 * Check if a given object implements the Point interface.
 */
export function instanceOfPoint(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "coordinates" in value;

    return isInstance;
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bbox': !exists(json, 'bbox') ? undefined : BboxFromJSON(json['bbox']),
        'type': json['type'],
        'coordinates': CoordinatesFromJSON(json['coordinates']),
    };
}

export function PointToJSON(value?: Point | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bbox': BboxToJSON(value.bbox),
        'type': value.type,
        'coordinates': CoordinatesToJSON(value.coordinates),
    };
}

