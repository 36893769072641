import type * as React from 'react'
import styles from './styles/email-login-form.module.scss'
import { Button, Form, Input, Typography } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import AccountCircle from '@material-design-icons/svg/round/account_circle.svg'
import LockKey from '@material-design-icons/svg/round/key.svg'
import { WebUIRoutes } from '@app/routes'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { AuthenticationFormLayout, type AuthenticationFormLayoutResult } from '@components/authentication/authentication-form-layout'

const { Text } = Typography

export interface EmailLoginFormResult {
  email: string
  password: string
  remember: boolean
}

export const EmailLoginForm: React.FC<AuthenticationFormLayoutResult<EmailLoginFormResult>> = (props) => {
  const intl = useIntl()

  return (
    <AuthenticationFormLayout
      onSubmit={props.onSubmit}
      title={
        <div className={styles.clemexAccount}>
          <img src="/images/login/account_logo.svg"/>
          <Text className={styles.clemexAccountText}>
            <FormattedMessage id="login.clemexAccount" defaultMessage={'Clemex Account'} />
          </Text>
        </div>
      }
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            min: 6,
            max: 64,
            message: <FormattedMessage id="login.form.error.required.email" defaultMessage={'Please input a valid email.'} />,
          }]}
      >
        <Input prefix={<AccountCircle />} placeholder={intl.formatMessage({
          id: 'login.form.email.placeholder',
          defaultMessage: 'Email',
        })} />
      </Form.Item>

      <Form.Item
        name="password"
        tooltip={intl.formatMessage({
          id: 'login.form.tooltip.password',
          defaultMessage: 'Password must contain at least 8 characters.',
        })}
        rules={[
          {
            required: true,
            min: 8,
            message: <FormattedMessage id="login.form.error.required.password" defaultMessage={'Please input a valid password.'} />,
          }]}
      >
        <Input.Password
          prefix={<LockKey className={styles.lock} />}
          placeholder={intl.formatMessage({
            id: 'login.form.placeholder.password',
            defaultMessage: 'Password',
          })}
        />
      </Form.Item>
      <Form.Item>
        <Button className={clsx(styles.floatRight, styles.signInButton)} type="primary" htmlType="submit">
          <FormattedMessage id="login.form.button.sign-in" defaultMessage={'Sign in'} />
        </Button>
      </Form.Item>
      <Form.Item className={clsx(styles.link)}>
        <FormattedMessage id="login.form.links.sign-up.label" defaultMessage={'Don\'t have an account? {signUp}'} values={{
          signUp: <Link to={WebUIRoutes.register().path}>
            <FormattedMessage id="login.form.links.sign-up.url" defaultMessage={'Sign Up'} />
          </Link>,
        }} />
      </Form.Item>
      <Form.Item className={clsx(styles.link)}>
        <FormattedMessage id="login.form.link.forgot-password.label" defaultMessage='Forgot your password? {resetYourPassword}'
          values={{
            resetYourPassword: <Link to={WebUIRoutes.requestResetPassword().path}>
              <FormattedMessage id="login.form.link.forgot-password.url" defaultMessage={'Reset your password'} />
            </Link>,
          }}
        />
      </Form.Item>
      <Form.Item className={styles.link}>
        <FormattedMessage
          id="login.form.link.term-use.label"
          defaultMessage="{termAndConditions} and {privacyPolicy}"
          values={{
            termAndConditions: <a href={'/api/assets/pdf_tou'} target='_blank' rel="noreferrer">
              <FormattedMessage
                id="login.form.link.term-use.url"
                defaultMessage={'Terms and Conditions'}
              />
            </a>,
            privacyPolicy: <a href={'https://clemex.com/privacy-policy/'} target='_blank' rel="noreferrer">
              <FormattedMessage
                id="login.form.link.privacy-policy.url"
                defaultMessage={'Privacy Policy'}
              />
            </a>,
          }}
        />
      </Form.Item>
    </AuthenticationFormLayout>
  )
}
