import { type ClassAnnotationProperties, ClemexMosaicCanvas } from '@clemex/mosaic-canvas'
import { createContext, useContext, useEffect } from 'react'

export const CLEMEX_STUDIO_CANVAS_ZOOM_FACTOR = 1.2

export interface StudioExtraClassAnnotationProperties extends Record<string, unknown> {
  projectId: string
  imageId: string
}
export type StudioClassAnnotationProperties = ClassAnnotationProperties<StudioExtraClassAnnotationProperties>
export type ClemexStudioMosaicCanvas = ClemexMosaicCanvas<StudioExtraClassAnnotationProperties>
const ClemexMosaicCanvasContext = createContext<ClemexStudioMosaicCanvas>(
  new ClemexMosaicCanvas<StudioExtraClassAnnotationProperties>({}, undefined, CLEMEX_STUDIO_CANVAS_ZOOM_FACTOR),
)

export const useClemexMosaicCanvasContext = (): ClemexStudioMosaicCanvas => {
  const clemexMosaicCanvas = useContext(ClemexMosaicCanvasContext)

  useEffect(() => {
    // In development, we want to expose the ClemexMosaicCanvas instance to the window.
    if (import.meta.env.DEV) {
      // Typescript ignore next line
      (window as unknown as { cmc: ClemexStudioMosaicCanvas }).cmc = clemexMosaicCanvas
    }
  }, [clemexMosaicCanvas])

  useEffect(() => {
    clemexMosaicCanvas.setEraseOtherClassesOnOverlap(true)
  }, [clemexMosaicCanvas])

  return clemexMosaicCanvas
}
