import { Layer } from 'ol/layer'
import WebGLVectorLayerRenderer from 'ol/renderer/webgl/VectorLayer'

export class SelectedDetectedObjectWebGLLayer extends Layer {
  public readonly createRenderer = (): WebGLVectorLayerRenderer => {
    return new WebGLVectorLayerRenderer(this, {
      style: [
        {
          'stroke-color': ['*', ['get', 'COLOR'], [255, 255, 255]],
          'stroke-width': 2,
          'stroke-offset': -1,
          'stroke-line-dash-offset': 0,
          'stroke-line-dash': [10, 10],
        },
        {
          'stroke-color': ['*', ['get', 'COLOR'], [0, 0, 0]],
          'stroke-width': 2,
          'stroke-offset': -1,
          'stroke-line-dash-offset': 10,
          'stroke-line-dash': [10, 10],
        },
      ],
      disableHitDetection: false,
    })
  }
}
