import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Input, Button, Popconfirm, notification, Flex, type FormInstance } from 'antd'
import { notifyProjectDeletionError } from '@app/helpers/project-notification-error'
import { type ProjectMetadata, useProjectFromId } from '@app/api/hooks'
import { EVENTS_ID } from '@app/constants'
import { type ProjectResponse } from '@app/api/openapi'
import styles from './styles/project-metadata-settings.module.scss'

interface ProjectMetadataSettingsProps {
  projectId: string
  canArchiveProject?: boolean
  onArchiveCompleted?: () => void
}
export const ProjectMetadataSettings: React.FC<ProjectMetadataSettingsProps> = ({ projectId, canArchiveProject, ...props }) => {
  const intl = useIntl()
  const { data: project, archiveProject, updateProjectMetadata } = useProjectFromId(projectId)
  const [form] = Form.useForm<ProjectMetadata>()
  const [waitingForRequestSave, setWaitingForRequestSave] = React.useState(false)
  const [waitingForRequestArchive, setWaitingForRequestArchive] = React.useState(false)
  const [errorNotificationApi] = notification.useNotification()

  React.useEffect(() => {
    const updatedProject = { name: project?.name ?? '', description: project?.description ?? '' }
    form.setFieldsValue(updatedProject)
  }, [form, project?.description, project?.name, projectId])

  const onArchive = async (): Promise<void> => {
    setWaitingForRequestArchive(true)
    try {
      await archiveProject()
      props.onArchiveCompleted?.()
    } catch (error) {
      notifyProjectDeletionError(intl, errorNotificationApi)
      throw error
    } finally {
      setWaitingForRequestArchive(false)
    }
  }

  // As we are using form.setFieldsValue(updatedProject) to initialize the form
  // The form will be considered as touched even if the user didn't change anything
  // We need to check if the form is untouched to disable the save button when the form is not modified
  const [canSave, setCanSave] = React.useState(false)
  const evalutateCanSave = (form: FormInstance<ProjectMetadata>, project: ProjectResponse): boolean => {
    return form.getFieldValue('name') !== project.name || form.getFieldValue('description') !== project.description
  }
  const onFormChange = React.useCallback(() => {
    if (project === undefined) {
      setCanSave(true)
    } else {
      setCanSave(evalutateCanSave(form, project))
    }
  }, [form, project])

  const onSubmit = async ({
    name,
    description,
  }: ProjectMetadata): Promise<void> => {
    setWaitingForRequestSave(true)
    try {
      const newProjectInfo = await updateProjectMetadata({ name, description })
      if (newProjectInfo !== undefined) {
        if (newProjectInfo.slug !== project?.slug) {
          window.dispatchEvent(new CustomEvent(EVENTS_ID.PROJECT_SLUG_CHANGED, { detail: { slug: newProjectInfo.slug } }))
        }
        setCanSave(evalutateCanSave(form, newProjectInfo))
      }
    } finally {
      setWaitingForRequestSave(false)
    }
  }

  return <Form<ProjectMetadata>
    className={styles.root}
    form={form}
    labelCol={{ span: 6 }}
    wrapperCol={{ span: 18 }}
    onFinish={(values) => { void onSubmit(values) }}
    id="edit-project-form"
    onChange={onFormChange}
  >
    <Form.Item
      label={intl.formatMessage({
        id: 'projects.modal.edit.form.name.label',
        defaultMessage: 'Name',
      })}
      name="name"
      required
      rules={[
        {
          required: true,
          type: 'string',
          min: 1,
          max: 255,
          message: intl.formatMessage({
            id: 'projects.modal.edit.form.name.rule.required',
            defaultMessage:
            'Please give a name to your project (1-255 characters)',
          }),
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label={intl.formatMessage({
        id: 'projects.modal.edit.form.description.label',
        defaultMessage: 'Description',
      })}
      name="description"
      rules={[
        {
          type: 'string',
          max: 1024,
        },
      ]}
    >
      <Input.TextArea rows={6} />
    </Form.Item>
    <Flex justify='flex-end' gap={8} className={styles.actionsContainer}>
      {
        canArchiveProject === true && (
          <Popconfirm
            key="delete-confirmation"
            onConfirm={onArchive}
            title={
              <FormattedMessage
                id="project-list.project.tooltip.delete-project.modal.confirm.content"
                defaultMessage='Delete project "{project}"?'
                values={{ project: project?.name }}
              />
            }
            okText={
              <FormattedMessage
                id="project-list.project.tooltip.delete-project.modal.confirm.ok.text"
                defaultMessage="Yes, I am sure"
              />
            }
          >
            <Button
              key={'archive'}
              loading={waitingForRequestArchive}
              disabled={waitingForRequestArchive || waitingForRequestSave}
              danger
            >
              <FormattedMessage
                id="projects.modal.edit.actions.archive"
                defaultMessage={'Delete'}
              />
            </Button>
          </Popconfirm>
        )
      }
      <Button
        form="edit-project-form"
        key="confirm"
        type="primary"
        loading={waitingForRequestSave}
        htmlType="submit"
        disabled={waitingForRequestSave || !canSave || waitingForRequestArchive}
      >
        <FormattedMessage
          id="projects.modal.edit.actions.ok"
          defaultMessage={'Save'}
        />
      </Button>
    </Flex>
  </Form>
}
