/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArrowGeometryProperties
 */
export interface ArrowGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof ArrowGeometryProperties
     */
    geometryType: ArrowGeometryPropertiesGeometryTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ArrowGeometryProperties
     */
    showLeftArrow: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArrowGeometryProperties
     */
    showRightArrow: boolean;
    /**
     * 
     * @type {number}
     * @memberof ArrowGeometryProperties
     */
    pinnedResolution: number;
}


/**
 * @export
 */
export const ArrowGeometryPropertiesGeometryTypeEnum = {
    Arrow: 'ARROW'
} as const;
export type ArrowGeometryPropertiesGeometryTypeEnum = typeof ArrowGeometryPropertiesGeometryTypeEnum[keyof typeof ArrowGeometryPropertiesGeometryTypeEnum];


/**
 * Check if a given object implements the ArrowGeometryProperties interface.
 */
export function instanceOfArrowGeometryProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "geometryType" in value;
    isInstance = isInstance && "showLeftArrow" in value;
    isInstance = isInstance && "showRightArrow" in value;
    isInstance = isInstance && "pinnedResolution" in value;

    return isInstance;
}

export function ArrowGeometryPropertiesFromJSON(json: any): ArrowGeometryProperties {
    return ArrowGeometryPropertiesFromJSONTyped(json, false);
}

export function ArrowGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArrowGeometryProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'showLeftArrow': json['show_left_arrow'],
        'showRightArrow': json['show_right_arrow'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function ArrowGeometryPropertiesToJSON(value?: ArrowGeometryProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry_type': value.geometryType,
        'show_left_arrow': value.showLeftArrow,
        'show_right_arrow': value.showRightArrow,
        'pinned_resolution': value.pinnedResolution,
    };
}

