import type * as React from 'react'
import styles from './styles/register-form.module.scss'
import { Button, Form, Input } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import AccountCircle from '@material-design-icons/svg/round/account_circle.svg'
import LockKey from '@material-design-icons/svg/round/key.svg'
import EmailIcon from '@material-design-icons/svg/round/email.svg'
import { WebUIRoutes } from '@app/routes'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import {
  AuthenticationFormLayout,
  type AuthenticationFormLayoutResult,
} from '@components/authentication/authentication-form-layout'
import { AuthenticationProviders } from '@components/authentication/authentication-providers'

export interface RegisterFormFormResult {
  email: string
  password: string
  firstName: string
  lastName: string
}

export const RegisterForm: React.FC<
AuthenticationFormLayoutResult<RegisterFormFormResult>
> = (props) => {
  const intl = useIntl()
  return (
    <AuthenticationFormLayout
      onSubmit={props.onSubmit}
      title={
        <div className={styles.headerRegistrationForm}>
          <FormattedMessage id="label.registration.top" defaultMessage="Automate your workflow in minutes!"/>
        </div>
      }
      className={styles.registerFormLayout}
    >
      <AuthenticationProviders className={styles.provider} register />
      <div className={styles.divider}>
        <span className={styles.dividerText}>
          <FormattedMessage id="label.authentication.provider.choice" defaultMessage= "or"/>
        </span>
      </div>
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            type: 'string',
            min: 1,
            max: 64,
            message: (
              <FormattedMessage
                id="register.form.error.required.firstName"
                defaultMessage={'Please input a valid first name.'}
              />
            ),
          },
        ]}
      >
        <Input
          prefix={<AccountCircle />}
          placeholder={intl.formatMessage({
            id: 'register.form.firstname.placeholder',
            defaultMessage: 'First name',
          })}
        />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          {
            required: true,
            type: 'string',
            min: 1,
            max: 64,
            message: (
              <FormattedMessage
                id="register.form.error.required.lastName"
                defaultMessage={'Please input a valid last name.'}
              />
            ),
          },
        ]}
      >
        <Input
          prefix={<AccountCircle />}
          placeholder={intl.formatMessage({
            id: 'register.form.lastName.placeholder',
            defaultMessage: 'Last name',
          })}
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            min: 6,
            max: 64,
            message: (
              <FormattedMessage
                id="register.form.error.required.email"
                defaultMessage={'Please input a valid email.'}
              />
            ),
          },
        ]}
      >
        <Input
          prefix={<EmailIcon />}
          placeholder={intl.formatMessage({
            id: 'register.form.email.placeholder',
            defaultMessage: 'Email',
          })}
        />
      </Form.Item>

      <Form.Item
        name="password"
        tooltip={intl.formatMessage({
          id: 'register.form.tooltip.password',
          defaultMessage: 'Password must contain at least 8 characters.',
        })}
        rules={[
          {
            required: true,
            min: 8,
            message: (
              <FormattedMessage
                id="register.form.error.required.password"
                defaultMessage={'Please input a valid password.'}
              />
            ),
          },
        ]}
      >
        <Input.Password
          prefix={<LockKey className={styles.lock} />}
          placeholder={intl.formatMessage({
            id: 'register.form.placeholder.password',
            defaultMessage: 'Password',
          })}
        />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        dependencies={['password']}
        hasFeedback
        tooltip={intl.formatMessage({
          id: 'register.form.tooltip.password',
          defaultMessage: 'Password must contain at least 8 characters.',
        })}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage
                id="register.form.item.confirmPassword.rule.required.message"
                defaultMessage="Please confirm your password!"
              />
            ),
          },
          ({ getFieldValue }) => ({
            async validator (_, value: string | undefined | null) {
              if (
                value === null ||
                value === undefined ||
                getFieldValue('password') === value
              ) {
                await Promise.resolve()
                return
              }
              return await Promise.reject(
                new Error(intl.formatMessage({
                  id: 'register.form.validation.passwordMismatch',
                  defaultMessage: 'The two passwords that you entered do not match!',
                })),
              )
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockKey className={styles.lock} />}
          placeholder={intl.formatMessage({
            id: 'register.form.placeholder.confirmPassword',
            defaultMessage: 'Confirm password',
          })}
        />
      </Form.Item>
      <Form.Item className={styles.link}>
        <FormattedMessage
          id="register.form.link.term-use.label"
          defaultMessage="I agree with the {termAndConditions} and {privacyPolicy}."
          values={{
            termAndConditions: <a href={'/api/assets/pdf_tou'}>
              <FormattedMessage
                id="register.form.link.term-use.url"
                defaultMessage={'Terms and Conditions'}
              />
            </a>,
            privacyPolicy: <a href={'https://clemex.com/privacy-policy/'}>
              <FormattedMessage
                id="register.form.link.privacy-policy.url"
                defaultMessage={'Privacy Policy'}
              />
            </a>,
          }}
        />
      </Form.Item>
      <Form.Item className={styles.button}>
        <Button
          className={clsx(styles.floatRight, styles.signInButton)}
          type="primary"
          htmlType="submit"
        >
          <FormattedMessage
            id="register.form.button.sign-up"
            defaultMessage={'Sign Up'}
          />
        </Button>
      </Form.Item>
      <Form.Item className={styles.link}>
        <FormattedMessage
          id="register.form.links.sign-in.label"
          defaultMessage={'Already have an account? {signIn}'}
          values={{
            signIn: <Link to={WebUIRoutes.authentication().path}>
              <FormattedMessage
                id="register.form.links.sign-in.url"
                defaultMessage={'Sign In'}
              />
            </Link>,
          }}
        />
      </Form.Item>
      <Form.Item className={styles.link}>
        <FormattedMessage
          id="register.form.link.forgot-password.label"
          defaultMessage={'Forgot your password? {resetYourPassword}'}
          values={{
            resetYourPassword: <Link to={WebUIRoutes.requestResetPassword().path}>
              <FormattedMessage
                id="register.form.link.forgot-password.url"
                defaultMessage={'Reset your password'}
              />
            </Link>,
          }}
        />
      </Form.Item>
    </AuthenticationFormLayout>
  )
}
