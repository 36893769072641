// Enable the maintenance platform in case of associated store status changes
import { useFeatureFlags } from '@app/api/hooks'
import { WebUIRoutes } from '@app/routes'
import React from 'react'
import { matchRoutes, useLocation, useNavigate } from 'react-router'

export const useMaintenance = (): void => {
  // Associate Flags store to be available for any page
  const { data: featureFlags } = useFeatureFlags()
  const navigate = useNavigate()
  const location = useLocation()
  const matches = matchRoutes([WebUIRoutes.maintenance()], location)
  const isOnMaintenancePage = matches !== null && matches.length > 0
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search])
  const byPassValue = searchParams.get('xkcd') ?? ''

  React.useEffect((): void => {
    if (featureFlags?.enableMaintenance === undefined) {
      return
    }
    const redirectionUrl = searchParams.get('redirectionUrl') ?? (
      isOnMaintenancePage
        ? WebUIRoutes.projectList().path
        : location.pathname + location.search
    )
    if (isOnMaintenancePage && !featureFlags.enableMaintenance) {
      navigate(redirectionUrl)
    } else if (featureFlags.enableMaintenance && byPassValue === '') {
      navigate(WebUIRoutes.maintenance().path + '?redirectionUrl=' + redirectionUrl)
    } else if (featureFlags.enableMaintenance && byPassValue !== '' && !redirectionUrl.includes('xkcd')) {
      navigate(redirectionUrl + '?xkcd=' + byPassValue)
    }
  }, [byPassValue, featureFlags?.enableMaintenance, isOnMaintenancePage, location.pathname, location.search, navigate, searchParams])
}
