import { LineString, Point, Polygon } from 'ol/geom'
import Style, { type StyleFunction } from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import { type FeatureLike } from 'ol/Feature'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import { DEFAULT_SHAPE_STYLE, FEATURE_TYPE, FeatureType, type ShapeStyle } from './common'
import { measureTextWidth } from 'ol/render/canvas'

export interface DistanceGeometryProperties {
  geometryType: 'DISTANCE'
  pinnedResolution: number
}

export const buildDirectMeasureDistanceStyle = (
  pixelSizeCalibration: number | undefined,
  selection: Set<string>,
  style: ShapeStyle = DEFAULT_SHAPE_STYLE,
): StyleFunction => {
  return (feature: FeatureLike, resolution: number) => {
    const geom = feature.getGeometry()

    if (geom?.getType() !== 'LineString') {
      console.error(`Unexpected geometry type ${geom?.getType()}`)
      return []
    }
    const { pinnedResolution: _pinnedResolution } = geom.getProperties() as DistanceGeometryProperties
    const pinnedResolution = _pinnedResolution ?? 1

    const lineStringCoordinates = (geom as LineString).getCoordinates()
    if (lineStringCoordinates.length !== 2) {
      return []
    }
    const start = lineStringCoordinates[0] as [number, number]
    const end = lineStringCoordinates[1] as [number, number]

    const [x1, y1] = start
    const [x2, y2] = end
    const mainLineLenght = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))

    const arrow = new Polygon([[[0, 0], [style.arrowWidth * pinnedResolution, -style.arrowLength * pinnedResolution], [-style.arrowWidth * pinnedResolution, -style.arrowLength * pinnedResolution], [0, 0]]])
    // If the distance between the two points is too small, we scale down the arrows
    // The space between the two arrows is 0.8 times the arrow length
    // The total length (arrows + spacing) should be at least 2.8 times the arrow length
    if (style.arrowLength * 2.8 > mainLineLenght / pinnedResolution) {
      const scale = (mainLineLenght / pinnedResolution) / (2.8 * style.arrowLength)
      arrow.scale(scale, scale, [0, 0])
    }
    const leftArrow = arrow.clone()
    leftArrow.rotate(Math.atan2(y2 - y1, x2 - x1), [0, 0])
    leftArrow.rotate(Math.PI / 2, [0, 0])
    leftArrow.translate(x1, y1)
    const rightArrow = arrow.clone()
    rightArrow.rotate(Math.atan2(y1 - y2, x1 - x2), [0, 0])
    rightArrow.rotate(Math.PI / 2, [0, 0])
    rightArrow.translate(x2, y2)

    // The grab handle block is a rectangle aligned with the distance axis
    // The rectangle has fixed dimensions, and is not scaled with the distance
    const grabHandleBlock = new Polygon([[
      [-style.grabHandleBlockWidth, -style.grabHandleBlockHeight],
      [style.grabHandleBlockWidth, -style.grabHandleBlockHeight],
      [style.grabHandleBlockWidth, style.grabHandleBlockHeight],
      [-style.grabHandleBlockWidth, style.grabHandleBlockHeight],
      [-style.grabHandleBlockWidth, -style.grabHandleBlockHeight],
    ]])

    // The grab handle should fit between the two arrows with some margin
    if ((style.arrowLength * 2.8 + style.grabHandleBlockWidth) > mainLineLenght / pinnedResolution) {
      const scale = (mainLineLenght / pinnedResolution) / (2.8 * style.arrowLength + style.grabHandleBlockWidth)
      grabHandleBlock.scale(scale, scale)
    }

    grabHandleBlock.scale(pinnedResolution)
    grabHandleBlock.rotate(Math.atan2(y2 - y1, x2 - x1), [0, 0])
    grabHandleBlock.translate((x1 + x2) / 2, (y1 + y2) / 2)

    const distanceLine: LineString = new LineString([start, end])
    const distanceMeasuringLine = new LineString([
      [0, -0.1 * style.measuringLineLength * pinnedResolution],
      [0, +0.9 * style.measuringLineLength * pinnedResolution],
    ])
    distanceMeasuringLine.rotate(Math.atan2(y2 - y1, x2 - x1), [0, 0])

    const leftDistanceMeasuringLine = distanceMeasuringLine.clone()
    leftDistanceMeasuringLine.translate(x1, y1)
    const rightDistanceMeasuringLine = distanceMeasuringLine.clone()
    rightDistanceMeasuringLine.translate(x2, y2)
    const selected = selection.has(feature.getProperties().id as string)
    const mainStroke = new Stroke({
      color: selected ? style.mainSelectedColor : style.mainColor,
      width: style.measuringLineThickness,
    })
    const measuringStroke = new Stroke({
      color: selected ? style.mainSelectedColor : style.mainColor,
      width: style.measuringLineThickness,
    })
    const fill = new Fill({
      color: selected ? style.mainSelectedColor : style.mainColor,
    })

    const styles = []
    styles.push(
      // main line
      new Style({
        stroke: mainStroke,
        fill,
        geometry: distanceLine,
        zIndex: style.zIndexOffSet + (selected ? style.zIndexSelectionOffset : 0),
      }),
      // Left arrow
      new Style({
        fill,
        geometry: leftArrow,
        zIndex: style.zIndexOffSet + 1 + (selected ? style.zIndexSelectionOffset : 0),
      }),
      // Right arrow
      new Style({
        fill,
        geometry: rightArrow,
        zIndex: style.zIndexOffSet + 1 + (selected ? style.zIndexSelectionOffset : 0),
      }),
    )
    if (feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_DISTANCE) {
      // Add the measuring lines
      styles.push(
        // Left measuring line
        new Style({
          stroke: measuringStroke,
          fill,
          geometry: leftDistanceMeasuringLine,
          zIndex: style.zIndexOffSet + (selected ? style.zIndexSelectionOffset : 0),
        }),
        // Right measuring line
        new Style({
          stroke: measuringStroke,
          fill,
          geometry: rightDistanceMeasuringLine,
          zIndex: style.zIndexOffSet + (selected ? style.zIndexSelectionOffset : 0),
        }),
        // Handle to move as a whole
        new Style({
          stroke: mainStroke,
          fill,
          geometry: grabHandleBlock,
          zIndex: style.zIndexOffSet + 1 + (selected ? style.zIndexSelectionOffset : 0),
        }),
      )
      // Add the measuring text
      const font = `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`
      const measureText = pixelSizeCalibration !== undefined ? `${(mainLineLenght * pixelSizeCalibration).toFixed(2)} µm` : `${mainLineLenght.toFixed(1)} px`

      const measureTextBoxHeight = style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2 + style.measurementTextBackgroundPadding * 2

      const measurementTextsWidth = measureTextWidth(font, measureText) + style.measurementTextFontStrokeWidth * 2
      const measurementTextsBoxWidth = measurementTextsWidth + style.measurementTextBackgroundPadding * 2

      const textPosition = [(x2 + x1) / 2, (y2 + y1) / 2]

      const slopeAngle = Math.atan2(y2 - y1, x2 - x1)
      let textRotationAngle = slopeAngle
      if (Math.abs(Math.cos(slopeAngle)) < Math.cos((Math.PI / 4))) {
        // If the line is almost vertical, we rotate the text to be horizontal
        // If the sinus is negative, we change the rotation direction, to keep the text in the right direction
        textRotationAngle += (Math.PI / 2) * Math.sign(-Math.sin(slopeAngle))
      } else {
        if (Math.cos(slopeAngle) < 0) {
          // If we are not in the previous case, and the cosine is negative
          // We need to rotate the text by PI (180deg) to keep it in the right direction
          textRotationAngle += Math.PI
        }
      }

      let textOffsetX = 0
      let textOffsetY = 0
      if (Math.abs(Math.cos(slopeAngle)) > Math.cos((Math.PI / 4))) {
        textOffsetY = Math.sign(Math.cos(slopeAngle)) * ((measureTextBoxHeight / 2) + style.measurementTextBackgroundDistanceShape)
      } else {
        textOffsetX = Math.sign(Math.sin(slopeAngle)) * ((measurementTextsBoxWidth / 2) + style.measurementTextBackgroundDistanceShape)
      }

      styles.push(
        // Text
        new Style({
          text: new Text({
            text: measureText,
            font,
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            fill: new Fill({ color: style.measurementTextFontFillColor }),
            stroke: new Stroke({ color: style.measurementTextFontStrokeColor, width: style.measurementTextFontStrokeWidth }),
            backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
            backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
            justify: 'center',
            textAlign: 'center',
            rotation: style.textRotateWithShape
              ? -textRotationAngle
              : 0,
            offsetY: textOffsetY * pinnedResolution / resolution,
            offsetX: textOffsetX * pinnedResolution / resolution,
            scale: pinnedResolution / resolution,
          }),
          geometry: new Point(textPosition),
          zIndex: style.zIndexOffSet + 3 + (selected ? style.zIndexSelectionOffset : 0),
        }),
      )
    }
    return styles
  }
}
