import { useEditorStore } from '@app/stores/editor'
import { Feature } from 'ol'
import { Polygon } from 'ol/geom'
import { swapPolygonCoordinates } from '@openlayer/helper'
import { type ArrowGeometryProperties, ClemexMosaicCanvasListenersType, type DetectedObjectProperties, DIRECT_MEASURE_FEATURE_TYPES, FEATURE_TYPE, FeatureType, METADATA_ANNOTATION_FEATURE_TYPES } from '@clemex/mosaic-canvas'
import { useEffect } from 'react'
import { EVENTS_ID } from '@app/constants'
import { EVENT_DISPATCHER } from '@app/event'
import { useProjectTrainingSnapshot } from '@app/api/hooks'
import { useAnalyticDataInstanceSegmentation } from '@app/api/analytic'
import { useProjectId, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'

export const useCanvasSelection = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): void => {
  const projectId = useProjectId()
  const selectedImageId = useSelectedImageId()
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: detectedObjects } = useAnalyticDataInstanceSegmentation(selectedImageId, projectTrainingSnapshot?.projectTrainingSnapshotId)

  const selectionModes = useEditorStore((state) => state.selectionModes)

  const [detectedObjectSelectedId, setDetectedObjectSelectedIds] = useEditorStore((store) => [store.getDetectedObjectSelectedId(), store.setDetectedObjectSelectedIds])
  const [directMeasureSelectedIds] = useEditorStore((store) => [store.directMeasureSelectedIds])
  const [metadataAnnotationSelectedIds] = useEditorStore((store) => [store.metadataAnnotationSelectedIds])

  useEffect(() => {
    clemexMosaicCanvas.selectDetectedObjectById(detectedObjectSelectedId)
    clemexMosaicCanvas.render()
  }, [clemexMosaicCanvas, detectedObjectSelectedId])

  useEffect(() => {
    clemexMosaicCanvas.selectDirectMeasureByIds(Object.entries(directMeasureSelectedIds).filter(([, isSelected]) => isSelected).map(([id]) => id))
    clemexMosaicCanvas.render()
  }, [clemexMosaicCanvas, directMeasureSelectedIds])

  useEffect(() => {
    clemexMosaicCanvas.selectMetadataAnnotationByIds(Object.entries(metadataAnnotationSelectedIds).filter(([, isSelected]) => isSelected).map(([id]) => id))
    clemexMosaicCanvas.render()
  }, [clemexMosaicCanvas, metadataAnnotationSelectedIds])

  useEffect(() => {
    const onDetectedObjectSelectionChanged = (detectedObjectId: string | undefined): void => {
      if (detectedObjectId !== undefined) {
        setDetectedObjectSelectedIds({ [detectedObjectId]: true })
        EVENT_DISPATCHER.dispatchEditorDataBrowserClassAnnotationFocusRow(detectedObjectId)
      } else {
        setDetectedObjectSelectedIds({})
      }
    }
    clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.SELECTION_DETECTED_OBJECT_CHANGED, onDetectedObjectSelectionChanged)
  }, [clemexMosaicCanvas, setDetectedObjectSelectedIds])

  useEffect(() => {
    if (detectedObjects === undefined || detectedObjects === null) {
      clemexMosaicCanvas.setDetectedObjects([])
    } else {
      const detectedObjectFeatures = detectedObjects.items.map((detectedObject, index) => {
        const detectedObjectFeature = new Feature(new Polygon(swapPolygonCoordinates(detectedObject.geometry.coordinates as number[][][])))
        detectedObjectFeature.setProperties({
          id: `${index}`,
          [FEATURE_TYPE]: FeatureType.DETECTED_OBJECT,
        } satisfies DetectedObjectProperties & { [FEATURE_TYPE]: FeatureType })
        return detectedObjectFeature
      })
      clemexMosaicCanvas.setDetectedObjects(detectedObjectFeatures)
    }
  }, [clemexMosaicCanvas, detectedObjects])

  const { setSelectedDirectMeasureIds, setMetadataAnnotationSelectedIds } = useEditorStore((state) => {
    return {
      setSelectedDirectMeasureIds: state.setDirectMeasureSelectedIds,
      setMetadataAnnotationSelectedIds: state.setMetadataAnnotationSelectedIds,
    }
  })

  // When the selection change, update the editor state.
  useEffect(() => {
    const onSelect = (): void => {
      const selection = clemexMosaicCanvas.getSelection()
      const directMeasureIds = selection.filter((feature) => DIRECT_MEASURE_FEATURE_TYPES.includes(feature.getProperties()[FEATURE_TYPE] as FeatureType)).map((feature) => feature.getProperties().id as string)
      setSelectedDirectMeasureIds(directMeasureIds.reduce<Record<string, boolean>>((acc, id) => {
        acc[id] = true
        return acc
      }, {}))
      const metadataAnnotationIds = selection.filter((feature) => METADATA_ANNOTATION_FEATURE_TYPES.includes(feature.getProperties()[FEATURE_TYPE] as FeatureType)).map((feature) => feature.getProperties().id as string)
      setMetadataAnnotationSelectedIds(metadataAnnotationIds.reduce<Record<string, boolean>>((acc, id) => {
        acc[id] = true
        return acc
      }, {}))
      if (directMeasureIds.length + metadataAnnotationIds.length === 1) {
        // Only one item is selected, we can trigger an event to focus it in the databrowser.
        if (directMeasureIds.length === 1) {
          EVENT_DISPATCHER.dispatchEditorDataBrowserDirectMeasureFocusRow(directMeasureIds[0])
        }
        if (metadataAnnotationIds.length === 1) {
          EVENT_DISPATCHER.dispatchEditorDataBrowserMetadataAnnotationFocusRow(metadataAnnotationIds[0])
        }
      }
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.SELECTION_CHANGED, onSelect)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, setSelectedDirectMeasureIds, setMetadataAnnotationSelectedIds])

  // Subscribe to EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES
  // When the event is triggered, update the properties of the selected arrows.
  useEffect(() => {
    const onUpdateArrowProperties = (event: Event): void => {
      const customEvent = event as CustomEvent<ArrowGeometryProperties>
      if (clemexMosaicCanvas === null) {
        return
      }
      const selection = clemexMosaicCanvas.getSelection()
      if (selection.length !== 1 || selection[0].getProperties()[FEATURE_TYPE] !== FeatureType.METADATA_ANNOTATION_ARROW) {
        console.error('EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES event was triggered but no arrow is selected.')
        return
      }
      const annotationId = selection[0].getProperties().id as string
      const arrowProperties = customEvent.detail
      clemexMosaicCanvas.setMetadataAnnotationArrowProperties(annotationId, arrowProperties)
    }
    window.addEventListener(EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES, onUpdateArrowProperties)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES, onUpdateArrowProperties)
    }
  }, [clemexMosaicCanvas])

  const [classAnnotationSelectedId, setClassAnnotationSelectedIds] = useEditorStore((store) => [store.getClassAnnotationSelectedId(), store.setClassAnnotationSelectedIds])
  useEffect(() => {
    clemexMosaicCanvas.selectClassAnnotationById(classAnnotationSelectedId)
    clemexMosaicCanvas.render()
  }, [clemexMosaicCanvas, classAnnotationSelectedId])

  useEffect(() => {
    const onClassAnnotationSelectionChanged = (classAnnotationId: string | undefined): void => {
      if (classAnnotationId !== undefined) {
        setClassAnnotationSelectedIds({ [classAnnotationId]: true })
        EVENT_DISPATCHER.dispatchEditorDataBrowserClassAnnotationFocusRow(classAnnotationId)
      } else {
        setClassAnnotationSelectedIds({})
      }
    }
    clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.SELECTION_CLASS_ANNOTATION_CHANGED, onClassAnnotationSelectionChanged)
  }, [clemexMosaicCanvas, setClassAnnotationSelectedIds])

  useEffect(() => {
    clemexMosaicCanvas.setSelectionModes(selectionModes)
  }, [clemexMosaicCanvas, selectionModes])

  // Bind EDITOR_TOOL_SELECTION_DELETE
  useEffect(() => {
    const onDeleteSelection = (): void => {
      clemexMosaicCanvas?.deleteSelection()
    }
    window.addEventListener(EVENTS_ID.EDITOR_TOOL_SELECTION_DELETE, onDeleteSelection)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_TOOL_SELECTION_DELETE, onDeleteSelection)
    }
  }, [clemexMosaicCanvas, selectedImageId])

  useEffect(() => {
    const onFocusObject = (event: Event): void => {
      const customEvent = event as CustomEvent<{ imageId: string, bbox: [number, number, number, number] }>
      const [minX, minY, maxX, maxY] = customEvent.detail.bbox
      clemexMosaicCanvas.navigateToBBox([minX, -maxY, maxX, -minY], { duration: 1000, padding: [10, 10, 10, 10] })

    }
    window.addEventListener(EVENTS_ID.EDITOR_CANVAS_FOCUS_OBJECT, onFocusObject)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_CANVAS_FOCUS_OBJECT, onFocusObject)
    }
  }, [clemexMosaicCanvas])
}
