/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InProgressTraining } from './InProgressTraining';
import {
    InProgressTrainingFromJSON,
    InProgressTrainingFromJSONTyped,
    InProgressTrainingToJSON,
} from './InProgressTraining';
import type { ProjectTrainingError } from './ProjectTrainingError';
import {
    ProjectTrainingErrorFromJSON,
    ProjectTrainingErrorFromJSONTyped,
    ProjectTrainingErrorToJSON,
} from './ProjectTrainingError';

/**
 * Project Training Status
 * @export
 * @interface ProjectTrainingStatus
 */
export interface ProjectTrainingStatus {
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingStatus
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingStatus
     */
    currentProjectTrainingSnapshotId: string | null;
    /**
     * 
     * @type {ProjectTrainingError}
     * @memberof ProjectTrainingStatus
     */
    trainingError: ProjectTrainingError | null;
    /**
     * 
     * @type {InProgressTraining}
     * @memberof ProjectTrainingStatus
     */
    inProgressTraining: InProgressTraining | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTrainingStatus
     */
    isFailed: boolean;
}

/**
 * Check if a given object implements the ProjectTrainingStatus interface.
 */
export function instanceOfProjectTrainingStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "currentProjectTrainingSnapshotId" in value;
    isInstance = isInstance && "trainingError" in value;
    isInstance = isInstance && "inProgressTraining" in value;
    isInstance = isInstance && "isFailed" in value;

    return isInstance;
}

export function ProjectTrainingStatusFromJSON(json: any): ProjectTrainingStatus {
    return ProjectTrainingStatusFromJSONTyped(json, false);
}

export function ProjectTrainingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'currentProjectTrainingSnapshotId': json['current_project_training_snapshot_id'],
        'trainingError': ProjectTrainingErrorFromJSON(json['training_error']),
        'inProgressTraining': InProgressTrainingFromJSON(json['in_progress_training']),
        'isFailed': json['is_failed'],
    };
}

export function ProjectTrainingStatusToJSON(value?: ProjectTrainingStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'current_project_training_snapshot_id': value.currentProjectTrainingSnapshotId,
        'training_error': ProjectTrainingErrorToJSON(value.trainingError),
        'in_progress_training': InProgressTrainingToJSON(value.inProgressTraining),
        'is_failed': value.isFailed,
    };
}

