import { createImagePredictionThumbnail, createImageThumbnailURL } from './api'
import { type ProjectResponse, type PosterImageResponse } from '@app/api/openapi'

export class Project {
  constructor (
    readonly id: string,
    readonly slug: string,
    readonly name: string,
    readonly description: string,
    readonly lastModifiedDate: Date,
    readonly posterImage?: PosterImage,
  ) { }

  static fromProjectResponse (projectResponse: ProjectResponse): Project {
    return new Project(
      projectResponse.id,
      projectResponse.slug,
      projectResponse.name,
      projectResponse.description,
      new Date(projectResponse.lastModifiedDate),
      (projectResponse.posterImage != null)
        ? PosterImage.fromImageInfo(projectResponse.posterImage)
        : undefined,
    )
  }
}
export class PosterImage {
  constructor (
    readonly imageId: string,
    readonly projectTrainingSnapshotid: string | null,
  ) { }

  get thumbnailUrl (): string {
    return createImageThumbnailURL(this.imageId, 284, 200)
  }

  get predictionMaskThumbnailUrl (): string | undefined {
    if (this.projectTrainingSnapshotid == null) {
      return undefined
    }
    return createImagePredictionThumbnail(this.imageId, this.projectTrainingSnapshotid, 284, 200)
  }

  static fromImageInfo (posterImage: PosterImageResponse): PosterImage {
    return new PosterImage(
      posterImage.id,
      posterImage.projectTrainingSnapshotId,
    )
  }
}
