import { createColumnHelper, type ColumnDef } from '@tanstack/react-table'
import { FormattedMessage } from 'react-intl'
import { EVENT_DISPATCHER } from '@app/event'
import { type MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Feature, Map } from 'ol'
import VectorSource from 'ol/source/Vector'
import { swapPolygonCoordinates } from '@openlayer/helper'
import { Polygon } from 'ol/geom'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import { VPE_COLORS_PALETTE } from '@app/constants'
import { type DetectedObjectStats } from '@app/api/openapi'
import { Button, Dropdown } from 'antd'
import ActionDropdownMenuIcon from '@material-design-icons/svg/outlined/more_vert.svg'
import { type Polygon as GeoJSONPolygon } from 'geojson'
import { sortableHeader, numberCellValue } from '@app/pages/editor-page/databrowser/table/commont'
import VectorLayer from 'ol/layer/Vector'

interface ThumbnailHeaderOptions {
  imageId: string | undefined
  projectTrainingSnapshotId: string | undefined
}
const thumbnailHeader = ({ imageId, projectTrainingSnapshotId }: ThumbnailHeaderOptions): ColumnDef<DetectedObjectStats>['cell'] => {
  // eslint-disable-next-line react/display-name
  return ({ row }) => {
    const { geometry, colorIndex } = row.original
    const index = row.index
    const openLayerId = `ol-layer-thumbnail-${imageId}-${projectTrainingSnapshotId}-${index}`
    const olRef = useRef<HTMLDivElement>(null)
    const [olCanvasMap, setOlCanvasMap] = useState<Map | null>(null)

    const olGeojsonGeometry = useMemo(() => {
      return new Feature({
        geometry: new Polygon(swapPolygonCoordinates(geometry.coordinates as number[][][])),
      })
    }, [geometry])

    useEffect(() => {
      const ol = new Map({
        target: openLayerId,
        controls: [],
        interactions: [],
        layers: [
          new VectorLayer({
            source: new VectorSource({
              features: [olGeojsonGeometry],
            }),
            style: new Style({
              fill: new Fill({
                color: VPE_COLORS_PALETTE[colorIndex],
              }),
            }),
          }),
        ],
      })
      setOlCanvasMap(ol)
      return () => {
        ol.dispose()
      }
    }, [colorIndex, olGeojsonGeometry, openLayerId])

    useEffect(() => {
      const geom = olGeojsonGeometry.getGeometry()
      if (olCanvasMap !== null && geom != null) {
        olCanvasMap.getView().fit(geom.getExtent())
      }
    }, [olCanvasMap, olGeojsonGeometry])

    const onClickFocus = (e: MouseEvent): void => {
      e.stopPropagation()
      row.toggleSelected(true)
      if (imageId !== undefined) {
        EVENT_DISPATCHER.dispatchEditorCanvasFocusObject(imageId, [row.original.minX, row.original.minY, row.original.maxX, row.original.maxY])
      }
    }

    return (
      <div className="flex items-center h-max-[36px] w-max-[36px] h-min-[36px] w-min-[36px] cursor-pointer" onClick={onClickFocus}>
        <div
          id={openLayerId}
          ref={olRef}
          className='h-[36px] w-[36px] pointer-events-none'
        />
      </div>
    )
  }
}

const detectedObjectColumnHelper = createColumnHelper<DetectedObjectStats>()

interface DetectedObjectStatsColumnsDefOptions {
  pixelSizeUm: number | undefined
  imageId: string | undefined
  projectTrainingSnapshotId: string | undefined
  setThicknessMeasureObjectId: (objectId: string) => void
}
export const createDetectedObjectStatsColumnsDef = ({
  pixelSizeUm = undefined,
  imageId,
  projectTrainingSnapshotId,
  setThicknessMeasureObjectId,
}: DetectedObjectStatsColumnsDefOptions): ColumnDef<DetectedObjectStats>[] => {
  return [
    detectedObjectColumnHelper.display({
      id: 'index',
      header: () => <FormattedMessage id="databrowser.prediction-detected-object.column.index.header.title" defaultMessage="#" />,
      cell: ({ row }) => {
        return row.index + 1
      },
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.display({
      id: 'thumbnail',
      header: () => <FormattedMessage id="databrowser.prediction-detected-object.column.thumbnail.header.title" defaultMessage="Thumbnail" />,
      cell: thumbnailHeader({ imageId, projectTrainingSnapshotId }),
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: false,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('className', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.className.header.title" defaultMessage="Class Name" />,
        'left',
      ),
      cell: ({ getValue }) => getValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableGlobalFilter: true,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('area', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.area.header.title.um" defaultMessage="Area (μm²)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.area.header.title.px" defaultMessage="Area (px²)" />,
        'right',
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('shellArea', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.area-shell.header.title.um" defaultMessage="Shell Area (μm²)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.area-shell.header.title.px" defaultMessage="Shell Area (px²)" />,
        'right',
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('hullArea', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.area-hull.header.title.um" defaultMessage="Hull Area (μm²)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.area-hull.header.title.px" defaultMessage="Hull Area (px²)" />,
        'right',
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('width', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.width.header.title.um" defaultMessage="Width (μm)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.width.header.title.px" defaultMessage="Width (px)" />,
        'right',
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('height', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.prediction-detected-object.column.height.header.title.um" defaultMessage="Height (μm)" />
          : <FormattedMessage id="databrowser.prediction-detected-object.column.height.header.title.px" defaultMessage="Height (px)" />,
        'right',
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('centroidX', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.centroid-x.header.title.um" defaultMessage="Centroid X" />,
        'right',
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('centroidY', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.centroid-y.header.title.um" defaultMessage="Centroid Y" />,
        'right',
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('minX', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.min-x.header.title.um" defaultMessage="Min X" />,
        'right',
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('minY', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.min-y.header.title.um" defaultMessage="Min Y" />,
        'right',
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('maxX', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.max-x.header.title.um" defaultMessage="Max X" />,
        'right',
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.accessor('maxY', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.prediction-detected-object.column.max-Y.header.title.um" defaultMessage="Max Y" />,
        'right',
      ),
      cell: numberCellValue(),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DetectedObjectStats>,
    detectedObjectColumnHelper.display({
      id: 'actions',
      header: () => <FormattedMessage id="databrowser.prediction-detected-object.column.action-show-detected-object.header.title" defaultMessage="Actions" />,
      cell: ({ row }) => {
        return <Button className='px-[8px] py-0' onClick={(e) => { e.stopPropagation() }}>
          <Dropdown menu={{
            items: [
              {
                key: 'use-as-annotation',
                label: <FormattedMessage id="databrowser.prediction-detected-object.column.action.use-as-annotation.cell.title" defaultMessage="Use as annotation" />,
                onClick: () => {
                  EVENT_DISPATCHER.dispatchAddAnnotationToCanvas({
                    geometry: {
                      type: 'Polygon',
                      coordinates: row.original.geometry.coordinates as GeoJSONPolygon['coordinates'],
                    },
                    colorIndex: row.original.colorIndex,
                  })
                },
              },
              {
                key: 'show-thickness-measure',
                label: <FormattedMessage id="databrowser.prediction-detected-object.column.action-show-detected-object.cell.title" defaultMessage="Thickness Measure" />,
                onClick: () => {
                  setThicknessMeasureObjectId(`${row.index}`)
                },
              },
            ],
          }}
          trigger={['click']}
          >
            <div className="flex justify-center" onClick={(e) => { e.preventDefault() }}>
              <ActionDropdownMenuIcon />
            </div>
          </Dropdown>
        </Button>
      },
    }) as ColumnDef<DetectedObjectStats>,
  ]
}
