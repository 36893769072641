/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserAssistanceType = {
    Help: 'HELP',
    LicenceAcquisition: 'LICENCE_ACQUISITION',
    ProblemReport: 'PROBLEM_REPORT',
    FeatureRequest: 'FEATURE_REQUEST',
    RequestLicenceFeature: 'REQUEST_LICENCE_FEATURE'
} as const;
export type UserAssistanceType = typeof UserAssistanceType[keyof typeof UserAssistanceType];


export function UserAssistanceTypeFromJSON(json: any): UserAssistanceType {
    return UserAssistanceTypeFromJSONTyped(json, false);
}

export function UserAssistanceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAssistanceType {
    return json as UserAssistanceType;
}

export function UserAssistanceTypeToJSON(value?: UserAssistanceType | null): any {
    return value as any;
}

