import type * as React from 'react'
import styles from './styles/rf-content.module.scss'
import clsx from 'clsx'
import { Flex, Layout, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'

const { Content } = Layout
interface RFContentProps {
  children: React.ReactNode
  className?: string
  isLoading?: boolean
}

export const RFContent: React.FC<RFContentProps> = ({ children, className, isLoading = false }) => {
  return <Layout>
    {isLoading
      ? <Flex vertical justify='center' align='center' className={styles.loading}>
          <Spin size="large" indicator={<LoadingOutlined/>} />
          <FormattedMessage id={'loading-page.message'} defaultMessage={'Loading...'}/>
        </Flex>
      : <Content className={clsx(styles.content, className)}>
          {children}
        </Content>
    }

  </Layout>
}
