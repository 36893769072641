import { type FeatureLike } from 'ol/Feature'
import { type Point } from 'ol/geom'
import { Circle, Fill, Stroke, Text } from 'ol/style'
import Style, { type StyleFunction } from 'ol/style/Style'
import { type ShapeStyle } from './common'

export interface TextGeometryProperties {
  geometryType: 'TEXT'
  text: string
  pinnedResolution: number
}

export const buildTextStyle = (
  selection: Set<string>,
  style: ShapeStyle,
): StyleFunction => {
  return (feature: FeatureLike, resolution) => {
    const geom = feature.getGeometry() as Point
    const { text, pinnedResolution } = geom.getProperties() as TextGeometryProperties
    const font = `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`
    const selected = selection.has(feature.getProperties().id as string)

    return [
      new Style({
        text: new Text({
          text: text === '' ? ' ' : text,
          font,
          fill: new Fill({
            color: 'black',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
          backgroundFill: new Fill({
            color: 'rgba(255, 255, 255, 0.5)',
          }),
          backgroundStroke: new Stroke({
            color: 'black',
            width: 1,
          }),
          justify: 'left',
          textAlign: 'left',
          textBaseline: 'top',
          padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
          offsetY: style.measurementTextBackgroundPadding + 1,
          offsetX: style.measurementTextBackgroundPadding + 1,
          scale: pinnedResolution / resolution,
        }),
        zIndex: style.zIndexOffSet,
      }),
      new Style({
        image: new Circle({
          radius: 3,
          fill: new Fill({
            color: selected ? style.mainSelectedColor : style.mainColor,
          }),
          stroke: new Stroke({
            color: 'white',
            width: 1,
          }),
        }),
        zIndex: style.zIndexOffSet + 1,
      }),
    ]
  }
}
