/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectMeasureInput } from './DirectMeasureInput';
import {
    DirectMeasureInputFromJSON,
    DirectMeasureInputFromJSONTyped,
    DirectMeasureInputToJSON,
} from './DirectMeasureInput';

/**
 * 
 * @export
 * @interface DirectMeasurePatchRequest
 */
export interface DirectMeasurePatchRequest {
    /**
     * 
     * @type {Array<DirectMeasureInput>}
     * @memberof DirectMeasurePatchRequest
     */
    add?: Array<DirectMeasureInput>;
    /**
     * 
     * @type {Array<DirectMeasureInput>}
     * @memberof DirectMeasurePatchRequest
     */
    remove?: Array<DirectMeasureInput>;
    /**
     * 
     * @type {Array<DirectMeasureInput>}
     * @memberof DirectMeasurePatchRequest
     */
    update?: Array<DirectMeasureInput>;
}

/**
 * Check if a given object implements the DirectMeasurePatchRequest interface.
 */
export function instanceOfDirectMeasurePatchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DirectMeasurePatchRequestFromJSON(json: any): DirectMeasurePatchRequest {
    return DirectMeasurePatchRequestFromJSONTyped(json, false);
}

export function DirectMeasurePatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasurePatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'add': !exists(json, 'add') ? undefined : ((json['add'] as Array<any>).map(DirectMeasureInputFromJSON)),
        'remove': !exists(json, 'remove') ? undefined : ((json['remove'] as Array<any>).map(DirectMeasureInputFromJSON)),
        'update': !exists(json, 'update') ? undefined : ((json['update'] as Array<any>).map(DirectMeasureInputFromJSON)),
    };
}

export function DirectMeasurePatchRequestToJSON(value?: DirectMeasurePatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add': value.add === undefined ? undefined : ((value.add as Array<any>).map(DirectMeasureInputToJSON)),
        'remove': value.remove === undefined ? undefined : ((value.remove as Array<any>).map(DirectMeasureInputToJSON)),
        'update': value.update === undefined ? undefined : ((value.update as Array<any>).map(DirectMeasureInputToJSON)),
    };
}

