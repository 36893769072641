import { ScaleOrigin, ScaleStyle } from '@clemex/mosaic-canvas'
import { useEffect } from 'react'
import { useMeasurementSettings } from '@app/api/hooks'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import { useProjectId } from '@app/pages/editor-page/hooks/editor-page'

export const useCanvasScaleBar = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): void => {
  const projectId = useProjectId()

  const { data: measurementSettings } = useMeasurementSettings(projectId)

  useEffect(() => {
    if (measurementSettings === undefined || clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas?.setScaleBarOrigin(ScaleOrigin.fromString(measurementSettings.scaleAnchor))
    const newStyle = new ScaleStyle(
      {
        background: measurementSettings.scaleBarColor,
        opacity: 1,
        widthPixel: measurementSettings.scaleBarSizePx,
        heightPixel: 20,
        thicknessPixel: measurementSettings.scaleBarThickness,
        position: measurementSettings.scaleBarTextPosition,
      },
      {
        background: '#ffffff',
        opacity: 1,
        fontSize: 10,
        color: '#000000',
      },
    )
    clemexMosaicCanvas?.setScaleStyle(newStyle)
  }, [measurementSettings, clemexMosaicCanvas])
}
