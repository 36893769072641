import { useBaseNotificationStore, type BaseNotification } from '@components/notifications/use-notifications'
import { notification } from 'antd'
import React from 'react'

export const BaseNotificationContainer: React.FC = () => {
  const { notifications, removeNotification } = useBaseNotificationStore()
  const [notificationContainer, notificationContextHolder] = notification.useNotification()
  React.useEffect(() => {
    notifications.forEach((notification: BaseNotification) => {
      notificationContainer[notification.type]({
        key: notification.id.toString(),
        ...notification,
        onClose: () => { removeNotification(notification.id) },
      })
    })
  }, [notifications, removeNotification, notificationContainer])

  return notificationContextHolder
}
