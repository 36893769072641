import type * as React from 'react'
import { Form } from 'antd'
import styles from './styles/authentication-form-layout.module.scss'
import clsx from 'clsx'
import Title from 'antd/lib/typography/Title'

export interface AuthenticationFormLayoutResult<T> {
  onSubmit: (formData: T) => void
}
interface AuthenticationFormLayoutProps<T> extends AuthenticationFormLayoutResult<T> {
  title: React.ReactNode
  className?: string
}
export const AuthenticationFormLayout = <Value = unknown>({ children, onSubmit, title, className }: React.PropsWithChildren<AuthenticationFormLayoutProps<Value>>): React.JSX.Element => {
  return (
    <div className={clsx(className, styles.formContainer)} >
      <Title level={4}>{title}</Title>
      <Form
        name="form"
        onFinish={onSubmit}
        labelCol={{ span: 8 }}
        className={styles.form}
      >
        { children }
      </Form>
    </div>
  )
}
