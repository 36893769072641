import { type OnChangeFn } from '@tanstack/react-table'
import { type StateCreator } from 'zustand'

interface CanvasSelectionState {
  classAnnotationSelectedIds: Record<string, boolean>
  detectedObjectSelectedIds: Record<string, boolean>
  directMeasureSelectedIds: Record<string, boolean>
  metadataAnnotationSelectedIds: Record<string, boolean>
  thicknessMeasureObjectId?: string
  thicknessMeasureType: 'annotation' | 'prediction'
}

interface CanvasSelectionComputedState {
  getClassAnnotationSelectedId: () => string | undefined
  getDetectedObjectSelectedId: () => string | undefined
}

interface CanvasSelectionActions {
  setClassAnnotationSelectedIds: OnChangeFn<Record<string, boolean>>
  setDetectedObjectSelectedIds: OnChangeFn<Record<string, boolean>>
  setDirectMeasureSelectedIds: OnChangeFn<Record<string, boolean>>
  setMetadataAnnotationSelectedIds: OnChangeFn<Record<string, boolean>>
  setThicknessMeasureObject: (id: string | undefined, type?: 'annotation' | 'prediction') => void
  resetCanvasSelection: () => void
}

const initialState: CanvasSelectionState = {
  classAnnotationSelectedIds: {},
  detectedObjectSelectedIds: {},
  directMeasureSelectedIds: {},
  metadataAnnotationSelectedIds: {},
  thicknessMeasureObjectId: undefined,
  thicknessMeasureType: 'annotation',
}

export type CanvasSelectionStore = CanvasSelectionState & CanvasSelectionComputedState & CanvasSelectionActions

export const createCanvasSelectionStoreSlice: StateCreator<CanvasSelectionStore> = (set, get) => {
  return {
    ...initialState,
    getClassAnnotationSelectedId () {
      const { classAnnotationSelectedIds: selection } = get()
      return Object.keys(selection).find((id) => selection[id])
    },
    getDetectedObjectSelectedId () {
      const { detectedObjectSelectedIds: selection } = get()
      return Object.keys(selection).find((id) => selection[id])
    },
    setClassAnnotationSelectedIds: (selection) => {
      set((state) => {
        if (typeof selection === 'function') {
          const newSelection = selection(state.classAnnotationSelectedIds)
          return { classAnnotationSelectedIds: newSelection }
        } else {
          return { classAnnotationSelectedIds: selection }
        }
      })
      set({ thicknessMeasureObjectId: undefined, detectedObjectSelectedIds: {} })
    },
    setDetectedObjectSelectedIds: (selection) => {
      set((state) => {
        if (typeof selection === 'function') {
          const newSelection = selection(state.detectedObjectSelectedIds)
          return { detectedObjectSelectedIds: newSelection }
        } else {
          return { detectedObjectSelectedIds: selection }
        }
      })
      set({ thicknessMeasureObjectId: undefined, classAnnotationSelectedIds: {} })
    },
    setDirectMeasureSelectedIds: (selection) => {
      set((state) => {
        if (typeof selection === 'function') {
          const newSelection = selection(state.directMeasureSelectedIds)
          return { directMeasureSelectedIds: newSelection }
        } else {
          return { directMeasureSelectedIds: selection }
        }
      })
    },
    setMetadataAnnotationSelectedIds: (selection) => {
      set((state) => {
        if (typeof selection === 'function') {
          const newSelection = selection(state.metadataAnnotationSelectedIds)
          return { metadataAnnotationSelectedIds: newSelection }
        } else {
          return { metadataAnnotationSelectedIds: selection }
        }
      })
    },
    setThicknessMeasureObject: (id, type) => {
      set({ thicknessMeasureObjectId: id })
      if (type !== undefined) {
        set({ thicknessMeasureType: type })
      }
    },
    resetCanvasSelection: () => { set(initialState) },
  }
}
