import useSWR from 'swr'
import { uniqueId } from 'lodash'
import { useCallback } from 'react'
import { createWithEqualityFn as create } from 'zustand/traditional'
import { type IconType, type ArgsProps } from 'antd/lib/notification/interface'

export enum ProjectNotificationType {
  TrainingForbidden = 'TrainingForbidden',
  TrainingError = 'TrainingError',
  TrainingUnknown = 'TrainingUnknown',
  CreateTrainingError = 'CreateTrainingError',
  CancelTrainingErrorUnknown = 'CancelTrainingErrorUnknown',
  PredictionUnknown = 'PredictionUnknown',
}

// This class is not required, I could use a simple object
// The advantage is mostly that it is easier to read
// and that it is easier to add new properties if needed
class ProjectNotification {
  readonly id: string
  constructor (
    readonly type: ProjectNotificationType,
    readonly projectId: string,
    // The duration is not really configurable and was left to the default ant.d value
    // I set it explicitly here, because I wanted to remove the notifications from the queue
    // after the duration is reached since ant.d removes them from the UI too.
    readonly duration = 4.5,
    readonly message?: string,
    readonly onClose?: () => void,
  ) {
    this.id = uniqueId()
  }
}

// This queue is a FIFO
const projectsNotificationQueue: ProjectNotification[] = []

export const useProjectsNotification = (): {
  data: ProjectNotification[] | undefined
  notify: (projectId: string, type: ProjectNotificationType, message?: string, onClose?: () => void) => void
} => {
  const { data, mutate } = useSWR(
    '/hooks/notification/projects',
    () => [...projectsNotificationQueue],
  )
  return {
    data,
    // Duration could be added to the notify function if needed
    notify: useCallback((projectId: string, type: ProjectNotificationType, message, onClose) => {
      const notification = new ProjectNotification(type, projectId, undefined, message, onClose)
      // This should not happen, but it seems that we have
      // a bug somewhere that causes the same notification to be added twice
      const isAlreadyInQueue = projectsNotificationQueue.find((n) => notification.type === n.type) !== undefined
      if (isAlreadyInQueue) {
        return
      }
      projectsNotificationQueue.push(notification)
      void mutate()
      // Removes the notification from the queue after the duration is reached
      // This was not done in the original code, but it was creating a potential memory leak
      // Since the queue could grow indefinitely.
      setTimeout(() => {
        projectsNotificationQueue.splice(projectsNotificationQueue.findIndex((n) => notification.id === n.id), 1)
      }, notification.duration * 1000)
    }, [mutate]),
  }
}

// Force the type compared to default notification
interface BaseNotificationContent extends ArgsProps { type: IconType }
export interface BaseNotification extends BaseNotificationContent {
  id: number
}

interface BaseNotificationStore {
  notifications: BaseNotification[]
  addNotification: (notificationProps: BaseNotificationContent) => void
  removeNotification: (id: number) => void
}

export const useBaseNotificationStore = create<BaseNotificationStore>((set) => ({
  notifications: [],
  addNotification: (baseNotificationProps: BaseNotificationContent) => {
    const id = Date.now()
    set((state) => ({ notifications: [...state.notifications, { id, ...baseNotificationProps }] }))
  },
  removeNotification: (id) => {
    set((state) => ({ notifications: state.notifications.filter((n) => n.id !== id) }))
  },
}))
