import { useEditorStore } from '@app/stores/editor'
import { Feature } from 'ol'
import { Point, Polygon } from 'ol/geom'
import { swapPointCoordinates, swapPolygonCoordinates } from '@openlayer/helper'
import { FEATURE_TYPE, FeatureType } from '@clemex/mosaic-canvas'
import { useEffect } from 'react'
import { useThicknessMeasure } from '@app/api/image-object/thickness-measure'
import { useProjectId, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'

export const useCanvasThicknessMeasure = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): void => {
  const projectId = useProjectId()
  const selectedImageId = useSelectedImageId()

  const [thicknessMeasureObjectId, thicknessMeasureType] = useEditorStore((store) => [store.thicknessMeasureObjectId, store.thicknessMeasureType])

  const { data: thicknessMeasure, isValidating: isValidatingThicknessMeasure } = useThicknessMeasure({
    projectId,
    imageId: selectedImageId,
    measure: 'thickness',
    objectId: thicknessMeasureObjectId,
    type: thicknessMeasureType,
  })

  // When thickness measure is available, set it in the ClemexMosaicCanvas
  useEffect(() => {
    if (thicknessMeasure === undefined || isValidatingThicknessMeasure) {
      clemexMosaicCanvas.setThicknessMeasureFeatures([])
    } else {
      const thicknessMeasureMosaicCanvas = thicknessMeasure.visualization.lines.map((feature) => {
        return new Feature({
          geometry: new Polygon(
            swapPolygonCoordinates(feature.coordinates as number[][][]),
          ),
          [FEATURE_TYPE]: FeatureType.THICKNESS_MEASURE_LINE,
        })
      })
      const thicknessMeasureMosaicCanvasPoints = thicknessMeasure.visualization.labels.map((label) => {
        return new Feature({
          geometry: new Point(
            swapPointCoordinates(label.position.coordinates as number[]),
          ),
          [FEATURE_TYPE]: FeatureType.THICKNESS_MEASURE_LABEL,
          label: label.text,
        })
      })
      clemexMosaicCanvas.setThicknessMeasureFeatures([
        ...thicknessMeasureMosaicCanvas,
        ...thicknessMeasureMosaicCanvasPoints,
      ])
    }
  }, [clemexMosaicCanvas, isValidatingThicknessMeasure, thicknessMeasure])
}
