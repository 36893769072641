import type * as React from 'react'
import { AuthenticationHeader, RFContent, RFPage } from '@components/layout'
import { FormattedMessage } from 'react-intl'
import styles from './styles/maintenance.module.scss'
import { WelcomeTitle } from '@components/authentication/welcome-title'

interface MaintenanceProps {
  previousUrl: string
}
export const MaintenancePage: React.FC<MaintenanceProps> = (props) => {
  const { previousUrl } = props
  return <RFPage background backgroundOnTopFooter>
    <AuthenticationHeader>
      <WelcomeTitle title={
        <FormattedMessage id="maintenance.title" defaultMessage={'Ongoing maintenance'} />
      }/>
    </AuthenticationHeader>
    <RFContent className={styles.maintenanceContent}>
      <div className={styles.container}>
        <div className={styles.maintenanceMessage}>
          <p>
            <FormattedMessage id="maintenance.page.message.part1" defaultMessage="Sorry about this, but Studio is under maintenance and will be back online soon."/>
          </p>
          <FormattedMessage id="maintenance.page.message.part2" defaultMessage="Once ready, you will be redirected back to your work at:"/>
          <p className={styles.url}>{window.location.origin}{previousUrl}</p>
          <img src="/images/maintenance.svg"/>
        </div>
      </div>
    </RFContent>
  </RFPage>
}
