/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
} from './TaskStatus';

/**
 * Prediction Status
 * 
 * Holds the status of image tiling task
 * @export
 * @interface ImageStatus
 */
export interface ImageStatus {
    /**
     * 
     * @type {TaskStatus}
     * @memberof ImageStatus
     */
    imageTilingStatus: TaskStatus;
}

/**
 * Check if a given object implements the ImageStatus interface.
 */
export function instanceOfImageStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imageTilingStatus" in value;

    return isInstance;
}

export function ImageStatusFromJSON(json: any): ImageStatus {
    return ImageStatusFromJSONTyped(json, false);
}

export function ImageStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageTilingStatus': TaskStatusFromJSON(json['image_tiling_status']),
    };
}

export function ImageStatusToJSON(value?: ImageStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image_tiling_status': TaskStatusToJSON(value.imageTilingStatus),
    };
}

