import { Button } from '@shadcn-ui/components/ui/button'
import { cn } from '@shadcn-ui/lib/utils'
import { type ColumnDef } from '@tanstack/react-table'
import { CaretSortIcon, CaretUpIcon, CaretDownIcon } from '@radix-ui/react-icons'
import { AngleUnit } from '@app/api/openapi'
import { FormattedMessage } from 'react-intl'

export function sortableHeader<T> (headerTitle: React.ReactNode, justify: 'left' | 'right' | 'center'): ColumnDef<T>['header'] {
  // eslint-disable-next-line react/display-name
  return ({ column }) => {
    return (
      <Button
        variant='ghost'
        className={cn('px-0 w-full', { 'justify-start': justify === 'left', 'justify-end': justify === 'right', 'justify-center': justify === 'center' })}
        onClick={column.getToggleSortingHandler()}
      >
        {headerTitle}
        {column.getIsSorted() === false && <CaretSortIcon className="ml-1 h-4 w-4" />}
        {column.getIsSorted() === 'desc' && <CaretDownIcon className="ml-1 h-4 w-4" />}
        {column.getIsSorted() === 'asc' && <CaretUpIcon className="ml-1 h-4 w-4" />}
      </Button>
    )
  }
}

export function numberCellValue<T> (pixelSizeUm: number | undefined = undefined, angleUnit: AngleUnit | undefined = undefined): ColumnDef<T>['cell'] {
  // eslint-disable-next-line react/display-name
  return ({ getValue }) => {
    const value = getValue() as number | undefined | null
    const valueTransform = (value: number): number => {
      if (angleUnit !== undefined) {
        if (angleUnit === AngleUnit.Radian) {
          return value * Math.PI / 180
        }
        if (angleUnit === AngleUnit.Degree) {
          return value
        }
      }
      return value
    }
    return <div className={cn('flex grow justify-end', { 'text-clemex-offGray': !(value != null) })}>
      {
        value != null
          ? <FormattedMessage id="databrowser.cell.area.value" defaultMessage="{ value }" values={{ value: (valueTransform(value) * (pixelSizeUm ?? 1)).toFixed(pixelSizeUm !== undefined ? 2 : 1) }}/>
          : <FormattedMessage id="databrowser.cell.not-available" defaultMessage="NA" />
      }
    </div>
  }
}
