import { Pointer } from 'ol/interaction'
import { type Layer } from 'ol/layer'
import { Collection, type Feature, type MapBrowserEvent } from 'ol'

const DEFAULT_SELECTION_TOOL_HIT_TOLERANCE = 6

interface SelectConstructor {
  selectableLayers?: Layer[]
  hitTolerance?: number
}
export class Select extends Pointer {
  private _selectableLayers: Layer[]
  private readonly _selection: Collection<Feature>
  private readonly _hitTolerance: number

  constructor ({
    selectableLayers,
    hitTolerance,
  }: SelectConstructor) {
    super()
    this._selectableLayers = selectableLayers ?? []
    this._hitTolerance = hitTolerance ?? DEFAULT_SELECTION_TOOL_HIT_TOLERANCE
    this._selection = new Collection()
  }

  public readonly handleEvent = (evt: MapBrowserEvent<MouseEvent>): boolean => {
    const map = evt.map
    if (map === null || map === undefined) {
      return true
    }
    if (evt.type !== 'pointerdown') {
      return true
    }
    const features: Feature[] = []
    map.forEachFeatureAtPixel(evt.pixel, (feature) => {
      const isAlreadySelected = this.getFeatures().getArray().some(selectedFeature => selectedFeature.getProperties().id === feature.getProperties().id)
      if (!isAlreadySelected) {
        features.push(feature as Feature)
      }
    }, {
      hitTolerance: this._hitTolerance,
      layerFilter: (layer) => this._selectableLayers.includes(layer),
    }) as Feature | undefined
    const feature = features[0] as Feature | undefined

    if (evt.originalEvent.shiftKey) {
      if (feature !== undefined) {
        this._selection.push(feature)
        feature.changed()
        return false
      }
      return true
    } else {
      const selectedFeature = this._selection.getArray().map(x => x)
      this._selection.clear()
      selectedFeature.forEach((feature) => {
        feature.changed()
      })
      if (feature !== undefined) {
        this._selection.push(feature)
        feature.changed()
        return false
      }
      return true
    }
  }

  public getFeatures (): Collection<Feature> {
    return this._selection
  }

  public readonly setSelectableLayers = (layers: Layer[]): void => {
    this._selectableLayers = layers
  }

  public readonly isCursorAbleToSelect = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }
    const features = evt.map.getFeaturesAtPixel(evt.pixel, {
      hitTolerance: this._hitTolerance,
      layerFilter: (layer) => this._selectableLayers.includes(layer),
    })
    return features.some((feature) => {
      return this.getFeatures().getArray().some(selectedFeature => selectedFeature.getProperties().id !== feature.getProperties().id)
    })
  }

  public readonly stopDown = (): boolean => {
    return true
  }
}
