import * as React from 'react'
import { Text, TutorialTour, type TutorialTourStepsProps } from '@components/tutorial/tutorial-tour'
import { FormattedMessage } from 'react-intl'
import { OnboardingTaskType } from '@app/api/openapi'
import { useOnboardingTasks } from '@app/api/hooks'
import { E2E_REQUIRED_CLASSES, ONBOARDING_TOUR_CONTEXT } from '@app/constants'

interface ProjectListTourProps {
  onboardingTourContext: ONBOARDING_TOUR_CONTEXT
}

export const ProjectListTour: React.FC<ProjectListTourProps> = ({ onboardingTourContext }) => {
  const { data: onboardingTasks } = useOnboardingTasks(onboardingTourContext)

  const stepsTour: TutorialTourStepsProps[] = React.useMemo(() => {
    if (onboardingTasks === undefined) {
      return []
    }
    const steps: TutorialTourStepsProps[] = [
      {
        keyIsCompleted: 'projectListUserHasReadWelcome',
        onboardingTaskCompletion: OnboardingTaskType.PublicProjectListUserHasReadWelcome,
        step: {
          title: (<FormattedMessage
            id={'tutorial-tour.project-list.welcome.title'}
            defaultMessage={'Welcome to Clemex Studio!'}
          />),
          description: <Text>
            <FormattedMessage
              id={'tutorial-tour.project-list.welcome.content-1'}
              defaultMessage={'Clemex Studio is a No-Code tool to produce stunning segmentation and detection of objects in images.'}
            />
          </Text>,
        },
      },
      {
        keyIsCompleted: 'irreversibleUserHasDiscoveredMenu',
        onboardingTaskCompletion: OnboardingTaskType.PublicIrreversibleUserHasDiscoveredMenu,
        step: {
          title: <FormattedMessage
            id={'header.tour.new-menu.title'}
            defaultMessage={'Studio Menu'}
          />,
          description: <FormattedMessage
            id={'header.tour.new-menu.content'}
            defaultMessage={'The menu will change based on the options available on each page.'}
          />,
          target: () => document.getElementsByClassName(E2E_REQUIRED_CLASSES.NAVIGATION_MENU)[0] as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'projectListUserHasReadPageTutorialWelcome',
        onboardingTaskCompletion: OnboardingTaskType.PublicProjectListUserHasReadPageTutorialWelcome,
        step: {
          title: <FormattedMessage
            id='tutorial-tour.project-list.project.title'
            defaultMessage='Project List'
          />,
          description: <>
            <Text>
              <FormattedMessage
                id='tutorial-tour.project-list.project.content-1'
                defaultMessage='The project list is where you can quickly access your projects.'
              />
            </Text>
            <Text>
              <FormattedMessage
                id='tutorial-tour.project-list.project.content-2'
                defaultMessage='A project is where you can create a custom image segmentation algorithm that fit your needs.'
              />
            </Text>
            <Text>
              <FormattedMessage
                id='tutorial-tour.project-list.project.content-3'
                defaultMessage='To get you started, you can modify any of the existing projects.'
              />
            </Text>
          </>,
        },
      },
      {
        keyIsCompleted: 'projectListUserHowToCreateProject',
        onboardingTaskCompletion: OnboardingTaskType.PublicProjectListUserHowToCreateProject,
        step: {
          title: <FormattedMessage
            id={'tutorial-tour.project-list.create-project.title'}
            defaultMessage={'Create a new project'}
          />,
          description: <FormattedMessage
            id={'tutorial-tour.project-list.create-project.content'}
            defaultMessage={'Click on that button to create a new empty project.'}
          />,
          target: () => document.getElementById('button-create-project') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'projectListUserHowToOpenProject',
        onboardingTaskCompletion: OnboardingTaskType.PublicProjectListUserHowToOpenProject,
        step: {
          title: <FormattedMessage
            id={'tutorial-tour.project-list.open-project.title'}
            defaultMessage={'Open a project'}
          />,
          description: <FormattedMessage
            id={'tutorial-tour.project-list.open-project.content'}
            defaultMessage={'Click on the project miniature image to access the project annotation page.'}
          />,
          target: () => document.getElementsByClassName('ant-card-cover')[0] as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'projectListUserHowToEditProject',
        onboardingTaskCompletion: OnboardingTaskType.PublicProjectListUserHowToEditProject,
        step: {
          title: <FormattedMessage
            id={'tutorial-tour.project-list.edit-project.title'}
            defaultMessage={'Edit a project settings'}
          />,
          description: <FormattedMessage
            id={'tutorial-tour.project-list.edit-project.content'}
            defaultMessage={'Click on this gear to display the project settings.'}
          />,
          target: () => document.getElementsByName('edit-project-settings')[0],
        },
      },
    ]
    if (onboardingTourContext === ONBOARDING_TOUR_CONTEXT.PROJECT_LIST) {
      steps.push({
        keyIsCompleted: 'irreversibleUserHasReadLogoutNotice',
        onboardingTaskCompletion: OnboardingTaskType.PublicIrreversibleUserHasReadLogoutNotice,
        step: {
          title: <FormattedMessage
            id={'header.tour.logout.title'}
            defaultMessage={'Logout'}
          />,
          description: <FormattedMessage
            id={'header.tour.logout.content'}
            defaultMessage={'The logout button has moved to the bottom of the navigation menu.'}
          />,
          target: () => document.getElementsByClassName(E2E_REQUIRED_CLASSES.NAVIGATION_MENU)[0] as HTMLElement,
        },
      },
      )
    }
    return steps
  }, [onboardingTasks, onboardingTourContext])

  return <TutorialTour
    steps={stepsTour}
    onboardingTourContext={onboardingTourContext}
  />
}
