import { type FeatureLike } from 'ol/Feature'
import Style, { type StyleFunction } from 'ol/style/Style'
import { FEATURE_TYPE, FeatureType, type ShapeStyle, type TransformInteractionStyle } from './common'
import ColorJS from 'colorjs.io'
import { Circle, type Polygon, Point } from 'ol/geom'
import { Fill, RegularShape, Stroke, Text } from 'ol/style'
import { fromCircle } from 'ol/geom/Polygon'

export interface CircleGeometryProperties {
  geometryType: 'CIRCLE'
  center: [number, number]
  radius: number
  filled: boolean
  pinnedResolution: number
}

export const buildCircleStyle = (
  selection: Set<string>,
  pixelSizeCalibration: number | undefined,
  style: ShapeStyle,
): StyleFunction => {
  return (feature: FeatureLike, resolution: number) => {
    const geometry = feature.getGeometry()
    if (geometry === undefined) {
      return []
    }

    const { center, radius, filled, pinnedResolution } = geometry.getProperties() as CircleGeometryProperties
    const selected = selection.has(feature.getProperties().id as string)
    const fillColor = new ColorJS(selected ? style.mainSelectedColor : style.mainColor)
    fillColor.alpha = style.fillOpacity
    const stroke = new Stroke({
      color: selected ? style.mainSelectedColor : style.mainColor,
      width: style.strokeWidth,
    })
    const fill = new Fill({
      color: filled ? fillColor.toString() : 'rgba(0, 0, 0, 0)',
    })

    const circle = buildCirclePolygon(center, radius)
    const styles = []
    styles.push(new Style({
      stroke,
      fill,
      geometry: circle,
    }))
    if (feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_CIRCLE) {
      const area = Math.PI * radius ** 2
      const font = `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`
      const areaMeasurementText = pixelSizeCalibration === undefined
        ? `A = ${area.toFixed(1)} px²`
        : `A = ${(area * pixelSizeCalibration).toFixed(2)} µm²`
      const perimeterMeasurementText = pixelSizeCalibration === undefined
        ? `r = ${radius.toFixed(1)} px`
        : `r = ${(radius * pixelSizeCalibration).toFixed(2)} µm`
      const measurementTexts = [
        areaMeasurementText,
        perimeterMeasurementText,
      ]
      const measurementTextHeight = style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2
      const measurementTextsHeight = (measurementTextHeight * measurementTexts.length) + (style.measurementTextFontLineHeight * (measurementTexts.length - 1))

      const measurementTextsBoxHeight = measurementTextsHeight +
        style.measurementTextFontStrokeWidth * 2 +
        style.measurementTextBackgroundPadding * 2

      const textOffsetX = 0

      const textOffsetY = (measurementTextsBoxHeight / 2) + style.measurementTextBackgroundDistanceShape
      const simpliedCoordinates = (circle.simplify(0) as Polygon).getCoordinates()
      let top = simpliedCoordinates[0][0]
      let left = simpliedCoordinates[0][0]
      let bottom = simpliedCoordinates[0][0]
      let right = simpliedCoordinates[0][0]
      for (const point of simpliedCoordinates[0]) {
        if (point[0] < left[0]) {
          left = point
        }
        if (point[0] > right[0]) {
          right = point
        }
        if (point[1] < bottom[1]) {
          bottom = point
        }
        if (point[1] > top[1]) {
          top = point
        }
      }
      styles.push(
        new Style({
          text: new Text({
            text: measurementTexts.join('\n'),
            font,
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
            backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
            fill: new Fill({
              color: style.measurementTextFontFillColor,
            }),
            stroke: new Stroke({
              color: style.measurementTextFontStrokeColor,
              width: style.measurementTextFontStrokeWidth,
            }),
            offsetX: textOffsetX * pinnedResolution / resolution,
            offsetY: textOffsetY * pinnedResolution / resolution,
            scale: pinnedResolution / resolution,
          }),
          geometry: () => {
            return new Point([
              (left[0] + right[0]) / 2,
              bottom[1],
            ])
          },
        }),
      )
    }
    return styles
  }
}

export const buildCirclePolygon = (center: [number, number], radius: number): Polygon => {
  const circle = new Circle([0, 0], 1)
  const polygon = fromCircle(circle, 64)
  polygon.scale(radius, radius, [0, 0])
  polygon.translate(center[0], center[1])
  return polygon
}

export const buildCircleDirectMesureTransformInteractionStyle = (): TransformInteractionStyle => {
  const stroke = new Stroke({
    color: 'rgba(255, 255, 255, 1)',
    width: 1,
  })
  const fill = new Fill({
    color: 'rgba(255, 255, 255, 0.5)',
  })
  const bigHandle = new Style({
    image: new RegularShape({
      fill,
      stroke,
      radius: 8,
      points: 4,
      angle: Math.PI / 4,
    }),
    fill,
    stroke,
  })

  return {
    scale: bigHandle,
    scale1: bigHandle,
    scale2: bigHandle,
    scale3: bigHandle,
    translate: bigHandle,
    scaleh1: [],
    scalev: [],
    rotate: [],
    rotate0: [],
    scaleh3: [],
    scalev2: [],
  }
}
