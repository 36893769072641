export enum CursorType {
  DEFAULT = 'DEFAULT',
  SELECT = 'SELECT',
  DRAG_PAN = 'DRAG_PAN',
  DRAWING = 'DRAWING',
  ERASING = 'ERASING',
  SMART_ANNOTATION = 'SMART_ANNOTATION',
  STRAIGHT_LINE = 'DIRECT_MEASURE_DISTANCE',
  GRAB_MODIFY_HANDLE = 'GRAB_MODIFY_HANDLE',
  GRAB_TRANSLATE_HANDLE = 'GRAB_TRANSLATE_HANDLE',
  ANGLE = 'BEVEL_PROTRACTOR',
  ELLIPSE = 'ELLIPSE_DIRECT_MEASURE',
  POLYGON = 'AREA_MEASUREMENT',
  ARC = 'ARC_MEASUREMENT',
  LINE = 'PERIMETER_MEASUREMENT',
  RECTANGLE = 'BOUNDING_BOX_MEASUREMENT',
  TEXT = 'TEXT',
}

export const getCircleCursor = (radius: number, isErasing: boolean): string => {
  const svgCrossPlus = `<rect shape-rendering="geometricPrecision" x="${radius - 5}" y="${radius - 1.5}" width="10" height="3" fill="white"/>
  <rect shape-rendering="geometricPrecision" x="${radius - 1.5}" y="${radius - 5}" width="3" height="10" fill="white"/>
  <rect shape-rendering="geometricPrecision" x="${radius - 4}" y="${radius - 0.5}" width="8" height="1" fill="black"/>
  <rect shape-rendering="geometricPrecision" x="${radius - 0.5}" y="${radius - 4}" width="1" height="8" fill="black"/>`

  const svgCrossMinus = `<rect shape-rendering="geometricPrecision" x="${radius - 5}" y="${radius - 1.5}" width="10" height="3" fill="white"/>
  <rect shape-rendering="geometricPrecision" x="${radius - 4}" y="${radius - 0.5}" width="8" height="1" fill="black"/>`

  const svgCrossDot = `<rect shape-rendering="geometricPrecision" x="${radius - 1.5}" y="${radius - 1.5}" width="3" height="3" fill="white"/>
  <rect shape-rendering="geometricPrecision" x="${radius - 0.5}" y="${radius - 0.5}" width="1" height="1" fill="black"/>`

  const svgCursor = `<svg viewBox="0 0 ${radius * 2} ${radius * 2}" height="${radius * 2}" width="${radius * 2}" xmlns="http://www.w3.org/2000/svg">
    <circle shape-rendering="geometricPrecision" cx="${radius}" cy="${radius}" r="${radius - 2}" stroke-width="4" stroke="white" fill="transparent"/>
    <circle shape-rendering="geometricPrecision" cx="${radius}" cy="${radius}" r="${radius - 2}" stroke-width="2" stroke="black" fill="transparent"/>
    ${!isErasing && radius >= 16 ? svgCrossPlus : ''}
    ${isErasing && radius >= 16 ? svgCrossMinus : ''}
    ${radius < 16 ? svgCrossDot : ''}
  </svg>`

  return `url("data:image/svg+xml,${encodeURIComponent(svgCursor)}") ${radius} ${radius}, auto`
}
