/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OnboardingTaskType } from './OnboardingTaskType';
import {
    OnboardingTaskTypeFromJSON,
    OnboardingTaskTypeFromJSONTyped,
    OnboardingTaskTypeToJSON,
} from './OnboardingTaskType';

/**
 * 
 * @export
 * @interface CompleteUserOnboardingTaskRequest
 */
export interface CompleteUserOnboardingTaskRequest {
    /**
     * 
     * @type {OnboardingTaskType}
     * @memberof CompleteUserOnboardingTaskRequest
     */
    taskType: OnboardingTaskType;
}

/**
 * Check if a given object implements the CompleteUserOnboardingTaskRequest interface.
 */
export function instanceOfCompleteUserOnboardingTaskRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "taskType" in value;

    return isInstance;
}

export function CompleteUserOnboardingTaskRequestFromJSON(json: any): CompleteUserOnboardingTaskRequest {
    return CompleteUserOnboardingTaskRequestFromJSONTyped(json, false);
}

export function CompleteUserOnboardingTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompleteUserOnboardingTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskType': OnboardingTaskTypeFromJSON(json['task_type']),
    };
}

export function CompleteUserOnboardingTaskRequestToJSON(value?: CompleteUserOnboardingTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task_type': OnboardingTaskTypeToJSON(value.taskType),
    };
}

