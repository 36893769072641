import type * as React from 'react'
import styles from './styles/training-options.module.scss'
import { Collapse, Flex } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useProjectSettings } from '@app/api/hooks'
import { AlgorithmSelector, AlgorithmSettings } from '@components/modals/project-settings/algorithm-settings'

interface TrainingOptionsProps {
  projectId: string | undefined
}
export const TrainingOptions: React.FC<TrainingOptionsProps> = ({ projectId }) => {
  const { data: projectSettings } = useProjectSettings(projectId)

  return <Flex vertical className={styles.trainingOptions}>
    <AlgorithmSelector projectId={projectId}/>
    <Collapse
      className={styles.collapseOptions}
      items={[{
        key: '1',
        label: <FormattedMessage id="training-section.algorithm-options.label" defaultMessage={'Training Options'} />,
        children: <>
          <AlgorithmSettings
            projectId={projectId}
            algorithmId={projectSettings?.algorithmId}
          />
        </>,
      }]}
      ghost />
  </Flex>
}
