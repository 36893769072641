import { EVENTS_ID } from '@app/constants'
import { Modal, Tabs, type TabsProps } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './styles/global-settings-modal.module.scss'
import type { DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable'
import Draggable from 'react-draggable'
import { EditorSettings } from '@components/modals/global-settings/editor-settings'

enum GlobalSettingsModalTabsKeys {
  EDITOR = 'EDITOR',
}

interface GlobalSettingsModalProps {
  isOpen?: boolean
  onClose?: () => void
}
export const GlobalSettingsModal: React.FC<GlobalSettingsModalProps> = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false)

  useEffect(() => {
    if (props.isOpen !== undefined) {
      setIsOpen(props.isOpen)
    }
  }, [props.isOpen])

  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
    props.onClose?.()
  }

  useEffect(() => {
    window.addEventListener(EVENTS_ID.GLOBAL_SETTING_OPEN, openModal)
    return () => {
      window.removeEventListener(EVENTS_ID.GLOBAL_SETTING_OPEN, openModal)
    }
  }, [])

  const draggleRef = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)
  const onStart: DraggableEventHandler = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (targetRect === undefined) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  const editorSettingsItem = {
    key: GlobalSettingsModalTabsKeys.EDITOR,
    label: <FormattedMessage id="global-settings.modal.tab.editor.title" defaultMessage={'Editor'} />,
    children: <EditorSettings />,
  }

  const tabsItems: TabsProps['items'] = [editorSettingsItem]

  return <Modal
    className={styles.globalSettingsModal}
    open={isOpen}
    title={
      <div
        className={styles.globalSettingsModalTitle}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false)
          }
        }}
        onMouseOut={() => {
          setDisabled(true)
        }}
      >
        <FormattedMessage id="global-settings.modal.title" defaultMessage={'Global Settings'} />
      </div>
    }
    closable={true}
    onCancel={closeModal}
    footer={null}
    onOk={closeModal}
    style={{ height: '600px' }}
    mask={false}
    maskClosable={false}
    wrapClassName={styles.globalSettingsModalWrapper}

    modalRender={(modal) => (
      <Draggable
        disabled={disabled}
        bounds={bounds}
        nodeRef={draggleRef}
        onStart={(event, uiData) => { onStart(event, uiData) }}
      >
        <div ref={draggleRef}>{modal}</div>
      </Draggable>
    )}
  >
    <Tabs
      className={styles.globalSettingsTabs}
      defaultActiveKey={GlobalSettingsModalTabsKeys.EDITOR}
      items={tabsItems}
    />
  </Modal>
}
