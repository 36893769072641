import type * as React from 'react'
import styles from './styles/classes-distribution.module.scss'
import { Flex, Tooltip, Typography } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { TOOLTIP_MOUSE_ENTER_DELAY, getColor } from '@app/constants'
import { useAnnotationClassesDistribution } from '@app/api/hooks'
import { useProjectId, useSelectedImage } from '@app/pages/editor-page/hooks/editor-page'

const { Title } = Typography

interface ClassesDistributionProps {
  classDistribution?: Record<string, number>
  imageId?: string
  title: string
}
export const ClassesDistributionComponent: React.FC<ClassesDistributionProps> = (props) => {
  return (
    <div className={styles.classesDistribution}>
      <div className={styles.headerContainer}>
        <Title level={5} className={styles.title}>
          {props.title}
        </Title>
      </div>
      <div className={styles.content}>
        <div className={styles.classesDistributionContour} >
          <Flex className={styles.classesDistributionFlex} gap={0} justify='center' align='middle'>
            {
              (props.classDistribution !== null) && (props.classDistribution !== undefined) && (Object.keys(props.classDistribution).length > 0) && Object.entries(props.classDistribution).map(([key, classMetricValue]) => {
                const distributionString: string = Math.ceil(classMetricValue * 100).toString()
                const distributionStringToolTip: string = (classMetricValue * 100).toFixed(2)
                const imageId: string = props.imageId ?? 'image'
                return (<Tooltip
                  title={
                    <FormattedMessage id={'class-editor.distribution-tooltip'} defaultMessage={'{distributionStringToolTip}%'} values={{ distributionStringToolTip }} />
                  }
                  key={`${imageId}-${key}-${classMetricValue}}`}
                  mouseLeaveDelay={0}
                  mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
                >
                  <div key={key} style={{ width: `${distributionString}%`, backgroundColor: getColor(+key) }} />
                </Tooltip>)
              })
            }
          </Flex>
        </div>
      </div>
    </div>
  )
}

export const AnnotationClassesDistributionComponent: React.FC = () => {
  const intl = useIntl()
  const projectId = useProjectId()
  const selectedImage = useSelectedImage()
  const { data: annotationDistribution } = useAnnotationClassesDistribution(projectId, selectedImage?.data?.id)
  return <ClassesDistributionComponent
    title={intl.formatMessage({
      id: 'class-editor.annotation.distribution.label',
      defaultMessage: 'Annotation distribution',
    })}
    imageId={selectedImage?.data?.id}
    classDistribution={annotationDistribution} />
}
