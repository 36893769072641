import { type IntlShape } from 'react-intl'
import { type NotificationInstance } from 'antd/lib/notification/interface'

export const notifyProjectDeletionError = (
  intl: IntlShape,
  notificationErrorApi: NotificationInstance,
): void => {
  notificationErrorApi.error({
    message: intl.formatMessage({
      id: 'project-list.edit.archive-project.notification.error.title',
      defaultMessage: 'Project deletion failed',
    }),
    description: intl.formatMessage({
      id: 'project-list.edit.archive-project.notification.error.body',
      defaultMessage: 'An error occurred while deleting a project. Please try again.',
    }),
  })
}

export const notifyProjectClassEditionError = (
  intl: IntlShape,
  notificationErrorApi: NotificationInstance,
  key?: string,
): void => {
  notificationErrorApi.error({
    key,
    message: intl.formatMessage({
      id: 'class-editor.edit-class.error.title',
      defaultMessage: 'Class Edition Failed',
    }),
    description: intl.formatMessage({
      id: 'class-editor.edit-class.error.description',
      defaultMessage: 'This may happen if the updated class name is not unique. Make sure there is no other class with the same name, then retry.',
    }),
  })
}

export const notifyProjectClassCreationError = (
  intl: IntlShape,
  notificationErrorApi: NotificationInstance,
): void => {
  notificationErrorApi.error({
    message: intl.formatMessage({
      id: 'class-editor.create-class.error.title',
      defaultMessage: 'Class Creation Failed',
    }),
    description: intl.formatMessage({
      id: 'class-editor.create-class.error.description',
      defaultMessage: 'This may happen if the name given to the new class is not unique. Make sure none of the existing classes have a name that look like "new class x", then retry.',
    }),
  })
}

export const notifyProjectClassDeletionError = (
  intl: IntlShape,
  notificationErrorApi: NotificationInstance,
): void => {
  notificationErrorApi.error({
    message: intl.formatMessage({
      id: 'class-editor.delete-class.error.title',
      defaultMessage: 'Class Deletion Failed',
    }),
    description: intl.formatMessage({
      id: 'class-editor.delete-class.error.description',
      defaultMessage: 'Please retry the operation',
    }),
  })
}
