import { type DirectMeasureWithStats, AngleUnit, DirectMeasureType } from '@app/api/openapi'
import { createColumnHelper, type ColumnDef } from '@tanstack/react-table'
import FocusIcon from '@material-design-icons/svg/outlined/center_focus_strong.svg'
import { Button } from '@shadcn-ui/components/ui/button'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from 'antd'
import { EVENT_DISPATCHER } from '@app/event'
import { sortableHeader, numberCellValue } from '@app/pages/editor-page/databrowser/table/commont'

const directMeasureColumnHelper = createColumnHelper<DirectMeasureWithStats>()

interface DirectMeasureColumnsDefOptions {
  pixelSizeUm: number | undefined
  angleUnit: AngleUnit | undefined
  imageId: string | undefined
}
export const createDirectMeasureColumnsDef = ({
  pixelSizeUm = undefined,
  angleUnit = AngleUnit.Degree,
  imageId,
}: DirectMeasureColumnsDefOptions): ColumnDef<DirectMeasureWithStats>[] => {
  return [
    directMeasureColumnHelper.display({
      id: 'action-focus',
      header: undefined,
      cell: ({ row }) => {
        const onClickFocus = (): void => {
          if (imageId !== undefined) {
            EVENT_DISPATCHER.dispatchEditorCanvasFocusObject(imageId, [row.original.minX, row.original.minY, row.original.maxX, row.original.maxY])
          }
        }
        return <Button variant="ghost" className="p-0" onClick={onClickFocus}>
          <Tooltip title={<FormattedMessage id="databrowser.prediction-detected-object.cell.action-focus.tooltip" defaultMessage="Click to focus" />}>
            <FocusIcon />
          </Tooltip>
        </Button>
      },
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: false,
    }) as ColumnDef<DirectMeasureWithStats>,
    directMeasureColumnHelper.display({
      id: 'index',
      header: () => <FormattedMessage id="databrowser.direct-measure.column.id.header.title" defaultMessage="#" />,
      cell: ({ row }) => {
        return row.index + 1
      },
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats>,
    directMeasureColumnHelper.accessor('type', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.direct-measure.column.direct-measure-type.header.title" defaultMessage="Type" />,
        'left',
      ),
      cell: ({ getValue }) => {
        const directMeasureType = getValue()
        switch (directMeasureType) {
          case DirectMeasureType.Distance:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.distance.value" defaultMessage="Distance" />
          case DirectMeasureType.Area:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.area.value" defaultMessage="Area" />
          case DirectMeasureType.Angle:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.angle.value" defaultMessage="Angle" />
          case DirectMeasureType.Arc:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.arc.value" defaultMessage="Arc" />
          case DirectMeasureType.Circle:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.circle.value" defaultMessage="Radius" />
          case DirectMeasureType.Ellipse:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.ellipse.value" defaultMessage="Ellipse" />
          case DirectMeasureType.Perimeter:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.perimeter.value" defaultMessage="Perimeter" />
          case DirectMeasureType.Rectangle:
            return <FormattedMessage id="databrowser.direct-measure.cell.direct-measure-type.rectangle.value" defaultMessage="Rectangle" />
        }
      },
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats>,
    directMeasureColumnHelper.accessor('area', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.direct-measure.column.area.header.title.um" defaultMessage="Area (μm²)" />
          : <FormattedMessage id="databrowser.direct-measure.column.area.header.title.px" defaultMessage="Area (px²)" />,
        'right',
      ),
      cell: numberCellValue(pixelSizeUm),
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats>,
    directMeasureColumnHelper.accessor('length', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.direct-measure.column.length.header.title.um" defaultMessage="Length (μm)" />
          : <FormattedMessage id="databrowser.direct-measure.column.length.header.title.px" defaultMessage="Length (px)" />,
        'right',
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats>,
    directMeasureColumnHelper.accessor('angle', {
      header: sortableHeader(
        angleUnit === AngleUnit.Degree
          ? <FormattedMessage id="databrowser.direct-measure.column.angle.header.title.degree" defaultMessage="Angle (°)" />
          : <FormattedMessage id="databrowser.direct-measure.column.angle.header.title.rad" defaultMessage="Angle (rad)" />,
        'right',
      ),
      cell: numberCellValue(undefined, angleUnit),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats>,
    directMeasureColumnHelper.accessor('radius', {
      header: sortableHeader(
        pixelSizeUm !== undefined
          ? <FormattedMessage id="databrowser.direct-measure.column.radius.header.title.um" defaultMessage="Radius (μm)" />
          : <FormattedMessage id="databrowser.direct-measure.column.radius.header.title.px" defaultMessage="Radius (px)" />,
        'right',
      ),
      cell: numberCellValue(pixelSizeUm),
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<DirectMeasureWithStats>,
  ]
}
