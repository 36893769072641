/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The tasks available as onboarding tutorial
 * The format follow
 * {Modifier}_{NAMESPACE}_{TASK_NAME}
 * - {Modifier} is PRIVATE or PUBLIC
 * - {NAMESPACE} is from UserOnboardingTaskNamespace
 * - {TASK_NAME} is depending on action required
 * @export
 */
export const OnboardingTaskType = {
    PrivateUnknown: 'PRIVATE_UNKNOWN',
    PublicIrreversibleUserHasCompletedOnboardingFeedback: 'PUBLIC_IRREVERSIBLE_USER_HAS_COMPLETED_ONBOARDING_FEEDBACK',
    PublicIrreversibleUserHasDiscoveredMenu: 'PUBLIC_IRREVERSIBLE_USER_HAS_DISCOVERED_MENU',
    PublicIrreversibleUserHasReadPluginInstallationInstructions: 'PUBLIC_IRREVERSIBLE_USER_HAS_READ_PLUGIN_INSTALLATION_INSTRUCTIONS',
    PublicIrreversibleUserHasReadLogoutNotice: 'PUBLIC_IRREVERSIBLE_USER_HAS_READ_LOGOUT_NOTICE',
    PublicProjectListUserHasCreatedProject: 'PUBLIC_PROJECT_LIST_USER_HAS_CREATED_PROJECT',
    PublicProjectListUserHasReadWelcome: 'PUBLIC_PROJECT_LIST_USER_HAS_READ_WELCOME',
    PublicProjectListUserHasReadPageTutorialWelcome: 'PUBLIC_PROJECT_LIST_USER_HAS_READ_PAGE_TUTORIAL_WELCOME',
    PublicProjectListUserHowToCreateProject: 'PUBLIC_PROJECT_LIST_USER_HOW_TO_CREATE_PROJECT',
    PublicProjectListUserHowToOpenProject: 'PUBLIC_PROJECT_LIST_USER_HOW_TO_OPEN_PROJECT',
    PublicProjectListUserHowToEditProject: 'PUBLIC_PROJECT_LIST_USER_HOW_TO_EDIT_PROJECT',
    PublicAnnotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain: 'PUBLIC_ANNOTATION_PAGE_USER_HAS_READ_ANNOTATE_PAGE_TUTORIAL_ANNOTATE_AND_TRAIN',
    PublicAnnotationPageUserHasReadUploadTrainingImage: 'PUBLIC_ANNOTATION_PAGE_USER_HAS_READ_UPLOAD_TRAINING_IMAGE',
    PublicAnnotationPageUserHasReadClassSection: 'PUBLIC_ANNOTATION_PAGE_USER_HAS_READ_CLASS_SECTION',
    PublicAnnotationPageUserHasReadAddAnnotation: 'PUBLIC_ANNOTATION_PAGE_USER_HAS_READ_ADD_ANNOTATION',
    PublicAnnotationPageUserHasReadTrain: 'PUBLIC_ANNOTATION_PAGE_USER_HAS_READ_TRAIN',
    PublicAnnotationPageUserHasReadAccessValidate: 'PUBLIC_ANNOTATION_PAGE_USER_HAS_READ_ACCESS_VALIDATE',
    PublicAnnotationPageUserHasReadAddSmartAnnotation: 'PUBLIC_ANNOTATION_PAGE_USER_HAS_READ_ADD_SMART_ANNOTATION',
    PublicValidationPageUserHasReadValidateIntroduction: 'PUBLIC_VALIDATION_PAGE_USER_HAS_READ_VALIDATE_INTRODUCTION',
    PublicValidationPageUserHasReadUploadImage: 'PUBLIC_VALIDATION_PAGE_USER_HAS_READ_UPLOAD_IMAGE',
    PublicValidationPageUserHasReadVisualizeResultsOpacity: 'PUBLIC_VALIDATION_PAGE_USER_HAS_READ_VISUALIZE_RESULTS_OPACITY',
    PublicValidationPageUserHasReadVisualizeResultsClassHide: 'PUBLIC_VALIDATION_PAGE_USER_HAS_READ_VISUALIZE_RESULTS_CLASS_HIDE',
    PublicValidationPageUserHasReadSendImageAnnotate: 'PUBLIC_VALIDATION_PAGE_USER_HAS_READ_SEND_IMAGE_ANNOTATE',
    PublicValidationPageUserHasReadDownloadPlugin: 'PUBLIC_VALIDATION_PAGE_USER_HAS_READ_DOWNLOAD_PLUGIN'
} as const;
export type OnboardingTaskType = typeof OnboardingTaskType[keyof typeof OnboardingTaskType];


export function OnboardingTaskTypeFromJSON(json: any): OnboardingTaskType {
    return OnboardingTaskTypeFromJSONTyped(json, false);
}

export function OnboardingTaskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingTaskType {
    return json as OnboardingTaskType;
}

export function OnboardingTaskTypeToJSON(value?: OnboardingTaskType | null): any {
    return value as any;
}

