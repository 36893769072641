import { toMercator, toWgs84 } from '@turf/projection'
import GeoJSON from 'ol/format/GeoJSON'
import { makePolygonValid } from './utils'
import difference from '@turf/difference'
import flatten from '@turf/flatten'
import bboxPolygon from '@turf/bbox-polygon'
import intersect from '@turf/intersect'
import union from '@turf/union'
import { type Feature as GeoJSONFeature, type MultiPolygon as GeoJSONMultiPolygon, type Polygon as GeoJSONPolygon, type FeatureCollection as GeoJSONFeatureCollection } from 'geojson'
import { type Map, type Collection, Feature } from 'ol'
import { MultiPolygon, type Polygon } from 'ol/geom'
import { FEATURE_TYPE, FeatureType } from './shapes/common'
import { featureCollection } from '@turf/helpers'
import { v4 as uuidv4 } from 'uuid'

export type DefaultClassAnnotationProperties = Record<string, unknown>
export type ClassAnnotationProperties<AP extends DefaultClassAnnotationProperties = DefaultClassAnnotationProperties> = AP & {
  colorIndex: number
  classAnnotationId: string
}

export interface ClassAnnotationsParameters {
  eraseOtherClassesOnOverlap: boolean
  annotationPixelResolution: number
}

export class ClassAnnotations<AP extends DefaultClassAnnotationProperties = DefaultClassAnnotationProperties> {
  private _annotationParameters: ClassAnnotationsParameters
  private _annotationProperties: AP
  private _colorIndex: number
  private readonly _getExtent: () => [number, number, number, number] | undefined
  private readonly _collection: Collection<Feature<Polygon>>
  private readonly _map: Map | undefined | null

  constructor (
    collection: Collection<Feature<Polygon>>,
    annotationParameters: ClassAnnotationsParameters,
    annotationProperties: AP,
    colorIndex: number,
    map: Map | undefined | null,
    getExtent: () => [number, number, number, number] | undefined,
  ) {
    this._collection = collection
    this._annotationParameters = annotationParameters
    this._annotationProperties = annotationProperties
    this._colorIndex = colorIndex
    this._map = map
    this._getExtent = getExtent
  }

  /**
   * Updates the annotations from the features.
   *
   */
  public readonly featuresUpdateAnnotations = (annotationFeatures: Feature<Polygon>[], colorIndex: number | undefined = undefined): void => {
    const extent = this._getExtent()
    if (extent === undefined) {
      console.error('Failed to get extends')
      return
    }

    if (this._map === undefined || this._map === null) {
      console.error('Failed to get map')
      return
    }

    const geojsonParser = new GeoJSON()
    // We need to clone the annotation properties to prevent the properties to change during the processing
    const annotationProperties = { ...this._annotationProperties, colorIndex: colorIndex ?? this._colorIndex }

    const annotationMultiPolygon = new MultiPolygon(annotationFeatures.map((feature) => feature.getGeometry() as unknown as Polygon))
    const annotationGeojson = geojsonParser.writeFeatureObject(new Feature(annotationMultiPolygon)) as GeoJSONFeature<GeoJSONMultiPolygon>
    const annotationMultiPolygonTurf = toWgs84(annotationGeojson)

    const turfWgs84DrawingExtentPolygon = toWgs84(bboxPolygon(extent))
    const turfWgs84PolygonWithinExtent = intersect(featureCollection<GeoJSONMultiPolygon | GeoJSONPolygon>([
      annotationMultiPolygonTurf,
      turfWgs84DrawingExtentPolygon,
    ]))

    if (turfWgs84PolygonWithinExtent === null) {
      return
    }

    const groupedAnnotationFeatures: Record<string, Feature<Polygon>[]> = {}
    // Group by color index
    this._collection.getArray().forEach((feature) => {
      const properties = feature.getProperties() as ClassAnnotationProperties<AP>
      groupedAnnotationFeatures[properties.colorIndex] = groupedAnnotationFeatures[properties.colorIndex] ?? []
      groupedAnnotationFeatures[properties.colorIndex].push(feature)
    })

    const newFeatures: Feature<Polygon>[] = []
    // For each group of annotation features
    // We make the diff between the new annotation and the existing annotations if the color index is different
    // And we only keep the diff of the existing annotations as there are overlapping with the new annotation
    // If the color index is the same, then we merge the annotation (this step is done later)
    Object.entries(groupedAnnotationFeatures).forEach(([colorIndex, arrayFeaturePolygon]) => {
      if (colorIndex === `${annotationProperties.colorIndex}`) {
        // We will merge the new annotation with the existing annotation of the same color index later
        return
      }
      const existingMultiPolygon = new MultiPolygon(arrayFeaturePolygon.map((feature) => feature.getGeometry() as unknown as Polygon))
      const existingGeojson = geojsonParser.writeFeatureObject(new Feature(existingMultiPolygon)) as GeoJSONFeature<GeoJSONMultiPolygon>
      const existingMultiPolygonTurf = toWgs84(existingGeojson)

      if (this._annotationParameters.eraseOtherClassesOnOverlap) {
        const featureDiff: GeoJSONFeature<GeoJSONPolygon | GeoJSONMultiPolygon> | null = difference(featureCollection([
          existingMultiPolygonTurf,
          annotationMultiPolygonTurf,
        ]))

        if (featureDiff !== null) {
          const diffTurfWgs84FeatureCollectionPolygon: GeoJSONFeatureCollection<GeoJSONPolygon> = flatten(featureDiff)
          diffTurfWgs84FeatureCollectionPolygon.features.forEach((diffTurfWgs84FeaturePolygon) => {
            const diffTurfMercatorFeaturePolygon: GeoJSONFeature<GeoJSONPolygon> = toMercator(diffTurfWgs84FeaturePolygon)
            const diffOlMercatorFeaturePolygon: Feature<Polygon> = geojsonParser.readFeature(diffTurfMercatorFeaturePolygon) as Feature<Polygon>
            const polygonGeometry = diffOlMercatorFeaturePolygon.getGeometry()

            if (polygonGeometry === null || polygonGeometry === undefined) {
              console.error(`Unexpected nullish polygon geometry (${polygonGeometry})`)
            } else {
              const validPolygon = makePolygonValid(polygonGeometry)
              if (validPolygon !== undefined) {
                diffOlMercatorFeaturePolygon.setProperties({
                  ...arrayFeaturePolygon[0].getProperties(),
                  [FEATURE_TYPE]: FeatureType.CLASS_ANNOTATION,
                  classAnnotationId: uuidv4(),
                })
                diffOlMercatorFeaturePolygon.setGeometry(validPolygon)
                newFeatures.push(diffOlMercatorFeaturePolygon)
              } else {
                diffOlMercatorFeaturePolygon.dispose()
              }
            }
          })
        }
      }
    })

    this._collection.clear()
    this._collection.extend(newFeatures)

    const existingAnnotationOfSameColorIndexAsMultipolygon = new MultiPolygon((groupedAnnotationFeatures[annotationProperties.colorIndex] ?? []).map((feature) => feature.getGeometry() as unknown as Polygon))
    const existingAnnotationOfSameColorIndexAsMultipolygonTurf = toWgs84(geojsonParser.writeFeatureObject(new Feature(existingAnnotationOfSameColorIndexAsMultipolygon)) as GeoJSONFeature<GeoJSONMultiPolygon>)

    // Merge turfWgs84PolygonWithinExtent and existingAnnotationOfSameColorIndexAsMultipolygon
    const mergedAnnotationGeoJSONMultiPolygon = flatten(union(
      featureCollection([
        turfWgs84PolygonWithinExtent,
        existingAnnotationOfSameColorIndexAsMultipolygonTurf,
      ]),
    ) as GeoJSONFeature<GeoJSONMultiPolygon>)

    mergedAnnotationGeoJSONMultiPolygon.features.forEach((turfWgs84FeaturePolygonWithinExtent) => {
      const turfMercatorFeaturePolygonWithinExtent = toMercator(turfWgs84FeaturePolygonWithinExtent)
      const olFeaturePolygonWithinExtent = geojsonParser.readFeature(turfMercatorFeaturePolygonWithinExtent) as Feature<Polygon>
      olFeaturePolygonWithinExtent.setProperties({
        ...annotationProperties,
        [FEATURE_TYPE]: FeatureType.CLASS_ANNOTATION,
        classAnnotationId: uuidv4(),
      })
      this._collection.push(olFeaturePolygonWithinExtent)
    })
  }

  public readonly setAnnotationProperties = (annotationProperties: AP): void => {
    this._annotationProperties = annotationProperties
  }

  public readonly setAnnotationParameters = (annotationParameters: ClassAnnotationsParameters): void => {
    this._annotationParameters = annotationParameters
  }

  public readonly setColorIndex = (colorIndex: number): void => {
    this._colorIndex = colorIndex
  }
}
