import { type EditorStoreState, useEditorStore, type EditorStoreAction, type EditorStoreStateComputed } from '../../../stores/editor'
import { api } from '@app/api/api'
import { useImageProjectInfo, useProject } from '@app/api/hooks'
import { ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetMeasureEnum, type ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetTypeEnum, type ProjectDatasetContext, type ProjectImageResponse, type ProjectResponse } from '@app/api/openapi'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { type SWRResponse } from 'swr'
import { mutate as globalMutate } from 'swr'
import { CACHE_KEYS, type CacheKey } from '@app/api/cache-keys'

type useSelectedToolReturn = [
  EditorStoreState['selectedTool'],
  EditorStoreAction['setSelectedTool'],
]
export function useSelectedTool (): useSelectedToolReturn {
  const selectedTool = useEditorStore((state) => state.selectedTool)
  const setSelectedTool = useEditorStore((state) => state.setSelectedTool)
  return [selectedTool, setSelectedTool]
}

type useBrushSizeReturn = [
  EditorStoreState['brushSize'],
  EditorStoreAction['setBrushSize'],
]
export function useBrushSize (): useBrushSizeReturn {
  return useEditorStore((state) => [state.brushSize, state.setBrushSize])
}

type useSelectedAnnotationClassIndexReturn = [
  EditorStoreState['selectedAnnotationClassColorIndex'],
  EditorStoreAction['setSelectedAnnotationClassColorIndex'],
]
export function useSelectedAnnotationClassColorIndex (): useSelectedAnnotationClassIndexReturn {
  const selectedAnnotationClassColorIndex = useEditorStore(
    (state) => state.selectedAnnotationClassColorIndex,
  )
  const setSelectedAnnotationClassColorIndex = useEditorStore(
    (state) => state.setSelectedAnnotationClassColorIndex,
  )
  return [selectedAnnotationClassColorIndex, setSelectedAnnotationClassColorIndex]
}

type useHiddenAnnotationClassIndexesReturn = [
  EditorStoreState['hiddenAnnotationClassIndexes'],
  EditorStoreAction['setHiddenAnnotationClassIndexes'],
]
export function useHiddenAnnotationClassIndexes (): useHiddenAnnotationClassIndexesReturn {
  const hiddenAnnotationClassIndexes = useEditorStore((state) => state.hiddenAnnotationClassIndexes)
  const setHiddenAnnotationClassIndexes = useEditorStore(
    (state) => state.setHiddenAnnotationClassIndexes,
  )
  return [hiddenAnnotationClassIndexes, setHiddenAnnotationClassIndexes]
}

type useGlobalAnnotationOpacityReturn = [
  ReturnType<EditorStoreStateComputed['getGlobalAnnotationOpacity']>,
  EditorStoreAction['setGlobalAnnotationOpacity'],
]
export function useGlobalAnnotationOpacity (): useGlobalAnnotationOpacityReturn {
  const globalAnnotationOpacity = useEditorStore((state) => state.getGlobalAnnotationOpacity())
  const setGlobalAnnotationOpacity = useEditorStore(
    (state) => state.setGlobalAnnotationOpacity,
  )
  return [globalAnnotationOpacity, setGlobalAnnotationOpacity]
}

type useGlobalAnnotationFullOpacityReturn = [
  EditorStoreState['globalAnnotationFullOpacity'],
  EditorStoreAction['setGlobalAnnotationFullOpacity'],
]
export function useGlobalAnnotationFullOpacity (): useGlobalAnnotationFullOpacityReturn {
  const globalAnnotationFullOpacity = useEditorStore((state) => state.globalAnnotationFullOpacity)
  const setGlobalAnnotationFullOpacity = useEditorStore(
    (state) => state.setGlobalAnnotationFullOpacity,
  )
  return [globalAnnotationFullOpacity, setGlobalAnnotationFullOpacity]
}

type useGlobalPredictionOpacityReturn = [
  ReturnType<EditorStoreStateComputed['getGlobalPredictionOpacity']>,
  EditorStoreAction['setGlobalPredictionOpacity'],
]
export function useGlobalPredictionOpacity (): useGlobalPredictionOpacityReturn {
  const globalPredictionOpacity = useEditorStore(
    (state) => state.getGlobalPredictionOpacity(),
  )
  const setGlobalPredictionOpacity = useEditorStore(
    (state) => state.setGlobalPredictionOpacity,
  )
  return [globalPredictionOpacity, setGlobalPredictionOpacity]
}

type useGlobalPredictionFullOpacityReturn = [
  EditorStoreState['globalPredictionFullOpacity'],
  EditorStoreAction['setGlobalPredictionFullOpacity'],
]
export function useGlobalPredictionFullOpacity (): useGlobalPredictionFullOpacityReturn {
  const globalPredictionFullOpacity = useEditorStore(
    (state) => state.globalPredictionFullOpacity,
  )
  const setGlobalPredictionFullOpacity = useEditorStore(
    (state) => state.setGlobalPredictionFullOpacity,
  )
  return [globalPredictionFullOpacity, setGlobalPredictionFullOpacity]
}

type useGlobalPredictionVisibilityReturn = [
  EditorStoreState['globalPredictionVisibility'],
  EditorStoreAction['setGlobalPredictionVisibility'],
]
export function useGlobalPredictionVisibility (): useGlobalPredictionVisibilityReturn {
  const globalPredictionVisibility = useEditorStore(
    (state) => state.globalPredictionVisibility,
  )
  const setGlobalPredictionVisibility = useEditorStore(
    (state) => state.setGlobalPredictionVisibility,
  )
  return [globalPredictionVisibility, setGlobalPredictionVisibility]
}

type UseIsDirectMeasureAreaToolFreehand = [
  EditorStoreState['isDirectMeasureAreaToolFreehand'],
  EditorStoreAction['setIsDirectMeasureAreaToolFreehand'],
]
export const useIsDirectMeasureAreaToolFreehand = (): UseIsDirectMeasureAreaToolFreehand => {
  const isFreehand = useEditorStore((state) => state.isDirectMeasureAreaToolFreehand)
  const setIsFreehand = useEditorStore((state) => state.setIsDirectMeasureAreaToolFreehand)
  return [isFreehand, setIsFreehand] as const
}

type UseIsDirectMeasurePerimeterToolFreehand = [
  EditorStoreState['isDirectMeasurePerimeterToolFreehand'],
  EditorStoreAction['setIsDirectMeasurePerimeterToolFreehand'],
]
export const useIsDirectMeasurePerimeterToolFreehand = (): UseIsDirectMeasurePerimeterToolFreehand => {
  const isFreehand = useEditorStore((state) => state.isDirectMeasurePerimeterToolFreehand)
  const setIsFreehand = useEditorStore((state) => state.setIsDirectMeasurePerimeterToolFreehand)
  return [isFreehand, setIsFreehand] as const
}

type useHiddenPredictionClassIndexesReturn = [
  EditorStoreState['hiddenPredictionClassIndexes'],
  EditorStoreAction['setHiddenPredictionClassIndexes'],
]
export function useHiddenPredictionClassIndexes (): useHiddenPredictionClassIndexesReturn {
  const hiddenPredictionClassIndexes = useEditorStore((state) => state.hiddenPredictionClassIndexes)
  const setHiddenPredictionClassIndexes = useEditorStore(
    (state) => state.setHiddenPredictionClassIndexes,
  )
  return [hiddenPredictionClassIndexes, setHiddenPredictionClassIndexes]
}

type useGlobalAnnotationVisibilityReturn = [
  EditorStoreState['globalAnnotationVisibility'],
  EditorStoreAction['setGlobalAnnotationVisibility'],
]
export function useGlobalAnnotationVisibility (): useGlobalAnnotationVisibilityReturn {
  const globalAnnotationVisibility = useEditorStore(
    (state) => state.globalAnnotationVisibility,
  )
  const setGlobalAnnotationVisibility = useEditorStore(
    (state) => state.setGlobalAnnotationVisibility,
  )
  return [globalAnnotationVisibility, setGlobalAnnotationVisibility]
}

export const useProjectSlug = (): string | undefined => {
  const projectSlug = useEditorStore((store) => store.projectSlug)
  const setProjectSlug = useEditorStore((store) => store.setProjectSlug)
  const { projectSlug: projectSlugFromURL } = useParams<{ projectSlug: string }>()
  useEffect(() => {
    if (projectSlugFromURL !== projectSlug) {
      setProjectSlug(projectSlugFromURL)
    }
  }, [projectSlug, projectSlugFromURL, setProjectSlug])
  return projectSlug
}

export const useCurrentProject = (): SWRResponse<ProjectResponse> => {
  const projectSlug = useProjectSlug()
  return useProject(projectSlug)
}

export const useProjectId = (): string | undefined => {
  const { data: project } = useCurrentProject()
  return project?.id
}

export const useDatasetContext = (): ProjectDatasetContext | undefined => {
  const datasetContext = useEditorStore((store) => store.datasetContext)
  return datasetContext
}

export const useSetDatasetContext = (): (datasetContext: ProjectDatasetContext | undefined) => void => {
  const setDatasetContext = useEditorStore((store) => store.setDatasetContext)
  return setDatasetContext
}

export const useSelectedImageId = (): string | undefined => {
  const selectedImageId = useEditorStore((store) => store.selectedImageId)
  return selectedImageId
}

export const useSetSelectedImageId = (): (imageId: string | undefined) => void => {
  const setSelectedImageId = useEditorStore((store) => store.setSelectedImageId)
  return setSelectedImageId
}

interface UseSelectedImageReturns {
  data: ProjectImageResponse | undefined
  mutate: (PixelSize: number | undefined) => Promise<void>
  isLoading: boolean
  isValidating: boolean
  error: unknown
}

export const useSelectedImage = (): UseSelectedImageReturns => {
  const projectId = useProjectId()
  const selectedImageId = useSelectedImageId()
  const imageProjectInfo = useImageProjectInfo(projectId, selectedImageId)
  const update = async (pixelSize: number | undefined): Promise<void> => {
    if (projectId !== undefined && selectedImageId !== undefined) {
      await api.updateImagePropertiesApiProjectsProjectIdImageImageIdPut({
        projectId,
        imageId: selectedImageId,
        projectImageProperties: {
          pixelSizeUm: pixelSize,
        },
      })
      await imageProjectInfo.mutate()
      await globalMutate((key) => {
        if (typeof key === 'object') {
          const cacheKey = key as CacheKey<unknown>
          if (cacheKey.keyId === CACHE_KEYS.IMAGE_OBJECT.name) {
            const imageObjectCacheKey = cacheKey as CacheKey<{ imageId: string, projectId: string, type: ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetTypeEnum, objectId: string, measure: ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetMeasureEnum }>
            if (imageObjectCacheKey.data.imageId === selectedImageId && imageObjectCacheKey.data.measure === ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetMeasureEnum.Thickness) {
              return true
            }
          }
        }
        return false
      })
    }
  }
  return {
    ...imageProjectInfo,
    data: selectedImageId !== undefined ? imageProjectInfo.data : undefined,
    mutate: update,
  }
}
