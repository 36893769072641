import { useAnalyticDataInstanceSegmentation } from '@app/api/analytic'
import { useImageProjectInfo, useProjectTrainingSnapshot } from '@app/api/hooks'
import { useEditorStore } from '@app/stores/editor'
import { Table, TableBody } from '@shadcn-ui/components/ui/table'
import { type FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Typography } from 'antd'
import { ObjectStatsContainer, StatNumberValue, StatStringValue, StatTitle, ThicknessMeasureTable } from '@app/pages/editor-page/object-overview/common'
import { useProjectId, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'

const { Title } = Typography

export const DetectedObjectOverview: FC = () => {
  const projectId = useProjectId()
  const selectedImageId = useSelectedImageId()
  const detectedObjectSelectedId = useEditorStore((state) => state.getDetectedObjectSelectedId())
  const [thicknessMeasureObjectId, setThicknessMeasureObject] = useEditorStore((state) => [state.thicknessMeasureObjectId, state.setThicknessMeasureObject])
  const imageProjectInfo = useImageProjectInfo(projectId, selectedImageId)
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: detectedObjects } = useAnalyticDataInstanceSegmentation(selectedImageId, projectTrainingSnapshot?.projectTrainingSnapshotId)

  const selectedDetectedObject = useMemo(() => {
    if (detectedObjectSelectedId === undefined || detectedObjects === undefined) {
      return undefined
    }
    return detectedObjects?.items.find((_detectedObject, index) => `${index}` === detectedObjectSelectedId)
  }, [detectedObjectSelectedId, detectedObjects])

  const selectedDetectedObjectIndex = useMemo(() => {
    if (detectedObjectSelectedId === undefined || detectedObjects === undefined) {
      return undefined
    }
    if (detectedObjects === null) {
      return undefined
    }
    for (const index of detectedObjects.items.keys()) {
      if (`${index}` === detectedObjectSelectedId) {
        return index + 1
      }
    }
    return undefined
  }, [detectedObjectSelectedId, detectedObjects])

  const pixelSizeUm = imageProjectInfo?.data?.pixelSizeUm ?? undefined

  if (selectedDetectedObject === undefined || selectedDetectedObjectIndex === undefined) {
    return <div className='text-clemex-shellGray'>
      <FormattedMessage
        id="detected-object-overview.no-selected-class-annotation"
        defaultMessage="No selected class annotation"
      />
    </div>
  }

  return <div className="flex flex-col gap-[8px]">
    <Table>
      <TableBody>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="detected-object-overview.class-annotation-id" defaultMessage='#' />
          } />
          <StatNumberValue value={selectedDetectedObjectIndex} decimal={0} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="detected-object-overview.class-name" defaultMessage='Class Name' />
          } />
          <StatStringValue value={selectedDetectedObject.className} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.detected-object-overview.area.um" defaultMessage={'Area (μm²)'} />
              : <FormattedMessage id="editor-page.object-overview.detected-object-overview.area.px" defaultMessage={'Area (px²)'} />
          } />
          <StatNumberValue value={selectedDetectedObject.area} pixelSizeUm={pixelSizeUm} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.detected-object-overview.shell-area.um" defaultMessage={'Shell Area (μm²)'} />
              : <FormattedMessage id="editor-page.object-overview.detected-object-overview.shell-area.px" defaultMessage={'Shell Area (px²)'} />
          } />
          <StatNumberValue value={selectedDetectedObject.shellArea} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.detected-object-overview.hull-area.um" defaultMessage={'Hull Area (μm²)'} />
              : <FormattedMessage id="editor-page.object-overview.detected-object-overview.hull-area.px" defaultMessage={'Hull Area (px²)'} />
          } />
          <StatNumberValue value={selectedDetectedObject.hullArea} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.detected-object-overview.width.um" defaultMessage={'Width (μm)'} />
              : <FormattedMessage id="editor-page.object-overview.detected-object-overview.width.px" defaultMessage={'Width (px)'} />
          } />
          <StatNumberValue value={selectedDetectedObject.width} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            pixelSizeUm !== undefined
              ? <FormattedMessage id="editor-page.object-overview.detected-object-overview.height.um" defaultMessage={'Height (μm)'} />
              : <FormattedMessage id="editor-page.object-overview.detected-object-overview.height.px" defaultMessage={'Height (px)'} />
          } />
          <StatNumberValue value={selectedDetectedObject.height} pixelSizeUm={pixelSizeUm}/>
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.detected-object-overview.centroid.x" defaultMessage={'Centroid X'} />
          } />
          <StatNumberValue value={selectedDetectedObject.centroidX} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.detected-object-overview.centroid.y" defaultMessage={'Centroid Y'} />
          } />
          <StatNumberValue value={selectedDetectedObject.centroidX} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.detected-object-overview.bbox.min_x" defaultMessage={'Min X'} />
          } />
          <StatNumberValue value={selectedDetectedObject.minX} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.detected-object-overview.bbox.min_y" defaultMessage={'Min Y'} />
          } />
          <StatNumberValue value={selectedDetectedObject.minY} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.detected-object-overview.bbox.max_x" defaultMessage={'Max X'} />
          } />
          <StatNumberValue value={selectedDetectedObject.maxX} />
        </ObjectStatsContainer>
        <ObjectStatsContainer>
          <StatTitle title={
            <FormattedMessage id="editor-page.object-overview.detected-object-overview.bbox.max_y" defaultMessage={'Max Y'} />
          } />
          <StatNumberValue value={selectedDetectedObject.maxY} />
        </ObjectStatsContainer>
      </TableBody>
    </Table>
    <div className='flex flex-col gap-[4px]'>
      <Title level={5}>
        <FormattedMessage id="editor-page.object-overview.detected-object-overview.measure.title" defaultMessage={'Measure'} />
      </Title>
      {
        thicknessMeasureObjectId === undefined
          ? <Button
              onClick={() => {
                setThicknessMeasureObject(detectedObjectSelectedId, 'prediction')
              }}
              className='text-clemex-primary'
            >
              <FormattedMessage
                id="editor-page.object-overview.detected-object-overview.measure.edit"
                defaultMessage={'Thickness measure'}
              />
            </Button>
          : <ThicknessMeasureTable type='prediction' />
      }
    </div>
  </div>
}
