import type * as React from 'react'
import { Space, Typography, Col, Row } from 'antd'
import Icon from '@ant-design/icons'
import { type IntlShape } from 'react-intl'
import styles from './styles/licence-explanation-modal.module.scss'
import freeSvg from '@components/icons/free.licence.svg'
import premiumSvg from '@components/icons/premium.licence.svg'
import trialSvg from '@components/icons/trial.licence.svg'
import stylesTag from '@components/licence/styles/licensing-tag-properties.module.scss'
import clsx from 'clsx'
const { Title, Text } = Typography

export const getLicenceModalContent: React.FC<{ intl: IntlShape }> = ({ intl }) => {
  return <>
    <Space direction='vertical'>
      <Row className={styles.row}>
        <Col span={2} className={styles.iconCell}>
          <Icon component={premiumSvg} className={clsx(stylesTag.premium, styles.icon)}/>
        </Col>
        <Col span={20}>
          <Text>
            {intl.formatMessage({ id: 'licence.premium.description', defaultMessage: 'A premium licence allows to download a plugin that never expires.' })}
          </Text>
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col span={2} className={styles.iconCell}>
          <Icon component={trialSvg} className={clsx(stylesTag.base, styles.icon)}/>
        </Col>
        <Col span={20}>
          <Text>
            {intl.formatMessage({ id: 'licence.trial.description', defaultMessage: 'A trial licence means you can download a plugin, but it will expire at the same time than the licence.' })}
          </Text>
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col span={2} className={styles.iconCell}>
          <Icon component={freeSvg} className={clsx(stylesTag.base, styles.icon)}/>
        </Col>
        <Col span={20}>
          <Text>
            {intl.formatMessage({ id: 'licence.free.description', defaultMessage: 'A free licence means you do not have a licence. You can use the platform, but you cannot download a plugin.' })}
          </Text>
        </Col>
      </Row>
      <Text>
        {intl.formatMessage({ id: 'licence.acquire.description', defaultMessage: 'In order to acquire a licence please contact Clemex sales department at' })}
                  &nbsp;
        <a href={
          'mailto:clemex-sales@clemex.com?subject=' + intl.formatMessage({
            id: 'page.licence.mailto.subject',
            defaultMessage: 'Clemex Studio Licence Request',
          })
        }>clemex-sales@clemex.com</a>
      </Text>
    </Space>
  </>
}
export const getLicenceModalTitle: React.FC<{ intl: IntlShape }> = ({ intl }) => {
  return <Title className={styles.licenceTitle} level={4}>
    { intl.formatMessage({ id: 'page.profile.licence-plans.title', defaultMessage: 'plans' }) }
  </Title>
}
