import Fill from 'ol/style/Fill'
import Style, { type StyleFunction } from 'ol/style/Style'
import { DEFAULT_CLEMEX_VISION_MASK_COLOR } from '../color'
import Stroke from 'ol/style/Stroke'
import { type ClassAnnotationProperties } from '../class-annotations'

export interface ClassAnnotationStyle {
  visibleAnnotationColorIndexes: Record<number, boolean>
  defaultMaskColor: Record<number, [number, number, number]>
  opacity: number
}

export const DEFAULT_CLASS_ANNOTATION_STYLE: ClassAnnotationStyle = {
  visibleAnnotationColorIndexes: {},
  defaultMaskColor: DEFAULT_CLEMEX_VISION_MASK_COLOR,
  opacity: 1,
}

const CLASS_ANNOTATION_Z_INDEX = 100

export const buildClassAnnotationStyle = (
  style: ClassAnnotationStyle,
  selectedClassAnnotationIds: Set<string>,
): StyleFunction => {
  // XXX: The layer opacity is ignored for some reason
  //      This might be due to the fact that we are defining a style with a color with an alpha value
  //      So let's use the layer opacity to set the alpha value of the color to mimic the behavior
  //      Note, that is the layer opacity bug is fixed, then need to remove this hack otherwise the opacity will be applied twice
  const opacity = style.opacity

  return (feature) => {
    const colorIndex = feature.get('colorIndex')
    const visibility = style.visibleAnnotationColorIndexes[colorIndex] ?? true
    const styles = []
    const featureProperties = feature.getProperties() as ClassAnnotationProperties
    const selected = selectedClassAnnotationIds.has(featureProperties.classAnnotationId)

    styles.push(new Style({
      fill: new Fill({
        color: [
          ...style.defaultMaskColor[colorIndex],
          visibility ? opacity : 0,
        ],
      }),
      zIndex: CLASS_ANNOTATION_Z_INDEX,
    }))
    if (selected) {
      styles.push(new Style({
        stroke: new Stroke({
          color: 'black',
          width: 2,
          lineDash: [10, 10],
        }),
        zIndex: CLASS_ANNOTATION_Z_INDEX + 1,
      }))
      styles.push(new Style({
        stroke: new Stroke({
          color: 'white',
          width: 2,
          lineDash: [10, 10],
          lineDashOffset: 10,
        }),
        zIndex: CLASS_ANNOTATION_Z_INDEX + 1,
      }))
    }
    return styles
  }
}
