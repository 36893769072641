/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Canvas settings for an user.
 * @export
 * @interface UserSettingsCanvasValueObject
 */
export interface UserSettingsCanvasValueObject {
    /**
     * 
     * @type {Date}
     * @memberof UserSettingsCanvasValueObject
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserSettingsCanvasValueObject
     */
    modificationDate?: Date;
    /**
     * Zoom factor to apply when zooming in or out.
     * @type {number}
     * @memberof UserSettingsCanvasValueObject
     */
    zoomFactor?: number;
}

/**
 * Check if a given object implements the UserSettingsCanvasValueObject interface.
 */
export function instanceOfUserSettingsCanvasValueObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserSettingsCanvasValueObjectFromJSON(json: any): UserSettingsCanvasValueObject {
    return UserSettingsCanvasValueObjectFromJSONTyped(json, false);
}

export function UserSettingsCanvasValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsCanvasValueObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationDate': !exists(json, 'creation_date') ? undefined : (new Date(json['creation_date'])),
        'modificationDate': !exists(json, 'modification_date') ? undefined : (new Date(json['modification_date'])),
        'zoomFactor': !exists(json, 'zoom_factor') ? undefined : json['zoom_factor'],
    };
}

export function UserSettingsCanvasValueObjectToJSON(value?: UserSettingsCanvasValueObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creation_date': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modification_date': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'zoom_factor': value.zoomFactor,
    };
}

