import { useProjectSettingsDirectMeasure } from '@app/api/hooks'
import { useProjectId } from '@app/pages/editor-page/hooks/editor-page'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import { DEFAULT_SHAPE_STYLE, type ShapeStyle } from '@clemex/mosaic-canvas'
import { useEffect, useMemo } from 'react'

export const useCanvasShapeStyle = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): void => {
  const projectId = useProjectId()
  const { data: projectSettingsDirectMeasureData } = useProjectSettingsDirectMeasure(projectId)

  const shapeStyle: ShapeStyle = useMemo(() => {
    if (projectSettingsDirectMeasureData === undefined) {
      return DEFAULT_SHAPE_STYLE
    }

    const style: ShapeStyle = {
      ...DEFAULT_SHAPE_STYLE,
      mainColor: projectSettingsDirectMeasureData.mainColor,
      measuringLineColor: projectSettingsDirectMeasureData.measuringLineColor,
      measuringPointColor: projectSettingsDirectMeasureData.measuringPointColor,
      measurementTextFontSizePx: projectSettingsDirectMeasureData.fontSize,
      measurementTextFontFamily: projectSettingsDirectMeasureData.fontFamily,
      measurementTextFontWeight: projectSettingsDirectMeasureData.fontWeight,
      measurementTextFontFillColor: projectSettingsDirectMeasureData.fontColor,
      measurementTextFontStrokeColor: projectSettingsDirectMeasureData.fontOutlineColor,
      textPositionToShape: projectSettingsDirectMeasureData.directMeasureTextPosition,
      angleUnit: projectSettingsDirectMeasureData.angleUnit,
    }
    return style
  }, [projectSettingsDirectMeasureData])

  useEffect(() => {
    clemexMosaicCanvas.setDirectMeasureDistanceStyle(shapeStyle)
    clemexMosaicCanvas.setAngleStyle(shapeStyle)
    clemexMosaicCanvas.setPerimeterMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setAreaMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setArcMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setBoundingBoxMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setEllipseMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setCircleMeasurementStyle(shapeStyle)
  }, [clemexMosaicCanvas, shapeStyle])
}
