import * as React from 'react'
import styles from './styles/upload-first-images.module.scss'
import { FormattedMessage } from 'react-intl'
import { Empty, Image, Button, Spin, Typography, notification, Collapse, type CollapseProps } from 'antd'
import clsx from 'clsx'
import Dragger from 'antd/lib/upload/Dragger'
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import { type UploadChangeParam, type UploadFile } from 'antd/lib/upload/interface'
import { type ImageResponse } from '@api/openapi'
import { SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE, MESSAGE_UPLOAD_IMAGE_DIMENSION, MINIMAL_IMAGE_DIMENSION_PIXEL, E2E_REQUIRED_CLASSES } from '@app/constants'
import { useFeatureFlags } from '@app/api/hooks'

const { Title } = Typography

interface UploadFirstImagesProps {
  onConfirm: (newImageIds: string[], demoImageIds: string[]) => Promise<void>
  demoImages: { src: string, imageDemoId: string }[]
  isLoadingDemoImages: boolean
  isConfirmingUpload: boolean
  uploadUrl: string
  uploadMethod?: 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch'
}

const toggleItemFromArray = (elements: string[], key: string): string[] => {
  if (elements.includes(key)) {
    return elements.filter((item) => item !== key)
  } else {
    return [...elements, key]
  }
}

export const UploadFirstImages: React.FC<UploadFirstImagesProps> = (props) => {
  const { onConfirm, demoImages, isLoadingDemoImages, isConfirmingUpload } = props
  const { uploadUrl, uploadMethod = 'POST' } = props
  const [selectedDemoImages, setSelectedDemoImages] = React.useState<string[]>([])
  const [uploadedImages, setUploadedImages] = React.useState<UploadFile<ImageResponse>[]>([])
  const [isUploadingImage, setIsUploadingImage] = React.useState<boolean>(false)
  const [draggerKey, setDraggerKey] = React.useState<number>(0)
  const [activeItem, setActiveItem] = React.useState<string[]>(['1', '2'])
  const [notificationApi, notificationContext] = notification.useNotification()
  const { data: flags } = useFeatureFlags()

  const onClickConfirm = async (): Promise<void> => {
    if (isConfirmingUpload || isUploadingImage) {
      return
    }
    const imageIds = uploadedImages
      .map((uploadedImage) => uploadedImage.response)
      .filter((response): response is ImageResponse => response !== undefined)
      .map((response: ImageResponse) => response.id)

    await onConfirm(imageIds, selectedDemoImages)
    setDraggerKey(draggerKey + 1)
    setUploadedImages([])
  }

  const onUploadStateChange = (info: UploadChangeParam): void => {
    const { status } = info.file
    if (info.fileList.every(file => file.status === 'done' || file.status === 'error')) {
      setIsUploadingImage(false)
    } else {
      setIsUploadingImage(true)
      setActiveItem(['1'])
    }
    if (status === 'done') {
      setUploadedImages(uploadedImages => ([...uploadedImages, info.file]))
    } else if (status === 'error') {
      notificationApi.error({
        message: <FormattedMessage
          id='projects.uploadImage.form.image.upload.error'
          defaultMessage='Image {fileName} upload failed. The image format or dimensions could be incorrect. Please validate the image is {minDimension}x{minDimension} and in the following formats: {imageFormat}'
          values={{
            fileName: info.file.name,
            minDimension: MINIMAL_IMAGE_DIMENSION_PIXEL,
            imageFormat: SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE,
          }}/>,
      })
    }
  }

  const onSelectDemoImage = (demoImageId: string): void => {
    if (selectedDemoImages.includes(demoImageId)) {
      setSelectedDemoImages(selectedDemoImages.filter((selectedDemoImageId) => selectedDemoImageId !== demoImageId))
    } else {
      setSelectedDemoImages([...selectedDemoImages, demoImageId])
    }
  }

  const onRemoveUploadedImage = (file: UploadFile): void => {
    setUploadedImages(uploadedImages => ((uploadedImages.filter((uploadedImage) => uploadedImage.uid !== file.uid))))
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: <FormattedMessage id="projects.upload-first-images.image-demo-selection.upload" defaultMessage={'Use your own'} />,
      showArrow: false,
      children: (
        <Dragger
          key={draggerKey}
          height={150}
          action={uploadUrl}
          method={uploadMethod}
          name="image"
          multiple={true}
          accept={SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE}
          onChange={onUploadStateChange}
          listType="picture-card"
          onRemove={onRemoveUploadedImage}
          className={clsx(E2E_REQUIRED_CLASSES.UPLOAD_IMAGES, styles.uploadeImagesDragger)}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            <FormattedMessage id="projects.uploadImage.form.image.upload.area.text" defaultMessage={'Click or drag file to this area to upload'} />
          </p>
          <p className="ant-upload-hint">
            <FormattedMessage id="projects.uploadImage.form.image.upload.area.hint" defaultMessage={'Support for multiple images upload (png, jpeg, bmp, tiff)'} />
            <br/>
            { MESSAGE_UPLOAD_IMAGE_DIMENSION }
          </p>

        </Dragger>
      ),
    },
  ]
  if (flags !== undefined && !flags.enableLocalUser) {
    items.push({
      key: '2',
      label: <FormattedMessage id="projects.upload-first-images.image-demo-selection.text" defaultMessage={'Or select from our images'} />,
      onClick: () => { setActiveItem((state) => toggleItemFromArray(state, '2')) },
      children: (
        <div className={styles.demoImages}>
          { isLoadingDemoImages &&
              <Spin
                className={styles.demoImagesLoading}
                size="large"
                indicator={<LoadingOutlined/>}
              />
          }
          {demoImages.length === 0 && <div className={styles.demoImagesEmpty}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>}
          {
            demoImages.map(({ src, imageDemoId }) => {
              return <div
                key={imageDemoId}
                className={clsx(styles.imageDemo, { [styles.imageDemoSelected]: selectedDemoImages.includes(imageDemoId) })}
              >
                <Image
                  width={150}
                  src={src}
                  onClick={(event) => {
                    onSelectDemoImage(imageDemoId)
                    event.stopPropagation()
                  }}
                  preview={false}
                />
              </div>
            })
          }
        </div>
      ),
    },
    )
  }
  const hideDemoImages = isUploadingImage || uploadedImages.length > 0

  return <div className={styles.uploadFirstImagesRoot}>
    <div className={styles.uploadFirstImagesContainer}>
      <div className={styles.uploadFirstImagesHeader}>
        <Title level={3} className={styles.onboardingMessageTop}>
          <FormattedMessage id="projects.upload-first-images.message" defaultMessage={'Please upload image(s)'} />
        </Title>
      </div>
      <div className={styles.uploadFirstImagesContent}>
        { notificationContext }
        <Collapse className={clsx({ [styles.biggerUploadZone]: hideDemoImages })} defaultActiveKey={activeItem} activeKey={activeItem} ghost items={items} />
      </div>

      <div className={styles.uploadFirstImagesFooter}>
        <Button
          key={'confirm-selected-demo-image'}
          className={E2E_REQUIRED_CLASSES.UPLOAD_FIRST_IMAGES_CONTINUE_BUTTON}
          type="primary"
          disabled={isUploadingImage || ((selectedDemoImages.length + uploadedImages.length) <= 0) }
          onClick={onClickConfirm}
        >
          <FormattedMessage id="projects.upload-first-images.actions.confirm" defaultMessage={'Continue'} />
        </Button>
      </div>
    </div>
  </div>
}
