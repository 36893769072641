import { type MetadataAnnotationResponse, MetadataAnnotationType } from '@app/api/openapi'
import { createColumnHelper, type ColumnDef } from '@tanstack/react-table'
import FocusIcon from '@material-design-icons/svg/outlined/center_focus_strong.svg'
import { Button } from '@shadcn-ui/components/ui/button'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from 'antd'
import { EVENT_DISPATCHER } from '@app/event'
import clsx from 'clsx'
import GeoJSON from 'ol/format/GeoJSON'
import { sortableHeader } from '@app/pages/editor-page/databrowser/table/commont'

const geojsonParser = new GeoJSON()

const direcMetadataAnnotationHelper = createColumnHelper<MetadataAnnotationResponse>()

interface MetadataAnnotationColumnsDefOptions {
  imageId: string | undefined
}
export const createMetadataAnnotationColumnsDef = ({
  imageId,
}: MetadataAnnotationColumnsDefOptions): ColumnDef<MetadataAnnotationResponse>[] => {
  return [
    direcMetadataAnnotationHelper.display({
      id: 'action-focus',
      header: undefined,
      cell: ({ row }) => {
        const onClickFocus = (): void => {
          if (imageId !== undefined) {
            const olGeom = geojsonParser.readGeometry(row.original.geometry)
            const bbox = olGeom.getExtent() as [number, number, number, number]
            EVENT_DISPATCHER.dispatchEditorCanvasFocusObject(imageId, bbox)
          }
        }
        return <Button variant="ghost" className="p-0" onClick={onClickFocus}>
          <Tooltip title={<FormattedMessage id="databrowser.prediction-detected-object.cell.action-focus.tooltip" defaultMessage="Click to focus" />}>
            <FocusIcon />
          </Tooltip>
        </Button>
      },
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: false,
    }) as ColumnDef<MetadataAnnotationResponse>,
    direcMetadataAnnotationHelper.display({
      id: 'index',
      header: () => <FormattedMessage id="databrowser.direct-measure.column.id.header.title" defaultMessage="#" />,
      cell: ({ row }) => {
        return row.index + 1
      },
      enableSorting: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<MetadataAnnotationResponse>,
    direcMetadataAnnotationHelper.accessor('type', {
      header: sortableHeader(
        <FormattedMessage id="databrowser.metadata-annotation.column.metadata-annotation-type.header.title" defaultMessage="Type" />,
        'left',
      ),
      cell: ({ getValue }) => {
        const directMeasureType = getValue()
        switch (directMeasureType) {
          case MetadataAnnotationType.Arrow:
            return <FormattedMessage id="databrowser.metadata-annotation.cell.metadata-annotation-type.arrow.value" defaultMessage="Arrow" />
          case MetadataAnnotationType.Circle:
            return <FormattedMessage id="databrowser.metadata-annotation.cell.metadata-annotation-type.circle.value" defaultMessage="Circle" />
          case MetadataAnnotationType.Ellipse:
            return <FormattedMessage id="databrowser.metadata-annotation.cell.metadata-annotation-type.ellipse.value" defaultMessage="Ellipse" />
          case MetadataAnnotationType.Line:
            return <FormattedMessage id="databrowser.metadata-annotation.cell.metadata-annotation-type.line.value" defaultMessage="Line" />
          case MetadataAnnotationType.Polygon:
            return <FormattedMessage id="databrowser.metadata-annotation.cell.metadata-annotation-type.polygon.value" defaultMessage="Polygon" />
          case MetadataAnnotationType.Rectangle:
            return <FormattedMessage id="databrowser.metadata-annotation.cell.metadata-annotation-type.rectangle.value" defaultMessage="Rectangle" />
          case MetadataAnnotationType.Text:
            return <FormattedMessage id="databrowser.metadata-annotation.cell.metadata-annotation-type.text.value" defaultMessage="Text" />
        }
      },
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<MetadataAnnotationResponse>,
    direcMetadataAnnotationHelper.display({
      id: 'text',
      header: sortableHeader(
        <FormattedMessage id="databrowser.metadata-annotation.column.text.header.title" defaultMessage="Text" />,
        'left',
      ),
      cell: ({ row }) => {
        const value = row.original.type === MetadataAnnotationType.Text && row.original.geometryProperties?.geometryType === 'TEXT'
          ? row.original.geometryProperties.text
          : null
        return <div className={clsx('flex grow', { 'text-clemex-offGray': !(value != null) })}>
          {
            value != null
              ? <FormattedMessage id="databrowser.cell.area.value" defaultMessage="{ value }" values={{ value }}/>
              : <FormattedMessage id="databrowser.cell.not-available" defaultMessage="NA" />
          }
        </div>
      },
      enableSorting: true,
      enableMultiSort: true,
      enableColumnFilter: false,
      enableHiding: true,
    }) as ColumnDef<MetadataAnnotationResponse>,
  ]
}
