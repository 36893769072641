import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style, { type StyleFunction } from 'ol/style/Style'
import ColorJS from 'colorjs.io'
import { LineString, Point, type Polygon } from 'ol/geom'
import Text from 'ol/style/Text'
import { measureTextWidth } from 'ol/render/canvas'
import { TextPositionToShapeValues, type ModifyInteractionStyle, buildModifyInteractionStyle, FeatureType, type ShapeStyle, DEFAULT_SHAPE_STYLE, FEATURE_TYPE } from './common'

export interface PolygonProperties {
  geometryType: 'POLYGON'
  filled: boolean
  pinnedResolution: number
}

export const buildAreaStyle = (
  selection: Set<string>,
  pixelSizeCalibration: number | undefined,
  style: ShapeStyle = DEFAULT_SHAPE_STYLE,
): StyleFunction => {
  // Create RGBA color for fill alpha
  // It would be nice to inline it like: `new Color(style.mainColor).set('alpha', style.fillOpacity)`
  // But it does not work at the moment, see issue:
  // https://github.com/color-js/color.js/issues/310

  return (feature, resolution: number) => {
    const geometry = feature.getGeometry()
    if (geometry === undefined) {
      return []
    }
    const type = geometry.getType()
    if (type !== 'Polygon') {
      return []
    }
    const selected = selection.has(feature.getProperties().id as string)
    const { filled, pinnedResolution } = geometry.getProperties() as PolygonProperties

    const fillColor = new ColorJS(selected ? style.mainSelectedColor : style.mainColor)
    fillColor.alpha = style.fillOpacity
    const polygonGeom = geometry as Polygon
    const area = polygonGeom.getArea()
    const perimeters = new LineString(polygonGeom.getCoordinates()[0]).getLength()

    const simpliedCoordinates = (polygonGeom.simplify(0) as Polygon).getCoordinates()
    let top = simpliedCoordinates[0][0]
    let left = simpliedCoordinates[0][0]
    let bottom = simpliedCoordinates[0][0]
    let right = simpliedCoordinates[0][0]
    for (const point of simpliedCoordinates[0]) {
      if (point[0] < left[0]) {
        left = point
      }
      if (point[0] > right[0]) {
        right = point
      }
      if (point[1] < bottom[1]) {
        bottom = point
      }
      if (point[1] > top[1]) {
        top = point
      }
    }
    const font = `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`
    const areaMeasurementText = pixelSizeCalibration === undefined
      ? `${area.toFixed(1)} px²`
      : `${(area * pixelSizeCalibration).toFixed(2)} µm²`
    const perimeterMeasurementText = pixelSizeCalibration === undefined
      ? `${perimeters.toFixed(1)} px`
      : `${(perimeters * pixelSizeCalibration).toFixed(2)} µm`
    const measurementTexts = [
      areaMeasurementText,
      perimeterMeasurementText,
    ]
    const measurementTextsWidth = Math.max(...measurementTexts.map((measurementText) => measureTextWidth(font, measurementText))) + style.measurementTextFontStrokeWidth * 2
    const measurementTextHeight = style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2
    const measurementTextsHeight = (measurementTextHeight * measurementTexts.length) + (style.measurementTextFontLineHeight * (measurementTexts.length - 1))

    const measurementTextsBoxWidth = measurementTextsWidth + style.measurementTextBackgroundPadding * 2
    const measurementTextsBoxHeight = measurementTextsHeight +
      style.measurementTextFontStrokeWidth * 2 +
      style.measurementTextBackgroundPadding * 2

    const textOffsetX = {
      [TextPositionToShapeValues.TOP]: 0,
      [TextPositionToShapeValues.BOTTOM]: 0,
      [TextPositionToShapeValues.LEFT]: -((measurementTextsBoxWidth / 2) + style.measurementTextBackgroundDistanceShape),
      [TextPositionToShapeValues.RIGHT]: (measurementTextsBoxWidth / 2) + style.measurementTextBackgroundDistanceShape,
    }[style.textPositionToShape]

    const textOffsetY = {
      [TextPositionToShapeValues.TOP]: -((measurementTextsBoxHeight / 2) + style.measurementTextBackgroundDistanceShape),
      [TextPositionToShapeValues.BOTTOM]: (measurementTextsBoxHeight / 2) + style.measurementTextBackgroundDistanceShape,
      [TextPositionToShapeValues.LEFT]: 0,
      [TextPositionToShapeValues.RIGHT]: 0,
    }[style.textPositionToShape]

    const displaySide = {
      [TextPositionToShapeValues.TOP]: top,
      [TextPositionToShapeValues.BOTTOM]: bottom,
      [TextPositionToShapeValues.LEFT]: left,
      [TextPositionToShapeValues.RIGHT]: right,
    }[style.textPositionToShape]

    const styles = []
    styles.push(new Style({
      stroke: new Stroke({
        color: selected ? style.mainSelectedColor : style.mainColor,
        width: style.strokeWidth,
      }),
      fill: new Fill({
        color: filled ? fillColor.toString() : 'rgba(0, 0, 0, 0)',
      }),
      zIndex: style.zIndexOffSet + (selected ? style.zIndexSelectionOffset : 0),
    }))

    if (feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_AREA) {
      styles.push(
        new Style({
          text: new Text({
            text: measurementTexts.join('\n'),
            font,
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
            backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
            fill: new Fill({
              color: style.measurementTextFontFillColor,
            }),
            stroke: new Stroke({
              color: style.measurementTextFontStrokeColor,
              width: style.measurementTextFontStrokeWidth,
            }),
            offsetX: textOffsetX * pinnedResolution / resolution,
            offsetY: textOffsetY * pinnedResolution / resolution,
            scale: pinnedResolution / resolution,
          }),
          geometry: () => {
            return new Point(displaySide)
          },
          zIndex: style.zIndexOffSet + 2 + (selected ? style.zIndexSelectionOffset : 0),
        }),
      )
    }
    return styles
  }
}

export const buildAreaInteractionStyle: (style: ModifyInteractionStyle) => StyleFunction = buildModifyInteractionStyle([FeatureType.DIRECT_MEASURE_AREA])
