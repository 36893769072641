import { Pointer } from 'ol/interaction'
import { Collection, type Feature, type MapBrowserEvent } from 'ol'

const DEFAULT_SELECTION_TOOL_HIT_TOLERANCE = 6

interface SelectDetectedObjectInteractionConstructor {
  selectableFeatures: Collection<Feature>
  hitTolerance?: number
}
export class SelectDetectedObjectInteraction extends Pointer {
  private readonly _selectableFeatures: Collection<Feature>
  private readonly _selection: Collection<Feature>
  private readonly _hitTolerance: number

  constructor ({
    selectableFeatures,
    hitTolerance,
  }: SelectDetectedObjectInteractionConstructor) {
    super({
    })
    this._selectableFeatures = selectableFeatures
    this._hitTolerance = hitTolerance ?? DEFAULT_SELECTION_TOOL_HIT_TOLERANCE
    this._selection = new Collection()
  }

  public readonly handleEvent = (evt: MapBrowserEvent<MouseEvent>): boolean => {
    if (evt.type !== 'pointerdown' || evt.originalEvent.button !== 0) {
      return true
    }
    const feature = this._selectableFeatures.getArray().find((feature) => {
      return feature.getGeometry()?.intersectsCoordinate(evt.coordinate)
    })

    if (feature !== undefined) {
      this._selection.clear()
      const selectedFeature = this._selection.getArray().map(x => x)
      selectedFeature.forEach((feature) => {
        feature.changed()
      })
      this._selection.push(feature)
      feature.changed()
      return false
    }
    return true
  }

  public getFeatures (): Collection<Feature> {
    return this._selection
  }

  public readonly isCursorAbleToSelect = (): boolean => {
    if (!this.getActive()) {
      return false
    }
    return true
  }

  public readonly stopDown = (): boolean => {
    return true
  }
}
