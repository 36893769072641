/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAssistanceLicenceFeature } from './UserAssistanceLicenceFeature';
import {
    UserAssistanceLicenceFeatureFromJSON,
    UserAssistanceLicenceFeatureFromJSONTyped,
    UserAssistanceLicenceFeatureToJSON,
} from './UserAssistanceLicenceFeature';
import type { UserAssistanceType } from './UserAssistanceType';
import {
    UserAssistanceTypeFromJSON,
    UserAssistanceTypeFromJSONTyped,
    UserAssistanceTypeToJSON,
} from './UserAssistanceType';

/**
 * 
 * @export
 * @interface UserAssistanceRequest
 */
export interface UserAssistanceRequest {
    /**
     * 
     * @type {Array<UserAssistanceType>}
     * @memberof UserAssistanceRequest
     */
    assistanceRequested: Array<UserAssistanceType>;
    /**
     * 
     * @type {UserAssistanceLicenceFeature}
     * @memberof UserAssistanceRequest
     */
    licenceFeature?: UserAssistanceLicenceFeature | null;
}

/**
 * Check if a given object implements the UserAssistanceRequest interface.
 */
export function instanceOfUserAssistanceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assistanceRequested" in value;

    return isInstance;
}

export function UserAssistanceRequestFromJSON(json: any): UserAssistanceRequest {
    return UserAssistanceRequestFromJSONTyped(json, false);
}

export function UserAssistanceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAssistanceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assistanceRequested': ((json['assistance_requested'] as Array<any>).map(UserAssistanceTypeFromJSON)),
        'licenceFeature': !exists(json, 'licence_feature') ? undefined : UserAssistanceLicenceFeatureFromJSON(json['licence_feature']),
    };
}

export function UserAssistanceRequestToJSON(value?: UserAssistanceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assistance_requested': ((value.assistanceRequested as Array<any>).map(UserAssistanceTypeToJSON)),
        'licence_feature': UserAssistanceLicenceFeatureToJSON(value.licenceFeature),
    };
}

