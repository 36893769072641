import type * as React from 'react'
import styles from './styles/icon-menu.module.scss'
import { Button, Flex, Tooltip } from 'antd'
import clsx from 'clsx'
import { TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'

export interface Item {
  icon: React.ReactNode
  selected: boolean
  disabled: boolean
  action: () => void
  helpTitle: React.ReactNode | (() => React.ReactNode)
}

interface IconMenuProps {
  items: Item[]
  vertical?: boolean
  firstItem?: boolean
}

export const IconMenu: React.FC<IconMenuProps> = (props) => {
  const {
    items,
    vertical = true,
    firstItem: firstIconMenu = true,
  } = props

  return (
    <Flex align='flex-start' gap={8} className={styles.container}>
      <Flex className={styles.subList} vertical={vertical}>
        {
          items.map(({ icon, selected, action, disabled, helpTitle }, i) => {
            // Placement uppers on top, lowers on bottom, and middles on left or right depending on the firstIconMenu
            const placement = vertical ? (i > 0 && i !== items.length - 1 ? (firstIconMenu ? 'left' : 'right') : (i !== 0 ? 'bottom' : 'top')) : 'top'
            return (
              <Button
                key={`icon-menu-${i}`}
                className={clsx(styles.item, { [styles.selected]: selected, [styles.disabled]: disabled, [styles.vertical]: vertical })}
                onClick={(e) => {
                  // Remove focus on the button
                  e.currentTarget.blur()
                  action()
                }}
                icon={
                  <Tooltip title={helpTitle} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY} mouseLeaveDelay={0} placement={placement} >
                    {icon}
                  </Tooltip>
                }
                disabled={disabled}
              />)
          })
        }
      </Flex>
    </Flex >
  )
}
