/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectResponse } from './ProjectResponse';
import {
    ProjectResponseFromJSON,
    ProjectResponseFromJSONTyped,
    ProjectResponseToJSON,
} from './ProjectResponse';

/**
 * 
 * @export
 * @interface ListResponseProjectResponse
 */
export interface ListResponseProjectResponse {
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof ListResponseProjectResponse
     */
    data: Array<ProjectResponse>;
}

/**
 * Check if a given object implements the ListResponseProjectResponse interface.
 */
export function instanceOfListResponseProjectResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ListResponseProjectResponseFromJSON(json: any): ListResponseProjectResponse {
    return ListResponseProjectResponseFromJSONTyped(json, false);
}

export function ListResponseProjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListResponseProjectResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ProjectResponseFromJSON)),
    };
}

export function ListResponseProjectResponseToJSON(value?: ListResponseProjectResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(ProjectResponseToJSON)),
    };
}

