import { CACHE_KEYS } from '@app/api/cache-keys'
import { useProjectTrainingSnapshot } from '@app/api/hooks'
import { useCurrentProjectTrainingSnapshotId, useIsProjectTrainingFailed, useProjectTrainingError } from '@app/api/resource-status'
import { useGlobalPredictionVisibility, useHiddenPredictionClassIndexes } from '@app/pages/editor-page/hooks/editor-page'
import { ProjectNotificationType, useProjectsNotification } from '@components/notifications/use-notifications'
import { useEffect, useState } from 'react'
import { mutate as globalMutate } from 'swr'
import { api } from '@app/api/api'

/**
 * This hook revalidate the resource when the project status changes.
 * Notably, when the current project training snapshot id changes, the project training snapshot is revalidated.
 * Other resources should be revalidated in this hook if they depend on the project status.
 * For now, there is only project training snapshot.
 *
 * Note: this hook should only be used once in the page.
 *       Using it more than once would result in the revalidation of the resource multiple times.
 *       Meaning it will trigger multiple requests to the server to fetch the project training snapshot.
 */
export const useRevalidateResourceWhenProjectStatusChange = (projectId: string | undefined): void => {
  const { data: dataProjectTrainingSnapshot, isLoading: isLoadingProjectTrainingSnapshot, isValidating: isValidatingProjectTrainingSnapshot, mutate: mutateProjectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: currentProjectTrainingSnapshotId } = useCurrentProjectTrainingSnapshotId(projectId)
  const [, setHiddenPredictionClassIndexes] = useHiddenPredictionClassIndexes()
  const [, setGlobalPredictionVisibility] = useGlobalPredictionVisibility()

  useEffect(() => {
    if (currentProjectTrainingSnapshotId !== undefined && !isLoadingProjectTrainingSnapshot && !isValidatingProjectTrainingSnapshot) {
      if (dataProjectTrainingSnapshot?.projectTrainingSnapshotId !== currentProjectTrainingSnapshotId) {
        // Force SWR to revalidate the project training snapshot
        void mutateProjectTrainingSnapshot()

        // Project training might have changed too
        void globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATE(projectId))

        // As the project training snapshot has changed
        // The prediction will change as well
        // So we need to reset the state of the prediction mask
        setHiddenPredictionClassIndexes([])
        setGlobalPredictionVisibility(true)
      }
    }
  }, [currentProjectTrainingSnapshotId, dataProjectTrainingSnapshot, isLoadingProjectTrainingSnapshot, isValidatingProjectTrainingSnapshot, mutateProjectTrainingSnapshot, projectId, setGlobalPredictionVisibility, setHiddenPredictionClassIndexes])
}

export const useProjectTrainingErrorNotitificationMonitoring = (projectId: string | undefined): void => {
  const { data: isTrainingFailed } = useIsProjectTrainingFailed(projectId)
  const { data: projectTrainingError } = useProjectTrainingError(projectId)
  const { notify } = useProjectsNotification()
  const [hasLastTrainingErrorBeenNotified, setHasLastTrainingErrorBeenNotified] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (projectId === undefined) {
      return
    }

    if (isTrainingFailed === true && projectTrainingError !== undefined && !(projectTrainingError?.acknowledged)) {
      if (hasLastTrainingErrorBeenNotified !== projectTrainingError.projectTrainingSnapshotId) {
        const onAcknowledge = async (): Promise<void> => {
          await api.postAcknowledgeProjectTrainingErrorApiProjectTrainingSnapshotProjectTrainingSnapshotIdAcknowledgeErrorPost({ projectTrainingSnapshotId: projectTrainingError.projectTrainingSnapshotId })
        }
        notify(projectId, ProjectNotificationType.TrainingError, projectTrainingError.errorMessage, onAcknowledge)
        setHasLastTrainingErrorBeenNotified(projectTrainingError.projectTrainingSnapshotId)
      }
    }
  }, [hasLastTrainingErrorBeenNotified, isTrainingFailed, notify, projectId, projectTrainingError])
}
