/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatasetStatistics } from './DatasetStatistics';
import {
    DatasetStatisticsFromJSON,
    DatasetStatisticsFromJSONTyped,
    DatasetStatisticsToJSON,
} from './DatasetStatistics';
import type { ProjectStatistics } from './ProjectStatistics';
import {
    ProjectStatisticsFromJSON,
    ProjectStatisticsFromJSONTyped,
    ProjectStatisticsToJSON,
} from './ProjectStatistics';
import type { UserStatistics } from './UserStatistics';
import {
    UserStatisticsFromJSON,
    UserStatisticsFromJSONTyped,
    UserStatisticsToJSON,
} from './UserStatistics';

/**
 * 
 * @export
 * @interface Statistics
 */
export interface Statistics {
    /**
     * 
     * @type {UserStatistics}
     * @memberof Statistics
     */
    users: UserStatistics;
    /**
     * 
     * @type {ProjectStatistics}
     * @memberof Statistics
     */
    projects: ProjectStatistics;
    /**
     * 
     * @type {{ [key: string]: DatasetStatistics; }}
     * @memberof Statistics
     */
    datasets: { [key: string]: DatasetStatistics; };
}

/**
 * Check if a given object implements the Statistics interface.
 */
export function instanceOfStatistics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "projects" in value;
    isInstance = isInstance && "datasets" in value;

    return isInstance;
}

export function StatisticsFromJSON(json: any): Statistics {
    return StatisticsFromJSONTyped(json, false);
}

export function StatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Statistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': UserStatisticsFromJSON(json['users']),
        'projects': ProjectStatisticsFromJSON(json['projects']),
        'datasets': (mapValues(json['datasets'], DatasetStatisticsFromJSON)),
    };
}

export function StatisticsToJSON(value?: Statistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': UserStatisticsToJSON(value.users),
        'projects': ProjectStatisticsToJSON(value.projects),
        'datasets': (mapValues(value.datasets, DatasetStatisticsToJSON)),
    };
}

