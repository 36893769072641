import { FormattedMessage } from 'react-intl'
import styles from './styles/export-settings.module.scss'
import { SettingRow, SettingsSection } from '@components/modals/project-settings/settings'
import { Button, ColorPicker, InputNumber, Radio, type RadioChangeEvent } from 'antd'
import { COLOR_PRESETS } from '@app/constants'
import { useCallback } from 'react'
import { useMeasurementSettings } from '@app/api/hooks'
import { AggregationColor } from 'antd/es/color-picker/color'

export const MeasurementSetting: React.FC<{ projectId: string | undefined }> = ({ projectId }) => {
  const { data: defaultSettings, mutateMeasurementSettings, reset } = useMeasurementSettings(projectId)

  const updateScaleAnchor = useCallback((e: RadioChangeEvent): void => {
    const scaleAnchor = (e.target as HTMLInputElement).value as 'top_left' | 'top_right' | 'bottom_left' | 'bottom_right'
    void mutateMeasurementSettings({ scaleAnchor })
  }, [mutateMeasurementSettings])

  const updateColorPicker = useCallback((value: AggregationColor): void => {
    void mutateMeasurementSettings({ scaleBarColor: value.toHexString() })
  }, [mutateMeasurementSettings])

  const updateScaleBarSize = useCallback((value: number | null): void => {
    if (value === null) {
      return
    }
    void mutateMeasurementSettings({ scaleBarSizePx: value })
  }, [mutateMeasurementSettings])

  const updateScaleBarThickness = useCallback((value: number | null): void => {
    if (value === null) {
      return
    }
    void mutateMeasurementSettings({ scaleBarThickness: value })
  }, [mutateMeasurementSettings])

  const updateScaleBarTextPosition = useCallback((e: RadioChangeEvent): void => {
    const scaleBarTextPosition = (e.target as HTMLInputElement).value as 'top' | 'middle' | 'bottom'
    void mutateMeasurementSettings({ scaleBarTextPosition })
  }, [mutateMeasurementSettings])

  // We could handle the loading, but it is not super important for a settings modal
  if (defaultSettings === undefined) {
    return null
  }

  const { scaleAnchor, scaleBarColor, scaleBarSizePx, scaleBarThickness, scaleBarTextPosition } = defaultSettings
  return <SettingsSection
    className={styles.root}
    title={
      <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar" defaultMessage={'Scale bar'} />
    }
  >
    <SettingRow
      title={
        <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.anchor" defaultMessage={'Anchor'} />
      }
    >
      <Radio.Group value={scaleAnchor} className={styles.radioAnchor} onChange={updateScaleAnchor}>
        <Radio.Button value="top_left">
          <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.anchor.top.left" defaultMessage={'Top Left'} />
        </Radio.Button>
        <Radio.Button value="top_right">
          <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.anchor.top.right" defaultMessage={'Top Right'} />
        </Radio.Button>
        <Radio.Button value="bottom_left">
          <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.anchor.bottom.left" defaultMessage={'Bottom Left'} />
        </Radio.Button>
        <Radio.Button value="bottom_right">
          <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.anchor.bottom.right" defaultMessage={'Bottom Right'} />
        </Radio.Button>
      </Radio.Group>
    </SettingRow>
    <SettingRow
      title={
        <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.text.position" defaultMessage={'Text Position'} />
      }
    >
      <Radio.Group value={scaleBarTextPosition} className={styles.radioAnchor} onChange={updateScaleBarTextPosition}>
        <Radio.Button value="top">
          <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.text.position.top" defaultMessage={'Top'} />
        </Radio.Button>
        <Radio.Button value="middle">
          <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.text.position.middle" defaultMessage={'Middle'} />
        </Radio.Button>
        <Radio.Button value="bottom">
          <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.text.position.bottom" defaultMessage={'Bottom'} />
        </Radio.Button>
      </Radio.Group>
    </SettingRow>
    <SettingRow
      title={
        <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.color" defaultMessage={'Bar color'} />
      }
    >
      <ColorPicker
        disabledAlpha={true}
        allowClear={false}
        value={scaleBarColor}
        onChangeComplete={updateColorPicker}
        presets={COLOR_PRESETS}
      />
    </SettingRow>
    <SettingRow
      title={
        <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.size" defaultMessage={'Bar Size'} />
      }
    >
      <InputNumber min={20} max={300} value={scaleBarSizePx} addonAfter={'px'} onChange={updateScaleBarSize} />
    </SettingRow>
    <SettingRow
      title={
        <FormattedMessage id="global-settings.modal.section.editor.canvas.scale-bar.thickness" defaultMessage={'Bar Thickness'} />
      }
    >
      <InputNumber min={1} max={10} value={scaleBarThickness} addonAfter={'px'} onChange={updateScaleBarThickness}/>
    </SettingRow>
    <SettingRow className={styles.exportButtonRow}>
      <Button type='primary' onClick={reset}>
        <FormattedMessage id="project-settings.modal.editor.measurement.settings.reset" defaultMessage={'Reset to default settings'} />
      </Button>
    </SettingRow>
  </SettingsSection>
}
