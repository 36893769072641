/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlgorithmId } from './AlgorithmId';
import {
    AlgorithmIdFromJSON,
    AlgorithmIdFromJSONTyped,
    AlgorithmIdToJSON,
} from './AlgorithmId';
import type { DeepLearningTrainingParametersValueObject } from './DeepLearningTrainingParametersValueObject';
import {
    DeepLearningTrainingParametersValueObjectFromJSON,
    DeepLearningTrainingParametersValueObjectFromJSONTyped,
    DeepLearningTrainingParametersValueObjectToJSON,
} from './DeepLearningTrainingParametersValueObject';
import type { DirectMeasureSettingsValueObject } from './DirectMeasureSettingsValueObject';
import {
    DirectMeasureSettingsValueObjectFromJSON,
    DirectMeasureSettingsValueObjectFromJSONTyped,
    DirectMeasureSettingsValueObjectToJSON,
} from './DirectMeasureSettingsValueObject';
import type { ExportOptionsValueObject } from './ExportOptionsValueObject';
import {
    ExportOptionsValueObjectFromJSON,
    ExportOptionsValueObjectFromJSONTyped,
    ExportOptionsValueObjectToJSON,
} from './ExportOptionsValueObject';
import type { MeasurementSettingsValueObject } from './MeasurementSettingsValueObject';
import {
    MeasurementSettingsValueObjectFromJSON,
    MeasurementSettingsValueObjectFromJSONTyped,
    MeasurementSettingsValueObjectToJSON,
} from './MeasurementSettingsValueObject';
import type { RfAlgorithmTrainingParametersValueObject } from './RfAlgorithmTrainingParametersValueObject';
import {
    RfAlgorithmTrainingParametersValueObjectFromJSON,
    RfAlgorithmTrainingParametersValueObjectFromJSONTyped,
    RfAlgorithmTrainingParametersValueObjectToJSON,
} from './RfAlgorithmTrainingParametersValueObject';

/**
 * 
 * @export
 * @interface ProjectSettingsUpdateRequest
 */
export interface ProjectSettingsUpdateRequest {
    /**
     * 
     * @type {AlgorithmId}
     * @memberof ProjectSettingsUpdateRequest
     */
    algorithmId?: AlgorithmId | null;
    /**
     * 
     * @type {RfAlgorithmTrainingParametersValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    rfAlgorithmTrainingParameters?: RfAlgorithmTrainingParametersValueObject | null;
    /**
     * 
     * @type {DeepLearningTrainingParametersValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    deepLearningTrainingParameters?: DeepLearningTrainingParametersValueObject | null;
    /**
     * 
     * @type {DirectMeasureSettingsValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    directMeasureSettings?: DirectMeasureSettingsValueObject | null;
    /**
     * 
     * @type {ExportOptionsValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    exportOptions?: ExportOptionsValueObject | null;
    /**
     * 
     * @type {MeasurementSettingsValueObject}
     * @memberof ProjectSettingsUpdateRequest
     */
    measurementSettings?: MeasurementSettingsValueObject | null;
}

/**
 * Check if a given object implements the ProjectSettingsUpdateRequest interface.
 */
export function instanceOfProjectSettingsUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectSettingsUpdateRequestFromJSON(json: any): ProjectSettingsUpdateRequest {
    return ProjectSettingsUpdateRequestFromJSONTyped(json, false);
}

export function ProjectSettingsUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'algorithmId': !exists(json, 'algorithm_id') ? undefined : AlgorithmIdFromJSON(json['algorithm_id']),
        'rfAlgorithmTrainingParameters': !exists(json, 'rf_algorithm_training_parameters') ? undefined : RfAlgorithmTrainingParametersValueObjectFromJSON(json['rf_algorithm_training_parameters']),
        'deepLearningTrainingParameters': !exists(json, 'deep_learning_training_parameters') ? undefined : DeepLearningTrainingParametersValueObjectFromJSON(json['deep_learning_training_parameters']),
        'directMeasureSettings': !exists(json, 'direct_measure_settings') ? undefined : DirectMeasureSettingsValueObjectFromJSON(json['direct_measure_settings']),
        'exportOptions': !exists(json, 'export_options') ? undefined : ExportOptionsValueObjectFromJSON(json['export_options']),
        'measurementSettings': !exists(json, 'measurement_settings') ? undefined : MeasurementSettingsValueObjectFromJSON(json['measurement_settings']),
    };
}

export function ProjectSettingsUpdateRequestToJSON(value?: ProjectSettingsUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'algorithm_id': AlgorithmIdToJSON(value.algorithmId),
        'rf_algorithm_training_parameters': RfAlgorithmTrainingParametersValueObjectToJSON(value.rfAlgorithmTrainingParameters),
        'deep_learning_training_parameters': DeepLearningTrainingParametersValueObjectToJSON(value.deepLearningTrainingParameters),
        'direct_measure_settings': DirectMeasureSettingsValueObjectToJSON(value.directMeasureSettings),
        'export_options': ExportOptionsValueObjectToJSON(value.exportOptions),
        'measurement_settings': MeasurementSettingsValueObjectToJSON(value.measurementSettings),
    };
}

