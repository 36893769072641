import { useEffect } from 'react'
import { useEditorStore } from '@app/stores/editor'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'

export const useCanvasPixelGrid = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): void => {
  const isPixelGridVisible = useEditorStore((store) => store.isPixelGridVisible)
  useEffect(() => {
    clemexMosaicCanvas.setPixelGridVisibility(isPixelGridVisible)
  }, [clemexMosaicCanvas, isPixelGridVisible])
}
