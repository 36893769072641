/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatasetStatistics
 */
export interface DatasetStatistics {
    /**
     * 
     * @type {number}
     * @memberof DatasetStatistics
     */
    totalImages: number;
}

/**
 * Check if a given object implements the DatasetStatistics interface.
 */
export function instanceOfDatasetStatistics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalImages" in value;

    return isInstance;
}

export function DatasetStatisticsFromJSON(json: any): DatasetStatistics {
    return DatasetStatisticsFromJSONTyped(json, false);
}

export function DatasetStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalImages': json['total_images'],
    };
}

export function DatasetStatisticsToJSON(value?: DatasetStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_images': value.totalImages,
    };
}

