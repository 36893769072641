/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface VisualizationTextLabel
 */
export interface VisualizationTextLabel {
    /**
     * 
     * @type {string}
     * @memberof VisualizationTextLabel
     */
    text: string;
    /**
     * 
     * @type {Point}
     * @memberof VisualizationTextLabel
     */
    position: Point;
}

/**
 * Check if a given object implements the VisualizationTextLabel interface.
 */
export function instanceOfVisualizationTextLabel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "position" in value;

    return isInstance;
}

export function VisualizationTextLabelFromJSON(json: any): VisualizationTextLabel {
    return VisualizationTextLabelFromJSONTyped(json, false);
}

export function VisualizationTextLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisualizationTextLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'position': PointFromJSON(json['position']),
    };
}

export function VisualizationTextLabelToJSON(value?: VisualizationTextLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'position': PointToJSON(value.position),
    };
}

