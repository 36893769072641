import { Flex, Switch, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/selected-annotation-arrow-parameters.module.scss'
import { EVENTS_ID } from '@app/constants'
import { MetadataAnnotationType, type ArrowGeometryProperties } from '@app/api/openapi'

import { useEditorStore } from '@app/stores/editor'
import { useMetadataAnnotation } from '@app/api/metadata-annotation'
import { useProjectId, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'

const { Title } = Typography

export const SelectedAnnotationArrowParameters: React.FC = () => {
  const projectId = useProjectId()
  const selectedImageId = useSelectedImageId()
  const [metadataAnnotationSelectedIds] = useEditorStore((store) => [
    store.metadataAnnotationSelectedIds,
  ])
  const { data: metadataAnnotations } = useMetadataAnnotation(projectId, selectedImageId)
  const metadataAnnotationSelectedId: string | undefined = Object.entries(metadataAnnotationSelectedIds).filter(([, value]) => {
    return value
  }).map(([key]) => key)[0]
  const selectedAnnotationArrow = metadataAnnotationSelectedId !== undefined
    ? metadataAnnotations?.find((metadataAnnotation) => {
      return metadataAnnotation.type === MetadataAnnotationType.Arrow && metadataAnnotationSelectedId === metadataAnnotation.metadataAnnotationId
    })
    : undefined

  const {
    showRightArrow,
    showLeftArrow,
    pinnedResolution,
  } = (selectedAnnotationArrow?.geometryProperties ?? {}) as ArrowGeometryProperties

  const onToggleShowRightArrow = (): void => {
    window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES, {
      detail: {
        showRightArrow: !(showRightArrow),
        showLeftArrow,
        geometryType: 'ARROW',
        pinnedResolution,
      } satisfies ArrowGeometryProperties,
    }))
  }

  const onToggleShowLeftArrow = (): void => {
    window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES, {
      detail: {
        showRightArrow,
        showLeftArrow: !(showLeftArrow),
        geometryType: 'ARROW',
        pinnedResolution,
      } satisfies ArrowGeometryProperties,
    }))
  }

  return <div className={styles.root}>
    <Title level={5} className={styles.title}>
      <FormattedMessage id="selected.annotation.arrow.parameters.title" defaultMessage={'Selected Annotation Arrow'} />
    </Title>
    <div className={styles.content}>
      <Flex justify='space-between' align='center' flex={1}>
        <Title level={5} className={styles.settingsItemTitle}>
          <FormattedMessage id="selected.annotation.arrow.parameters.showRightArrow" defaultMessage={'Show Right Arrow'} />
        </Title>
        <Switch checked={showRightArrow} onClick={onToggleShowRightArrow}/>
      </Flex>
      <Flex justify='space-between' align='center' flex={1}>
        <Title level={5} className={styles.settingsItemTitle}>
          <FormattedMessage id="selected.annotation.arrow.parameters.showLeftArrow" defaultMessage={'Show Left Arrow'} />
        </Title>
        <Switch checked={showLeftArrow} onClick={onToggleShowLeftArrow}/>
      </Flex>
    </div>
  </div>
}

export default SelectedAnnotationArrowParameters
