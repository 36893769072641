import type * as React from 'react'
import { RFPage, RFContent, AuthenticationHeader } from '@components/layout'
import { Button, Result } from 'antd'
import { WebUIRoutes } from '@app/routes'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'

export const NotFound: React.FC = () => {
  const navigate = useNavigate()
  const onClick = (): void => {
    navigate(WebUIRoutes.projectList().path)
  }
  const intl = useIntl()

  return <RFPage
    title={intl.formatMessage({
      id: 'page.title.404',
      defaultMessage: 'Sorry the page you are looking for is nowhere to be found',
    })}
  >
    <AuthenticationHeader/>
    <RFContent>
      <Result
        status="404"
        title="404"
        subTitle={<FormattedMessage id="404.title" defaultMessage={'Sorry, the page you visited does not exist.'} />}
        extra={<Button type="primary" onClick={onClick}><FormattedMessage id="404.button" defaultMessage={'Back Home'} /></Button>}
      />
    </RFContent>
  </RFPage>
}
