import { Button } from 'antd'
import clsx from 'clsx'
import { type FC } from 'react'
import styles from './styles/tutorial-icon.module.scss'
import SchoolIcon from '@material-design-icons/svg/outlined/school.svg'

export const TutorialIcon: FC = () => {
  return <Button
    className={clsx(styles.tutorialButton)}
    onClick={(e) => {
      e.currentTarget.blur()
    }}
  >
    <SchoolIcon />
  </Button >
}
