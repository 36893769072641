/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PendingTraining } from './PendingTraining';
import {
    PendingTrainingFromJSON,
    PendingTrainingFromJSONTyped,
    PendingTrainingToJSON,
} from './PendingTraining';
import type { RunningTraining } from './RunningTraining';
import {
    RunningTrainingFromJSON,
    RunningTrainingFromJSONTyped,
    RunningTrainingToJSON,
} from './RunningTraining';

/**
 * 
 * @export
 * @interface InProgressTraining
 */
export interface InProgressTraining {
    /**
     * 
     * @type {string}
     * @memberof InProgressTraining
     */
    projectTrainingSnapshotId: string;
    /**
     * 
     * @type {Date}
     * @memberof InProgressTraining
     */
    creationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof InProgressTraining
     */
    startingDatetime: Date;
    /**
     * 
     * @type {string}
     * @memberof InProgressTraining
     */
    status?: InProgressTrainingStatusEnum;
}


/**
 * @export
 */
export const InProgressTrainingStatusEnum = {
    Pending: 'PENDING'
} as const;
export type InProgressTrainingStatusEnum = typeof InProgressTrainingStatusEnum[keyof typeof InProgressTrainingStatusEnum];


/**
 * Check if a given object implements the InProgressTraining interface.
 */
export function instanceOfInProgressTraining(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectTrainingSnapshotId" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "startingDatetime" in value;

    return isInstance;
}

export function InProgressTrainingFromJSON(json: any): InProgressTraining {
    return InProgressTrainingFromJSONTyped(json, false);
}

export function InProgressTrainingFromJSONTyped(json: any, ignoreDiscriminator: boolean): InProgressTraining {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectTrainingSnapshotId': json['project_training_snapshot_id'],
        'creationDate': (new Date(json['creation_date'])),
        'startingDatetime': (new Date(json['starting_datetime'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function InProgressTrainingToJSON(value?: InProgressTraining | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_training_snapshot_id': value.projectTrainingSnapshotId,
        'creation_date': (value.creationDate.toISOString()),
        'starting_datetime': (value.startingDatetime.toISOString()),
        'status': value.status,
    };
}

