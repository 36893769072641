/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFeedbackStatistics } from './UserFeedbackStatistics';
import {
    UserFeedbackStatisticsFromJSON,
    UserFeedbackStatisticsFromJSONTyped,
    UserFeedbackStatisticsToJSON,
} from './UserFeedbackStatistics';

/**
 * 
 * @export
 * @interface UserStatistics
 */
export interface UserStatistics {
    /**
     * 
     * @type {number}
     * @memberof UserStatistics
     */
    totalUsers: number;
    /**
     * 
     * @type {number}
     * @memberof UserStatistics
     */
    totalActivatedUsers: number;
    /**
     * 
     * @type {{ [key: string]: UserFeedbackStatistics; }}
     * @memberof UserStatistics
     */
    userFeedbacks: { [key: string]: UserFeedbackStatistics; };
}

/**
 * Check if a given object implements the UserStatistics interface.
 */
export function instanceOfUserStatistics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalUsers" in value;
    isInstance = isInstance && "totalActivatedUsers" in value;
    isInstance = isInstance && "userFeedbacks" in value;

    return isInstance;
}

export function UserStatisticsFromJSON(json: any): UserStatistics {
    return UserStatisticsFromJSONTyped(json, false);
}

export function UserStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalUsers': json['total_users'],
        'totalActivatedUsers': json['total_activated_users'],
        'userFeedbacks': (mapValues(json['user_feedbacks'], UserFeedbackStatisticsFromJSON)),
    };
}

export function UserStatisticsToJSON(value?: UserStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_users': value.totalUsers,
        'total_activated_users': value.totalActivatedUsers,
        'user_feedbacks': (mapValues(value.userFeedbacks, UserFeedbackStatisticsToJSON)),
    };
}

