import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Typography, Button, Switch } from 'antd'
import styles from './styles/plugin-installation-instructions-modal.module.scss'

const { Title } = Typography

interface PluginInstallationInstructionsModalProps {
  isOpen: boolean
  onClose: (doNotShowAgain: boolean) => void
}
export const PluginInstallationInstructionsModal: React.FC<PluginInstallationInstructionsModalProps> = (props) => {
  const { isOpen, onClose } = props

  const [doNotShowAgain, setDoNotShowAgain] = React.useState(false)

  const title = <Title level={3}>
    <FormattedMessage id="plugin-installation-instruction.modal.title" defaultMessage={'Plugin installation instructions'} />
  </Title>

  return <Modal
    width={700}
    title={title}
    open={isOpen}
    transitionName=""
    maskTransitionName=""
    closable={false}
    footer={
      <div className={styles.footer}>
        <div className={styles.doNotShowAgainContainer}>
          <Title level={5}>
            <FormattedMessage id="parameters.edges.labelplugin-installation-instruction.modal.footer.actions.switch.do-not-show-again.text" defaultMessage={'Do not show again'} />
          </Title>
          <Switch checked={doNotShowAgain} onChange={() => {
            setDoNotShowAgain(!doNotShowAgain)
          }} />
        </div>
        <Button type="primary" onClick={() => {
          onClose(doNotShowAgain)
          setDoNotShowAgain(false)
        }}>
          &nbsp;<FormattedMessage id="plugin-installation-instruction.modal.footer.actions.button.close.text" defaultMessage={'Close'} />
        </Button>
      </div>
    }
  >
    <div>
      <FormattedMessage id="plugin-installation-instruction.modal.content.main" defaultMessage={'To use the plugin you just created for Vision, follow these steps:'} />
    </div>
    <ul>
      <li>
        <FormattedMessage id="plugin-installation-instruction.modal.content.step-1" defaultMessage={'Move the plugin file you just downloaded to {vision_installation_path}'} values={{ vision_installation_path: 'C:\\IAFiles\\Plugins' }} />
      </li>
      <li>
        <FormattedMessage id="plugin-installation-instruction.modal.content.step-2" defaultMessage={'Restart Vision'} />
      </li>
      <li>
        <FormattedMessage id="plugin-installation-instruction.modal.content.step-3" defaultMessage={'Your plugin will be located in the π section of the Vision TOOLBOX'} />
      </li>
    </ul>
  </Modal>
}
