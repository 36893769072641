import type * as React from 'react'
import styles from './styles/project-info-card.module.scss'
import { Card, Typography, Button, Row, Tooltip } from 'antd'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { SettingOutlined, LoadingOutlined } from '@ant-design/icons'
import Edit from '@material-design-icons/svg/filled/edit.svg'
import DuplicateIcon from '@material-design-icons/svg/filled/content_copy.svg'
import { TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'
import clsx from 'clsx'
import { type PosterImage } from '@app/api/models'
import { ImageThumbnail } from '@components/image-thumbnail'

const { Text, Title, Paragraph } = Typography

interface CardCoverProps {
  posterImage?: PosterImage
  onOpen: () => void
  children?: React.ReactNode
}
const CardCover: React.FC<CardCoverProps> = ({ posterImage, onOpen, children }) => {
  return <div
    onClick={onOpen}
    key="editor-icon"
    className={styles.cardCover}
  >
    <ImageThumbnail
      imageThumbnailUrl={posterImage?.thumbnailUrl}
      imagePredictionMaskThumbnailUrl={posterImage?.predictionMaskThumbnailUrl}
      width={284}
      height={200}
    />
    <Tooltip
      placement="topLeft"
      title={<FormattedMessage
        id="project-info-card.tooltip.annotate-project"
        defaultMessage="Open project in annotation editor" />}
      mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
      <Button
        type="text"
        size="middle"
        icon={
          <Edit />
        }
        className={clsx(styles.cardCoverButton, styles.topRight)}
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
      >
      </Button>
    </Tooltip>
    {children}
  </div>
}

export interface ProjectInfoCardProps {
  projectName: string
  lastModifiedDate: string
  description: string
  posterImage?: PosterImage
  onClickEditProject: () => void
  onDuplicateAndNavigate: () => Promise<void>
  onOpen: () => void
  cardCoverChildren: React.ReactNode
  isDuplicating: boolean
}
export const ProjectInfoCard: React.FC<ProjectInfoCardProps> = (props) => {
  const { projectName, lastModifiedDate, description, posterImage, onClickEditProject, onDuplicateAndNavigate, onOpen, cardCoverChildren, isDuplicating } = props

  return <Card
    className={styles.projectInfoCard}
    cover={
      <CardCover
        posterImage={posterImage}
        onOpen={onOpen}
      >
        {cardCoverChildren}
      </CardCover>
    }
  >
    <div className={styles.projectInfoCardContent}>
      <Row className={styles.cardRow}>
        <Title level={3} className={styles.projectInfoCardTitle} ellipsis title={projectName}>
          {projectName}
        </Title>
        <div className={styles.cardActions}>
          <Tooltip
            placement="top"
            title={<FormattedMessage
              id="project-info-card.action.duplicate.tooltip"
              defaultMessage="Duplicate project and open it" />}
            mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            <Button
              className={styles.cardAction}
              type="text"
              size="middle"
              icon={
                isDuplicating
                  ? <LoadingOutlined />
                  : <DuplicateIcon />
              }
              onClick={onDuplicateAndNavigate}
            >
            </Button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={<FormattedMessage
              id="project-info-card.tooltip.edit-project"
              defaultMessage="Edit project" />}
            mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            <Button
              className={styles.cardAction}
              key="settings-icon"
              type="text"
              size="middle"
              name='edit-project-settings'
              icon={
                <SettingOutlined />
              }
              onClick={onClickEditProject}
            >
            </Button>
          </Tooltip>
        </div>
      </Row>
      <Row>
        <Text className={styles.projectInfoCardInfo} type={'secondary'} >
          <FormattedMessage id={'project-info-card.info.last-modified-date.label'} defaultMessage={'Last modified date: '} />{' '}<FormattedDate value={lastModifiedDate} />
        </Text>
      </Row>
      <Row>
        <Paragraph ellipsis={{ rows: 3 }} className={styles.projectInfoCardDescription}>
          {description}
        </Paragraph>
      </Row>
    </div>
  </Card>
}
