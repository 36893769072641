import type * as React from 'react'
import styles from './styles/drawing-parameters.module.scss'
import { FormattedMessage } from 'react-intl'
import { Typography, Tooltip } from 'antd'
import { TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'
import clsx from 'clsx'

const { Title } = Typography

interface DrawingParametersProps {
  brushSize: number
  onSetBrushSize: (brushSize: number) => void
}
const DrawingParametersRadioButton: React.FC<{ value: number, sizeClass: string, onSetBrushSize: (brushSize: number) => void, brushSize: number }> = ({ value, sizeClass, brushSize, onSetBrushSize }) => {
  return <button
    type='button'
    onClick={() => {
      onSetBrushSize(value)
    }}
    tabIndex={-1}
    className={clsx(styles.brush, sizeClass, { [styles.selected]: brushSize === value })}
  >
    &nbsp;
  </button>
}
export const DrawingParameters: React.FC<DrawingParametersProps> = (props) => {
  const { brushSize, onSetBrushSize } = props

  return <>
    <div className={styles.drawingParameters}>
      <Title level={5} className={styles.title}>
        <FormattedMessage id="drawing-parameters.brush.size.slider.label" defaultMessage={'Brush size'} />
      </Title>
      <div className={styles.content}>
        <div className={styles.brushGroup}>
          <Tooltip title={<FormattedMessage id="drawing-parameters.brush.size.option.size1" defaultMessage={'XS'} />} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            <DrawingParametersRadioButton brushSize={brushSize} onSetBrushSize={onSetBrushSize} value={8} sizeClass={styles.xxsm}/>
          </Tooltip>
          <div className={styles.brushSeparator} />
          <Tooltip title={<FormattedMessage id="drawing-parameters.brush.size.option.size2" defaultMessage={'S'} />} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            <DrawingParametersRadioButton brushSize={brushSize} onSetBrushSize={onSetBrushSize} value={16} sizeClass={styles.xsm} />
          </Tooltip>
          <div className={styles.brushSeparator} />
          <Tooltip title={<FormattedMessage id="drawing-parameters.brush.size.option.size3" defaultMessage={'M'} />} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            <DrawingParametersRadioButton brushSize={brushSize} onSetBrushSize={onSetBrushSize} value={20} sizeClass={styles.sm} />
          </Tooltip>
          <div className={styles.brushSeparator} />
          <Tooltip title={<FormattedMessage id="drawing-parameters.brush.size.option.size4" defaultMessage={'L'} />} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            <DrawingParametersRadioButton brushSize={brushSize} onSetBrushSize={onSetBrushSize} value={32} sizeClass={styles.med} />
          </Tooltip>
          <div className={styles.brushSeparator} />
          <Tooltip title={<FormattedMessage id="drawing-parameters.brush.size.option.size5" defaultMessage={'XL'} />} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            <DrawingParametersRadioButton brushSize={brushSize} onSetBrushSize={onSetBrushSize} value={48} sizeClass={styles.lg} />
          </Tooltip>
          <div className={styles.brushSeparator} />
          <Tooltip title={<FormattedMessage id="drawing-parameters.brush.size.option.size6" defaultMessage={'XXL'} />} mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            <DrawingParametersRadioButton brushSize={brushSize} onSetBrushSize={onSetBrushSize} value={64} sizeClass={styles.xlg} />
          </Tooltip>
        </div>
      </div>
    </div>
  </>
}
