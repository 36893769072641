export type Color = [number, number, number]
export type MaskColors = Color[]
export const DEFAULT_CLEMEX_VISION_MASK_COLOR: MaskColors = [
  [21, 0, 255], // #1500FF
  [255, 0, 0], // #FF0000
  [0, 255, 0], // #00FF00
  [255, 2, 255], // #FF02FF
  [254, 255, 0], // #FEFF00
  [0, 128, 128], // #008080
  [128, 0, 128], // #800080
  [6, 255, 255], // #06FFFF
  [128, 0, 0], // #800000
  [0, 128, 1], // #008001
  [191, 1, 191], // #BF01BF
  [5, 0, 128], // #050080
  [254, 104, 22], // #FE6816
  [23, 148, 246], // #1794F6
  [128, 128, 255], // #8080FF
  [97, 71, 21], // #614715
]
