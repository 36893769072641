import { type StyleFunction } from 'ol/style/Style'
import { buildArcStyle } from './arc'
import { buildAreaStyle } from './area'
import { buildAngleStyle } from './angle'
import { buildPerimeterStyle } from './perimeter'
import { FeatureType, FEATURE_TYPE, type ShapeStyle, DEFAULT_SHAPE_STYLE } from './common'
import { DEFAULT_CLASS_ANNOTATION_STYLE, buildClassAnnotationStyle, type ClassAnnotationStyle } from './class-annotation'
import { buildBoundingBoxStyle } from './rectangle'
import { buildEllipseStyle } from './ellipse'
import { buildDirectMeasureDistanceStyle as buildDistanceStyle } from './distance'
import { buildCircleStyle } from './circle'
import { buildArrowStyle } from './arrow'
import { buildTextStyle } from './text'
import { buildSmartAnnotationStyle } from './smart-annotation'
import { buildThicknessMeasureLabelStyle, buildThicknessMeasureStyle } from './thickness-measure'
import { buildDetectedObjectStyle } from './detected-object'

export interface MappedStyle {
  classAnnotation: ClassAnnotationStyle
  detectedObject: ClassAnnotationStyle
  directMeasureDistance: ShapeStyle
  directMeasureAngle: ShapeStyle
  directMeasureArea: ShapeStyle
  directMeasurePerimeter: ShapeStyle
  directMeasureArc: ShapeStyle
  directMeasureEllipse: ShapeStyle
  directMeasureCircle: ShapeStyle
  directMeasureRectangle: ShapeStyle
  metadataAnnotationStraightLine: ShapeStyle
  metadataAnnotationAngle: ShapeStyle
  metadataAnnotationLine: ShapeStyle
  metadataAnnotationPolygon: ShapeStyle
  metadataAnnotationRectangle: ShapeStyle
  metadataAnnotationCircle: ShapeStyle
  metadataAnnotationEllipse: ShapeStyle
  metadataAnnotationText: ShapeStyle
  thicknessMeasure: ShapeStyle
}
export type PartialMappedStyle = Partial<MappedStyle>

export const buildMappedStyle = (
  pixelSizeCalibration: number | undefined,
  selectedClassAnnotationIds: Set<string>,
  selectedDetectedObjectIds: Set<string>,
  selectedDirectMeasureIds: Set<string>,
  selecteMetadataAnnotationIds: Set<string>,
  style: PartialMappedStyle | undefined = undefined,
): StyleFunction => {
  const _mappedStyle: MappedStyle = {
    classAnnotation: style?.classAnnotation ?? DEFAULT_CLASS_ANNOTATION_STYLE,
    detectedObject: style?.detectedObject ?? DEFAULT_CLASS_ANNOTATION_STYLE,
    directMeasureDistance: style?.directMeasureDistance ?? DEFAULT_SHAPE_STYLE,
    directMeasureAngle: style?.directMeasureAngle ?? DEFAULT_SHAPE_STYLE,
    directMeasureArea: style?.directMeasureArea ?? DEFAULT_SHAPE_STYLE,
    directMeasurePerimeter: style?.directMeasurePerimeter ?? DEFAULT_SHAPE_STYLE,
    directMeasureArc: style?.directMeasureArc ?? DEFAULT_SHAPE_STYLE,
    directMeasureRectangle: style?.directMeasureRectangle ?? DEFAULT_SHAPE_STYLE,
    directMeasureEllipse: style?.directMeasureEllipse ?? DEFAULT_SHAPE_STYLE,
    directMeasureCircle: style?.directMeasureCircle ?? DEFAULT_SHAPE_STYLE,
    metadataAnnotationStraightLine: style?.metadataAnnotationStraightLine ?? DEFAULT_SHAPE_STYLE,
    metadataAnnotationAngle: style?.metadataAnnotationAngle ?? DEFAULT_SHAPE_STYLE,
    metadataAnnotationLine: style?.metadataAnnotationLine ?? DEFAULT_SHAPE_STYLE,
    metadataAnnotationPolygon: style?.metadataAnnotationPolygon ?? DEFAULT_SHAPE_STYLE,
    metadataAnnotationRectangle: style?.metadataAnnotationRectangle ?? DEFAULT_SHAPE_STYLE,
    metadataAnnotationCircle: style?.metadataAnnotationCircle ?? DEFAULT_SHAPE_STYLE,
    metadataAnnotationEllipse: style?.metadataAnnotationEllipse ?? DEFAULT_SHAPE_STYLE,
    metadataAnnotationText: style?.metadataAnnotationText ?? DEFAULT_SHAPE_STYLE,
    thicknessMeasure: style?.thicknessMeasure ?? DEFAULT_SHAPE_STYLE,
  }

  const selectedDirectMeasureAndMetadataAnnotationIds = new Set([...selectedDirectMeasureIds, ...selecteMetadataAnnotationIds])
  const classAnnotationStyle = buildClassAnnotationStyle(_mappedStyle.classAnnotation, selectedClassAnnotationIds)
  const detectedObjectStyle = buildDetectedObjectStyle(_mappedStyle.detectedObject, selectedDetectedObjectIds)
  const areaStyle = buildAreaStyle(selectedDirectMeasureAndMetadataAnnotationIds, pixelSizeCalibration, _mappedStyle.directMeasureArea)
  const distanceStyle = buildDistanceStyle(pixelSizeCalibration, selectedDirectMeasureAndMetadataAnnotationIds, _mappedStyle.directMeasureDistance)
  const arrowStyle = buildArrowStyle(selectedDirectMeasureAndMetadataAnnotationIds, _mappedStyle.directMeasureDistance)
  const angleStyle = buildAngleStyle(selectedDirectMeasureAndMetadataAnnotationIds, _mappedStyle.directMeasureAngle)
  const perimeterStyle = buildPerimeterStyle(selectedDirectMeasureAndMetadataAnnotationIds, pixelSizeCalibration, _mappedStyle.directMeasurePerimeter)
  const arcStyle = buildArcStyle(selectedDirectMeasureAndMetadataAnnotationIds, pixelSizeCalibration, _mappedStyle.directMeasureArc)
  const boundingBoxStyle = buildBoundingBoxStyle(selectedDirectMeasureAndMetadataAnnotationIds, pixelSizeCalibration, _mappedStyle.directMeasureRectangle)
  const ellipseStyle = buildEllipseStyle(selectedDirectMeasureAndMetadataAnnotationIds, pixelSizeCalibration, _mappedStyle.directMeasureEllipse)
  const circleStyle = buildCircleStyle(selectedDirectMeasureAndMetadataAnnotationIds, pixelSizeCalibration, _mappedStyle.directMeasureCircle)
  const textStyle = buildTextStyle(selectedDirectMeasureAndMetadataAnnotationIds, _mappedStyle.metadataAnnotationText)
  const smartAnnotationStyle = buildSmartAnnotationStyle()
  const thicknessMeasureStyle = buildThicknessMeasureStyle()
  const thicknessMeasureLabelStyle = buildThicknessMeasureLabelStyle(_mappedStyle.thicknessMeasure)

  const featureTypeToStyle: Record<FeatureType, StyleFunction | undefined> = {
    [FeatureType.DIRECT_MEASURE_DISTANCE]: distanceStyle,
    [FeatureType.METADATA_ANNOTATION_ARROW]: arrowStyle,
    [FeatureType.DIRECT_MEASURE_ANGLE]: angleStyle,
    [FeatureType.METADATA_ANNOTATION_ANGLE]: angleStyle,
    [FeatureType.DIRECT_MEASURE_AREA]: areaStyle,
    [FeatureType.METADATA_ANNOTATION_POLYGON]: areaStyle,
    [FeatureType.DIRECT_MEASURE_PERIMETER]: perimeterStyle,
    [FeatureType.METADATA_ANNOTATION_LINE]: perimeterStyle,
    [FeatureType.DIRECT_MEASURE_ARC]: arcStyle,
    [FeatureType.DIRECT_MEASURE_RECTANGLE]: boundingBoxStyle,
    [FeatureType.METADATA_ANNOTATION_RECTANGLE]: boundingBoxStyle,
    [FeatureType.CLASS_ANNOTATION]: classAnnotationStyle,
    [FeatureType.DETECTED_OBJECT]: detectedObjectStyle,
    [FeatureType.DIRECT_MEASURE_CIRCLE]: circleStyle,
    [FeatureType.METADATA_ANNOTATION_CIRCLE]: circleStyle,
    [FeatureType.DIRECT_MEASURE_ELLIPSE]: ellipseStyle,
    [FeatureType.METADATA_ANNOTATION_ELLIPSE]: ellipseStyle,
    [FeatureType.METADATA_ANNOTATION_TEXT]: textStyle,
    [FeatureType.SMART_ANNOTATION]: smartAnnotationStyle,
    [FeatureType.THICKNESS_MEASURE_LINE]: thicknessMeasureStyle,
    [FeatureType.THICKNESS_MEASURE_LABEL]: thicknessMeasureLabelStyle,
  }

  return (feature, resolution) => {
    const featureShapeType = feature.get(FEATURE_TYPE)
    const style = featureTypeToStyle[featureShapeType as FeatureType]
    if (style !== undefined) {
      return style(feature, resolution)
    }
    return []
  }
}
