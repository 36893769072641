import { useStageStore } from '@app/stores/stage'

interface UseStageZoomReturn {
  zoom: number | undefined
  setZoom: (zoom: number | undefined) => void
  canZoomIn: () => boolean
  canZoomOut: () => boolean
  canZoomReset: () => boolean
}

export function useStageZoom (): UseStageZoomReturn {
  return useStageStore((state) => ({
    zoom: state.zoom,
    setZoom: state.setZoom,
    canZoomIn: state.canZoomIn,
    canZoomOut: state.canZoomOut,
    canZoomReset: state.canZoomReset,
  }))
}
