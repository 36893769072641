import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style, { type StyleFunction } from 'ol/style/Style'
import ColorJS from 'colorjs.io'
import { Point, type Polygon } from 'ol/geom'
import Text from 'ol/style/Text'
import { measureTextWidth } from 'ol/render/canvas'
import { RegularShape } from 'ol/style'
import { DEFAULT_SHAPE_STYLE, FEATURE_TYPE, FeatureType, type ShapeStyle, type TransformInteractionStyle } from './common'

export interface BoundingBoxStyle extends ShapeStyle {}
export const DEFAULT_BOUNDING_BOX_STYLE: BoundingBoxStyle = DEFAULT_SHAPE_STYLE

export interface RectangleGeometryProperties {
  geometryType: 'RECTANGLE'
  filled: boolean
  pinnedResolution: number
}

export const buildBoundingBoxStyle = (
  selection: Set<string>,
  pixelSizeCalibration: number | undefined,
  style: BoundingBoxStyle = DEFAULT_BOUNDING_BOX_STYLE,
): StyleFunction => {
  // Create RGBA color for fill alpha
  // It would be nice to inline it like: `new Color(style.mainColor).set('alpha', style.fillOpacity)`
  // But it does not work at the moment, see issue:
  // https://github.com/color-js/color.js/issues/310

  return (feature, resolution: number) => {
    const geometry = feature.getGeometry()
    if (geometry === undefined) {
      return []
    }
    const type = geometry.getType()
    if (type !== 'Polygon') {
      return []
    }
    const polygonGeom = geometry as Polygon

    const { pinnedResolution } = geometry.getProperties() as RectangleGeometryProperties

    const selected = selection.has(feature.getProperties().id as string)
    const fillColor = new ColorJS(selected ? style.mainSelectedColor : style.mainColor)
    fillColor.alpha = style.fillOpacity

    const simpliedCoordinates = (polygonGeom.simplify(0) as Polygon).getCoordinates()
    let top = simpliedCoordinates[0][0]
    let left = simpliedCoordinates[0][0]
    let bottom = simpliedCoordinates[0][0]
    let right = simpliedCoordinates[0][0]
    for (const point of simpliedCoordinates[0]) {
      if (point[0] < left[0]) {
        left = point
      }
      if (point[0] > right[0]) {
        right = point
      }
      if (point[1] < bottom[1]) {
        bottom = point
      }
      if (point[1] > top[1]) {
        top = point
      }
    }

    const bboxWidth = right[0] - left[0]
    const bboxHeight = top[1] - bottom[1]
    const bboxArea = bboxWidth * bboxHeight

    const font = `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`

    const bboxWidthMeasurementText = pixelSizeCalibration === undefined
      ? `${bboxWidth.toFixed(1)} px`
      : `${(bboxWidth * pixelSizeCalibration).toFixed(2)} µm`
    const bboxHeightMeasurementText = pixelSizeCalibration === undefined
      ? `${bboxHeight.toFixed(1)} px`
      : `${(bboxHeight * pixelSizeCalibration).toFixed(2)} µm`
    const bboxAreaMeasurementText = pixelSizeCalibration === undefined
      ? `${bboxArea.toFixed(1)} px²`
      : `${(bboxArea * pixelSizeCalibration).toFixed(2)} µm²`
    const bboxWidthMeasurementTexts = [
      bboxWidthMeasurementText,
    ]
    const bboxHeightMeasurementTexts = [
      bboxHeightMeasurementText,
    ]
    const bboxAreaMeasurementTexts = [
      bboxAreaMeasurementText,
    ]

    // const bboxWidthMeasurementTextsWidth = Math.max(...bboxWidthMeasurementTexts.map((measurementText) => measureTextWidth(font, measurementText))) + style.measurementTextFontStrokeWidth * 2
    const bboxWidthMeasurementTextHeight = style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2
    const bboxWidthMeasurementTextsHeight = (bboxWidthMeasurementTextHeight * bboxWidthMeasurementTexts.length) + (style.measurementTextFontLineHeight * (bboxWidthMeasurementTexts.length - 1))
    // const bboxWidthMeasurementTextsBoxWidth = bboxWidthMeasurementTextsWidth + style.measurementTextBackgroundPadding * 2
    const bboxWidthMeasurementTextsBoxHeight = bboxWidthMeasurementTextsHeight +
      style.measurementTextFontStrokeWidth * 2 +
      style.measurementTextBackgroundPadding * 2

    const bboxWidthMeasurementTextPositionOffsetX = 0
    const bboxWidthMeasurementTextPositionOffsetY = (bboxWidthMeasurementTextsBoxHeight / 2) + style.measurementTextBackgroundDistanceShape

    const bboxHeightMeasurementTextsWidth = Math.max(...bboxHeightMeasurementTexts.map((measurementText) => measureTextWidth(font, measurementText))) + style.measurementTextFontStrokeWidth * 2
    // const bboxHeightMeasurementTextHeight = style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2
    // const bboxHeightMeasurementTextsHeight = (bboxHeightMeasurementTextHeight * bboxHeightMeasurementTexts.length) + (style.measurementTextFontLineHeight * (bboxWidthMeasurementTexts.length - 1))
    const bboxHeightMeasurementTextsBoxWidth = bboxHeightMeasurementTextsWidth + style.measurementTextBackgroundPadding * 2
    // const bboxHeightMeasurementTextsBoxHeight = bboxHeightMeasurementTextsHeight +
    //   style.measurementTextFontStrokeWidth * 2 +
    //   style.measurementTextBackgroundPadding * 2

    const bboxHeightMeasurementTextPositionOffsetX = -((bboxHeightMeasurementTextsBoxWidth / 2) + style.measurementTextBackgroundDistanceShape)
    const bboxHeightMeasurementTextPositionOffsetY = 0

    const { filled } = polygonGeom.getProperties() as RectangleGeometryProperties
    const styles = []
    styles.push(
      new Style({
        stroke: new Stroke({
          color: selected ? style.mainSelectedColor : style.mainColor,
          width: style.strokeWidth,
        }),
        fill: new Fill({
          color: filled ? fillColor.toString() : 'rgba(0, 0, 0, 0)',
        }),
      }),
    )
    if (feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_RECTANGLE) {
      styles.push(
        // Bbox height measurement text
        new Style({
          text: new Text({
            text: bboxHeightMeasurementTexts.join('\n'),
            font,
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
            backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
            fill: new Fill({
              color: style.measurementTextFontFillColor,
            }),
            stroke: new Stroke({
              color: style.measurementTextFontStrokeColor,
              width: style.measurementTextFontStrokeWidth,
            }),
            offsetX: bboxHeightMeasurementTextPositionOffsetX * pinnedResolution / resolution,
            offsetY: bboxHeightMeasurementTextPositionOffsetY * pinnedResolution / resolution,
            scale: pinnedResolution / resolution,
          }),
          geometry: () => {
            return new Point([
              left[0],
              (top[1] + bottom[1]) / 2,
            ])
          },
          zIndex: style.zIndexOffSet + 2,
        }),
        // Bbox width measurement text
        new Style({
          text: new Text({
            text: bboxWidthMeasurementTexts.join('\n'),
            font,
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
            backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
            fill: new Fill({
              color: style.measurementTextFontFillColor,
            }),
            stroke: new Stroke({
              color: style.measurementTextFontStrokeColor,
              width: style.measurementTextFontStrokeWidth,
            }),
            offsetX: bboxWidthMeasurementTextPositionOffsetX * pinnedResolution / resolution,
            offsetY: bboxWidthMeasurementTextPositionOffsetY * pinnedResolution / resolution,
            scale: pinnedResolution / resolution,
          }),
          geometry: () => {
            return new Point([
              (left[0] + right[0]) / 2,
              bottom[1],
            ])
          },
          zIndex: style.zIndexOffSet + 2,
        }),
        // Bbox area measurement text
        new Style({
          text: new Text({
            text: bboxAreaMeasurementTexts.join('\n'),
            font,
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
            backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
            fill: new Fill({
              color: style.measurementTextFontFillColor,
            }),
            stroke: new Stroke({
              color: style.measurementTextFontStrokeColor,
              width: style.measurementTextFontStrokeWidth,
            }),
            offsetX: bboxHeightMeasurementTextPositionOffsetX * pinnedResolution / resolution,
            offsetY: bboxWidthMeasurementTextPositionOffsetY * pinnedResolution / resolution,
            scale: pinnedResolution / resolution,
          }),
          geometry: () => {
            return new Point([
              left[0],
              bottom[1],
            ])
          },
          zIndex: style.zIndexOffSet + 2,
        }),
      )
    }
    return styles
  }
}

export const buildBoundingBoxInteractionStyle = (): TransformInteractionStyle => {
  const stroke = new Stroke({
    color: 'rgba(255, 255, 255, 1)',
    width: 1,
  })
  const fill = new Fill({
    color: 'rgba(255, 255, 255, 0.5)',
  })
  const bigHandle = new Style({
    image: new RegularShape({
      fill,
      stroke,
      radius: 8,
      points: 4,
      angle: Math.PI / 4,
    }),
    fill,
    stroke,
  })
  const smallHandle = new Style({
    image: new RegularShape({
      fill,
      stroke,
      radius: 6,
      points: 4,
      angle: Math.PI / 4,
    }),
    fill,
    stroke,
  })

  return {
    default: [],
    rotate: new Style({}),
    rotate0: new Style({}),
    scale: bigHandle,
    scale1: bigHandle,
    scale2: bigHandle,
    scale3: bigHandle,
    scalev: smallHandle,
    scaleh1: smallHandle,
    scalev2: smallHandle,
    scaleh3: smallHandle,
  }
}
