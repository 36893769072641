import type * as React from 'react'
import styles from './styles/reset-password-form.module.scss'
import { Button, Form, Input, Space } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { WebUIRoutes } from '@app/routes'
import clsx from 'clsx'
import { AuthenticationFormLayout, type AuthenticationFormLayoutResult } from '@components/authentication/authentication-form-layout'
import { useNavigate } from 'react-router'
import Key from '@material-design-icons/svg/filled/key.svg'

export interface ActivateAccountFormResult {
  token: string
}
interface ActivateAccountFormProps extends AuthenticationFormLayoutResult<ActivateAccountFormResult> {
  token: string
}
export const ActivateAccountForm: React.FC<ActivateAccountFormProps> = (props) => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <AuthenticationFormLayout
      onSubmit={props.onSubmit}
      title={
        <FormattedMessage id="activate-account.title" defaultMessage={'Activate account'} />
      }
    >
      <Space direction="vertical" size="small" className={styles.spaceSeparation}>
        <Form.Item
          name="token"
          rules={[{
            required: true,
            type: 'string',
            min: 100,
            message: <FormattedMessage id="activate-account.form.error.required.token" defaultMessage={'Please input a valid token.'} />,
          }]}
          hasFeedback
          initialValue={props.token}
          hidden
        >
          <Input prefix={<Key />} placeholder={intl.formatMessage({
            id: 'activate-account.form.ActivateAccount.placeholder',
            defaultMessage: 'Activate account Token',
          })}
          />
        </Form.Item>

        <Form.Item>
          <Button className={styles.floatRight} type="primary" htmlType="submit">
            <FormattedMessage id="activate-account.form.ActivateAccount.submit" defaultMessage={'Activate'} />
          </Button>
          <Button className={clsx(styles.floatRight, styles.marginRight)} onClick={() => { navigate(WebUIRoutes.authentication().path) }}>
            <FormattedMessage id="login.form.ActivateAccount.login" defaultMessage={'Go to login'} />
          </Button>
        </Form.Item>
      </Space>
    </AuthenticationFormLayout>
  )
}
