import type * as React from 'react'
import styles from './styles/rate-user-experience.module.scss'
import { Popover, Rate } from 'antd'
import { FormattedMessage } from 'react-intl'

interface RateUserExperienceProps {
  isVisible: boolean
  hasGivenFeedback: boolean
  onGiveFeedback: (value: number) => void
  children: React.ReactNode
}
export const RateUserExperience: React.FC<RateUserExperienceProps> = ({ isVisible, hasGivenFeedback, onGiveFeedback, children }) => {
  return <Popover
    open={isVisible}
    content={
      <div className={styles.content}>
        {hasGivenFeedback
          ? <FormattedMessage id="validate-editor.download-button.user-feedback.thanks" defaultMessage="Thanks for your feedback!"/>
          : <Rate defaultValue={0} onChange={onGiveFeedback} />}
      </div>
    }
    title={
      <div className={styles.title}>
        <FormattedMessage id="validate-editor.download-button.user-feedback.title" defaultMessage="Please rate your experience"/>
      </div>
    }
  >
    {children}
  </Popover>
}
