/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdateProfileRequest
 */
export interface UserUpdateProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    organization?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateProfileRequest
     */
    phoneNumber?: string | null;
}

/**
 * Check if a given object implements the UserUpdateProfileRequest interface.
 */
export function instanceOfUserUpdateProfileRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserUpdateProfileRequestFromJSON(json: any): UserUpdateProfileRequest {
    return UserUpdateProfileRequestFromJSONTyped(json, false);
}

export function UserUpdateProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateProfileRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
    };
}

export function UserUpdateProfileRequestToJSON(value?: UserUpdateProfileRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'organization': value.organization,
        'job_title': value.jobTitle,
        'phone_number': value.phoneNumber,
    };
}

