import { useEffect } from 'react'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import { useSelectedImage } from '@app/pages/editor-page/hooks/editor-page'

export const useCanvasPixelSize = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): void => {
  const selectedImage = useSelectedImage()

  useEffect(() => {
    clemexMosaicCanvas.setPixelSize(selectedImage.data?.pixelSizeUm ?? undefined)
  }, [clemexMosaicCanvas, selectedImage.data?.pixelSizeUm])
}
