/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ServerEnvironment = {
    Prod: 'PROD',
    Staging: 'STAGING',
    NotDefined: 'NOT_DEFINED'
} as const;
export type ServerEnvironment = typeof ServerEnvironment[keyof typeof ServerEnvironment];


export function ServerEnvironmentFromJSON(json: any): ServerEnvironment {
    return ServerEnvironmentFromJSONTyped(json, false);
}

export function ServerEnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServerEnvironment {
    return json as ServerEnvironment;
}

export function ServerEnvironmentToJSON(value?: ServerEnvironment | null): any {
    return value as any;
}

