/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LineGeometryProperties
 */
export interface LineGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof LineGeometryProperties
     */
    geometryType: LineGeometryPropertiesGeometryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof LineGeometryProperties
     */
    pinnedResolution: number;
}


/**
 * @export
 */
export const LineGeometryPropertiesGeometryTypeEnum = {
    Line: 'LINE'
} as const;
export type LineGeometryPropertiesGeometryTypeEnum = typeof LineGeometryPropertiesGeometryTypeEnum[keyof typeof LineGeometryPropertiesGeometryTypeEnum];


/**
 * Check if a given object implements the LineGeometryProperties interface.
 */
export function instanceOfLineGeometryProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "geometryType" in value;
    isInstance = isInstance && "pinnedResolution" in value;

    return isInstance;
}

export function LineGeometryPropertiesFromJSON(json: any): LineGeometryProperties {
    return LineGeometryPropertiesFromJSONTyped(json, false);
}

export function LineGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineGeometryProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function LineGeometryPropertiesToJSON(value?: LineGeometryProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry_type': value.geometryType,
        'pinned_resolution': value.pinnedResolution,
    };
}

