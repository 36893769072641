import type * as React from 'react'
import { Divider, type DividerProps } from 'antd'
import styles from './styles/divider.module.scss'
import clsx from 'clsx'

interface AuthenticationFormDividerProps extends DividerProps {
  type?: 'vertical' | 'horizontal'
}

export const AuthenticationFormDivider: React.FC<AuthenticationFormDividerProps> = ({ type = 'vertical', ...dividerProps }) => {
  return <Divider {...dividerProps} className={clsx(dividerProps.className, styles.divider, { [styles.horizontal]: type === 'horizontal' })} type={type}/>
}
