import { Typography, Flex, Button, Slider, Switch, Alert, Select, Modal } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './styles/algorithm-settings.module.scss'
import { useAlgorithmId, useDeepLearningProjectTrainingParameters, useRFAlgorithmProjectTrainingParameters, useProjectTrainingState, useLicence, useProjectClasses, type LicenceInformation } from '@app/api/hooks'
import clsx from 'clsx'
import { AlgorithmId } from '@app/api/openapi'
import { RequestLicenceFeature } from '@components/messages/request-licensed-feature'
import { UserAssistanceLicenceFeature } from '@app/api/openapi/models/UserAssistanceLicenceFeature'
import { SettingRow, SettingsSection } from '@components/modals/project-settings/settings'
import { useRef } from 'react'
import { ALGORITHM_ID_TO_MANDATORY_CLASSES } from '@app/constants'

const { Text, Paragraph } = Typography

const isAvailableAlgorithm = (licence: LicenceInformation, algorithmId: AlgorithmId | undefined): boolean => {
  return (licence?.algoClemexNetLiteV1 && algorithmId === AlgorithmId.ClemexNetLiteV1) ||
    (licence?.algoClemexNetLiteV2 && algorithmId === AlgorithmId.ClemexNetLiteV2) ||
    (licence?.algoClemexGrainsegV3 && algorithmId === AlgorithmId.ClemexGrainsegV3) ||
    (licence?.algoClemexGrainsegV3Aluminum && algorithmId === AlgorithmId.ClemexGrainsegV3Aluminum) ||
    (licence?.algoClemexMedcleanV1 && algorithmId === AlgorithmId.ClemexMedcleanV1)
}

interface AlgorithmSelectorProps {
  projectId: string | undefined
}
export const AlgorithmSelector: React.FC<AlgorithmSelectorProps> = ({ projectId }) => {
  const { data: algorithmId, mutate: mutateAlgorithmId } = useAlgorithmId(projectId)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)

  const { invalidate: invalidateProjectClass } = useProjectClasses(projectId)
  const { data: licence } = useLicence()
  const intl = useIntl()
  const available = licence !== undefined && (
    isAvailableAlgorithm(licence, algorithmId) ||
    (algorithmId === AlgorithmId.RfAlgorithmSklearn) ||
    (algorithmId === AlgorithmId.RfAlgorithmCuml)
  )
  const licenseFeatureRequest = {
    [AlgorithmId.RfAlgorithmSklearn]: null,
    [AlgorithmId.RfAlgorithmCuml]: null,
    [AlgorithmId.ClemexNetLiteV1]: UserAssistanceLicenceFeature.AlgoClemexNetLiteV1,
    [AlgorithmId.ClemexNetLiteV2]: UserAssistanceLicenceFeature.AlgoClemexNetLiteV2,
    [AlgorithmId.ClemexGrainsegV3]: UserAssistanceLicenceFeature.AlgoClemexGrainsegV3,
    [AlgorithmId.ClemexGrainsegV3Aluminum]: UserAssistanceLicenceFeature.AlgoClemexGrainsegV3Aluminum,
    [AlgorithmId.ClemexMedcleanV1]: UserAssistanceLicenceFeature.AlgoClemexMedcleanV1,
  }[algorithmId ?? AlgorithmId.RfAlgorithmSklearn] // Default to a use case that will never be used

  const algoLabel = {
    [AlgorithmId.RfAlgorithmSklearn]: intl.formatMessage({ id: 'algorithm-id.random-forest.label', defaultMessage: 'Random Forest' }),
    [AlgorithmId.RfAlgorithmCuml]: intl.formatMessage({ id: 'algorithm-id.random-forest.label', defaultMessage: 'Random Forest' }),
    [AlgorithmId.ClemexNetLiteV1]: intl.formatMessage({ id: 'algorithm-id.clemex-net-lite-v1.label', defaultMessage: 'ClemexNet Lite V1' }),
    [AlgorithmId.ClemexNetLiteV2]: intl.formatMessage({ id: 'algorithm-id.clemex-net-lite-v2.label', defaultMessage: 'ClemexNet Lite V2' }),
    [AlgorithmId.ClemexGrainsegV3]: intl.formatMessage({ id: 'algorithm-id.clemex-grainseg-v3.label', defaultMessage: 'Universal GrainSeg V3' }),
    [AlgorithmId.ClemexGrainsegV3Aluminum]: intl.formatMessage({ id: 'algorithm-id.clemex-grainseg-v3-aluminum.label', defaultMessage: 'GrainSeg V3 Aluminum' }),
    [AlgorithmId.ClemexMedcleanV1]: intl.formatMessage({ id: 'algorithm-id.clemex-medclean-v1.label', defaultMessage: 'Medclean V1' }),
  }

  const algoSelectOptions = Object.values(AlgorithmId)
    .filter((value) => value !== AlgorithmId.RfAlgorithmCuml) // Remove RfAlgorithmCuml from the list of available algorithms
    .map((value) => ({
      value,
      label: algoLabel[value],
    }))

  const [algorithmChangeModal, algorithmChangeModalContextHolder] = Modal.useModal()

  const performAlgorithChange = async (value: AlgorithmId): Promise<void> => {
    await mutateAlgorithmId(value)
    await mutateProjectTrainingState()
    await invalidateProjectClass()
  }

  const selectAlgorithmRef = useRef<HTMLDivElement>(null)

  const onAlgorithmChange = async (value: AlgorithmId): Promise<void> => {
    if (ALGORITHM_ID_TO_MANDATORY_CLASSES[value].length > 0) {
      const rect = selectAlgorithmRef.current?.getBoundingClientRect()
      const top = rect?.top ?? 0
      const left = rect?.left ?? 0

      void algorithmChangeModal.confirm({
        title: intl.formatMessage(
          { id: 'algorithm.selector.modal.confirm.title', defaultMessage: 'Changing algorithm require to perform changes' },
        ),
        content: <div className='flex flex-col gap-[8px]'>
          <div className='flex flex-col gap-[8px]'>
            <FormattedMessage
              id="algorithm.selector.modal.confirm.content.main"
              defaultMessage={'The {number} first classes will be renamed according to the requirements of the {algorithm}.'}
              values={{ algorithm: algoLabel[value], number: ALGORITHM_ID_TO_MANDATORY_CLASSES[value].length }}
            />
            {
              (value === AlgorithmId.ClemexGrainsegV3 || value === AlgorithmId.ClemexGrainsegV3Aluminum) &&
              <div>
                <FormattedMessage
                  id="algorithm.selector.modal.confirm.content.grainseg"
                  defaultMessage={'First class will be "Grain", second class will be "Resin", and third class will be "Grain boundary".'}
                />
              </div>
            }
            {
              (value === AlgorithmId.ClemexMedcleanV1) &&
              <div>
                <FormattedMessage
                  id="algorithm.selector.modal.confirm.content.medclean"
                  defaultMessage={'First class is "Background" and the second class is "Particle".'}
                />
              </div>
            }
          </div>
          <div>
            <FormattedMessage
              id="algorithm.selector.modal.content.question"
              defaultMessage={'Would you like to continue and apply the changes?'}
            />
          </div>
        </div>,
        onOk: async () => { await performAlgorithChange(value) },
        okText: intl.formatMessage({ id: 'algorithm.selector.modal.confirm.button.accept', defaultMessage: 'Apply' }),
        centered: true,
        transitionName: '',
        style: {
          left: left - 200,
          top: top - 250,
          position: 'absolute',
        },
      })
    } else {
      await performAlgorithChange(value)
    }
  }

  return <Flex vertical flex={1} className={styles.root}>
    <SettingsSection
      title={
        <FormattedMessage id='training-options.models.label' defaultMessage='Models' />
      }
    >
      {algorithmChangeModalContextHolder}
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.algorithm.choice" defaultMessage={'Model'} />
        }
      >
        <div ref={selectAlgorithmRef}>
          <Select
            className={styles.algorithmSelect}
            options={algoSelectOptions}
            onChange={onAlgorithmChange}
            value={algorithmId}
          />
        </div>
      </SettingRow>
    </SettingsSection>
    {!available && licenseFeatureRequest !== null && algorithmId !== undefined && <SettingRow>
      <Alert
        message={<Text>
          <Paragraph>
            <FormattedMessage id="project-settings.modal.deep-learning.clemex-net-lite-v1.training-parameters.licence-restriction.warning.model" defaultMessage='This model use deep learning technology.' />
          </Paragraph>
          <RequestLicenceFeature featureText={algoLabel[algorithmId]} licenceFeatureRequest={licenseFeatureRequest} />
        </Text>}
        type="warning"
      />
    </SettingRow>
    }
  </Flex>
}
interface AlgorithmSettingsProps {
  projectId: string | undefined
  algorithmId?: AlgorithmId
}
export const DeepLearningSettings: React.FC<AlgorithmSettingsProps> = ({ projectId, algorithmId }) => {
  const { data: projectSettingsDeepLearningTrainingParameters, isLoading, mutateTrainingParameters, reset } = useDeepLearningProjectTrainingParameters(projectId)
  const { data: licence } = useLicence()
  const available = licence !== undefined && isAvailableAlgorithm(licence, algorithmId)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)

  const onChangeEpochs = async (epochs: number): Promise<void> => {
    if (projectSettingsDeepLearningTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsDeepLearningTrainingParameters,
      epochs,
    })
    await mutateProjectTrainingState()
  }
  if (isLoading || projectSettingsDeepLearningTrainingParameters === undefined) {
    return <FormattedMessage id="project-settings.modal.tab.algorithm.loading" defaultMessage={'Loading...'} />
  }
  const epochs: number = projectSettingsDeepLearningTrainingParameters.epochs ?? 20

  return <Flex vertical flex={1} className={styles.root}>
    <SettingsSection
      title={
        <FormattedMessage id="project-settings.modal.section.algorithm.section.training-parameters" defaultMessage={'Training options'} />
      }
    >
      <SettingRow
        className={clsx({ [styles.disabledRow]: !available })}
        title={
          <FormattedMessage id="project-settings.modal.section.deep-learning.training-parameters.epochs" defaultMessage={'Epochs'} />
        }
      >
        <Slider
          disabled={!available}
          className={styles.slider}
          tooltip={{ open: false }}
          min={1}
          max={150}
          step={1}
          marks={{
            [epochs]: epochs,
          }}
          value={epochs}
          onChange={onChangeEpochs}
        />
      </SettingRow>
      <SettingRow className={styles.actionsContainer}>
        <Button type='primary' onClick={reset}>
          <FormattedMessage id="project-settings.modal.section.deep-learning.training-parameters.reset" defaultMessage={'Reset to default training parameters'} />
        </Button>
      </SettingRow>
    </SettingsSection>
  </Flex>
}
export const RfAlgorithmSettings: React.FC<AlgorithmSettingsProps> = ({ projectId }) => {
  const { data: projectSettingsRfAlgorithmTrainingParameters, isLoading, mutateTrainingParameters, reset } = useRFAlgorithmProjectTrainingParameters(projectId)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)
  const onChangeSigma = async (sigma: number[]): Promise<void> => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      sigmaMin: sigma[0],
      sigmaMax: sigma[1],
    })
    await mutateProjectTrainingState()
  }
  const onToggleIntensity = async (): Promise<void> => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      intensity: !projectSettingsRfAlgorithmTrainingParameters.intensity,
    })
    await mutateProjectTrainingState()
  }
  const onToggleTexture = async (): Promise<void> => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      texture: !projectSettingsRfAlgorithmTrainingParameters.texture,
    })
    await mutateProjectTrainingState()
  }
  const onToggleEdges = async (): Promise<void> => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    await mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      edges: !projectSettingsRfAlgorithmTrainingParameters.edges,
    })
    await mutateProjectTrainingState()
  }

  if (isLoading || projectSettingsRfAlgorithmTrainingParameters === undefined) {
    return <FormattedMessage id="project-settings.modal.tab.algorithm.loading" defaultMessage={'Loading...'} />
  }

  const { sigmaMin, sigmaMax, edges, intensity, texture } = projectSettingsRfAlgorithmTrainingParameters
  const sigma = [sigmaMin, sigmaMax]
  const marks = { [sigma[0]]: sigma[0], [sigma[1]]: sigma[1] }
  const features = [intensity, edges, texture]
  const enabledFeatures = features.filter((feature) => feature)
  const onlyHasOneFeatureEnabled = enabledFeatures.length === 1

  const disabledIntensity = onlyHasOneFeatureEnabled && intensity
  const disabledEdges = onlyHasOneFeatureEnabled && edges
  const disabledTexture = onlyHasOneFeatureEnabled && texture

  return <Flex vertical flex={1} className={styles.root}>
    <SettingsSection
      className={styles.settingsSection}
      title={
        <FormattedMessage id="project-settings.modal.section.algorithm.section.training-parameters" defaultMessage={'Training options'} />
      }
    >
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.sigma" defaultMessage={'Sigma'} />
        }
      >
        <Slider
          className={styles.slider}
          tooltip={{ open: false }}
          range
          min={0.1}
          max={20.0}
          step={0.1}
          marks={marks}
          value={sigma}
          onChange={onChangeSigma}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.intensity" defaultMessage={'Intensity'} />
        }
      >
        <Switch
          className={clsx({ [styles.disabledSwitch]: disabledIntensity })}
          checked={intensity}
          onChange={() => {
            const element = document.activeElement as HTMLElement
            if (!disabledIntensity) {
              void onToggleIntensity()
            }
            element.blur()
          }}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.edges" defaultMessage={'Edges'} />
        }
      >
        <Switch
          className={clsx({ [styles.disabledSwitch]: disabledEdges })}
          checked={edges}
          onChange={() => {
            const element = document.activeElement as HTMLElement
            if (!disabledEdges) {
              void onToggleEdges()
            }
            element.blur()
          }}
        />
      </SettingRow>
      <SettingRow
        title={
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.texture" defaultMessage={'Texture'} />
        }
      >
        <Switch
          className={clsx({ [styles.disabledSwitch]: disabledTexture })}
          checked={texture}
          onChange={() => {
            const element = document.activeElement as HTMLElement
            if (!disabledTexture) {
              void onToggleTexture()
            }
            element.blur()
          }}
        />
      </SettingRow>
      {onlyHasOneFeatureEnabled && <SettingRow>
        <Alert
          className={styles.alert}
          message={<FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.switch.warning" defaultMessage="At least one option is required. You will not be able to deactivate the last one." />}
          type="warning"
        />
      </SettingRow>}
      <SettingRow className={styles.actionsContainer}>
        <Button type='primary' onClick={reset}>
          <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.reset" defaultMessage={'Reset to default training parameters'} />
        </Button>
      </SettingRow>
    </SettingsSection>
  </Flex>
}

interface AlgorithmSettingsProps {
  projectId: string | undefined
  algorithmId?: AlgorithmId
}

export const AlgorithmSettings: React.FC<AlgorithmSettingsProps> = ({ projectId, algorithmId }) => {
  switch (algorithmId) {
    case AlgorithmId.RfAlgorithmSklearn:
    case AlgorithmId.RfAlgorithmCuml:
      return <RfAlgorithmSettings projectId={projectId} />
    case AlgorithmId.ClemexNetLiteV1:
    case AlgorithmId.ClemexNetLiteV2:
    case AlgorithmId.ClemexGrainsegV3:
    case AlgorithmId.ClemexGrainsegV3Aluminum:
    case AlgorithmId.ClemexMedcleanV1:
      return <DeepLearningSettings projectId={projectId} algorithmId={algorithmId} />
    default:
      return null
  }
}
