/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserAssistanceLicenceFeature = {
    ExportProject: 'export_project',
    AlgoClemexNetLiteV1: 'algo_clemex_net_lite_v1',
    AlgoClemexNetLiteV2: 'algo_clemex_net_lite_v2',
    AlgoClemexGrainsegV3: 'algo_clemex_grainseg_v3',
    AlgoClemexGrainsegV3Aluminum: 'algo_clemex_grainseg_v3_aluminum',
    AlgoClemexMedcleanV1: 'algo_clemex_medclean_v1'
} as const;
export type UserAssistanceLicenceFeature = typeof UserAssistanceLicenceFeature[keyof typeof UserAssistanceLicenceFeature];


export function UserAssistanceLicenceFeatureFromJSON(json: any): UserAssistanceLicenceFeature {
    return UserAssistanceLicenceFeatureFromJSONTyped(json, false);
}

export function UserAssistanceLicenceFeatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAssistanceLicenceFeature {
    return json as UserAssistanceLicenceFeature;
}

export function UserAssistanceLicenceFeatureToJSON(value?: UserAssistanceLicenceFeature | null): any {
    return value as any;
}

