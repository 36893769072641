import type * as React from 'react'
import styles from './styles/reset-password-form.module.scss'
import { Button, Form, Input, Space } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { WebUIRoutes } from '@app/routes'
import clsx from 'clsx'
import { AuthenticationFormLayout, type AuthenticationFormLayoutResult } from '@components/authentication/authentication-form-layout'
import { useNavigate } from 'react-router'
import Key from '@material-design-icons/svg/filled/key.svg'
import LockKey from '@material-design-icons/svg/round/key.svg'

export interface ResetPasswordFormResult {
  email: string
  password: string
  remember: boolean
  token: string
}
interface ResetPasswordFormProps extends AuthenticationFormLayoutResult<ResetPasswordFormResult> {
  resetPasswordToken: string
}
export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <AuthenticationFormLayout
      onSubmit={props.onSubmit}
      title={
        <FormattedMessage id="reset-password.title" defaultMessage={'Reset Password'} />
      }
    >
      <Space direction="vertical" size="small" className={styles.spaceSeparation}>
        <Form.Item
          name="token"
          rules={[{
            required: true,
            type: 'string',
            min: 100,
            message: <FormattedMessage id="reset-password.form.error.required.token" defaultMessage={'Please input a valid token.'} />,
          }]}
          hasFeedback
          initialValue={props.resetPasswordToken}
          hidden
        >
          <Input prefix={<Key />} placeholder={intl.formatMessage({
            id: 'reset-password.form.resetPassword.placeholder',
            defaultMessage: 'Reset Password Token',
          })}
          />
        </Form.Item>

        <Form.Item
          name="password"
          tooltip={intl.formatMessage({
            id: 'reset-password.form.tooltip.password',
            defaultMessage: 'Password must contain at least 8 characters.',
          })}
          rules={[
            {
              required: true,
              min: 8,
              message: <FormattedMessage id="reset-password.form.error.required.password" defaultMessage={'Please input a valid password.'} />,
            }]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockKey />}
            type="password"
            placeholder={intl.formatMessage({
              id: 'reset-password.form.placeholder.password',
              defaultMessage: 'New Password',
            })}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          tooltip={intl.formatMessage({
            id: 'reset-password.form.tooltip.password',
            defaultMessage: 'Password must contain at least 8 characters.',
          })}
          rules={[
            {
              required: true,
              min: 8,
              message: <FormattedMessage id="reset-password.form.error.required.password" defaultMessage={'Please input a valid password.'} />,
            },
            ({ getFieldValue }) => ({
              async validator (_, value) {
                if (value === '' || getFieldValue('password') === value) {
                  await Promise.resolve()
                  return
                }
                return await Promise.reject(new Error(intl.formatMessage({
                  id: 'reset-password.form.error.passwordMustMatch',
                  defaultMessage: 'Passwords does not match',
                })))
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockKey />}
            type="password"
            placeholder={intl.formatMessage({
              id: 'reset-password.form.placeholder.password.confirm',
              defaultMessage: 'Confirm password',
            })}
          />
        </Form.Item>

        <Form.Item>
          <Button className={styles.floatRight} type="primary" htmlType="submit">
            <FormattedMessage id="reset-password.form.resetPassword.submit" defaultMessage={'Submit'} />
          </Button>
          <Button className={clsx(styles.floatRight, styles.marginRight)} onClick={() => { navigate(WebUIRoutes.authentication().path) }}>
            <FormattedMessage id="login.form.resetPassword.login" defaultMessage={'Go to login'} />
          </Button>
        </Form.Item>
      </Space>
    </AuthenticationFormLayout>
  )
}
