/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeasurementSettingsValueObject
 */
export interface MeasurementSettingsValueObject {
    /**
     * Anchor point for the scale bar.
     * @type {string}
     * @memberof MeasurementSettingsValueObject
     */
    scaleAnchor: MeasurementSettingsValueObjectScaleAnchorEnum;
    /**
     * The position of the text relative to the scale bar.
     * @type {string}
     * @memberof MeasurementSettingsValueObject
     */
    scaleBarTextPosition: MeasurementSettingsValueObjectScaleBarTextPositionEnum;
    /**
     * Size of the Scale bar in pixel
     * @type {number}
     * @memberof MeasurementSettingsValueObject
     */
    scaleBarSizePx: number;
    /**
     * The main color of the scale bar
     * @type {string}
     * @memberof MeasurementSettingsValueObject
     */
    scaleBarColor: string;
    /**
     * The main color of the scale bar
     * @type {number}
     * @memberof MeasurementSettingsValueObject
     */
    scaleBarThickness: number;
}


/**
 * @export
 */
export const MeasurementSettingsValueObjectScaleAnchorEnum = {
    TopLeft: 'top_left',
    TopRight: 'top_right',
    BottomLeft: 'bottom_left',
    BottomRight: 'bottom_right'
} as const;
export type MeasurementSettingsValueObjectScaleAnchorEnum = typeof MeasurementSettingsValueObjectScaleAnchorEnum[keyof typeof MeasurementSettingsValueObjectScaleAnchorEnum];

/**
 * @export
 */
export const MeasurementSettingsValueObjectScaleBarTextPositionEnum = {
    Top: 'top',
    Middle: 'middle',
    Bottom: 'bottom'
} as const;
export type MeasurementSettingsValueObjectScaleBarTextPositionEnum = typeof MeasurementSettingsValueObjectScaleBarTextPositionEnum[keyof typeof MeasurementSettingsValueObjectScaleBarTextPositionEnum];


/**
 * Check if a given object implements the MeasurementSettingsValueObject interface.
 */
export function instanceOfMeasurementSettingsValueObject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scaleAnchor" in value;
    isInstance = isInstance && "scaleBarTextPosition" in value;
    isInstance = isInstance && "scaleBarSizePx" in value;
    isInstance = isInstance && "scaleBarColor" in value;
    isInstance = isInstance && "scaleBarThickness" in value;

    return isInstance;
}

export function MeasurementSettingsValueObjectFromJSON(json: any): MeasurementSettingsValueObject {
    return MeasurementSettingsValueObjectFromJSONTyped(json, false);
}

export function MeasurementSettingsValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementSettingsValueObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scaleAnchor': json['scale_anchor'],
        'scaleBarTextPosition': json['scale_bar_text_position'],
        'scaleBarSizePx': json['scale_bar_size_px'],
        'scaleBarColor': json['scale_bar_color'],
        'scaleBarThickness': json['scale_bar_thickness'],
    };
}

export function MeasurementSettingsValueObjectToJSON(value?: MeasurementSettingsValueObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scale_anchor': value.scaleAnchor,
        'scale_bar_text_position': value.scaleBarTextPosition,
        'scale_bar_size_px': value.scaleBarSizePx,
        'scale_bar_color': value.scaleBarColor,
        'scale_bar_thickness': value.scaleBarThickness,
    };
}

