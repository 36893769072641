import * as React from 'react'
import * as RFAPI from '@api/api'
import { DownloadButton } from '@app/pages/editor-page/panel/panel-elements/download-button'
import { EVENTS_ID } from '@app/constants'
import { RateUserExperience } from '@components/rate-user-experience'
import { ContactSales } from '@components/contact-sales'
import { useFeatureFlags, useOnboardingTasks, useProfile, useProjectBundle } from '@app/api/hooks'
import { PluginInstallationInstructionsModal } from '@components/modals/plugin-installation-instructions'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'
import { useIntl } from 'react-intl'
import { PluginStatus } from '@app/api/openapi'
interface DownloadPluginButtonProps {
  projectId?: string
}
export const DownloadPluginButton: React.FC<DownloadPluginButtonProps> = ({ projectId }) => {
  const { data: featureFlags } = useFeatureFlags()
  const { data: onboardingTasks, mutate: mutateOnboardingTasks } = useOnboardingTasks()
  const [isRateUserExperienceVisible, setIsRateUserExperienceVisible] = React.useState(false)
  const [shouldContactSales, setShouldContactSales] = React.useState(false)
  const [showInstallInstruction, setShowInstallInstruction] = React.useState(false)
  const { data: projectBundle } = useProjectBundle(projectId)
  const { data: userProfile, isLoading: userIsLoading } = useProfile()
  const [hasGivenFeedback, setHasGivenFeedback] = React.useState(false)
  const intl = useIntl()
  const disableRating = React.useMemo(() => {
    return featureFlags?.enableLocalUser ?? false
  }, [featureFlags?.enableLocalUser])
  const [downloadNotification, downloadContextHolder] = notification.useNotification()

  React.useEffect(() => {
    setHasGivenFeedback(onboardingTasks?.irreversibleUserHasCompletedOnboardingFeedback === true)
  }, [onboardingTasks?.irreversibleUserHasCompletedOnboardingFeedback])

  const doDownload = (): void => {
    if (onboardingTasks !== undefined && (onboardingTasks.irreversibleUserHasReadPluginInstallationInstructions === false)) {
      setShowInstallInstruction(true)
    }
    void mutateOnboardingTasks()
    try {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = RFAPI.getPluginLinkURL(projectId ?? '')
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (err: unknown) {
      downloadNotification.error({
        message: intl.formatMessage({
          id: 'download_plugin.notification.download.fail',
          defaultMessage: 'Plugin download failed',
        }),
      })
      Sentry.captureException(err)
    }
  }

  const onDownload = (): void => {
    if (onboardingTasks === undefined) {
      return
    }
    const isLocalUser = featureFlags?.enableLocalUser ?? false
    if (!isLocalUser && (onboardingTasks.irreversibleUserHasCompletedOnboardingFeedback === false)) {
      // Prevent the download if not allowed
      setIsRateUserExperienceVisible(true)
    } else {
      onDownloadAfterFeedback()
    }
  }

  const onDownloadAfterFeedback = (): void => {
    if (onboardingTasks === undefined) {
      return
    }
    if (projectBundle?.status === PluginStatus.MissingDownloadPermission) {
      setShouldContactSales(true)
      setTimeout(() => {
        setShouldContactSales(false)
      }, 7000)
    } else {
      doDownload()
    }
  }

  const onGiveFeedback = async (rating: number): Promise<void> => {
    await RFAPI.giveCompletedOnboardingFeedback(rating)

    setHasGivenFeedback(true)
    await mutateOnboardingTasks()

    setTimeout(() => {
      setIsRateUserExperienceVisible(false)
      onDownloadAfterFeedback()
    }, 1000)
  }

  const onClose = React.useCallback((doNotShowAgain: boolean): void => {
    if (doNotShowAgain) {
      window.dispatchEvent(new CustomEvent(EVENTS_ID.USER_HAS_READ_PLUGIN_INSTALLATION_INSTRUCTIONS))
    }
    setShowInstallInstruction(false)
  }, [setShowInstallInstruction])

  return <>
    <ContactSales
      isVisible={shouldContactSales}
    >
      <RateUserExperience
        isVisible={isRateUserExperienceVisible && !disableRating}
        hasGivenFeedback={hasGivenFeedback}
        onGiveFeedback={onGiveFeedback}
      >
        {downloadContextHolder}
        <DownloadButton
          onDownload={onDownload}
          isDisabled={userIsLoading}
          userName={userProfile?.email ?? ''}
          isNotTrainedYet={projectBundle?.status === PluginStatus.NotTrainedYet}
          algorithmIsNotAvailable={projectBundle?.status === PluginStatus.AlgorithmIsNotAvailable}
        />
      </RateUserExperience>
    </ContactSales>
    <PluginInstallationInstructionsModal
      isOpen={showInstallInstruction}
      onClose={onClose}
    />
  </>
}
