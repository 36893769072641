import { type MapEvent } from 'ol'
import { type State } from 'ol/View'
import { Control } from 'ol/control'
import { CLASS_UNSELECTABLE } from 'ol/css.js'
import { getPointResolution } from 'ol/proj'

// This class mimic the basic scale control from OpenLayers called ScaleLine
// Unfortunately, ScaleLine is tied to work with map resolution of earth
// But in our case, we want to make it work with a custom pixel size
export class ScaleOrigin {
  constructor (readonly offset: {
    top: number
    left: number
    right: number
    bottom: number
  }) { }

  public static topLeft (): ScaleOrigin {
    return new ScaleOrigin({ top: 15, left: 15, right: 0, bottom: 0 })
  }

  public static topRight (): ScaleOrigin {
    return new ScaleOrigin({ top: 15, left: 0, right: 15, bottom: 0 })
  }

  public static bottomLeft (): ScaleOrigin {
    return new ScaleOrigin({ top: 0, left: 15, right: 0, bottom: 15 })
  }

  public static bottomRight (): ScaleOrigin {
    return new ScaleOrigin({ top: 0, left: 0, right: 15, bottom: 15 })
  }

  public static fromString (str: string): ScaleOrigin {
    switch (str) {
      case 'top_left':
        return ScaleOrigin.topLeft()
      case 'top_right':
        return ScaleOrigin.topRight()
      case 'bottom_left':
        return ScaleOrigin.bottomLeft()
      case 'bottom_right':
        return ScaleOrigin.bottomRight()
      default:
        throw new Error(`Invalid ScaleOrigin string: ${str}`)
    }
  }
}

export class ScaleStyle {
  constructor (
    readonly barStyle: {
      background: string
      opacity: number
      widthPixel: number
      heightPixel: number
      thicknessPixel: number
      position: 'top' | 'bottom' | 'middle'
    },
    readonly textStyle: {
      background: string
      opacity: number
      fontSize: number
      color: string
    },
  ) {}
}

const DEFAULT_STYLE = new ScaleStyle(
  {
    background: '#666666',
    opacity: 1,
    widthPixel: 100,
    heightPixel: 20,
    thicknessPixel: 1,
    position: 'middle',
  },
  {
    background: '#ffffff',
    opacity: 0.5,
    fontSize: 10,
    color: '#000000',
  },
)

const TEXT_DISTANCE_FROM_BAR_PIXEL = 1

export class ScaleControl extends Control {
  private readonly textContainer: HTMLDivElement
  private viewState_: State | null = null
  private renderedHTML_: string | null = null
  private readonly scaleBar: HTMLDivElement

  private pixelSize: number | undefined = undefined
  private readonly scaleStyleSheet = new CSSStyleSheet()

  constructor (private origin: ScaleOrigin = ScaleOrigin.bottomLeft(), private style: ScaleStyle = DEFAULT_STYLE) {
    const element = document.createElement('div')
    element.style.pointerEvents = 'cursor'

    super({
      element,
    })

    // This class name take advantages of OpenLayers CSS defining a class element
    this.textContainer = document.createElement('div')
    this.scaleBar = document.createElement('div')

    const className = 'ol-scale-line'
    this.element.classList.add(CLASS_UNSELECTABLE, 'clemex-mosaic-scale-bar-container')
    this.textContainer.classList.add(className + '-inner', 'clemex-mosaic-scale-bar-text')
    this.scaleBar.classList.add('clemex-mosaic-scale-bar')

    this.element.appendChild(this.textContainer)
    this.element.appendChild(this.scaleBar)

    document.adoptedStyleSheets = [...document.adoptedStyleSheets, this.scaleStyleSheet]
    this.applyStyles()
  }

  public setOrigin (origin: ScaleOrigin): void {
    this.origin = origin
    this.applyStyles()
  }

  public setStyle (style: ScaleStyle): void {
    this.style = style
    this.applyStyles()
  }

  private applyStyles (): void {
    this.scaleBar.classList.remove('top', 'bottom', 'middle')
    this.scaleBar.classList.add(this.style.barStyle.position)
    this.textContainer.classList.remove('top', 'bottom', 'middle')
    this.textContainer.classList.add(this.style.barStyle.position)
    const scaleBarStyles = `
      .clemex-mosaic-scale-bar-container {
        margin: initial;
        padding: initial;
        background: transparent;
        border: none;
        position: absolute;
        top: ${this.origin.offset.top === 0 ? 'initial' : `${this.origin.offset.top}px`};
        left: ${this.origin.offset.left === 0 ? 'initial' : `${this.origin.offset.left}px`};
        right: ${this.origin.offset.right === 0 ? 'initial' : `${this.origin.offset.right}px`};
        bottom: ${this.origin.offset.bottom === 0 ? 'initial' : `${this.origin.offset.bottom}px`};
        width: ${this.style.barStyle.widthPixel}px;
        height: ${this.style.barStyle.heightPixel}px;
      }
      .clemex-mosaic-scale-bar-text {
        position: absolute;
        margin: 0px;
        padding: 2px;
        width: 60px;
        height: 20px;
        background: ${this.style.textStyle.background};
        color: ${this.style.textStyle.color};
        opacity: ${this.style.textStyle.opacity};
        font-size: ${this.style.textStyle.fontSize}px;
        border: 1px solid #000;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
      .clemex-mosaic-scale-bar-text.top {
        bottom: ${this.style.barStyle.thicknessPixel + TEXT_DISTANCE_FROM_BAR_PIXEL}px;
      }
      .clemex-mosaic-scale-bar-text.bottom {
        top: ${this.style.barStyle.thicknessPixel + TEXT_DISTANCE_FROM_BAR_PIXEL}px;
      }
      .clemex-mosaic-scale-bar {
        width: 100%;
        margin-top: ${(this.style.barStyle.heightPixel / 2) - (this.style.barStyle.thicknessPixel / 2)}px;
        background: ${this.style.barStyle.background};
        opacity: ${this.style.barStyle.opacity};
        height: ${this.style.barStyle.thicknessPixel}px;
        z-index: 1;
      }
      .clemex-mosaic-scale-bar.bottom {
        margin-top: 0;
      }
      .clemex-mosaic-scale-bar.top {
        margin-top: ${this.style.barStyle.heightPixel - this.style.barStyle.thicknessPixel}px;
      }
      .clemex-mosaic-scale-bar::before, .clemex-mosaic-scale-bar::after {
        content: "";
        position: absolute;
        width: ${this.style.barStyle.thicknessPixel}px;
        height: ${this.style.barStyle.heightPixel}px;
        background: ${this.style.barStyle.background};
        top: 0;
      }
      .clemex-mosaic-scale-bar::after {
        left: calc(100% - ${this.style.barStyle.thicknessPixel}px);
      }
    `
    this.scaleStyleSheet.replaceSync(scaleBarStyles)
  }

  render (mapEvent: MapEvent): void {
    const frameState = mapEvent.frameState
    if (frameState === null) {
      this.viewState_ = null
    } else {
      this.viewState_ = frameState.viewState
    }
    this.updateElement_()
  }

  private updateElement_ (): void {
    const viewState = this.viewState_

    if (viewState === null) {
      return
    }

    const center = viewState.center
    const projection = viewState.projection
    const pointResolutionUnits = 'm'
    const pointResolution = getPointResolution(
      projection,
      viewState.resolution,
      center,
      pointResolutionUnits,
    )

    const html = this.pixelSize === undefined
      ? `${(pointResolution * this.style.barStyle.widthPixel).toFixed(1)} px`
      : `${(pointResolution * this.style.barStyle.widthPixel * this.pixelSize).toFixed(2)} µm`

    if (this.renderedHTML_ !== html) {
      this.textContainer.innerHTML = html
      this.renderedHTML_ = html
    }
  }

  public setPixelSize (pixelSize: number | undefined): void {
    this.pixelSize = pixelSize
    this.updateElement_()
  }
}
