/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOut
 */
export interface UserOut {
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    organization?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOut
     */
    hasPassword: boolean;
}

/**
 * Check if a given object implements the UserOut interface.
 */
export function instanceOfUserOut(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "hasPassword" in value;

    return isInstance;
}

export function UserOutFromJSON(json: any): UserOut {
    return UserOutFromJSONTyped(json, false);
}

export function UserOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'email': json['email'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'hasPassword': json['has_password'],
    };
}

export function UserOutToJSON(value?: UserOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'organization': value.organization,
        'job_title': value.jobTitle,
        'phone_number': value.phoneNumber,
        'has_password': value.hasPassword,
    };
}

