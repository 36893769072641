/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Geometry } from './Geometry';
import {
    GeometryFromJSON,
    GeometryFromJSONTyped,
    GeometryToJSON,
} from './Geometry';
import type { GeometryProperties1 } from './GeometryProperties1';
import {
    GeometryProperties1FromJSON,
    GeometryProperties1FromJSONTyped,
    GeometryProperties1ToJSON,
} from './GeometryProperties1';
import type { MetadataAnnotationType } from './MetadataAnnotationType';
import {
    MetadataAnnotationTypeFromJSON,
    MetadataAnnotationTypeFromJSONTyped,
    MetadataAnnotationTypeToJSON,
} from './MetadataAnnotationType';

/**
 * This model redefine the MetadataAnnotation model in order to be a response model.
 * This allow to force OpenAPI to not generate `Optional` fields.
 * Notably for the `metadata_annotation_id` field.
 * @export
 * @interface MetadataAnnotationResponse
 */
export interface MetadataAnnotationResponse {
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotationResponse
     */
    metadataAnnotationId: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotationResponse
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAnnotationResponse
     */
    imageId: string;
    /**
     * 
     * @type {MetadataAnnotationType}
     * @memberof MetadataAnnotationResponse
     */
    type: MetadataAnnotationType;
    /**
     * 
     * @type {Geometry}
     * @memberof MetadataAnnotationResponse
     */
    geometry: Geometry;
    /**
     * 
     * @type {GeometryProperties1}
     * @memberof MetadataAnnotationResponse
     */
    geometryProperties: GeometryProperties1;
}

/**
 * Check if a given object implements the MetadataAnnotationResponse interface.
 */
export function instanceOfMetadataAnnotationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadataAnnotationId" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "imageId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "geometry" in value;
    isInstance = isInstance && "geometryProperties" in value;

    return isInstance;
}

export function MetadataAnnotationResponseFromJSON(json: any): MetadataAnnotationResponse {
    return MetadataAnnotationResponseFromJSONTyped(json, false);
}

export function MetadataAnnotationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataAnnotationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadataAnnotationId': json['metadata_annotation_id'],
        'projectId': json['project_id'],
        'imageId': json['image_id'],
        'type': MetadataAnnotationTypeFromJSON(json['type']),
        'geometry': GeometryFromJSON(json['geometry']),
        'geometryProperties': GeometryProperties1FromJSON(json['geometry_properties']),
    };
}

export function MetadataAnnotationResponseToJSON(value?: MetadataAnnotationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata_annotation_id': value.metadataAnnotationId,
        'project_id': value.projectId,
        'image_id': value.imageId,
        'type': MetadataAnnotationTypeToJSON(value.type),
        'geometry': GeometryToJSON(value.geometry),
        'geometry_properties': GeometryProperties1ToJSON(value.geometryProperties),
    };
}

