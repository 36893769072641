import type * as React from 'react'
import styles from './styles/prediction-parameters.module.scss'
import { Typography, Tooltip } from 'antd'
import { FormattedMessage } from 'react-intl'
import { SliderOpacity } from '@components/common/slider-opacity'
import clsx from 'clsx'

const { Title } = Typography

interface PredictionParametersProps {
  globalPredictionVisibility: boolean
  globalPredictionOpacity: number
  onChangeGlobalPredictionOpacity: (globalPredictionOpacity: number) => void
  onToggleGlobalPredictionVisibility: () => void
  onToggleGlobalPredictionFullOpacity: () => void
  disabled?: boolean
  className?: string
}
export const PredictionParameters: React.FC<PredictionParametersProps> = (props) => {
  const { globalPredictionOpacity, onChangeGlobalPredictionOpacity, onToggleGlobalPredictionFullOpacity, onToggleGlobalPredictionVisibility, disabled = false } = props
  const changeOpacity = (nextOpacity: number): void => {
    onChangeGlobalPredictionOpacity(nextOpacity / 100)
  }

  return <div className={clsx(styles.predictionParameters, { [styles.disabled]: disabled }, props.className ?? '')} id='prediction-opacity'>
    <Tooltip
      trigger={disabled ? ['hover'] : []}
      title={
        <FormattedMessage id={'prediction-parameters.disabled'} defaultMessage={'Please train the project to change the prediction opacity.'} />
      }
    >
      <div className={styles.header}>
        <Title level={5} className={styles.title}>
          <FormattedMessage id="prediction-parameters.opacity.slider.label" defaultMessage={'Prediction opacity'} />
        </Title>
      </div>
      <div className={styles.content}>
        <SliderOpacity
          prediction
          min={0}
          max={100}
          value={globalPredictionOpacity * 100}
          onChange={changeOpacity}
          onClickMin={onToggleGlobalPredictionVisibility}
          onClickMax={onToggleGlobalPredictionFullOpacity}
        />
      </div>
    </Tooltip>

  </div>
}
