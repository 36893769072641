import Style, { type StyleLike, type StyleFunction } from 'ol/style/Style'
import CircleStyle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import { type Feature } from 'ol'
import { type Color } from '../color'

const TOP = 'TOP'
const BOTTOM = 'BOTTOM'
const LEFT = 'LEFT'
const RIGHT = 'RIGHT'
export type TextPositionToShape = typeof TOP | typeof BOTTOM | typeof LEFT | typeof RIGHT
export const TextPositionToShapeValues = {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT,
} as const

const ABOVE_MAIN_LINE = 'ABOVE_MAIN_LINE'
const BELOW_MAIN_LINE = 'BELOW_MAIN_LINE'
export type TextPositionToLine = typeof ABOVE_MAIN_LINE | typeof BELOW_MAIN_LINE
export const TextPositionToLineValues = {
  ABOVE_MAIN_LINE,
  BELOW_MAIN_LINE,
} as const

const DEGREE = 'DEGREE'
const RADIAN = 'RADIAN'
export type AngleUnit = typeof DEGREE | typeof RADIAN
export const AngleUnitValues = {
  DEGREE,
  RADIAN,
} as const

export interface ShapeStyle {
  mainColor: string
  mainSelectedColor: string
  strokeWidth: number
  fillOpacity: number
  arrowLength: number
  grabHandleBlockWidth: number
  grabHandleBlockHeight: number
  arrowWidth: number
  selectedMeasuringPointRadius: number
  measuringLineLength: number
  measuringLineThickness: number
  measuringLineColor: Color | string
  measuringPointColor: Color | string
  measuringPointRadius: number
  measurementTextFontWeight: string | number
  measurementTextFontSizePx: number
  measurementTextFontFamily: string
  measurementTextFontLineHeight: number
  measurementTextFontFillColor: Color | string
  measurementTextFontStrokeColor: Color | string
  measurementTextFontStrokeWidth: number
  measurementTextBackgroundPadding: number
  measurementTextBackgroundFillColor: Color | string
  measurementTextBackgroundStrokeColor: Color | string
  measurementTextBackgroundStrokeWidth: number
  measurementTextBackgroundDistanceShape: number
  textPositionToShape: TextPositionToShape
  textPositionToLine: TextPositionToLine
  textRotateWithShape: boolean
  angleUnit: AngleUnit
  zIndexOffSet: number
  zIndexSelectionOffset: number
  hitDetectionToleranceRadius: number
}

export const DEFAULT_SHAPE_STYLE: ShapeStyle = {
  mainColor: '#3333FF',
  mainSelectedColor: '#FFFF33',
  strokeWidth: 2,
  fillOpacity: 0.5,
  arrowLength: 20,
  arrowWidth: 8,
  grabHandleBlockWidth: 6,
  grabHandleBlockHeight: 1.5,
  measuringLineLength: 96,
  measuringLineThickness: 1,
  measuringLineColor: 'red',
  measuringPointRadius: 0.5,
  measuringPointColor: 'lime',
  selectedMeasuringPointRadius: 2,
  measurementTextFontWeight: 'bold',
  measurementTextFontSizePx: 18,
  measurementTextFontLineHeight: 4,
  measurementTextFontFamily: 'sans-serif',
  measurementTextFontFillColor: 'black',
  measurementTextFontStrokeColor: 'white',
  measurementTextFontStrokeWidth: 2,
  measurementTextBackgroundPadding: 4,
  measurementTextBackgroundFillColor: 'rgba(255, 255, 255, 0.5)',
  measurementTextBackgroundStrokeColor: 'rgba(0, 0, 0, 0.5)',
  measurementTextBackgroundStrokeWidth: 2,
  measurementTextBackgroundDistanceShape: 16,
  textPositionToShape: TextPositionToShapeValues.BOTTOM,
  textPositionToLine: TextPositionToLineValues.ABOVE_MAIN_LINE,
  textRotateWithShape: true,
  angleUnit: AngleUnitValues.DEGREE,
  zIndexOffSet: 300,
  zIndexSelectionOffset: 16,
  hitDetectionToleranceRadius: 16,
}

export interface ModifyInteractionStyle {
  hitDetectionToleranceRadius: number
}
export const buildModifyInteractionStyle = (featureTypes: FeatureType[]) => {
  return (style: ModifyInteractionStyle): StyleFunction => {
    return (_feature) => {
      const targetFeatures = _feature.get('features')[0] as Feature | undefined
      if (targetFeatures === undefined) {
        return undefined
      }
      if (!featureTypes.includes(targetFeatures.get(FEATURE_TYPE) as FeatureType)) {
        return undefined
      }
      return new Style({
        image: new CircleStyle({
          radius: style.hitDetectionToleranceRadius / 2,
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.5)',
          }),
          stroke: new Stroke({
            color: 'rgba(255, 255, 255, 0.8)',
            width: 2,
          }),
        }),
        zIndex: Infinity,
      })
    }
  }
}

export const FEATURE_TYPE = 'FeatureType'
export const FeatureType = {
  CLASS_ANNOTATION: 'CLASS_ANNOTATION',
  DETECTED_OBJECT: 'DETECTED_OBJECT',
  DIRECT_MEASURE_DISTANCE: 'DIRECT_MEASURE_DISTANCE',
  METADATA_ANNOTATION_ARROW: 'METADATA_ANNOTATION_ARROW',
  DIRECT_MEASURE_ANGLE: 'DIRECT_MEASURE_ANGLE',
  METADATA_ANNOTATION_ANGLE: 'METADATA_ANNOTATION_ANGLE',
  DIRECT_MEASURE_AREA: 'DIRECT_MEASURE_AREA',
  METADATA_ANNOTATION_POLYGON: 'METADATA_ANNOTATION_POLYGON',
  DIRECT_MEASURE_PERIMETER: 'DIRECT_MEASURE_PERIMETER',
  METADATA_ANNOTATION_LINE: 'METADATA_ANNOTATION_LINE',
  DIRECT_MEASURE_RECTANGLE: 'DIRECT_MEASURE_RECTANGLE',
  METADATA_ANNOTATION_RECTANGLE: 'METADATA_ANNOTATION_RECTANGLE',
  DIRECT_MEASURE_ARC: 'DIRECT_MEASURE_ARC',
  DIRECT_MEASURE_ELLIPSE: 'DIRECT_MEASURE_ELLIPSE',
  METADATA_ANNOTATION_ELLIPSE: 'METADATA_ANNOTATION_ELLIPSE',
  DIRECT_MEASURE_CIRCLE: 'DIRECT_MEASURE_CIRCLE',
  METADATA_ANNOTATION_CIRCLE: 'METADATA_ANNOTATION_CIRCLE',
  METADATA_ANNOTATION_TEXT: 'METADATA_ANNOTATION_TEXT',
  SMART_ANNOTATION: 'SMART_ANNOTATION',
  THICKNESS_MEASURE_LINE: 'THICKNESS_MEASURE_LINE',
  THICKNESS_MEASURE_LABEL: 'THICKNESS_MEASURE_LABEL',
} as const
export type FeatureType = typeof FeatureType[keyof typeof FeatureType]
export type AngleFeatureType = typeof FeatureType.DIRECT_MEASURE_ANGLE | typeof FeatureType.METADATA_ANNOTATION_ANGLE
export type ArcFeatureType = typeof FeatureType.DIRECT_MEASURE_ARC
export type PolygonFeatureType = typeof FeatureType.DIRECT_MEASURE_AREA | typeof FeatureType.METADATA_ANNOTATION_POLYGON
export type RectangleFeatureType = typeof FeatureType.DIRECT_MEASURE_RECTANGLE | typeof FeatureType.METADATA_ANNOTATION_RECTANGLE
export type StraightLineFeatureType = typeof FeatureType.DIRECT_MEASURE_DISTANCE | typeof FeatureType.METADATA_ANNOTATION_ARROW
export type LineFeatureType = typeof FeatureType.DIRECT_MEASURE_PERIMETER | typeof FeatureType.METADATA_ANNOTATION_LINE
export type EllipseFeatureType = typeof FeatureType.DIRECT_MEASURE_ELLIPSE | typeof FeatureType.METADATA_ANNOTATION_ELLIPSE
export type CircleFeatureType = typeof FeatureType.DIRECT_MEASURE_CIRCLE | typeof FeatureType.METADATA_ANNOTATION_CIRCLE
export type TextFeatureType = typeof FeatureType.METADATA_ANNOTATION_TEXT

export interface TransformInteractionStyle {
  'default'?: StyleLike | StyleLike[]
  'translate'?: StyleLike | StyleLike[]
  'rotate'?: StyleLike | StyleLike[]
  'rotate0'?: StyleLike | StyleLike[]
  'scale'?: StyleLike | StyleLike[]
  'scale1'?: StyleLike | StyleLike[]
  'scale2'?: StyleLike | StyleLike[]
  'scale3'?: StyleLike | StyleLike[]
  'scalev'?: StyleLike | StyleLike[]
  'scaleh1'?: StyleLike | StyleLike[]
  'scalev2'?: StyleLike | StyleLike[]
  'scaleh3'?: StyleLike | StyleLike[]
}

export interface UndefinedGeometryProperties {
  geometryType: 'UNDEFINED'
}

export const UNDEFINED_GEOMETRY_PROPERTIES: UndefinedGeometryProperties = {
  geometryType: 'UNDEFINED',
}

export const DIRECT_MEASURE_FEATURE_TYPES: FeatureType[] = [
  FeatureType.DIRECT_MEASURE_DISTANCE,
  FeatureType.DIRECT_MEASURE_ANGLE,
  FeatureType.DIRECT_MEASURE_ELLIPSE,
  FeatureType.DIRECT_MEASURE_AREA,
  FeatureType.DIRECT_MEASURE_ARC,
  FeatureType.DIRECT_MEASURE_PERIMETER,
  FeatureType.DIRECT_MEASURE_RECTANGLE,
  FeatureType.DIRECT_MEASURE_CIRCLE,
]

export const METADATA_ANNOTATION_FEATURE_TYPES: FeatureType[] = [
  FeatureType.METADATA_ANNOTATION_ARROW,
  FeatureType.METADATA_ANNOTATION_LINE,
  FeatureType.METADATA_ANNOTATION_POLYGON,
  FeatureType.METADATA_ANNOTATION_RECTANGLE,
  FeatureType.METADATA_ANNOTATION_ELLIPSE,
  FeatureType.METADATA_ANNOTATION_TEXT,
]
