/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageResponse } from './ImageResponse';
import {
    ImageResponseFromJSON,
    ImageResponseFromJSONTyped,
    ImageResponseToJSON,
} from './ImageResponse';

/**
 * 
 * @export
 * @interface ImageGroupResponse
 */
export interface ImageGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageGroupResponse
     */
    groupId: string;
    /**
     * 
     * @type {Array<ImageResponse>}
     * @memberof ImageGroupResponse
     */
    images: Array<ImageResponse>;
}

/**
 * Check if a given object implements the ImageGroupResponse interface.
 */
export function instanceOfImageGroupResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "images" in value;

    return isInstance;
}

export function ImageGroupResponseFromJSON(json: any): ImageGroupResponse {
    return ImageGroupResponseFromJSONTyped(json, false);
}

export function ImageGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageGroupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupId': json['group_id'],
        'images': ((json['images'] as Array<any>).map(ImageResponseFromJSON)),
    };
}

export function ImageGroupResponseToJSON(value?: ImageGroupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_id': value.groupId,
        'images': ((value.images as Array<any>).map(ImageResponseToJSON)),
    };
}

