/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DeepLearningAlgorithmTrainingProgression } from './DeepLearningAlgorithmTrainingProgression';
import {
    instanceOfDeepLearningAlgorithmTrainingProgression,
    DeepLearningAlgorithmTrainingProgressionFromJSON,
    DeepLearningAlgorithmTrainingProgressionFromJSONTyped,
    DeepLearningAlgorithmTrainingProgressionToJSON,
} from './DeepLearningAlgorithmTrainingProgression';
import type { RFAlgorithmTrainingProgression } from './RFAlgorithmTrainingProgression';
import {
    instanceOfRFAlgorithmTrainingProgression,
    RFAlgorithmTrainingProgressionFromJSON,
    RFAlgorithmTrainingProgressionFromJSONTyped,
    RFAlgorithmTrainingProgressionToJSON,
} from './RFAlgorithmTrainingProgression';

/**
 * @type TrainingProgressionProgression
 * 
 * @export
 */
export type TrainingProgressionProgression = { algorithmId: 'CLEMEX_GRAINSEG_V3' } & DeepLearningAlgorithmTrainingProgression | { algorithmId: 'CLEMEX_GRAINSEG_V3_ALUMINUM' } & DeepLearningAlgorithmTrainingProgression | { algorithmId: 'CLEMEX_MEDCLEAN_V1' } & DeepLearningAlgorithmTrainingProgression | { algorithmId: 'CLEMEX_NET_LITE_V1' } & DeepLearningAlgorithmTrainingProgression | { algorithmId: 'CLEMEX_NET_LITE_V2' } & DeepLearningAlgorithmTrainingProgression | { algorithmId: 'RF_ALGORITHM_CUML' } & RFAlgorithmTrainingProgression | { algorithmId: 'RF_ALGORITHM_SKLEARN' } & RFAlgorithmTrainingProgression;

export function TrainingProgressionProgressionFromJSON(json: any): TrainingProgressionProgression {
    return TrainingProgressionProgressionFromJSONTyped(json, false);
}

export function TrainingProgressionProgressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingProgressionProgression {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['algorithm_id']) {
        case 'CLEMEX_GRAINSEG_V3':
            return {...DeepLearningAlgorithmTrainingProgressionFromJSONTyped(json, true), algorithmId: 'CLEMEX_GRAINSEG_V3'};
        case 'CLEMEX_GRAINSEG_V3_ALUMINUM':
            return {...DeepLearningAlgorithmTrainingProgressionFromJSONTyped(json, true), algorithmId: 'CLEMEX_GRAINSEG_V3_ALUMINUM'};
        case 'CLEMEX_MEDCLEAN_V1':
            return {...DeepLearningAlgorithmTrainingProgressionFromJSONTyped(json, true), algorithmId: 'CLEMEX_MEDCLEAN_V1'};
        case 'CLEMEX_NET_LITE_V1':
            return {...DeepLearningAlgorithmTrainingProgressionFromJSONTyped(json, true), algorithmId: 'CLEMEX_NET_LITE_V1'};
        case 'CLEMEX_NET_LITE_V2':
            return {...DeepLearningAlgorithmTrainingProgressionFromJSONTyped(json, true), algorithmId: 'CLEMEX_NET_LITE_V2'};
        case 'RF_ALGORITHM_CUML':
            return {...RFAlgorithmTrainingProgressionFromJSONTyped(json, true), algorithmId: 'RF_ALGORITHM_CUML'};
        case 'RF_ALGORITHM_SKLEARN':
            return {...RFAlgorithmTrainingProgressionFromJSONTyped(json, true), algorithmId: 'RF_ALGORITHM_SKLEARN'};
        default:
            throw new Error(`No variant of TrainingProgressionProgression exists with 'algorithmId=${json['algorithmId']}'`);
    }
}

export function TrainingProgressionProgressionToJSON(value?: TrainingProgressionProgression | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['algorithmId']) {
        case 'CLEMEX_GRAINSEG_V3':
            return DeepLearningAlgorithmTrainingProgressionToJSON(value);
        case 'CLEMEX_GRAINSEG_V3_ALUMINUM':
            return DeepLearningAlgorithmTrainingProgressionToJSON(value);
        case 'CLEMEX_MEDCLEAN_V1':
            return DeepLearningAlgorithmTrainingProgressionToJSON(value);
        case 'CLEMEX_NET_LITE_V1':
            return DeepLearningAlgorithmTrainingProgressionToJSON(value);
        case 'CLEMEX_NET_LITE_V2':
            return DeepLearningAlgorithmTrainingProgressionToJSON(value);
        case 'RF_ALGORITHM_CUML':
            return RFAlgorithmTrainingProgressionToJSON(value);
        case 'RF_ALGORITHM_SKLEARN':
            return RFAlgorithmTrainingProgressionToJSON(value);
        default:
            throw new Error(`No variant of TrainingProgressionProgression exists with 'algorithmId=${value['algorithmId']}'`);
    }

}

