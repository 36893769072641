import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/welcome-title.module.scss'

const { Title } = Typography

interface WelcomeTitleProps {
  title?: React.ReactNode
}
export const WelcomeTitle: React.FC<WelcomeTitleProps> = ({ title }) => {
  return <Title className={styles.title}>
    {
      title === undefined
        ? <FormattedMessage id="authentication.title" defaultMessage={'Welcome to Clemex Studio'} />
        : title
    }
  </Title>
}
