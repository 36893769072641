import type React from 'react'
import { type FC } from 'react'
import DatabrowserIcon from '@material-design-icons/svg/outlined/table_chart.svg'
import LeftPanelSwitchIcon from '@material-design-icons/svg/outlined/panorama.svg'
import { Button, Tooltip } from 'antd'
import { TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'
import { FormattedMessage } from 'react-intl'
import { useDataBrowserStore } from '@app/stores/databrowser'
import clsx from 'clsx'
import { useEditorStore } from '@app/stores/editor'

interface PanelItemProps {
  icon: React.ReactNode
  tooltip?: React.ReactNode
  onClick?: () => void
  isActive: boolean
}
const PanelItem: FC<PanelItemProps> = ({ icon, tooltip, onClick, isActive = false }) => {
  return <Tooltip
    title={tooltip}
    placement='right'
    mouseLeaveDelay={0}
    mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
  >
    <Button
      className={clsx(
        'my-0 mx-[8px] p-0 border-[2px] border-clemex-offGray !hover:bg-clemex-offGray !hover:border-clemex-shellGray',
        {
          'bg-clemex-offGray': isActive,
          'bg-clemex-offWhite': !isActive,
        },
      )}
      onClick={onClick}
    >
      <div className='p-[1px] !ml-[2px] !mt-[2px] fill-clemex-darkGray w-[28px] h-[28px] rounded-[8px]'>
        {icon}
      </div>
    </Button>
  </Tooltip>
}

interface PanelBarProps {
  onClickLeftPanel: () => void
  isLeftPanelActive: boolean
}

export const PanelBar: FC<PanelBarProps> = ({ onClickLeftPanel, isLeftPanelActive }) => {
  const { toggleOpenClose: dataBrowserToggleOpenClose, isOpen: dataBrowserIsOpen } = useDataBrowserStore((state) => ({
    toggleOpenClose: state.toggleOpenClose,
    isOpen: state.isOpen,
  }))
  const setClassAnnotationSelectedIds = useEditorStore((state) => state.setClassAnnotationSelectedIds)

  const onClickDataBrowser = (): void => {
    if (dataBrowserIsOpen) {
      // if the databrowser was open, then unselect the selected object
      setClassAnnotationSelectedIds({})
    }
    dataBrowserToggleOpenClose()
  }

  return <div className="w-[52px] grow-0 flex flex-col justify-start item gap-[8px] pt-[8px] border-r-[2px] border-clemex-shellGray">
    <PanelItem
      icon={<LeftPanelSwitchIcon />}
      tooltip={<FormattedMessage id='panel-bar.panel-item.left-panel.tooltip' defaultMessage={'Left Panel'} />}
      onClick={onClickLeftPanel}
      isActive={isLeftPanelActive}
    />
    <PanelItem
      icon={<DatabrowserIcon />}
      tooltip={<FormattedMessage id='panel-bar.panel-item.databrowser.tooltip' defaultMessage={'Data Browser'} />}
      onClick={onClickDataBrowser}
      isActive={dataBrowserIsOpen}
    />
  </div>
}
