import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Modal, Typography, notification } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { InboxOutlined } from '@ant-design/icons'
import { type UploadChangeParam, type UploadFile } from 'antd/lib/upload/interface'
import { type ImageResponse } from '@api/openapi'
import { SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE, MESSAGE_UPLOAD_IMAGE_DIMENSION, MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT, MINIMAL_IMAGE_DIMENSION_PIXEL } from '@app/constants'

const { Title } = Typography

interface UploadImagesModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (newImageIds: string[]) => Promise<void>
  isConfirmingUpload: boolean
  uploadUrl: string
  uploadMethod?: 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch'
}
export const UploadImagesModal: React.FC<UploadImagesModalProps> = (props) => {
  const { isOpen, onClose, onConfirm, isConfirmingUpload, uploadUrl, uploadMethod = 'POST' } = props
  const [notificationApi, notificationContext] = notification.useNotification()

  const [uploadedImages, setUploadedImages] = React.useState<UploadFile<ImageResponse>[]>([])
  const [isUploadingImage, setIsUploadingImage] = React.useState<boolean>(false)
  const [draggerKey, setDraggerKey] = React.useState<number>(0)
  const onConfirmUploadImage = async (): Promise<void> => {
    if (isConfirmingUpload) {
      return
    }
    const imageIds = uploadedImages
      .map((uploadedImage) => uploadedImage.response)
      .filter((response): response is ImageResponse => response !== undefined)
      .map((response: ImageResponse) => response.id)
    await onConfirm(imageIds)
    setDraggerKey(draggerKey + 1)
    setUploadedImages([])
  }
  const onUploadStateChange = (info: UploadChangeParam): void => {
    const { status } = info.file
    if (info.fileList.every(file => file.status === 'done' || file.status === 'error')) {
      setIsUploadingImage(false)
    } else {
      setIsUploadingImage(true)
    }
    if (status === 'done') {
      setUploadedImages(uploadedImages => ([...uploadedImages, info.file]))
    } else if (status === 'error') {
      notificationApi.error({
        message: <FormattedMessage
          id='projects.uploadImage.form.image.upload.error'
          defaultMessage='Image {fileName} upload failed. The image format or dimensions could be incorrect. Please validate the image is {minDimension}x{minDimension} and in the following formats: {imageFormat}'
          values={{
            fileName: info.file.name,
            minDimension: MINIMAL_IMAGE_DIMENSION_PIXEL,
            imageFormat: SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE,
          }}/>,
      })
    }
  }

  const title = <Title level={3}>
    <FormattedMessage id="projects.modal.uploadImages.title" defaultMessage={'Upload images'} />
  </Title>

  const onRemoveUploadedImage = (file: UploadFile): void => {
    setUploadedImages(uploadedImages => (uploadedImages.filter((uploadedImage) => uploadedImage.uid !== file.uid)))
  }

  return <Modal
    width={640}
    title={title}
    open={isOpen}
    onCancel={onClose}
    footer={[
      <Button key={'cancel'} onClick={onClose}>
        <FormattedMessage id="projects.modal.uploadImages.actions.cancel" defaultMessage={'Cancel'} />
      </Button>,
      <Button key={'confirm'} type="primary" disabled={isUploadingImage || uploadedImages.length === 0} loading={isConfirmingUpload} onClick={onConfirmUploadImage}>
        &nbsp;<FormattedMessage id="projects.modal.uploadImages.actions.confirm" defaultMessage={'Add images'} />
      </Button>,
    ]}
  >
    { notificationContext }
    <Dragger
      key={draggerKey}
      height={300}
      action={uploadUrl}
      method={uploadMethod}
      name="image"
      multiple={true}
      accept={SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE}
      onChange={onUploadStateChange}
      listType="picture"
      onRemove={onRemoveUploadedImage}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        <FormattedMessage id="projects.modal.uploadImages.form.image.upload.area.text" defaultMessage={'Click or drag file to this area to upload'} />
      </p>
      <p className="ant-upload-hint">
        { MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT }
        <br/>
        { MESSAGE_UPLOAD_IMAGE_DIMENSION }
      </p>
    </Dragger>
  </Modal>
}
