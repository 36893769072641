import * as React from 'react'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/editor-tools.module.scss'
import { IconMenu } from '@components/common/icon-menu'
import { EVENTS_ID, Tool } from '@app/constants'
import AngleMeasureIcon from '@material-design-icons/svg/filled/architecture.svg'
import ArcMeasureIcon from '@material-design-icons/svg/outlined/incomplete_circle.svg'
import AreaMeasureIcon from '@material-design-icons/svg/outlined/hexagon.svg'
import DistanceMeasureIcon from '@material-design-icons/svg/filled/straighten.svg'
import PerimeterMeasureIcon from '@material-design-icons/svg/outlined/show_chart.svg'
import RectangleIcon from '@material-design-icons/svg/outlined/rectangle.svg'
import EllipseIcon from '@material-design-icons/svg/outlined/circle.svg'
import DeleteSelectionIcon from '@material-design-icons/svg/outlined/delete.svg'
import ShowIcon from '@material-design-icons/svg/outlined/visibility.svg'
import HideIcon from '@material-design-icons/svg/outlined/visibility_off.svg'
import { useEditorStore } from '@app/stores/editor'

const { Title } = Typography

export const DirectMeasureTools: React.FC = () => {
  const selectedTool = useEditorStore((store) => store.selectedTool)
  const directMeasureAndMetadataAnnotationVisibility = useEditorStore((store) => store.directMeasureAndMetadataAnnotationVisibility)
  const disabledTools = useEditorStore((store) => store.disabledTools)

  const lengthDirectMeasureToolItem = React.useMemo(() => {
    const directMeasureDistanceTool = {
      icon: <DistanceMeasureIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.DIRECT_MEASURE_DISTANCE,
      disabled: disabledTools.includes(Tool.DIRECT_MEASURE_DISTANCE),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_DISTANCE_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.direct-measure.distance" defaultMessage={'Distance (m)'} />,
    }
    const directMeasureAngleTool = {
      icon: <AngleMeasureIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.DIRECT_MEASURE_ANGLE,
      disabled: disabledTools.includes(Tool.DIRECT_MEASURE_ANGLE),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ANGLE_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.direct-measure.angle" defaultMessage={'Angle (v)'} />,
    }

    const directMeasurePerimeterTool = {
      icon: <PerimeterMeasureIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.DIRECT_MEASURE_PERIMETER,
      disabled: disabledTools.includes(Tool.DIRECT_MEASURE_PERIMETER),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_PERIMETER_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.direct-measure.perimeter" defaultMessage={'Perimeter'} />,
    }
    const directMeasureArcTool = {
      icon: <ArcMeasureIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.DIRECT_MEASURE_ARC,
      disabled: disabledTools.includes(Tool.DIRECT_MEASURE_ARC),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ARC_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.direct-measure.arc" defaultMessage={'Arc'} />,
    }

    return [directMeasureDistanceTool, directMeasureAngleTool, directMeasurePerimeterTool, directMeasureArcTool]
  }, [selectedTool, disabledTools])

  const editDirectMeasureToolItem = React.useMemo(() => {
    const deleteSelection = {
      icon: <DeleteSelectionIcon className={styles.tooltipIcon} />,
      selected: false,
      disabled: false,
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_SELECTION_DELETE))
      },
      helpTitle: <FormattedMessage id="tools.selection.delete" defaultMessage={'Delete selection'} />,
    }

    const showDirectMeasureAndMetadataAnnotation = {
      icon: <HideIcon className={styles.tooltipIcon} />,
      selected: false,
      disabled: false,
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_SHOW_DIRECT_MEASURE_AND_METADATA_ANNOTATION))
      },
      helpTitle: <FormattedMessage id="tools.annotation.show" defaultMessage={'Show direct measure and metadata annotation'} />,
    }
    const hideDirectMeasureAndMetadataAnnotation = {
      icon: <ShowIcon className={styles.tooltipIcon} />,
      selected: false,
      disabled: false,
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_HIDE_DIRECT_MEASURE_AND_METADATA_ANNOTATION))
      },
      helpTitle: <FormattedMessage id="tools.annotation.hide" defaultMessage={'Hide direct measure and metadata annotation'} />,
    }
    return [
      deleteSelection,
      directMeasureAndMetadataAnnotationVisibility
        ? hideDirectMeasureAndMetadataAnnotation
        : showDirectMeasureAndMetadataAnnotation,
    ]
  }, [directMeasureAndMetadataAnnotationVisibility])

  const areaDirectMeasureToolItem = React.useMemo(() => {
    const directMeasureAreaTool = {
      icon: <AreaMeasureIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.DIRECT_MEASURE_AREA,
      disabled: disabledTools.includes(Tool.DIRECT_MEASURE_AREA),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_AREA_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.direct-measure.area" defaultMessage={'Area'} />,
    }
    const directMeasureRectangleTool = {
      icon: <RectangleIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.DIRECT_MEASURE_RECTANGLE,
      disabled: disabledTools.includes(Tool.DIRECT_MEASURE_RECTANGLE),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_RECTANGLE_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.direct-measure.rectangle" defaultMessage={'Rectangle'} />,
    }
    const directMeasureEllipseTool = {
      icon: <EllipseIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.DIRECT_MEASURE_ELLIPSE,
      disabled: disabledTools.includes(Tool.DIRECT_MEASURE_ELLIPSE),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_DIRECT_MEASURE_ELLIPSE_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.direct-measure.ellipse" defaultMessage={'Ellipse.{newline} Hold Shift while drawing for a circle.'} values={{ newline: <br /> }} />,
    }

    return [directMeasureAreaTool, directMeasureRectangleTool, directMeasureEllipseTool]
  }, [selectedTool, disabledTools])

  return <div id='tools-direct-measure' className={styles.editorTools}>
    <div>
      <Title level={5} className={styles.titleSection}>
        <div>
          <FormattedMessage id="tools.direct-measure.title" defaultMessage={'Direct Measure'} />
        </div>
        <div>
          <FormattedMessage id="tools.direct-measure.edit.title" defaultMessage={'Control'} />
        </div>
      </Title>
    </div>
    <div className={styles.container}>
      <div className={styles.subSection}>
        <div>
          <IconMenu
            items={lengthDirectMeasureToolItem}
          />
        </div>
        <div>
          <IconMenu
            items={areaDirectMeasureToolItem}
            firstItem={false}
          />
        </div>
      </div>
      <div className={styles.subSection}>
        <div>
          <IconMenu
            items={editDirectMeasureToolItem}
          />
        </div>
      </div>
    </div>
  </div>
}

export default DirectMeasureTools
