import { Map, Collection, View, Feature, Kinetic, type MapBrowserEvent } from 'ol'
import { type MapOptions } from 'ol/Map'
import { LineString, type Point, type Polygon, type SimpleGeometry } from 'ol/geom'
import { Group as GroupLayer } from 'ol/layer'
import LayerGroup from 'ol/layer/Group'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Projection } from 'ol/proj'
import { type Color, DEFAULT_CLEMEX_VISION_MASK_COLOR, type MaskColors } from './color'
import { type ClemexMosaicImage, type ClemexMosaicImageSource, type ClemexMosaicMask, type ClemexMosaicMaskSource, type ClemexMosaicSource, makeLayerFromImageSource } from './clemex-mosaic-source'
import { type Constraints, createCenterConstraint } from 'ol/View'
import { DragPan, DragZoom, MouseWheelZoom } from 'ol/interaction'
import { CursorType, getCircleCursor } from './cursor'
import { BrushInterraction } from './interactions/brush'
import { EraserInterraction } from './interactions/eraser'
import { DrawStraightLineInteraction, ModifyStraightLineInteraction, TranslateStraightLineInteraction } from './interactions/straight-line'
import { ScaleControl, type ScaleOrigin, type ScaleStyle } from './controls/scale'
import { defaults as DefaultInteractions } from 'ol/interaction/defaults'
import 'ol/ol.css'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import { ClassAnnotations, type DefaultClassAnnotationProperties, type ClassAnnotationProperties } from './class-annotations'
import { DrawAngleInteraction, ModifyAngleInteraction } from './interactions/angle'
import { DrawPolygonInteraction, ModifyPolygonInteraction } from './interactions/polygon'
import { ModifyLineInteraction, DrawLineInteraction } from './interactions/line'
import { DrawArcInteraction, ModifyArcInteraction, TranslateArcInteraction } from './interactions/arc'
import { DEFAULT_CLASS_ANNOTATION_STYLE } from './shapes/class-annotation'
import { type PartialMappedStyle, buildMappedStyle } from './shapes/mapped-styles'
import { DIRECT_MEASURE_FEATURE_TYPES, FEATURE_TYPE, FeatureType, METADATA_ANNOTATION_FEATURE_TYPES, type ShapeStyle } from './shapes/common'
import { Select } from './interactions/select'
import { type ChangePatch } from './interactions/common'
import { DrawRectangleInteraction, ModifyRectangleInteraction } from './interactions/rectangle'
import { type BoundingBoxStyle } from './shapes/rectangle'
import { DrawEllipseInteraction, ModifyEllipseInteraction } from './interactions/ellipse'
import { ModifyCircleDirectMeasureInteraction } from './interactions/circle'
import { type ArrowGeometryProperties } from './shapes/arrow'
import { DrawTextInteraction, EditTextInteraction, TranslateTextInteraction } from './interactions/text'
import { SmartAnnotationInteraction } from './interactions/smart-annotation'
import WebGLTileLayer, { type Style as ImageWegGLFilter } from 'ol/layer/WebGLTile'
import { SelectClassAnnotationInteraction } from './interactions/select-class-annotation'
import { type DetectedObjectProperties } from './shapes/detected-object'
import { SelectDetectedObjectInteraction } from './interactions/select-detected-object'
import { SelectedDetectedObjectWebGLLayer } from './layers/selected-detected-object-webgl-layer'

const DEFAULT_ZOOM_FACTOR = 1.2
const DEFAULT_ZOOM_DURATION = 0
const DEFAULT_ZOOM_TIMEOUT = 0
const DEFAULT_COLOR_INDEX = 0
const DEFAULT_BRUSH_SIZE = 10
const DEFAULT_ERASE_OTHER_CLASSES_ON_OVERLAP = false
export const DEFAULT_MAX_PIXELGRID_RESOLUTION = 1 / 8
// DEFAULT_MAX_RESOLUTION is the maximum resolution that the user can zoom in to
export const DEFAULT_MAX_RESOLUTION = 1 / 64

// Public Modes
export enum ClemexMosaicCanvasMode {
  PAN = 'PAN',
  SELECT = 'SELECT',
  BRUSH = 'BRUSH',
  ERASER = 'ERASER',
  DIRECT_MEASURE_DISTANCE = 'DIRECT_MEASURE_DISTANCE',
  DIRECT_MEASURE_ANGLE = 'DIRECT_MEASURE_ANGLE',
  DIRECT_MEASURE_ELLIPSE = 'DIRECT_MEASURE_ELLIPSE',
  DIRECT_MEASURE_AREA = 'DIRECT_MEASURE_AREA',
  DIRECT_MEASURE_ARC = 'DIRECT_MEASURE_ARC',
  DIRECT_MEASURE_PERIMETER = 'DIRECT_MEASURE_PERIMETER',
  DIRECT_MEASURE_RECTANGLE = 'DIRECT_MEASURE_RECTANGLE',
  METADATA_ANNOTATION_ARROW = 'METADATA_ANNOTATION_ARROW',
  METADATA_ANNOTATION_ELLIPSE = 'METADATA_ANNOTATION_ELLIPSE',
  METADATA_ANNOTATION_POLYGON = 'METADATA_ANNOTATION_POLYGON',
  METADATA_ANNOTATION_TEXT = 'METADATA_ANNOTATION_TEXT',
  METADATA_ANNOTATION_LINE = 'METADATA_ANNOTATION_LINE',
  METADATA_ANNOTATION_RECTANGLE = 'METADATA_ANNOTATION_RECTANGLE',
  SMART_ANNOTATION = 'SMART_ANNOTATION',
}
const DEFAULT_CLEMEX_MOSAIC_CANVAS_MODE = ClemexMosaicCanvasMode.DIRECT_MEASURE_DISTANCE

export interface SelectionModes {
  directMeasurementAnnotations: boolean
  metadataAnnotations: boolean
}
const DEFAULT_SELECTION_MODES: SelectionModes = {
  metadataAnnotations: false,
  directMeasurementAnnotations: true,
}

const MODE_NOT_ALLOWING_SELECT_OR_MODIFY: ClemexMosaicCanvasMode[] = [
  ClemexMosaicCanvasMode.PAN,
  ClemexMosaicCanvasMode.SMART_ANNOTATION,
]

// Private temp Modes for internal usage
enum ClemexMosaicCanvasTempMode {
  SPACE_TO_DRAG_PAN = 'SPACE_TO_DRAG_PAN',
  MIDDLE_CLICK_TO_DRAG_PAN = 'MIDDLE_CLICK_TO_DRAG_PAN',
  SHIFT_TO_BRUSH_ERASE = 'SHIFT_TO_BRUSH_ERASE',
}

export enum ClemexMosaicCanvasListenersType {
  START_ANNOTATING = 'START_ANNOTATING',
  START_ERASING = 'START_ERASING',
  SELECTION_CHANGED = 'SELECTION_CHANGED',
  SELECTION_CLASS_ANNOTATION_CHANGED = 'SELECTION_CLASS_ANNOTATION_CHANGED',
  SELECTION_DETECTED_OBJECT_CHANGED = 'SELECTION_DETECTED_OBJECT_CHANGED',
  CLASS_ANNOTATION_CHANGED = 'CLASS_ANNOTATION_CHANGED',
  DIRECT_MEASURE_CHANGED = 'DIRECT_MEASURE_CHANGED',
  METADATA_ANNOTATION_CHANGED = 'ANNOTATION_CHANGED',
  ZOOM_CHANGED = 'ZOOM_CHANGED',
  START_SMART_ANNOTATION = 'START_SMART_ANNOTATION',
  STOP_SMART_ANNOTATION = 'STOP_SMART_ANNOTATION',
}

export interface ClemexMosaicCanvasListeners {
  [ClemexMosaicCanvasListenersType.START_ANNOTATING]: () => void
  [ClemexMosaicCanvasListenersType.START_ERASING]: () => void
  [ClemexMosaicCanvasListenersType.START_SMART_ANNOTATION]: (smartAnnotationPosition: Feature<Point>) => void
  [ClemexMosaicCanvasListenersType.STOP_SMART_ANNOTATION]: () => void
  [ClemexMosaicCanvasListenersType.SELECTION_CHANGED]: () => void
  [ClemexMosaicCanvasListenersType.SELECTION_CLASS_ANNOTATION_CHANGED]: (selectedClassAnnotationId: string | undefined) => void
  [ClemexMosaicCanvasListenersType.SELECTION_DETECTED_OBJECT_CHANGED]: (selectedDetectedObjectId: string | undefined) => void
  [ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED]: (annotationClassChangePath: ChangePatch<Feature<Polygon>>) => void
  [ClemexMosaicCanvasListenersType.METADATA_ANNOTATION_CHANGED]: (metadataAnnotationChangePath: ChangePatch<Feature>) => void
  [ClemexMosaicCanvasListenersType.DIRECT_MEASURE_CHANGED]: (directMeasureChangePath: ChangePatch<Feature>) => void
  [ClemexMosaicCanvasListenersType.ZOOM_CHANGED]: (values: { zoom: number | undefined, resolution: number | undefined }) => void
}

export type ClemexMosaicCanvasListenersMap = {
  [K in ClemexMosaicCanvasListenersType]?: ClemexMosaicCanvasListeners[K][]
}

export class ClemexMosaicCanvas<AP extends DefaultClassAnnotationProperties = DefaultClassAnnotationProperties> extends Map {
  private readonly _projection: Projection
  // Interactions
  private readonly _eraserInteraction: EraserInterraction
  private readonly _brushInteraction: BrushInterraction<AP>
  private readonly _selectInteraction: Select
  private readonly _selectClassAnnotationInteraction: SelectClassAnnotationInteraction
  private readonly _selectDetectedObjectInteraction: SelectDetectedObjectInteraction
  private readonly _smartAnnotationInteraction: SmartAnnotationInteraction

  private readonly _drawDirectMeasureDistanceInteraction: DrawStraightLineInteraction
  private readonly _drawDirectMeasureAngleInteraction: DrawAngleInteraction
  private readonly _drawDirectMeasureEllipseInteraction: DrawEllipseInteraction
  private readonly _drawDirectMeasureAreaInteraction: DrawPolygonInteraction
  private readonly _drawDirectMeasureArcInteraction: DrawArcInteraction
  private readonly _drawDirectMeasurePerimeterInteraction: DrawLineInteraction
  private readonly _drawDirectMeasureRectangleInteraction: DrawRectangleInteraction

  private readonly _drawMetadataAnnotationArrowInteraction: DrawStraightLineInteraction
  private readonly _drawMetadataAnnotationEllipseInteraction: DrawEllipseInteraction
  private readonly _drawMetadataAnnotationPolygonInteraction: DrawPolygonInteraction
  private readonly _drawMetadataAnnotationLineInteraction: DrawLineInteraction
  private readonly _drawMetadataAnnotationRectangleInteraction: DrawRectangleInteraction
  private readonly _drawMetadataAnnotationTextInteraction: DrawTextInteraction

  private readonly _modifyMetadataAnnotationArrowInteraction: ModifyStraightLineInteraction
  private readonly _modifyDirectMeasureDistanceInteraction: ModifyStraightLineInteraction
  private readonly _translateStraightLineInteraction: TranslateStraightLineInteraction
  private readonly _modifyAngleInteraction: ModifyAngleInteraction
  private readonly _modifyMetadataAnnotationPolygonInteraction: ModifyPolygonInteraction
  private readonly _modifyDirectMeasureAreaInteraction: ModifyPolygonInteraction
  private readonly _modifyArcInteraction: ModifyArcInteraction
  private readonly _translateArcInteraction: TranslateArcInteraction
  private readonly _modifyMetadataAnnotationLineInteraction: ModifyLineInteraction
  private readonly _modifyDirectMeasurePerimeterInteraction: ModifyLineInteraction
  private readonly _modifyRectangleInteraction: ModifyRectangleInteraction
  private readonly _modifyEllipseInteraction: ModifyEllipseInteraction
  private readonly _modifyCircleDirectMeasureInteraction: ModifyCircleDirectMeasureInteraction
  private readonly _translateTextInteraction: TranslateTextInteraction
  private readonly _editTextInteraction: EditTextInteraction

  private readonly _tempDragPanInteraction: DragPan
  // Controls
  private readonly _scaleControl: ScaleControl
  // Layers
  private readonly _gridLayer: VectorLayer<VectorSource<Feature<LineString>>>
  private readonly _imageLayer: GroupLayer
  private readonly _imageSourceGroupLayer: GroupLayer
  private readonly _annotationClassLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _detectedObjectLayerToRender: SelectedDetectedObjectWebGLLayer
  private readonly _directMeasureDistanceLayer: VectorLayer<VectorSource<Feature<LineString>>>
  private readonly _directMeasureAngleLayer: VectorLayer<VectorSource<Feature<LineString>>>
  private readonly _directMeasureEllipseLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _directMeasureCircleLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _directMeasureAreaLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _directMeasureArcLayer: VectorLayer<VectorSource<Feature<LineString>>>
  private readonly _directMeasurePerimeterLayer: VectorLayer<VectorSource<Feature<LineString>>>
  private readonly _directMeasureRectangleLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _metadataAnnotationArrowLayer: VectorLayer<VectorSource<Feature<LineString>>>
  private readonly _metadataAnnotationEllipseLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _metadataAnnotationCircleLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _metadataAnnotationPolygonLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _metadataAnnotationLineLayer: VectorLayer<VectorSource<Feature<LineString>>>
  private readonly _metadataAnnotationRectangleLayer: VectorLayer<VectorSource<Feature<Polygon>>>
  private readonly _metadataAnnotationTextLayer: VectorLayer<VectorSource<Feature<Point>>>
  private readonly _smartAnnotationLayer: VectorLayer<VectorSource<Feature<Point>>>
  private readonly _thicknessMeasureLayer: VectorLayer<VectorSource>
  private readonly _maskGroupLayers: GroupLayer[]

  // Clemex sources
  private _image: ClemexMosaicImage | undefined
  private readonly _masks: Record<number, ClemexMosaicMask>
  private _imageSources: ClemexMosaicImageSource[]
  private readonly _maskSources: ClemexMosaicMaskSource[]
  // Data
  private readonly _classAnnotationCollection: Collection<Feature<Polygon>>
  private readonly _detectedObjectCollection: Collection<Feature<Polygon>>
  private readonly _detectedObjectCollectionToRender: Collection<Feature<Polygon>>
  private readonly _directMeasureDistanceCollection: Collection<Feature<LineString>>
  private readonly _directMeasureAngleCollection: Collection<Feature<LineString>>
  private readonly _directMeasureEllipseCollection: Collection<Feature<Polygon>>
  private readonly _directMeasureCircleCollection: Collection<Feature<Polygon>>
  private readonly _directMeasureAreaCollection: Collection<Feature<Polygon>>
  private readonly _directMeasureArcCollection: Collection<Feature<LineString>>
  private readonly _directMeasurePerimeterCollection: Collection<Feature<LineString>>
  private readonly _directMeasureRectangleCollection: Collection<Feature<Polygon>>
  private readonly _metadataAnnotationArrowCollection: Collection<Feature<LineString>>
  private readonly _metadataAnnotationEllipseCollection: Collection<Feature<Polygon>>
  private readonly _metadataAnnotationCircleCollection: Collection<Feature<Polygon>>
  private readonly _metadataAnnotationPolygonCollection: Collection<Feature<Polygon>>
  private readonly _metadataAnnotationLineCollection: Collection<Feature<LineString>>
  private readonly _metadataAnnotationRectangleCollection: Collection<Feature<Polygon>>
  private readonly _metadataAnnotationTextCollection: Collection<Feature<Point>>
  private readonly _smartAnnotationCollection: Collection<Feature<Point>>
  private readonly _thicknessMeasureCollection: Collection<Feature>

  // State
  private readonly _selectedClassAnnotationIds: Set<string>
  private readonly _selectedDetectedObjectIds: Set<string>
  private readonly _selectedDirectMeasureIds: Set<string>
  private readonly _selectedMetadataAnnotationIds: Set<string>
  private _mode: ClemexMosaicCanvasMode
  private _tempMode: ClemexMosaicCanvasTempMode | undefined = undefined
  private _selectionModes: SelectionModes = DEFAULT_SELECTION_MODES
  private _colorIndex: number
  private _drawingBrushSize: number
  private _eraseOtherClassesOnOverlap: boolean
  private _imagePixelResolution = 0.05
  private _minClassAnnotationArea = 0.01
  private _pixelSize: number | undefined = undefined
  private _imageWebGLFilter: ImageWegGLFilter = {}
  private _classAnnotationProperties: AP
  private readonly _mappedStyle: PartialMappedStyle
  private _listeners: ClemexMosaicCanvasListenersMap

  // Config
  private _defaultMaskColor: MaskColors
  private _maskGroupLayerColor: MaskColors
  private readonly _maxResolution: number
  private _zoomFactor: number
  private readonly _zoomDuration?: number
  private readonly _zoomTimeout?: number
  private readonly GRID_Z_INDEX_LAYER_OFFSET = 150
  private readonly IMAGE_Z_INDEX_LAYER_OFFSET = 0
  private readonly MASK_Z_INDEX_LAYER_OFFSET = 100
  private readonly ANNOTATION_CLASS_Z_INDEX_LAYER_OFFSET = 200
  private readonly DETECTED_OBJECT_Z_INDEX_LAYER_OFFSET = 300
  private readonly THICKNESS_MEASURE_Z_INDEX_LAYER_OFFSET = 400
  private readonly ANNOTATION_Z_INDEX_LAYER_OFFSET = 500
  private readonly MEASUREMENT_Z_INDEX_LAYER_OFFSET = 600
  private readonly SMART_ANNOTATION_Z_INDEX_LAYER_OFFSET = 700
  private readonly ERASOR_OPACITY = 0.5

  private readonly _classAnnotations: ClassAnnotations<AP>

  constructor(
    mapParameters: MapOptions,
    defaultMaskColor: MaskColors = DEFAULT_CLEMEX_VISION_MASK_COLOR,
    zoomFactor: number = DEFAULT_ZOOM_FACTOR,
    zoomDuration: number = DEFAULT_ZOOM_DURATION,
    zoomTimeout: number | undefined = DEFAULT_ZOOM_TIMEOUT,
    mode: ClemexMosaicCanvasMode = DEFAULT_CLEMEX_MOSAIC_CANVAS_MODE,
    colorIndex: number = DEFAULT_COLOR_INDEX,
    drawingBrushSize: number = DEFAULT_BRUSH_SIZE,
    eraseOtherClassesOnOverlap: boolean = DEFAULT_ERASE_OTHER_CLASSES_ON_OVERLAP,
    maxResolution: number = DEFAULT_MAX_RESOLUTION,
    maxPixelGridResolution: number = DEFAULT_MAX_PIXELGRID_RESOLUTION,
    // Note(defaultAnnotationProperties): This is a hack to avoid having to pass the type of the annotation properties as most of the time it will be the default one.
    //       If the annotation type is different than default, then it needs to be set manually.
    //       Typescript will not warn about it as consequence of this hack.
    defaultClassAnnotationProperties: AP = {} as unknown as AP,
  ) {
    super({
      ...mapParameters,
      maxTilesLoading: Infinity,
      controls: [],
      interactions: [],
    })
    this._projection = new Projection({
      code: 'ClemexMosaic',
      units: 'pixels',
      metersPerUnit: 1,
    })
    this._maskGroupLayers = []
    this._maskGroupLayerColor = []
    this._defaultMaskColor = defaultMaskColor
    this._image = undefined
    this._imageSources = []
    this._masks = {}
    this._maskSources = []
    this._zoomFactor = zoomFactor
    this._zoomDuration = zoomDuration
    this._zoomTimeout = zoomTimeout
    this._mode = mode
    this._colorIndex = colorIndex
    this._drawingBrushSize = drawingBrushSize
    this._eraseOtherClassesOnOverlap = eraseOtherClassesOnOverlap
    this._pixelSize = undefined
    this._classAnnotationProperties = {
      ...defaultClassAnnotationProperties,
      colorIndex: this._colorIndex,
    }
    this._listeners = {}
    this._maxResolution = maxResolution

    this._selectedClassAnnotationIds = new Set<string>()
    this._selectedDetectedObjectIds = new Set<string>()
    this._selectedDirectMeasureIds = new Set<string>()
    this._selectedMetadataAnnotationIds = new Set<string>()

    const gridSource = new VectorSource<Feature<LineString>>()
    this._gridLayer = new VectorLayer({
      source: gridSource,
      zIndex: this.GRID_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      style: new Style({
        stroke: new Stroke({
          color: 'rgba(128, 128, 128, 128)',
          width: 1,
        }),
      }),
      maxResolution: maxPixelGridResolution,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    })

    this._imageLayer = new GroupLayer({
      zIndex: this.IMAGE_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
    })
    this._imageSourceGroupLayer = new GroupLayer({
      zIndex: this.IMAGE_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
    })
    this._classAnnotationCollection = new Collection<Feature<Polygon>>([], { unique: true })

    const classAnnotationVectorSource = new VectorSource<Feature<Polygon>>({
      useSpatialIndex: false,
      overlaps: false,
      features: this._classAnnotationCollection,
    })

    this._detectedObjectCollection = new Collection<Feature<Polygon>>([], { unique: true })

    this._detectedObjectCollectionToRender = new Collection<Feature<Polygon>>([], { unique: true })
    const detectedObjectVectorToRenderSource = new VectorSource<Feature>({
      useSpatialIndex: false,
      overlaps: false,
      features: this._detectedObjectCollectionToRender,
    })

    this._mappedStyle = {}
    this._selectInteraction = new Select({
      selectableLayers: this._getSelectableLayers(),
    })
    const mappedStyle = buildMappedStyle(
      this._pixelSize,
      this._selectedClassAnnotationIds,
      this._selectedDetectedObjectIds,
      this._selectedDirectMeasureIds,
      this._selectedMetadataAnnotationIds,
      this._mappedStyle,
    )

    // Note: Different strategies can be used for performance of the VectorLayer.
    // Because, we are using a `VectorSource`, we can use either `VectorLayer` or `VectorImageLayer` as vector layer instance.
    // Using `VectorLayer`, `updateWhileAnimating` and `updateWhileInteracting` give the best UX but decrease the performances
    // when there is too many objects.
    // This setting seems to be great for a number of object added by an human (<~1000) and keeping good performance.
    // Stress test need to be done to explore the performance when over this number.
    this._annotationClassLayer = new VectorLayer({
      source: classAnnotationVectorSource,
      zIndex: this.ANNOTATION_CLASS_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })

    this._detectedObjectLayerToRender = new SelectedDetectedObjectWebGLLayer({
      source: detectedObjectVectorToRenderSource,
      zIndex: this.DETECTED_OBJECT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
    })

    this._directMeasureDistanceCollection = new Collection<Feature<LineString>>([], { unique: true })
    this._directMeasureAngleCollection = new Collection<Feature<LineString>>([], { unique: true })
    this._directMeasureEllipseCollection = new Collection<Feature<Polygon>>([], { unique: true })
    this._directMeasureCircleCollection = new Collection<Feature<Polygon>>([], { unique: true })
    this._directMeasureAreaCollection = new Collection<Feature<Polygon>>([], { unique: true })
    this._directMeasureArcCollection = new Collection<Feature<LineString>>([], { unique: true })
    this._directMeasurePerimeterCollection = new Collection<Feature<LineString>>([], { unique: true })
    this._directMeasureRectangleCollection = new Collection<Feature<Polygon>>([], { unique: true })
    this._metadataAnnotationArrowCollection = new Collection<Feature<LineString>>([], { unique: true })
    this._metadataAnnotationEllipseCollection = new Collection<Feature<Polygon>>([], { unique: true })
    this._metadataAnnotationCircleCollection = new Collection<Feature<Polygon>>([], { unique: true })
    this._metadataAnnotationPolygonCollection = new Collection<Feature<Polygon>>([], { unique: true })
    this._metadataAnnotationLineCollection = new Collection<Feature<LineString>>([], { unique: true })
    this._metadataAnnotationRectangleCollection = new Collection<Feature<Polygon>>([], { unique: true })
    this._metadataAnnotationTextCollection = new Collection<Feature<Point>>([], { unique: true })
    this._smartAnnotationCollection = new Collection<Feature<Point>>([], { unique: true })
    this._thicknessMeasureCollection = new Collection<Feature>([], { unique: true })

    const measurementSource = new VectorSource<Feature<LineString>>({
      features: this._directMeasureDistanceCollection,
    })
    this._directMeasureDistanceLayer = new VectorLayer({
      source: measurementSource,
      zIndex: this.MEASUREMENT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const angleMeasurementSource = new VectorSource<Feature<LineString>>({
      features: this._directMeasureAngleCollection,
    })
    this._directMeasureAngleLayer = new VectorLayer({
      source: angleMeasurementSource,
      zIndex: this.MEASUREMENT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const ellipseDirectMeasureSource = new VectorSource<Feature<Polygon>>({
      features: this._directMeasureEllipseCollection,
    })
    this._directMeasureEllipseLayer = new VectorLayer({
      source: ellipseDirectMeasureSource,
      zIndex: this.MEASUREMENT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const circleDirectMeasureSource = new VectorSource<Feature<Polygon>>({
      features: this._directMeasureCircleCollection,
    })
    this._directMeasureCircleLayer = new VectorLayer({
      source: circleDirectMeasureSource,
      zIndex: this.MEASUREMENT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const areaMeasurementSource = new VectorSource<Feature<Polygon>>({
      features: this._directMeasureAreaCollection,
    })
    this._directMeasureAreaLayer = new VectorLayer({
      source: areaMeasurementSource,
      zIndex: this.MEASUREMENT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const arcMeasurementSource = new VectorSource<Feature<LineString>>({
      features: this._directMeasureArcCollection,
    })
    this._directMeasureArcLayer = new VectorLayer({
      source: arcMeasurementSource,
      zIndex: this.MEASUREMENT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const perimeterMeasurementSource = new VectorSource<Feature<LineString>>({
      features: this._directMeasurePerimeterCollection,
    })
    this._directMeasurePerimeterLayer = new VectorLayer({
      source: perimeterMeasurementSource,
      zIndex: this.MEASUREMENT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const boundingBoxMeasurementSource = new VectorSource<Feature<Polygon>>({
      features: this._directMeasureRectangleCollection,
    })
    this._directMeasureRectangleLayer = new VectorLayer({
      source: boundingBoxMeasurementSource,
      zIndex: this.MEASUREMENT_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })

    const metadataAnnotationArrowSource = new VectorSource<Feature<LineString>>({
      features: this._metadataAnnotationArrowCollection,
    })
    this._metadataAnnotationArrowLayer = new VectorLayer({
      source: metadataAnnotationArrowSource,
      zIndex: this.ANNOTATION_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const metadataAnnotationEllipseSource = new VectorSource<Feature<Polygon>>({
      features: this._metadataAnnotationEllipseCollection,
    })
    this._metadataAnnotationEllipseLayer = new VectorLayer({
      source: metadataAnnotationEllipseSource,
      zIndex: this.ANNOTATION_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const metadataAnnotationCircleSource = new VectorSource<Feature<Polygon>>({
      features: this._metadataAnnotationCircleCollection,
    })
    this._metadataAnnotationCircleLayer = new VectorLayer({
      source: metadataAnnotationCircleSource,
      zIndex: this.ANNOTATION_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const metadataAnnotationPolygonSource = new VectorSource<Feature<Polygon>>({
      features: this._metadataAnnotationPolygonCollection,
    })
    this._metadataAnnotationPolygonLayer = new VectorLayer({
      source: metadataAnnotationPolygonSource,
      zIndex: this.ANNOTATION_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const smartAnnotationSource = new VectorSource<Feature<Point>>({
      features: this._smartAnnotationCollection,
    })
    this._smartAnnotationLayer = new VectorLayer({
      source: smartAnnotationSource,
      zIndex: this.SMART_ANNOTATION_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })

    const thicknessMeasureSource = new VectorSource<Feature>({
      features: this._thicknessMeasureCollection,
    })
    this._thicknessMeasureLayer = new VectorLayer({
      source: thicknessMeasureSource,
      zIndex: this.THICKNESS_MEASURE_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })

    const metadataAnnotationTextSource = new VectorSource<Feature<Point>>({
      features: this._metadataAnnotationTextCollection,
    })
    this._metadataAnnotationTextLayer = new VectorLayer({
      source: metadataAnnotationTextSource,
      zIndex: this.ANNOTATION_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })

    const metadataAnnotationLineSource = new VectorSource<Feature<LineString>>({
      features: this._metadataAnnotationLineCollection,
    })
    this._metadataAnnotationLineLayer = new VectorLayer({
      source: metadataAnnotationLineSource,
      zIndex: this.ANNOTATION_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })
    const metadataAnnotationRectangleSource = new VectorSource<Feature<Polygon>>({
      features: this._metadataAnnotationRectangleCollection,
    })
    this._metadataAnnotationRectangleLayer = new VectorLayer({
      source: metadataAnnotationRectangleSource,
      zIndex: this.ANNOTATION_Z_INDEX_LAYER_OFFSET,
      opacity: 1,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: mappedStyle,
    })

    this._classAnnotations = new ClassAnnotations(
      this._classAnnotationCollection,
      {
        eraseOtherClassesOnOverlap: this._eraseOtherClassesOnOverlap,
        annotationPixelResolution: this._imagePixelResolution,
      },
      this._classAnnotationProperties,
      this._colorIndex,
      this,
      () => {
        return this.getMosaicExtent()
      },
    )

    this._selectClassAnnotationInteraction = new SelectClassAnnotationInteraction({
      selectableLayers: [this._annotationClassLayer],
    })

    this._selectDetectedObjectInteraction = new SelectDetectedObjectInteraction({
      selectableFeatures: this._detectedObjectCollection,
    })

    this._brushInteraction = new BrushInterraction<AP>(
      this._zoomFactor,
      this._classAnnotationCollection,
      {
        color: this._defaultMaskColor[this._colorIndex],
        width: this._drawingBrushSize,
        eraseOtherClassesOnOverlap: this._eraseOtherClassesOnOverlap,
        annotationPixelResolution: this._imagePixelResolution,
        minAnnotationArea: this._minClassAnnotationArea,
      },
      this._classAnnotations,
      () => {
        return this.getMosaicExtent()
      },
      () => {
        this._dispatchEvent(ClemexMosaicCanvasListenersType.START_ANNOTATING)
      },
      (changePatch) => {
        this._dispatchEvent(ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED, changePatch)
      },
    )
    this._eraserInteraction = new EraserInterraction(
      this._zoomFactor,
      this._annotationClassLayer,
      this._classAnnotationCollection,
      { width: this._drawingBrushSize, eraserOpacity: this.ERASOR_OPACITY, annotationPixelResolution: this._imagePixelResolution, minAnnotationArea: this._minClassAnnotationArea },
      () => {
        this._dispatchEvent(ClemexMosaicCanvasListenersType.START_ERASING)
      },
      (changePatch) => {
        this._dispatchEvent(ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED, changePatch)
      },
    )
    this._smartAnnotationInteraction = new SmartAnnotationInteraction(
      this._smartAnnotationLayer,
      this._smartAnnotationCollection,
      {
        color: this._defaultMaskColor[this._colorIndex],
      },
      (smartAnnotationPosition: Feature<Point>) => {
        this._dispatchEvent(ClemexMosaicCanvasListenersType.START_SMART_ANNOTATION, smartAnnotationPosition)
      },
      () => {
        this._dispatchEvent(ClemexMosaicCanvasListenersType.STOP_SMART_ANNOTATION)
      },
    )

    const onDirectMeasureChange = (changePatch: ChangePatch<Feature>): void => {
      this._dispatchEvent(ClemexMosaicCanvasListenersType.DIRECT_MEASURE_CHANGED, changePatch)
    }
    const onMetadataChange = (changePatch: ChangePatch<Feature>): void => {
      this._dispatchEvent(ClemexMosaicCanvasListenersType.METADATA_ANNOTATION_CHANGED, changePatch)
    }

    const selectFeatures = (features: Feature[]): void => {
      this._selectInteraction.getFeatures().clear()
      this._selectInteraction.getFeatures().extend(features)
    }

    this._drawDirectMeasureDistanceInteraction = new DrawStraightLineInteraction(
      this._directMeasureDistanceCollection,
      onDirectMeasureChange,
      FeatureType.DIRECT_MEASURE_DISTANCE,
    )
    this._drawDirectMeasureAngleInteraction = new DrawAngleInteraction(
      this._directMeasureAngleCollection,
      mappedStyle,
      onDirectMeasureChange,
      FeatureType.DIRECT_MEASURE_ANGLE,
    )
    this._drawDirectMeasureEllipseInteraction = new DrawEllipseInteraction({
      circleDestinationCollection: this._directMeasureCircleCollection,
      ellipseDestinationCollection: this._directMeasureEllipseCollection,
      style: mappedStyle,
      onEndInteraction: onDirectMeasureChange,
      isDirectMeasure: true,
    })
    this._drawDirectMeasureAreaInteraction = new DrawPolygonInteraction(
      this._directMeasureAreaCollection,
      onDirectMeasureChange,
      FeatureType.DIRECT_MEASURE_AREA,
    )
    this._drawDirectMeasureArcInteraction = new DrawArcInteraction(
      this._directMeasureArcCollection,
      mappedStyle,
      onDirectMeasureChange,
      FeatureType.DIRECT_MEASURE_ARC,
    )
    this._drawDirectMeasurePerimeterInteraction = new DrawLineInteraction(
      this._directMeasurePerimeterCollection,
      mappedStyle,
      onDirectMeasureChange,
      FeatureType.DIRECT_MEASURE_PERIMETER,
    )
    this._drawDirectMeasureRectangleInteraction = new DrawRectangleInteraction(
      this._directMeasureRectangleCollection,
      mappedStyle,
      onDirectMeasureChange,
      FeatureType.DIRECT_MEASURE_RECTANGLE,
    )

    this._drawMetadataAnnotationArrowInteraction = new DrawStraightLineInteraction(
      this._metadataAnnotationArrowCollection,
      onMetadataChange,
      FeatureType.METADATA_ANNOTATION_ARROW,
    )
    this._drawMetadataAnnotationEllipseInteraction = new DrawEllipseInteraction({
      circleDestinationCollection: this._metadataAnnotationCircleCollection,
      ellipseDestinationCollection: this._metadataAnnotationEllipseCollection,
      style: mappedStyle,
      onEndInteraction: onMetadataChange,
      isDirectMeasure: false,
    })
    this._drawMetadataAnnotationPolygonInteraction = new DrawPolygonInteraction(
      this._metadataAnnotationPolygonCollection,
      onMetadataChange,
      FeatureType.METADATA_ANNOTATION_POLYGON,
    )
    this._drawMetadataAnnotationLineInteraction = new DrawLineInteraction(
      this._metadataAnnotationLineCollection,
      mappedStyle,
      onMetadataChange,
      FeatureType.METADATA_ANNOTATION_LINE,
    )
    this._drawMetadataAnnotationRectangleInteraction = new DrawRectangleInteraction(
      this._metadataAnnotationRectangleCollection,
      mappedStyle,
      onMetadataChange,
      FeatureType.METADATA_ANNOTATION_RECTANGLE,
    )

    this._drawMetadataAnnotationTextInteraction = new DrawTextInteraction(
      this._metadataAnnotationTextCollection,
      (changePatch) => {
        onMetadataChange(changePatch)
        const createdFeature = changePatch.add?.[0].data
        if (createdFeature !== undefined) {
          // select the text after creation
          this._selectInteraction.getFeatures().clear()
          this._selectInteraction.getFeatures().push(createdFeature)
          this._editTextInteraction.startEditing(createdFeature)
        }
      },
      FeatureType.METADATA_ANNOTATION_TEXT,
    )

    this._modifyArcInteraction = new ModifyArcInteraction({
      modifiableFeatures: this._directMeasureArcCollection,
      layers: [this._directMeasureArcLayer],
      onEndInteraction: onDirectMeasureChange,
      onStartInteraction: selectFeatures,
      style: mappedStyle,
    })
    this._translateArcInteraction = new TranslateArcInteraction({
      layers: [this._directMeasureArcLayer],
      onEndInteraction: onDirectMeasureChange,
      onStartInteraction: selectFeatures,
    })

    this._modifyMetadataAnnotationArrowInteraction = new ModifyStraightLineInteraction({
      modifiableFeatures: this._metadataAnnotationArrowCollection,
      layers: [this._metadataAnnotationArrowLayer],
      style: mappedStyle,
      onEndInteraction: onMetadataChange,
      onStartInteraction: selectFeatures,
    })

    this._modifyDirectMeasureDistanceInteraction = new ModifyStraightLineInteraction({
      modifiableFeatures: this._directMeasureDistanceCollection,
      layers: [this._directMeasureDistanceLayer],
      style: mappedStyle,
      onEndInteraction: onDirectMeasureChange,
      onStartInteraction: selectFeatures,
    })

    this._translateStraightLineInteraction = new TranslateStraightLineInteraction({
      layers: [this._directMeasureDistanceLayer],
      onEndInteraction: onDirectMeasureChange,
      onStartInteraction: selectFeatures,
    })

    this._modifyAngleInteraction = new ModifyAngleInteraction({
      modifiableFeatures: this._directMeasureAngleCollection,
      layers: [this._directMeasureAngleLayer],
      onStartInteraction: selectFeatures,
      onEndInteraction: onDirectMeasureChange,
    })

    this._modifyMetadataAnnotationPolygonInteraction = new ModifyPolygonInteraction({
      modifiableFeatures: this._metadataAnnotationPolygonCollection,
      layer: this._metadataAnnotationPolygonLayer,
      onStartInteraction: selectFeatures,
      onEndInteraction: onMetadataChange,
      style: mappedStyle,
    })

    this._modifyDirectMeasureAreaInteraction = new ModifyPolygonInteraction({
      modifiableFeatures: this._directMeasureAreaCollection,
      layer: this._directMeasureAreaLayer,
      onStartInteraction: selectFeatures,
      onEndInteraction: onDirectMeasureChange,
      style: mappedStyle,
    })

    this._modifyMetadataAnnotationLineInteraction = new ModifyLineInteraction({
      modifiableFeatures: this._metadataAnnotationLineCollection,
      layers: [this._metadataAnnotationLineLayer],
      style: mappedStyle,
      onStartInteraction: selectFeatures,
      onEndInteraction: onMetadataChange,
    })

    this._modifyDirectMeasurePerimeterInteraction = new ModifyLineInteraction({
      modifiableFeatures: this._directMeasurePerimeterCollection,
      layers: [this._directMeasurePerimeterLayer],
      style: mappedStyle,
      onStartInteraction: selectFeatures,
      onEndInteraction: onDirectMeasureChange,
    })

    // FIXME: The ModifyRectangleInteraction, ModifyEllipseInteraction, and ModifyCircleDirectMeasureInteraction is not working as on change events
    this._modifyRectangleInteraction = new ModifyRectangleInteraction({
      modifiableFeatures: this._directMeasureRectangleCollection,
      layers: [this._directMeasureRectangleLayer, this._metadataAnnotationRectangleLayer],
      onEndInteraction: onDirectMeasureChange,
    })

    this._modifyEllipseInteraction = new ModifyEllipseInteraction({
      modifiableFeatures: this._directMeasureEllipseCollection,
      layers: [this._directMeasureEllipseLayer, this._metadataAnnotationEllipseLayer],
      onEndInteraction: onDirectMeasureChange,
    })

    this._modifyCircleDirectMeasureInteraction = new ModifyCircleDirectMeasureInteraction({
      modifiableFeatures: this._directMeasureCircleCollection,
      layers: [this._directMeasureCircleLayer, this._metadataAnnotationCircleLayer],
      onEndInteraction: onDirectMeasureChange,
    })

    this._translateTextInteraction = new TranslateTextInteraction({
      modifiableFeatures: this._metadataAnnotationTextCollection,
      layers: [this._metadataAnnotationTextLayer],
      onEndInteraction: onMetadataChange,
    })

    this._editTextInteraction = new EditTextInteraction({
      modifiableFeatures: this._metadataAnnotationTextCollection,
      layers: [this._metadataAnnotationTextLayer],
      onEndInteraction: (changePatch) => {
        if (changePatch.remove !== undefined) {
          // Manually remove the feature from the collection as the interaction does not do it
          this._selectInteraction.getFeatures().clear()
          changePatch.remove.forEach((feature) => {
            this._metadataAnnotationTextCollection.remove(feature.data as Feature<Point>)
          })
        }
        onMetadataChange(changePatch)
        this._updateInterractions()
      },
      onStartEditing: () => {
        this._updateInterractions()
      },
    })

    this._tempDragPanInteraction = new DragPan({})

    this._scaleControl = new ScaleControl()

    this._initialize()
    this._updateInterractions()
  }

  private readonly _initialize = (): void => {
    this._initializeState()
    this._initializeEventsHandler()
  }

  private readonly _initializeState = (): void => {
    this._tempDragPanInteraction.setActive(false)

    this.setView(new View({
      zoomFactor: this._zoomFactor,
      projection: this._projection,
      smoothExtentConstraint: true,
    }))
    // Set the default interactions, disable interactions that we will overide
    const defaultInterations = DefaultInteractions({
      shiftDragZoom: false,
      mouseWheelZoom: false,
    })
    this.interactions.clear()
    this.interactions.extend(defaultInterations.getArray())
    // Configure the mouse wheel interaction to behave according to `this._zoomFactor` and match behavior of KeyZoom
    this.addInteraction(new MouseWheelZoom({
      // setting contrainResolution to true force to not have intermediary zoom level
      constrainResolution: true,
      // In combination with constrainResolution, not contraining the zoom delta ensure that the zoom level match `this._zoomFactor`
      maxDelta: +Infinity,
      duration: this._zoomDuration,
      timeout: this._zoomTimeout,
    }))
    this.addInteraction(new DragZoom({
      condition: (mapBrowserEvent) => {
        const originalEvent = mapBrowserEvent.originalEvent as MouseEvent
        return (
          originalEvent.altKey && originalEvent.button === 0 // alt key and left click
        )
      },
    }))
    this.addInteraction(this._tempDragPanInteraction)
    this.addInteraction(new DragPan({
      condition: (mapBrowserEvent) => {
        if (mapBrowserEvent.type === 'pointerdown') {
          const originalEvent = mapBrowserEvent.originalEvent as MouseEvent
          return (
            originalEvent.button === 1 || originalEvent.button === 2 // middle or right click
          )
        }
        return false
      },
      kinetic: new Kinetic(-0.005, 0.05, 100),
    }))

    // Add tool interactions
    this.addInteraction(this._brushInteraction)
    this.addInteraction(this._eraserInteraction)
    this.addInteraction(this._smartAnnotationInteraction)

    this.addInteraction(this._drawDirectMeasureDistanceInteraction)
    this.addInteraction(this._drawDirectMeasureAngleInteraction)
    this.addInteraction(this._drawDirectMeasureEllipseInteraction)
    this.addInteraction(this._drawDirectMeasureAreaInteraction)
    this.addInteraction(this._drawDirectMeasureArcInteraction)
    this.addInteraction(this._drawDirectMeasurePerimeterInteraction)
    this.addInteraction(this._drawDirectMeasureRectangleInteraction)

    this.addInteraction(this._drawMetadataAnnotationArrowInteraction)
    this.addInteraction(this._drawMetadataAnnotationEllipseInteraction)
    this.addInteraction(this._drawMetadataAnnotationPolygonInteraction)
    this.addInteraction(this._drawMetadataAnnotationLineInteraction)
    this.addInteraction(this._drawMetadataAnnotationRectangleInteraction)
    this.addInteraction(this._drawMetadataAnnotationTextInteraction)

    this.addInteraction(this._selectDetectedObjectInteraction)
    this.addInteraction(this._selectClassAnnotationInteraction)
    this.addInteraction(this._selectInteraction)

    // Add modify/translate interactions
    this.addInteraction(this._translateArcInteraction)
    this.addInteraction(this._modifyArcInteraction)
    this.addInteraction(this._translateStraightLineInteraction)
    this.addInteraction(this._modifyMetadataAnnotationArrowInteraction)
    this.addInteraction(this._modifyDirectMeasureDistanceInteraction)
    this.addInteraction(this._modifyAngleInteraction)
    this.addInteraction(this._modifyMetadataAnnotationPolygonInteraction)
    this.addInteraction(this._modifyDirectMeasureAreaInteraction)
    this.addInteraction(this._modifyMetadataAnnotationLineInteraction)
    this.addInteraction(this._modifyDirectMeasurePerimeterInteraction)
    this.addInteraction(this._modifyRectangleInteraction)
    this.addInteraction(this._modifyEllipseInteraction)
    this.addInteraction(this._modifyCircleDirectMeasureInteraction)
    this.addInteraction(this._modifyRectangleInteraction)
    this.addInteraction(this._translateTextInteraction)
    this.addInteraction(this._editTextInteraction)

    // Add layers
    this.addLayer(this._gridLayer)
    this.addLayer(this._imageSourceGroupLayer)
    this.addLayer(this._annotationClassLayer)
    this.addLayer(this._detectedObjectLayerToRender)
    this.addLayer(this._imageLayer)
    this.addLayer(this._smartAnnotationLayer)
    this.addLayer(this._thicknessMeasureLayer)

    this.addLayer(this._directMeasureDistanceLayer)
    this.addLayer(this._directMeasureAngleLayer)
    this.addLayer(this._directMeasureEllipseLayer)
    this.addLayer(this._directMeasureCircleLayer)
    this.addLayer(this._directMeasureAreaLayer)
    this.addLayer(this._directMeasureArcLayer)
    this.addLayer(this._directMeasurePerimeterLayer)
    this.addLayer(this._directMeasureRectangleLayer)

    this.addLayer(this._metadataAnnotationArrowLayer)
    this.addLayer(this._metadataAnnotationEllipseLayer)
    this.addLayer(this._metadataAnnotationCircleLayer)
    this.addLayer(this._metadataAnnotationPolygonLayer)
    this.addLayer(this._metadataAnnotationTextLayer)
    this.addLayer(this._metadataAnnotationLineLayer)
    this.addLayer(this._metadataAnnotationRectangleLayer)

    // The eraser interaction use a temp layer to draw the eraser shape
    // This layer need to be added to the map to be rendered
    // EraserInteraction cannot do it by itself
    this.addLayer(this._eraserInteraction.tempLayer)

    this.addControl(this._scaleControl)
  }

  private readonly _initializeEventsHandler = (): void => {
    this.getView().on('change:resolution', () => {
      this._dispatchEvent(ClemexMosaicCanvasListenersType.ZOOM_CHANGED, { zoom: this.getZoom(), resolution: this.getResolution() })
    })

    this.on('change:target', (): void => {
      this._bindTargetEventsToInteractions()
    })
    this._bindTargetEventsToInteractions()

    this.on('pointermove', (evt: MapBrowserEvent<UIEvent>) => {
      // When the point move, it can be on a position that will have an interaction
      // ie: the mouse is on a direct measure distance anchor to modify it
      //     In this case, we need to update the cursor style

      if (this._modifyMetadataAnnotationArrowInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._modifyDirectMeasureDistanceInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._translateStraightLineInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_TRANSLATE_HANDLE)
      } else if (this._modifyAngleInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._modifyMetadataAnnotationPolygonInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._modifyDirectMeasureAreaInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._modifyMetadataAnnotationLineInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._modifyDirectMeasurePerimeterInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._modifyArcInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._translateArcInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_TRANSLATE_HANDLE)
      } else if (this._modifyEllipseInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._modifyCircleDirectMeasureInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._modifyRectangleInteraction.isCursorAbleToModify(evt)) {
        this._setCursorStyle(CursorType.GRAB_MODIFY_HANDLE)
      } else if (this._mode === ClemexMosaicCanvasMode.SELECT) {
        if (this._selectInteraction.isCursorAbleToSelect(evt)) {
          this._setCursorStyle(CursorType.SELECT)
        } else if (this._selectClassAnnotationInteraction.isCursorAbleToSelect(evt)) {
          this._setCursorStyle(CursorType.SELECT)
        } else if (this._selectDetectedObjectInteraction.isCursorAbleToSelect()) {
          this._setCursorStyle(CursorType.SELECT)
        } else {
          this._setCursorStyle(CursorType.SELECT)
        }
      } else {
        // Otherwise, we need to update the cursor style according to the active interaction
        this._updateCursorStyleFromActiveInterraction()
      }
    });

    // Setup interaction with `Draw` base interaction
    [
      this._brushInteraction,
      this._eraserInteraction,
      this._smartAnnotationInteraction,
      this._drawDirectMeasureDistanceInteraction,
      this._drawDirectMeasureAngleInteraction,
      this._drawDirectMeasureEllipseInteraction,
      this._drawDirectMeasureAreaInteraction,
      this._drawDirectMeasureArcInteraction,
      this._drawDirectMeasurePerimeterInteraction,
      this._drawDirectMeasureRectangleInteraction,
      this._drawMetadataAnnotationArrowInteraction,
      this._drawMetadataAnnotationEllipseInteraction,
      this._drawMetadataAnnotationPolygonInteraction,
      this._drawMetadataAnnotationLineInteraction,
      this._drawMetadataAnnotationRectangleInteraction,
    ].forEach((interaction) => {
      interaction.addEventListener('drawstart', () => {
        this._updateInterractions()
      })
      interaction.addChangeListener('drawabort', () => {
        this._updateInterractions()
      })
      interaction.addEventListener('drawend', () => {
        this._updateInterractions()
      })
    })

    const onSelectionChange = (): void => {
      const features = this._selectInteraction.getFeatures().getArray()

      this._selectedDirectMeasureIds.clear()
      const directMeasureFeatures = features.filter((feature) => {
        return DIRECT_MEASURE_FEATURE_TYPES.includes(feature.get(FEATURE_TYPE) as FeatureType)
      })
      directMeasureFeatures.forEach((feature) => {
        this._selectedDirectMeasureIds.add(feature.getProperties().id as string)
      })

      this._selectedMetadataAnnotationIds.clear()
      const metadataAnnotationFeatures = features.filter((feature) => {
        return METADATA_ANNOTATION_FEATURE_TYPES.includes(feature.get(FEATURE_TYPE) as FeatureType)
      })

      metadataAnnotationFeatures.forEach((feature) => {
        this._selectedMetadataAnnotationIds.add(feature.getProperties().id as string)
      })

      // Dispatch event to notify the selection change
      this._dispatchEvent(ClemexMosaicCanvasListenersType.SELECTION_CHANGED)
    }
    this._selectInteraction.getFeatures().on('add', onSelectionChange)
    this._selectInteraction.getFeatures().on('remove', onSelectionChange)

    // On selected class annotation changed
    const onSelectedClassAnnotationChanged = (): void => {
      const feature: Feature | undefined = this._selectClassAnnotationInteraction.getFeatures().getArray()[0]
      this._selectedClassAnnotationIds.clear()
      const classAnnotationId = (feature?.getProperties() as ClassAnnotationProperties)?.classAnnotationId
      this._selectedClassAnnotationIds.add(classAnnotationId)
      this.render()
      this._dispatchEvent(ClemexMosaicCanvasListenersType.SELECTION_CLASS_ANNOTATION_CHANGED, classAnnotationId)
    }
    this._selectClassAnnotationInteraction.getFeatures().on('add', onSelectedClassAnnotationChanged)
    this._selectClassAnnotationInteraction.getFeatures().on('remove', onSelectedClassAnnotationChanged)

    // On selected detected object changed
    const onSelectedDetectedObjectChanged = (): void => {
      const feature: Feature | undefined = this._selectDetectedObjectInteraction.getFeatures().getArray()[0]
      this._selectedDetectedObjectIds.clear()
      const detectedObjectId = (feature?.getProperties() as DetectedObjectProperties)?.id
      this._selectedDetectedObjectIds.add(detectedObjectId)
      this.render()
      this._dispatchEvent(ClemexMosaicCanvasListenersType.SELECTION_DETECTED_OBJECT_CHANGED, detectedObjectId)
    }
    this._selectDetectedObjectInteraction.getFeatures().on('add', onSelectedDetectedObjectChanged)
    this._selectDetectedObjectInteraction.getFeatures().on('remove', onSelectedDetectedObjectChanged)

    this._updateInterractions()
  }

  // Note: openlayer does not provide event interraction from the keyup event (only keydown and keypress events)
  //       so we need to bind the keyup event to the target element manually.
  private readonly _bindTargetEventsToInteractions = (): void => {
    document.addEventListener('keyup', (keyupEvent) => {
      if (keyupEvent.key === ' ') {
        if (this._tempMode === ClemexMosaicCanvasTempMode.SPACE_TO_DRAG_PAN) {
          this._tempMode = undefined
          this._updateInterractions()
        }
      }
      if (keyupEvent.key === 'Shift') {
        if (this._tempMode === ClemexMosaicCanvasTempMode.SHIFT_TO_BRUSH_ERASE) {
          this._tempMode = undefined
          // If the user was drawing during with the erasor, we need to finish the drawing
          this._eraserInteraction.finishDrawing()
          this._updateInterractions()
        }
      }
    })
    document.addEventListener('keydown', (keydownEvent) => {
      if (keydownEvent.key === ' ') {
        this._tempMode = ClemexMosaicCanvasTempMode.SPACE_TO_DRAG_PAN
        this._updateInterractions()
      }
      if (this._mode === ClemexMosaicCanvasMode.BRUSH) {
        if (keydownEvent.key === 'Shift') {
          this._tempMode = ClemexMosaicCanvasTempMode.SHIFT_TO_BRUSH_ERASE
          // If the user was drawing during with the brush, we need to finish the drawing
          this._brushInteraction.finishDrawing()
          this._updateInterractions()
        }
      }
    })
    document.addEventListener('pointerup', (pointerUpEvent) => {
      if (pointerUpEvent.button === 1) { // middle click
        if (this._tempMode === ClemexMosaicCanvasTempMode.MIDDLE_CLICK_TO_DRAG_PAN) {
          this._tempMode = undefined
          this._updateInterractions()
        }
      }
    })
    document.addEventListener('pointerdown', (pointerDownEvent) => {
      if (pointerDownEvent.button === 1) { // middle click
        this._tempMode = ClemexMosaicCanvasTempMode.MIDDLE_CLICK_TO_DRAG_PAN
        this._updateInterractions()
      }
    })

    document.addEventListener('mouseover', (mouseOverEvent) => {
      // If the user release the shift key while the mouse is out of the target, the keyup event is not triggered
      // The user enter a state where the temp mode is stuck to `SHIFT_TO_BRUSH_ERASE` without understanding why
      // This can be prevented by continously checking the state of the shift key during the mouseover event
      if (this._tempMode === ClemexMosaicCanvasTempMode.SHIFT_TO_BRUSH_ERASE) {
        if (!mouseOverEvent.shiftKey) {
          this._tempMode = undefined
          this._updateInterractions()
        }
      }
    })
  }

  // Internal API
  private readonly _updateStyles = (): void => {
    const mappedStyle = buildMappedStyle(
      this._pixelSize,
      this._selectedClassAnnotationIds,
      this._selectedDetectedObjectIds,
      this._selectedDirectMeasureIds,
      this._selectedMetadataAnnotationIds,
      this._mappedStyle,
    )

    this._annotationClassLayer.setStyle(mappedStyle)
    this._smartAnnotationLayer.setStyle(mappedStyle)
    this._thicknessMeasureLayer.setStyle(mappedStyle)

    this._directMeasureDistanceLayer.setStyle(mappedStyle)
    this._directMeasureAngleLayer.setStyle(mappedStyle)
    this._directMeasureAreaLayer.setStyle(mappedStyle)
    this._directMeasurePerimeterLayer.setStyle(mappedStyle)
    this._directMeasureArcLayer.setStyle(mappedStyle)
    this._directMeasureRectangleLayer.setStyle(mappedStyle)
    this._directMeasureEllipseLayer.setStyle(mappedStyle)
    this._directMeasureCircleLayer.setStyle(mappedStyle)

    this._metadataAnnotationArrowLayer.setStyle(mappedStyle)
    this._metadataAnnotationEllipseLayer.setStyle(mappedStyle)
    this._metadataAnnotationCircleLayer.setStyle(mappedStyle)
    this._metadataAnnotationPolygonLayer.setStyle(mappedStyle)
    this._metadataAnnotationTextLayer.setStyle(mappedStyle)
    this._metadataAnnotationLineLayer.setStyle(mappedStyle)
    this._metadataAnnotationRectangleLayer.setStyle(mappedStyle)

    this._drawDirectMeasureDistanceInteraction.setStyle(mappedStyle)
    this._drawDirectMeasureAngleInteraction.setStyle(mappedStyle)
    this._drawDirectMeasureAreaInteraction.setStyle(mappedStyle)
    this._drawDirectMeasurePerimeterInteraction.setStyle(mappedStyle)
    this._drawDirectMeasureArcInteraction.setStyle(mappedStyle)
    this._drawDirectMeasureRectangleInteraction.setStyle(mappedStyle)
    this._drawDirectMeasureEllipseInteraction.setStyle(mappedStyle)

    this._drawMetadataAnnotationArrowInteraction.setStyle(mappedStyle)
    this._drawMetadataAnnotationPolygonInteraction.setStyle(mappedStyle)
    this._drawMetadataAnnotationLineInteraction.setStyle(mappedStyle)
    this._drawMetadataAnnotationRectangleInteraction.setStyle(mappedStyle)
    this._drawMetadataAnnotationEllipseInteraction.setStyle(mappedStyle)

    this._modifyMetadataAnnotationArrowInteraction.setStyle(mappedStyle)
    this._modifyDirectMeasureDistanceInteraction.setStyle(mappedStyle)
    this._modifyAngleInteraction.setStyle(mappedStyle)
    this._modifyMetadataAnnotationPolygonInteraction.setStyle(mappedStyle)
    this._modifyDirectMeasureAreaInteraction.setStyle(mappedStyle)
    this._modifyMetadataAnnotationLineInteraction.setStyle(mappedStyle)
    this._modifyDirectMeasurePerimeterInteraction.setStyle(mappedStyle)
    this._modifyArcInteraction.setStyle(mappedStyle)

    this._imageLayer.getLayers().forEach((layer) => {
      if (layer instanceof WebGLTileLayer) {
        layer.setStyle(this._imageWebGLFilter)
      }
    })
  }

  private readonly _updateScaleControl = (): void => {
    this._scaleControl.setPixelSize(this._pixelSize)
  }

  private readonly _updateInterractionsFromSelection = (): void => {
    const collections = [
      this._directMeasureAngleCollection,
      this._directMeasureAreaCollection,
      this._directMeasureArcCollection,
      this._directMeasureCircleCollection,
      this._directMeasureDistanceCollection,
      this._directMeasureEllipseCollection,
      this._directMeasurePerimeterCollection,
      this._directMeasureRectangleCollection,
      this._metadataAnnotationArrowCollection,
      this._metadataAnnotationCircleCollection,
      this._metadataAnnotationEllipseCollection,
      this._metadataAnnotationLineCollection,
      this._metadataAnnotationPolygonCollection,
      this._metadataAnnotationRectangleCollection,
      this._metadataAnnotationTextCollection,
    ]
    const selectedIds = new Set([
      ...this._selectedDirectMeasureIds,
      ...this._selectedMetadataAnnotationIds,
    ])

    const features = collections.reduce<Feature[]>((acc, collection) => {
      const features = collection.getArray()
      const selectedFeatures = features.filter((feature) => {
        return selectedIds.has(feature.getProperties().id as string)
      })
      return acc.concat(selectedFeatures)
    }, [])

    const boundingBoxFeatures = features.filter((feature) => {
      return feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_RECTANGLE || feature.get(FEATURE_TYPE) === FeatureType.METADATA_ANNOTATION_RECTANGLE
    })
    const modifiableBoundingBox = boundingBoxFeatures.length === 1 ? boundingBoxFeatures[0] : undefined;
    (this._modifyRectangleInteraction as unknown as { setSelection: (features: Feature[]) => void }).setSelection(modifiableBoundingBox !== undefined ? [modifiableBoundingBox] : [])

    const ellipseFeatures = features.filter((feature) => {
      return feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_ELLIPSE || feature.get(FEATURE_TYPE) === FeatureType.METADATA_ANNOTATION_ELLIPSE
    })
    const modifiableEllipse = ellipseFeatures.length === 1 ? ellipseFeatures[0] : undefined;
    (this._modifyEllipseInteraction as unknown as { setSelection: (features: Feature[]) => void }).setSelection(modifiableEllipse !== undefined ? [modifiableEllipse] : [])

    const circleFeatures = features.filter((feature) => {
      return feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_CIRCLE || feature.get(FEATURE_TYPE) === FeatureType.METADATA_ANNOTATION_CIRCLE
    })
    const modifiableCircle = circleFeatures.length === 1 ? circleFeatures[0] : undefined;
    (this._modifyCircleDirectMeasureInteraction as unknown as { setSelection: (features: Feature[]) => void }).setSelection(modifiableCircle !== undefined ? [modifiableCircle] : [])
  }

  private readonly _updateInterractions = (): void => {
    // First update interactions settings
    // Note: the brushInterraction color does not know about the color palette, so we need to forward it
    //       Also, when the annotation is created, the annotation does not contains the color but should contains the colorIndex
    //       So we need to also forward the colorIndex
    this._brushInteraction.setBrushParameters({ color: this._defaultMaskColor[this._colorIndex], width: this._drawingBrushSize, eraseOtherClassesOnOverlap: this._eraseOtherClassesOnOverlap, annotationPixelResolution: this._imagePixelResolution, minAnnotationArea: this._minClassAnnotationArea })
    this._eraserInteraction.setEraserParameters({ width: this._drawingBrushSize, eraserOpacity: this.ERASOR_OPACITY, annotationPixelResolution: this._imagePixelResolution, minAnnotationArea: this._minClassAnnotationArea })
    this._selectInteraction.setSelectableLayers(this._getSelectableLayers())
    this._smartAnnotationInteraction.setBrushParameters({ color: this._defaultMaskColor[this._colorIndex] })

    this._classAnnotations.setColorIndex(this._colorIndex)
    this._classAnnotations.setAnnotationProperties({ ...this._classAnnotationProperties })
    this._classAnnotations.setAnnotationParameters({ eraseOtherClassesOnOverlap: this._eraseOtherClassesOnOverlap, annotationPixelResolution: this._imagePixelResolution })

    const isInteracting = [
      this._drawDirectMeasureDistanceInteraction,
      this._drawDirectMeasureAngleInteraction,
      this._drawDirectMeasureEllipseInteraction,
      this._drawDirectMeasureAreaInteraction,
      this._drawDirectMeasureArcInteraction,
      this._drawDirectMeasurePerimeterInteraction,
      this._drawDirectMeasureRectangleInteraction,
      this._editTextInteraction,
    ].some((interaction) => interaction.isInteracting())

    this._brushInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.BRUSH)
    this._eraserInteraction.setActive((this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.ERASER) || this._tempMode === ClemexMosaicCanvasTempMode.SHIFT_TO_BRUSH_ERASE)
    this._smartAnnotationInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.SMART_ANNOTATION)

    this._drawDirectMeasureDistanceInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.DIRECT_MEASURE_DISTANCE)
    this._drawDirectMeasureAngleInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.DIRECT_MEASURE_ANGLE)
    this._drawDirectMeasureEllipseInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.DIRECT_MEASURE_ELLIPSE)
    this._drawDirectMeasureAreaInteraction.setActive(this._tempMode === undefined && (this._mode === ClemexMosaicCanvasMode.DIRECT_MEASURE_AREA))
    this._drawDirectMeasurePerimeterInteraction.setActive(this._tempMode === undefined && (this._mode === ClemexMosaicCanvasMode.DIRECT_MEASURE_PERIMETER))
    this._drawDirectMeasureRectangleInteraction.setActive(this._tempMode === undefined && (this._mode === ClemexMosaicCanvasMode.DIRECT_MEASURE_RECTANGLE))
    this._drawDirectMeasureArcInteraction.setActive(this._tempMode === undefined && (this._mode === ClemexMosaicCanvasMode.DIRECT_MEASURE_ARC))

    this._drawMetadataAnnotationArrowInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.METADATA_ANNOTATION_ARROW)
    this._drawMetadataAnnotationEllipseInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.METADATA_ANNOTATION_ELLIPSE)
    this._drawMetadataAnnotationPolygonInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.METADATA_ANNOTATION_POLYGON)
    this._drawMetadataAnnotationLineInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.METADATA_ANNOTATION_LINE)
    this._drawMetadataAnnotationRectangleInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.METADATA_ANNOTATION_RECTANGLE)
    this._drawMetadataAnnotationTextInteraction.setActive(this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.METADATA_ANNOTATION_TEXT && !this._editTextInteraction.isInteracting())

    // Selecting direct measure and metadata annotation is always possible
    this._selectInteraction.setActive(!isInteracting && this._tempMode === undefined && !MODE_NOT_ALLOWING_SELECT_OR_MODIFY.includes(this._mode))

    // Selecting class annotation and detected object is possible only when the select mode is active
    this._selectClassAnnotationInteraction.setActive(!isInteracting && this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.SELECT)
    this._selectDetectedObjectInteraction.setActive(!isInteracting && this._tempMode === undefined && this._mode === ClemexMosaicCanvasMode.SELECT)

    const canModify = !isInteracting && this._tempMode === undefined && !MODE_NOT_ALLOWING_SELECT_OR_MODIFY.includes(this._mode)
    const modifyInteractions = [
      this._modifyMetadataAnnotationArrowInteraction,
      this._modifyDirectMeasureDistanceInteraction,
      this._translateStraightLineInteraction,
      this._modifyAngleInteraction,
      this._modifyMetadataAnnotationPolygonInteraction,
      this._modifyDirectMeasureAreaInteraction,
      this._modifyMetadataAnnotationLineInteraction,
      this._modifyDirectMeasurePerimeterInteraction,
      this._modifyRectangleInteraction,
      this._modifyEllipseInteraction,
      this._modifyCircleDirectMeasureInteraction,
      this._modifyArcInteraction,
      this._translateArcInteraction,
      this._translateTextInteraction,
      this._editTextInteraction,
    ]
    modifyInteractions.forEach((interaction) => {
      interaction.setActive(canModify)
    })

    this._tempDragPanInteraction.setActive(this._tempMode === ClemexMosaicCanvasTempMode.SPACE_TO_DRAG_PAN || this._tempMode === ClemexMosaicCanvasTempMode.MIDDLE_CLICK_TO_DRAG_PAN)

    // This need to be called after `setActive` as `ol-ext/Transform` reset the selection when `setActive` is called weither it is set to true or false
    this._updateInterractionsFromSelection()

    // Finally update the cursor
    this._updateCursorStyleFromActiveInterraction()
  }

  private readonly _updateCursorStyleFromActiveInterraction = (): void => {
    if (this._brushInteraction.getActive()) {
      this._setCursorStyle(CursorType.DRAWING)
    } else if (this._eraserInteraction.getActive()) {
      this._setCursorStyle(CursorType.ERASING)
    } else if (this._smartAnnotationInteraction.getActive()) {
      this._setCursorStyle(CursorType.SMART_ANNOTATION)
    } else if (this._drawDirectMeasureDistanceInteraction.getActive() || this._drawMetadataAnnotationArrowInteraction.getActive()) {
      this._setCursorStyle(CursorType.STRAIGHT_LINE)
    } else if (this._drawDirectMeasureAngleInteraction.getActive()) {
      this._setCursorStyle(CursorType.ANGLE)
    } else if (this._drawDirectMeasureEllipseInteraction.getActive() || this._drawMetadataAnnotationEllipseInteraction.getActive()) {
      this._setCursorStyle(CursorType.ELLIPSE)
    } else if (this._drawDirectMeasureAreaInteraction.getActive() || this._drawMetadataAnnotationPolygonInteraction.getActive()) {
      this._setCursorStyle(CursorType.POLYGON)
    } else if (this._drawDirectMeasureArcInteraction.getActive()) {
      this._setCursorStyle(CursorType.ARC)
    } else if (this._drawDirectMeasurePerimeterInteraction.getActive() || this._drawMetadataAnnotationLineInteraction.getActive()) {
      this._setCursorStyle(CursorType.LINE)
    } else if (this._drawDirectMeasureRectangleInteraction.getActive() || this._drawMetadataAnnotationRectangleInteraction.getActive()) {
      this._setCursorStyle(CursorType.RECTANGLE)
    } else if (this._drawMetadataAnnotationTextInteraction.getActive()) {
      this._setCursorStyle(CursorType.TEXT)
    } else {
      this._setCursorStyle(CursorType.DRAG_PAN)
    }
  }

  private readonly _setCursorStyle = (cursorType: CursorType): void => {
    const cursor: Record<CursorType, () => string> = {
      [CursorType.DEFAULT]: () => 'default',
      [CursorType.SELECT]: () => 'pointer',
      [CursorType.STRAIGHT_LINE]: () => 'crosshair',
      [CursorType.ANGLE]: () => 'crosshair',
      [CursorType.ELLIPSE]: () => 'crosshair',
      [CursorType.POLYGON]: () => 'crosshair',
      [CursorType.ARC]: () => 'crosshair',
      [CursorType.LINE]: () => 'crosshair',
      [CursorType.RECTANGLE]: () => 'crosshair',
      [CursorType.TEXT]: () => 'text',
      [CursorType.DRAWING]: () => getCircleCursor(this._drawingBrushSize / 2, false),
      [CursorType.ERASING]: () => getCircleCursor(this._drawingBrushSize / 2, true),
      // TODO: Create dedicated
      [CursorType.SMART_ANNOTATION]: () => getCircleCursor(10, false),
      [CursorType.DRAG_PAN]: () => 'move',
      [CursorType.GRAB_MODIFY_HANDLE]: () => 'grab',
      [CursorType.GRAB_TRANSLATE_HANDLE]: () => 'move',
    }
    this.getViewport().style.cursor = cursor[cursorType]()
  }

  private readonly _updateGrid = (): void => {
    const gridSource = this._gridLayer.getSource()
    if (gridSource === null) {
      return
    }
    const mosaicExtent = this.getMosaicExtent()
    if (mosaicExtent === undefined) {
      return
    }
    for (let x = mosaicExtent[0]; x <= mosaicExtent[2]; x++) {
      const lineGeom = new LineString([[x, mosaicExtent[1]], [x, mosaicExtent[3]]])
      const gridFeature = new Feature(lineGeom)
      gridSource.addFeature(gridFeature)
    }
    for (let y = mosaicExtent[1]; y <= mosaicExtent[3]; y++) {
      const lineGeom = new LineString([[mosaicExtent[0], y], [mosaicExtent[2], y]])
      const gridFeature = new Feature(lineGeom)
      gridSource.addFeature(gridFeature)
    }
    gridSource.changed()
  }

  private readonly _updateViewConstraints = (): void => {
    const mosaicExtent = this.getMosaicExtent()

    if (mosaicExtent === undefined) {
      return
    }

    // XXX: The result of getZoomForResolution is dependent on the minZoom
    //      As we are trying to compute the minZoom and maxZoom from the resolution, we need to reset the minZoom to 0
    //      Otherwise, the result of getZoomForResolution will be wrong (biaised by the minZoom set from the previous time)
    this.getView().setMinZoom(0)

    // Set the max zoom to match this._maxResolution (default to 1/64th of the image extent)
    const openLayerMosaicMaxZoom = this.getView().getZoomForResolution(this._maxResolution)

    if (openLayerMosaicMaxZoom !== undefined) {
      this.getView().setMaxZoom(openLayerMosaicMaxZoom)
    }

    // Set the min zoom to one zoom level above the one that fits the image extent
    const openLayerMosaicResolution = this.getView().getResolutionForExtent(mosaicExtent)

    const openLayerMosaicZoom = this.getView().getZoomForResolution(openLayerMosaicResolution)

    if (openLayerMosaicZoom !== undefined) {
      this.getView().setMinZoom(openLayerMosaicZoom - 1)
    }

    // XXX: This is a hack to force set the extent constraint to the image extent
    //      OpenLayers does not provider a way to set the extent constraint
    //      This needs to be called last as setMinZoom and setMaxZoom reset the center constraint
    (this.getView() as unknown as { constraints_: Constraints }).constraints_.center = createCenterConstraint({
      extent: mosaicExtent,
    })
  }

  private readonly _getDirectMeasureLayers = (): VectorLayer<VectorSource>[] => {
    return [
      this._directMeasureDistanceLayer as unknown as VectorLayer<VectorSource>,
      this._directMeasureAngleLayer as unknown as VectorLayer<VectorSource>,
      this._directMeasureAreaLayer as unknown as VectorLayer<VectorSource>,
      this._directMeasureArcLayer as unknown as VectorLayer<VectorSource>,
      this._directMeasurePerimeterLayer as unknown as VectorLayer<VectorSource>,
      this._directMeasureRectangleLayer as unknown as VectorLayer<VectorSource>,
      this._directMeasureEllipseLayer as unknown as VectorLayer<VectorSource>,
      this._directMeasureCircleLayer as unknown as VectorLayer<VectorSource>,
    ]
  }

  private readonly _getMetadataAnnotationLayers = (): VectorLayer<VectorSource>[] => {
    return [
      this._metadataAnnotationArrowLayer as unknown as VectorLayer<VectorSource>,
      this._metadataAnnotationEllipseLayer as unknown as VectorLayer<VectorSource>,
      this._metadataAnnotationCircleLayer as unknown as VectorLayer<VectorSource>,
      this._metadataAnnotationPolygonLayer as unknown as VectorLayer<VectorSource>,
      this._metadataAnnotationTextLayer as unknown as VectorLayer<VectorSource>,
      this._metadataAnnotationLineLayer as unknown as VectorLayer<VectorSource>,
      this._metadataAnnotationRectangleLayer as unknown as VectorLayer<VectorSource>,
    ]
  }

  private readonly _getSelectableLayers = (): VectorLayer<VectorSource>[] => {
    const selectableLayers: VectorLayer<VectorSource>[] = []
    if (this._selectionModes.directMeasurementAnnotations) {
      selectableLayers.push(...this._getDirectMeasureLayers())
    }
    if (this._selectionModes.metadataAnnotations) {
      selectableLayers.push(...this._getMetadataAnnotationLayers())
    }
    return selectableLayers
  }

  // Public API
  public readonly setImage = (image: ClemexMosaicImage): void => {
    const imageLayer = makeLayerFromImageSource(image, this._maskGroupLayerColor, this._projection)
    imageLayer.setZIndex(0)
    this._imageLayer.setLayers(new Collection([imageLayer]))
    this._image = image

    this._updateViewConstraints()
    this._updateGrid()
    this._updateStyles()
  }

  public readonly removeImage = (): void => {
    this._imageLayer.setLayers(new Collection([]))
    this._image = undefined
  }

  public readonly addImageSource = (imageSource: ClemexMosaicImageSource): void => {
    this._imageSourceGroupLayer.setLayers(new Collection([
      ...this._imageSourceGroupLayer.getLayersArray(),
      makeLayerFromImageSource(imageSource, this._maskGroupLayerColor, this._projection),
    ]))
    this._imageSources.push(imageSource)
  }

  public readonly addMaskLayerGroup = (zIndex: number, color: [number, number, number] | undefined = undefined, opacity = 100): void => {
    const newLayer = new LayerGroup({
      // XXX: zIndex on LayerGroup seems tot not affect child groups
      zIndex: this.MASK_Z_INDEX_LAYER_OFFSET,
      opacity,
    })
    if (this._maskGroupLayers[zIndex] !== undefined) {
      this.removeLayer(this._maskGroupLayers[zIndex])
    }
    this.addLayer(newLayer)
    this._maskGroupLayers[zIndex] = newLayer
    if (color === undefined) {
      color = this._defaultMaskColor[zIndex]
    }
    this._maskGroupLayerColor[zIndex] = color
  }

  public readonly addMaskSource = (maskSource: ClemexMosaicMaskSource): void => {
    if (this._maskGroupLayers[maskSource.maskIndex] === undefined) {
      this.addMaskLayerGroup(maskSource.maskIndex)
    }
    const newLayer = makeLayerFromImageSource(maskSource, this._maskGroupLayerColor, this._projection)
    this._imageSourceGroupLayer.setLayers(new Collection([
      ...this._imageSourceGroupLayer.getLayersArray(),
      newLayer,
    ]))
    this._maskSources.push(maskSource)
  }

  public readonly setMask = (mask: ClemexMosaicMask): void => {
    if (this._maskGroupLayers[mask.maskIndex] === undefined) {
      this.addMaskLayerGroup(mask.maskIndex)
    }
    const newLayer = makeLayerFromImageSource(mask, this._maskGroupLayerColor, this._projection)
    newLayer.setZIndex(mask.maskIndex + this.MASK_Z_INDEX_LAYER_OFFSET)
    this._maskGroupLayers[mask.maskIndex].setLayers(new Collection([
      ...this._maskGroupLayers[mask.maskIndex].getLayersArray(),
      newLayer,
    ]))
    this._masks[mask.maskIndex] = mask
  }

  public readonly setMasks = (masks: ClemexMosaicMask[]): void => {
    this.removeAllMasks()
    masks.forEach((mask) => {
      this.setMask(mask)
    })
  }

  public readonly setMaskColor = (zIndex: number, maskColor: Color): void => {
    this._defaultMaskColor[zIndex] = maskColor
  }

  public readonly setMaskColors = (maskColors: MaskColors): void => {
    this._defaultMaskColor = maskColors
  }

  public readonly setClassAnnotations = (features: Feature<Polygon>[]): void => {
    this._classAnnotationCollection.clear()
    this._classAnnotationCollection.extend(features)
  }

  public readonly addClassAnnotationFromSmartAnnotation = (features: Feature<Polygon>[], featureCompleted: Feature<Point>): void => {
    // Ignore any addition if requested feature is not there
    if (!this._smartAnnotationCollection.getArray().includes(featureCompleted)) {
      return
    }

    // TODO: Ensure to avoid having multiple updates as it broke the animation
    this._classAnnotations.featuresUpdateAnnotations(
      features,
    )

    this._dispatchEvent(ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED, {
      // TODO: Generate a patch correctly
      add: this._classAnnotationCollection.getArray().map((feature) => {
        return {
          id: (feature.getProperties() as ClassAnnotationProperties).classAnnotationId,
          data: feature,
        }
      }),
    })

    this._smartAnnotationInteraction.abortSmartAnnotation(featureCompleted)
  }

  public readonly addClassAnnotations = (features: Feature<Polygon>[], colorIndex: number): void => {
    this._classAnnotations.featuresUpdateAnnotations(features, colorIndex)
    this._dispatchEvent(ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED, {
      // TODO: Generate a patch correctly
      add: this._classAnnotationCollection.getArray().map((feature) => {
        return {
          id: (feature.getProperties() as ClassAnnotationProperties).classAnnotationId,
          data: feature,
        }
      }),
    })
  }

  public readonly setDetectedObjects = (features: Feature<Polygon>[]): void => {
    this._detectedObjectCollection.clear()
    this._detectedObjectCollection.extend(features)
  }

  public readonly abortSmartAnnotation = (featureAborted: Feature<Point>): void => {
    this._smartAnnotationInteraction.abortSmartAnnotation(featureAborted)
  }

  public readonly abortAllSmartAnnotation = (): void => {
    this._smartAnnotationInteraction.abortAllSmartAnnotation()
  }

  public setThicknessMeasureFeatures = (features: Feature[]): void => {
    this._thicknessMeasureCollection.clear()
    this._thicknessMeasureCollection.extend(features)
  }

  public readonly setDirectMeasureDistance = (features: Feature<LineString>[]): void => {
    this._directMeasureDistanceCollection.clear()
    this._directMeasureDistanceCollection.extend(features)
  }

  public readonly setDirectMeasureAngle = (features: Feature<LineString>[]): void => {
    this._directMeasureAngleCollection.clear()
    this._directMeasureAngleCollection.extend(features)
  }

  public readonly setDirectMeasureArea = (features: Feature<Polygon>[]): void => {
    this._directMeasureAreaCollection.clear()
    this._directMeasureAreaCollection.extend(features)
  }

  public readonly setDirectMeasureArc = (features: Feature<LineString>[]): void => {
    this._directMeasureArcCollection.clear()
    this._directMeasureArcCollection.extend(features)
  }

  public readonly setPerimeterMeasurements = (features: Feature<LineString>[]): void => {
    this._directMeasurePerimeterCollection.clear()
    this._directMeasurePerimeterCollection.extend(features)
  }

  public readonly setDirectMeasureRectangle = (features: Feature<Polygon>[]): void => {
    this._directMeasureRectangleCollection.clear()
    this._directMeasureRectangleCollection.extend(features)
  }

  public readonly setDirectMeasureEllipse = (features: Feature<Polygon>[]): void => {
    this._directMeasureEllipseCollection.clear()
    this._directMeasureEllipseCollection.extend(features)
  }

  public readonly setDirectMeasureCircle = (features: Feature<Polygon>[]): void => {
    this._directMeasureCircleCollection.clear()
    this._directMeasureCircleCollection.extend(features)
  }

  public readonly setMetadataAnnotationArrow = (features: Feature<LineString>[]): void => {
    this._metadataAnnotationArrowCollection.clear()
    this._metadataAnnotationArrowCollection.extend(features)
  }

  public readonly setMetadataAnnotationEllipse = (features: Feature<Polygon>[]): void => {
    this._metadataAnnotationEllipseCollection.clear()
    this._metadataAnnotationEllipseCollection.extend(features)
  }

  public readonly setMetadataAnnotationCircle = (features: Feature<Polygon>[]): void => {
    this._metadataAnnotationCircleCollection.clear()
    this._metadataAnnotationCircleCollection.extend(features)
  }

  public readonly setMetadataAnnotationPolygon = (features: Feature<Polygon>[]): void => {
    this._metadataAnnotationPolygonCollection.clear()
    this._metadataAnnotationPolygonCollection.extend(features)
  }

  public readonly setMetadataAnnotationText = (features: Feature<Point>[]): void => {
    this._metadataAnnotationTextCollection.clear()
    this._metadataAnnotationTextCollection.extend(features)
  }

  public readonly setMetadataAnnotationLine = (features: Feature<LineString>[]): void => {
    this._metadataAnnotationLineCollection.clear()
    this._metadataAnnotationLineCollection.extend(features)
  }

  public readonly setMetadataAnnotationRectangle = (features: Feature<Polygon>[]): void => {
    this._metadataAnnotationRectangleCollection.clear()
    this._metadataAnnotationRectangleCollection.extend(features)
  }

  public readonly setDirectMeasureDistanceStyle = (style: ShapeStyle): void => {
    this._mappedStyle.directMeasureDistance = style
    this._updateStyles()
  }

  public readonly setAngleStyle = (style: ShapeStyle): void => {
    this._mappedStyle.directMeasureAngle = style
    this._updateStyles()
  }

  public readonly setAreaMeasurementStyle = (style: ShapeStyle): void => {
    this._mappedStyle.directMeasureArea = style
    this._updateStyles()
  }

  public readonly setArcMeasurementStyle = (style: ShapeStyle): void => {
    this._mappedStyle.directMeasureArc = style
    this._updateStyles()
  }

  public readonly setPerimeterMeasurementStyle = (style: ShapeStyle): void => {
    this._mappedStyle.directMeasurePerimeter = style
    this._updateStyles()
  }

  public readonly setBoundingBoxMeasurementStyle = (style: BoundingBoxStyle): void => {
    this._mappedStyle.directMeasureRectangle = style
    this._updateStyles()
  }

  public readonly setEllipseMeasurementStyle = (style: ShapeStyle): void => {
    this._mappedStyle.directMeasureEllipse = style
    this._updateStyles()
  }

  public readonly setCircleMeasurementStyle = (style: ShapeStyle): void => {
    this._mappedStyle.directMeasureCircle = style
    this._updateStyles()
  }

  public readonly resetSources = (): void => {
    this._imageLayer.dispose()
    this._imageSourceGroupLayer.dispose()
    this._imageSourceGroupLayer.dispose()
    this.removeAllMasks()
    this._imageSources = []
  }

  public readonly removeAllMasks = (): void => {
    this._maskGroupLayers.forEach((maskGroupLayer): void => {
      maskGroupLayer.dispose()
      maskGroupLayer.setLayers(new Collection([]))
    })
    for (const maskIndex of Object.keys(this._masks)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this._masks[maskIndex as unknown as number]
    }
  }

  public readonly setImageFilterStyle = (parameters: ImageWegGLFilter): void => {
    this._imageWebGLFilter = parameters
    this._updateStyles()
  }

  public readonly setImageLayerOpacity = (opacity: number): void => {
    this._imageLayer.setOpacity(opacity)
  }

  public readonly setImageSourceLayerOpacity = (opacity: number): void => {
    this._imageSourceGroupLayer.setOpacity(opacity)
  }

  public readonly setMeasurementLayerOpacity = (opacity: number): void => {
    this._directMeasureDistanceLayer.setOpacity(opacity)
    this._directMeasureAngleLayer.setOpacity(opacity)
    this._directMeasureAreaLayer.setOpacity(opacity)
    this._directMeasurePerimeterLayer.setOpacity(opacity)
    this._directMeasureArcLayer.setOpacity(opacity)
  }

  public readonly setMaskLayerGroupOpacity = (opacity: number): void => {
    this._maskGroupLayers.forEach((maskGroupLayer): void => {
      maskGroupLayer.setOpacity(opacity)
      maskGroupLayer.getLayers().forEach((maskLayer) => {
        maskLayer.changed()
      })
    })
    try {
      // Force a render to ensure the mask is updated
      this.renderSync()
    } catch {
      // Ignore error
      // It might happen during the first render
    }
  }

  public readonly setMaskLayerOpacity = (zIndex: number, opacity: number): void => {
    this._maskGroupLayers[zIndex]?.setOpacity(opacity)
    this._maskGroupLayers[zIndex]?.getLayers().forEach((maskLayer) => {
      maskLayer.setOpacity(opacity)
      maskLayer.changed()
    })
  }

  public readonly setMaskLayerVisibility = (zIndex: number, isVisible: boolean): void => {
    this._maskGroupLayers[zIndex]?.setVisible(isVisible)
    this._maskGroupLayers[zIndex]?.getLayers().forEach((maskLayer) => {
      maskLayer.setVisible(isVisible)
    })
  }

  public readonly setAnnotationsLayerOpacity = (opacity: number): void => {
    this._mappedStyle.classAnnotation = {
      ...this._mappedStyle.classAnnotation ?? DEFAULT_CLASS_ANNOTATION_STYLE,
      opacity,
    }
    this._updateStyles()
  }

  public readonly setAnnotationsColorIndexVisibility = (colorIndex: number, visible: boolean): void => {
    const visibleAnnotationColorIndexes = {
      ...this._mappedStyle.classAnnotation?.visibleAnnotationColorIndexes ?? DEFAULT_CLASS_ANNOTATION_STYLE.visibleAnnotationColorIndexes,
      [colorIndex]: visible,
    }
    this._mappedStyle.classAnnotation = {
      ...this._mappedStyle.classAnnotation ?? DEFAULT_CLASS_ANNOTATION_STYLE,
      visibleAnnotationColorIndexes,
    }
    this._updateStyles()
  }

  public readonly getMosaicExtent = (): [number, number, number, number] | undefined => {
    const mosaicExtent: [number, number, number, number] = [+Infinity, +Infinity, -Infinity, -Infinity]

    const sources: ClemexMosaicSource[] = [...(this._image !== undefined ? [this._image] : []), ...this._imageSources, ...this._maskSources, ...Object.values(this._masks)]
    if (sources.length === 0) {
      return undefined
    }
    sources.forEach((imageSource): void => {
      const imageSourceExtent = imageSource.extent
      mosaicExtent[0] = Math.min(imageSourceExtent[0], mosaicExtent[0])
      mosaicExtent[1] = Math.min(imageSourceExtent[1], mosaicExtent[1])
      mosaicExtent[2] = Math.max(imageSourceExtent[2], mosaicExtent[2])
      mosaicExtent[3] = Math.max(imageSourceExtent[3], mosaicExtent[3])
    })
    return mosaicExtent
  }

  public readonly navigateToBBox: View['fit'] = (geometryOrExtent, fitOptions): void => {
    this.getView().fit(geometryOrExtent, fitOptions)
  }

  public readonly navigateToCoordinate = (x: number, y: number): void => {
    this.getView().setCenter([x, y])
  }

  public readonly navigateToCoordinateAndZoom = (x: number, y: number, zoomLevel: number): void => {
    this.navigateToCoordinate(x, y)
    this.setZoom(zoomLevel)
  }

  public readonly navigateToImageSource = (imageSourceId: string): void => {
    const imageSource = this._imageSources.find((imageSource) => {
      return imageSource.imageSourceId === imageSourceId
    })
    if (imageSource !== undefined) {
      this.navigateToBBox(imageSource.extent)
    } else {
      console.warn(`Could not find imageSourceId ${imageSourceId}`)
    }
  }

  public readonly navigateToAnnotationId = (annotationId: string): void => {
    const feature = this._annotationClassLayer.getSource()?.getFeatureById(annotationId)
    if (feature !== null && feature !== undefined) {
      const geom = (feature as unknown as Feature).getGeometry()
      if (geom !== undefined) {
        this.navigateToAnnotationObject(geom as SimpleGeometry)
      }
    }
  }

  public readonly navigateToAnnotationObject = (annotation: SimpleGeometry): void => {
    this.getView().fit(annotation)
  }

  public readonly zoomIn = (): void => {
    const currentZoom = this.getView().getZoom()
    if (currentZoom !== undefined) {
      this.getView().setZoom(currentZoom + Math.log2(this._zoomFactor))
    }
  }

  public readonly zoomOut = (): void => {
    const currentZoom = this.getView().getZoom()
    if (currentZoom !== undefined) {
      this.getView().setZoom(currentZoom - Math.log2(this._zoomFactor))
    }
  }

  public readonly setZoom = (mosaicZoom: number): void => {
    const mosaicExtent = this.getMosaicExtent()
    if (mosaicExtent === undefined) {
      return
    }
    const openLayerMosaicResolution = this.getView().getResolutionForExtent(mosaicExtent)
    const openLayerMosaicZoom100 = this.getView().getZoomForResolution(openLayerMosaicResolution)
    if (openLayerMosaicZoom100 !== undefined) {
      this.getView().setZoom(openLayerMosaicZoom100 * mosaicZoom / 100)
    } else {
      console.warn(`Fail to set zoom to ${mosaicZoom}`)
    }
  }

  public readonly setZoomFactor = (zoomFactor: number): void => {
    this._zoomFactor = zoomFactor
    this._brushInteraction.setZoomFactor(zoomFactor)
    this._eraserInteraction.setZoomFactor(zoomFactor)
    // XXX: OpenLayer does not provide a way to update the zoom factor of the view
    //      So we do it in a hacky way
    const view = this.getView();
    (view as unknown as { zoomFactor_: number }).zoomFactor_ = zoomFactor
    view.setProperties({ zoomFactor })
    this._updateViewConstraints()
  }

  public readonly resetView = (): void => {
    const mosaicExtent = this.getMosaicExtent()
    if (mosaicExtent === undefined) {
      return
    }
    this.getView().fit(mosaicExtent)
  }

  public readonly setMode = (mode: ClemexMosaicCanvasMode): void => {
    this._mode = mode
    this._updateInterractions()
  }

  public readonly setDrawingBrushSize = (drawingBrushSize: number): void => {
    this._drawingBrushSize = drawingBrushSize
    this._updateInterractions()
  }

  public readonly setMetadataDataPolygonToolFreehand = (isFreehand: boolean): void => {
    this._drawMetadataAnnotationPolygonInteraction.setFreehand(isFreehand)
  }

  public readonly setMetadataDataLineToolFreehand = (isFreehand: boolean): void => {
    this._drawMetadataAnnotationLineInteraction.setFreehand(isFreehand)
  }

  public readonly setDirectMeasureAreaToolFreehand = (isFreehand: boolean): void => {
    this._drawDirectMeasureAreaInteraction.setFreehand(isFreehand)
  }

  public readonly setDirectMeasurePerimeterToolFreehand = (isFreehand: boolean): void => {
    this._drawDirectMeasurePerimeterInteraction.setFreehand(isFreehand)
  }

  public readonly setPixelSize = (pixelSize: number | undefined): void => {
    this._pixelSize = pixelSize
    this._updateStyles()
    this._updateScaleControl()
  }

  public readonly setEraseOtherClassesOnOverlap = (eraseOtherClassesOnOverlap: boolean): void => {
    this._eraseOtherClassesOnOverlap = eraseOtherClassesOnOverlap
    this._updateInterractions()
  }

  public readonly setImagePixelResolution = (annotationPixelResolution: number): void => {
    this._imagePixelResolution = annotationPixelResolution
    this._updateInterractions()
  }

  public readonly setMinAnnotationArea = (minAnnotationArea: number): void => {
    this._minClassAnnotationArea = minAnnotationArea
    this._updateInterractions()
  }

  public readonly setColorIndex = (colorIndex: number): void => {
    this._colorIndex = colorIndex
    this._updateInterractions()
  }

  public readonly setAnnotationProperties = (annotationProperties: AP): void => {
    this._classAnnotationProperties = {
      ...this._classAnnotationProperties,
      ...annotationProperties,
    }
    this._updateInterractions()
  }

  public readonly setDrawMetadataAnnotationArrowDefaultArrowProperties = (defaultArrowProperties: ArrowGeometryProperties): void => {
    this._drawMetadataAnnotationArrowInteraction.setArrowProperties(defaultArrowProperties)
  }

  public readonly setMetadataAnnotationArrowProperties = (annotationId: string, arrowProperties: ArrowGeometryProperties): void => {
    const metadataAnnotation = this._metadataAnnotationArrowCollection.getArray().find((feature) => feature.getProperties().id === annotationId)
    if (metadataAnnotation !== undefined) {
      metadataAnnotation.getGeometry()?.setProperties(arrowProperties)
      metadataAnnotation.changed()
      // Publish event that the annotation changed
      this._dispatchEvent(ClemexMosaicCanvasListenersType.METADATA_ANNOTATION_CHANGED, {
        update: [{
          id: annotationId,
          data: metadataAnnotation,
        }],
      })
      this._dispatchEvent(ClemexMosaicCanvasListenersType.SELECTION_CHANGED)
    }
  }

  public readonly setDirectMeasureAndMetadataAnnotationLayerVisibility = (visible: boolean): void => {
    this._getDirectMeasureLayers().forEach((layer) => {
      layer.setVisible(visible)
    })
    this._getMetadataAnnotationLayers().forEach((layer) => {
      layer.setVisible(visible)
    })
  }

  public setPixelGridVisibility = (visible: boolean): void => {
    this._gridLayer.setVisible(visible)
  }

  public readonly setSelectionModes = (selectionModes: SelectionModes): void => {
    this._selectionModes = selectionModes
    this._updateInterractions()
  }

  private readonly getFeatureLayer = (feature: Feature): VectorLayer<VectorSource> | undefined => {
    const layers = this._getMetadataAnnotationLayers().concat(this._getDirectMeasureLayers())
    return layers.find((layer) => {
      const source = (layer).getSource()
      if (source === null && source === undefined) {
        return false
      }
      if (!(source instanceof VectorSource)) {
        return false
      }
      return source.hasFeature(feature)
    })
  }

  public readonly deleteSelection = (): void => {
    // Gather the selected features and group them by layer category
    const selectedFeatures = this._selectInteraction.getFeatures().getArray()
    const directMeasurementAnnotationsToDelete: Feature[] = []
    const metadataAnnotationsToDelete: Feature[] = []
    selectedFeatures.forEach((feature) => {
      const featureLayer = this.getFeatureLayer(feature)

      if (featureLayer === undefined) {
        return
      }
      if (this._getDirectMeasureLayers().includes(featureLayer)) {
        directMeasurementAnnotationsToDelete.push(feature)
      }
      if (this._getMetadataAnnotationLayers().includes(featureLayer)) {
        metadataAnnotationsToDelete.push(feature)
      }
      featureLayer.getSource()?.removeFeature(feature)
    })

    // Emtpty the selection
    this._selectInteraction.getFeatures().clear()

    if (directMeasurementAnnotationsToDelete.length > 0) {
      this._dispatchEvent(ClemexMosaicCanvasListenersType.DIRECT_MEASURE_CHANGED, {
        remove: directMeasurementAnnotationsToDelete.map((feature) => ({
          id: feature.getProperties().id,
          data: feature,
        })),
      })
    }
    if (metadataAnnotationsToDelete.length > 0) {
      this._dispatchEvent(ClemexMosaicCanvasListenersType.METADATA_ANNOTATION_CHANGED, {
        remove: metadataAnnotationsToDelete.map((feature) => ({
          id: feature.getProperties().id,
          data: feature,
        })),
      })
    }
  }

  public readonly selectClassAnnotationById = (classAnnotationId: string | undefined): void => {
    this._selectedClassAnnotationIds.clear()
    if (classAnnotationId !== undefined) {
      this._selectedClassAnnotationIds.add(classAnnotationId)
    }
    // To prevent OL to render ghost selection style, we call changed on the layer
    this._annotationClassLayer.changed()
  }

  public readonly selectDetectedObjectById = (detectedObjectId: string | undefined): void => {
    this._selectedDetectedObjectIds.clear()
    if (detectedObjectId !== undefined) {
      this._selectedDetectedObjectIds.add(detectedObjectId)
    }

    this._detectedObjectCollectionToRender.clear()

    this._detectedObjectCollectionToRender.extend(
      this._detectedObjectCollection.getArray()
        .filter((feature) => {
          return this._selectedDetectedObjectIds.has((feature.getProperties() as DetectedObjectProperties).id)
        }),
    )

    this._detectedObjectLayerToRender.changed()
  }

  public readonly selectDirectMeasureByIds = (directMeasureIds: string[]): void => {
    this._selectedDirectMeasureIds.clear()
    directMeasureIds.forEach((directMeasureId) => {
      this._selectedDirectMeasureIds.add(directMeasureId)
    });
    [
      this._directMeasureDistanceLayer,
      this._directMeasureAngleLayer,
      this._directMeasureAreaLayer,
      this._directMeasureArcLayer,
      this._directMeasurePerimeterLayer,
      this._directMeasureRectangleLayer,
      this._directMeasureEllipseLayer,
      this._directMeasureCircleLayer,
    ].forEach((layer) => {
      layer.changed()
    })
    this._updateInterractions()
    this._updateStyles()
  }

  public readonly selectMetadataAnnotationByIds = (metadataAnnotationIds: string[]): void => {
    this._selectedMetadataAnnotationIds.clear()
    metadataAnnotationIds.forEach((metadataAnnotationId) => {
      this._selectedMetadataAnnotationIds.add(metadataAnnotationId)
    });
    [
      this._metadataAnnotationArrowLayer,
      this._metadataAnnotationEllipseLayer,
      this._metadataAnnotationCircleLayer,
      this._metadataAnnotationPolygonLayer,
      this._metadataAnnotationTextLayer,
      this._metadataAnnotationLineLayer,
      this._metadataAnnotationRectangleLayer,
    ].forEach((layer) => {
      layer.changed()
    })
    this._updateInterractions()
    this._updateStyles()
  }

  // Access data
  // Note: we do not give access to the Collection directly to avoid unwanted modifications

  public readonly getClassAnnotationList = (): Feature<Polygon>[] => {
    return this._classAnnotationCollection.getArray()
  }

  public readonly getDirectMeasureDistanceList = (): Feature<LineString>[] => {
    return this._directMeasureDistanceCollection.getArray()
  }

  public readonly getSelection = (): Feature[] => {
    return this._selectInteraction.getFeatures().getArray()
  }

  public readonly getZoom = (): number | undefined => {
    const mosaicExtent = this.getMosaicExtent()
    if (mosaicExtent === undefined) {
      return undefined
    }
    const openLayerMosaicResolution = this.getView().getResolutionForExtent(mosaicExtent)
    const openLayerMosaicZoom100 = this.getView().getZoomForResolution(openLayerMosaicResolution)
    const currentZoom = this.getView().getZoom()
    if (openLayerMosaicZoom100 === undefined || currentZoom === undefined) {
      return undefined
    }
    return Math.pow(this._zoomFactor, currentZoom - openLayerMosaicZoom100)
  }

  public readonly getResolution = (): number | undefined => {
    return this.getView().getResolution()
  }

  public readonly getAnnotationProperties = (): AP => {
    return this._classAnnotationProperties
  }

  // Subscriptions
  public readonly addListener = <T extends ClemexMosaicCanvasListenersType>(listenerType: T, listeners: ClemexMosaicCanvasListeners[T]): (() => void) => {
    const previousListeners: ClemexMosaicCanvasListenersMap[T] | undefined = this._listeners[listenerType]
    if (previousListeners === undefined) {
      this._listeners[listenerType] = [listeners] as ClemexMosaicCanvasListenersMap[T]
    } else {
      this._listeners[listenerType] = [...previousListeners, listeners] as ClemexMosaicCanvasListenersMap[T]
    }
    return (): void => {
      if (this._listeners[listenerType] !== undefined) {
        this._listeners[listenerType] = this._listeners[listenerType]?.filter((l) => l !== listeners) as ClemexMosaicCanvasListenersMap[T]
      }
    }
  }

  public readonly setScaleBarOrigin = (origin: ScaleOrigin): void => {
    this._scaleControl.setOrigin(origin)
  }

  public readonly setScaleStyle = (style: ScaleStyle): void => {
    this._scaleControl.setStyle(style)
  }

  private readonly _dispatchEvent = <T extends ClemexMosaicCanvasListenersType>(listenerType: T, ...eventParameters: Parameters<ClemexMosaicCanvasListeners[T]>): void => {
    this._listeners[listenerType]?.forEach((listener) => {
      // Call listener with event parameters as rest parameters
      // XXX: This is a hack to make TypeScript happy, because typescript does not understand that:
      //      ClemexMosaicCanvasListeners[T] is a function with a finite number of parameters
      //      eventParameters: Parameters<ClemexMosaicCanvasListeners[T]> is infered as a list (infinite) of parameters
      //      Typescript complains that the list of parameters is not compatible with the function
      //      So we cast listener to (...args: Parameters<ClemexMosaicCanvasListeners[T]>) => void) which should be the same as ClemexMosaicCanvasListeners[T]
      (listener as (...args: Parameters<ClemexMosaicCanvasListeners[T]>) => void)(...eventParameters)
    })
  }
}
