import Style, { type StyleFunction } from 'ol/style/Style'
import { type FeatureLike } from 'ol/Feature'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import { Stroke } from 'ol/style'
import { type ShapeStyle } from './common'

export const buildThicknessMeasureStyle = (
): StyleFunction => {
  return () => {
    const styles = []
    styles.push(
      new Style({
        fill: new Fill({
          color: 'white',
        }),
      }),
    )
    return styles
  }
}

export const buildThicknessMeasureLabelStyle = (
  style: ShapeStyle,
): StyleFunction => {
  return (feature: FeatureLike) => {
    const labelText = feature.getProperties().label
    const font = `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`
    const styles = []
    styles.push(
      new Style({
        text: new Text({
          text: labelText,
          justify: 'center',
          placement: 'point',
          textAlign: 'center',
          textBaseline: 'middle',
          font,
          fill: new Fill({
            color: 'black',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
          backgroundFill: new Fill({
            color: 'rgba(255, 255, 255, 0.5)',
          }),
          backgroundStroke: new Stroke({
            color: 'black',
            width: 1,
          }),
          padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
        }),
      }),
    )
    return styles
  }
}
