import { ClemexMosaicCanvasListenersType, ClemexMosaicCanvasMode } from '@clemex/mosaic-canvas'
import { useEffect } from 'react'
import { Tool } from '@app/constants'
import { useBrushSize, useIsDirectMeasureAreaToolFreehand, useIsDirectMeasurePerimeterToolFreehand, useSelectedTool } from '@app/pages/editor-page/hooks/editor-page'
import { useEditorStore } from '@app/stores/editor'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'

const TOOL_MAPPING: Record<Tool, ClemexMosaicCanvasMode> = {
  [Tool.SELECT]: ClemexMosaicCanvasMode.SELECT,
  [Tool.BRUSH]: ClemexMosaicCanvasMode.BRUSH,
  [Tool.ERASER]: ClemexMosaicCanvasMode.ERASER,
  [Tool.PAN]: ClemexMosaicCanvasMode.PAN,
  [Tool.DIRECT_MEASURE_DISTANCE]: ClemexMosaicCanvasMode.DIRECT_MEASURE_DISTANCE,
  [Tool.DIRECT_MEASURE_ANGLE]: ClemexMosaicCanvasMode.DIRECT_MEASURE_ANGLE,
  [Tool.DIRECT_MEASURE_ELLIPSE]: ClemexMosaicCanvasMode.DIRECT_MEASURE_ELLIPSE,
  [Tool.DIRECT_MEASURE_AREA]: ClemexMosaicCanvasMode.DIRECT_MEASURE_AREA,
  [Tool.DIRECT_MEASURE_ARC]: ClemexMosaicCanvasMode.DIRECT_MEASURE_ARC,
  [Tool.DIRECT_MEASURE_PERIMETER]: ClemexMosaicCanvasMode.DIRECT_MEASURE_PERIMETER,
  [Tool.DIRECT_MEASURE_RECTANGLE]: ClemexMosaicCanvasMode.DIRECT_MEASURE_RECTANGLE,
  [Tool.METADATA_ANNOTATION_ARROW]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_ARROW,
  [Tool.METADATA_ANNOTATION_LINE]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_LINE,
  [Tool.METADATA_ANNOTATION_POLYGON]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_POLYGON,
  [Tool.METADATA_ANNOTATION_RECTANGLE]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_RECTANGLE,
  [Tool.METADATA_ANNOTATION_ELLIPSE]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_ELLIPSE,
  [Tool.METADATA_ANNOTATION_TEXT]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_TEXT,
  [Tool.SMART_ANNOTATION_ON]: ClemexMosaicCanvasMode.SMART_ANNOTATION,
}

export const useCanvasTools = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): void => {
  const [selectedTool] = useSelectedTool()
  const [brushSize] = useBrushSize()
  const { isMetadataAnnotationLineToolFreehand, isMetadataAnnotationPolygonToolFreehand } = useEditorStore((state) => {
    return {
      isMetadataAnnotationLineToolFreehand: state.isMetadataAnnotationLineToolFreehand,
      isMetadataAnnotationPolygonToolFreehand: state.isMetadataAnnotationPolygonToolFreehand,
    }
  })
  const showAnnotationClassColorIndex = useEditorStore((state) => state.showSelectedAnnotationClass)
  const [isDirectMeasureAreaToolFreehand] = useIsDirectMeasureAreaToolFreehand()
  const [isDirectMeasurePerimeterFreehand] = useIsDirectMeasurePerimeterToolFreehand()

  useEffect(() => {
    clemexMosaicCanvas.setMode(TOOL_MAPPING[selectedTool])
  }, [clemexMosaicCanvas, selectedTool])

  useEffect(() => {
    clemexMosaicCanvas.setDrawingBrushSize(brushSize)
  }, [clemexMosaicCanvas, brushSize])

  useEffect(() => {
    clemexMosaicCanvas.setMetadataDataPolygonToolFreehand(isMetadataAnnotationPolygonToolFreehand)
  }, [clemexMosaicCanvas, isMetadataAnnotationPolygonToolFreehand])

  useEffect(() => {
    clemexMosaicCanvas.setMetadataDataLineToolFreehand(isMetadataAnnotationLineToolFreehand)
  }, [clemexMosaicCanvas, isMetadataAnnotationLineToolFreehand])

  // When the annotating or erasing interaction start, change the visibility of the annotations to visible
  // - the selected class color should be visible
  useEffect(() => {
    const onInteractionStart = (): void => {
      showAnnotationClassColorIndex()
    }
    const deleteAnnotatingStartListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.START_ANNOTATING, onInteractionStart)
    const deleteErasingStartListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.START_ERASING, onInteractionStart)
    return () => {
      deleteAnnotatingStartListenerCB()
      deleteErasingStartListenerCB()
    }
  }, [clemexMosaicCanvas, showAnnotationClassColorIndex])

  useEffect(() => {
    clemexMosaicCanvas.setDirectMeasureAreaToolFreehand(isDirectMeasureAreaToolFreehand)
  }, [clemexMosaicCanvas, isDirectMeasureAreaToolFreehand])

  useEffect(() => {
    clemexMosaicCanvas.setDirectMeasurePerimeterToolFreehand(isDirectMeasurePerimeterFreehand)
  }, [clemexMosaicCanvas, isDirectMeasurePerimeterFreehand])
}
