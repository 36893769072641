import type * as React from 'react'
import { Button, Tooltip } from 'antd'
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl'
import styles from './styles/download-button.module.scss'

const useMailToString = (userName: string): string => {
  const intl = useIntl()
  return 'mailto:info@clemex.com?subject=' + intl.formatMessage({
    id: 'download_plugin.mail.template.subject',
    defaultMessage: 'Request {site} access to the plugin download feature for {user}',
  },
  {
    user: userName,
    site: 'clemex.ai',
  },
  )
}

const mailToStringForContactingSupportInCaseOfPluginBuildTrouble = (userName: string, intl: IntlShape): string => {
  const mailSubject = intl.formatMessage({
    id: 'download_plugin.error.mail.template.subject',
    defaultMessage: 'I need some help [User name {userName}]',
  }, { userName })
  const mailBody = intl.formatMessage({
    id: 'download_plugin.error.mail.template.body',
    defaultMessage: 'Could you please help me troubleshoot my issue?',
  })
  return `mailto:info@clemex.com?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`
}

interface DownloadButtonProps {
  onDownload: () => void
  isDisabled: boolean
  isNotTrainedYet: boolean
  algorithmIsNotAvailable: boolean
  userName: string
}

export const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  const { onDownload, isDisabled, userName, isNotTrainedYet, algorithmIsNotAvailable } = props

  const intl = useIntl()

  const emailHRef = useMailToString(userName)

  if (isDisabled) {
    return <Tooltip
      title={
        <>
          <FormattedMessage id='download_plugin.notification.download.info' defaultMessage='Please contact clemex sales to unlock this feature'/>
          <br/>
          <a href={emailHRef}>info@clemex.com</a>
          <br/>
        1 450 651 6573
          <br/>
        1 888 651 6573
        </>
      }
    >
      <div className={styles.downloadButton} id='download-button'>
        <Button
          type="default"
          size='large'
          block
          disabled={true}
        >
          <div className={styles.buttonText}>
            <FormattedMessage id="download_plugin.title" defaultMessage={'Download Plugin'} />
          </div>
        </Button>
      </div>
    </Tooltip>
  } else if (isNotTrainedYet) {
    return <Tooltip
      title={
        <FormattedMessage id='download_plugin.tooltip.isNotAvailable.message' defaultMessage='Please train your project before downloading the plugin.'/>
      }
    >
      <div className={styles.downloadButton} id='download-button'>
        <Button
          type="default"
          size='large'
          block
          disabled={true}
        >
          <div className={styles.buttonText}>
            &nbsp;<FormattedMessage id="download_plugin.isNotAvailable.title" defaultMessage={'Download Plugin'} />
          </div>
        </Button>
      </div>
    </Tooltip>
  } else if (algorithmIsNotAvailable) {
    return <Tooltip
      title={
        <>
          <FormattedMessage id='download_plugin.tooltip.algorithmIsNotAvailable.description1' defaultMessage='The selected algorithm is not available as a plugin yet.'/>
          <br />
          <FormattedMessage id='download_plugin.tooltip.algorithmIsNotAvailable.description2' defaultMessage='Please contact us if you have any questions:'/>
          <br />
          <br />
          <a href={mailToStringForContactingSupportInCaseOfPluginBuildTrouble(userName, intl)}>info@clemex.com</a>
        </>
      }
    >
      <div className={styles.downloadButton} id='download-button'>
        <Button
          type="default"
          size='large'
          block
          disabled={true}
          loading={false}
        >
          <div className={styles.buttonText}>
              &nbsp;<FormattedMessage id="download_plugin.isNotAvailable.title" defaultMessage={'Download Plugin'} />
          </div>
        </Button>
      </div>
    </Tooltip>
  } else {
    return <div className={styles.downloadButton} id='download-button'><Button
      type="primary"
      size='large'
      block
      onClick={onDownload}
      disabled={false}
    >
      <div className={styles.buttonText}>
        <FormattedMessage id="download_plugin.title" defaultMessage={'Download Plugin'} />
      </div>
    </Button></div>
  }
}
