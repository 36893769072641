import { CLEMEX_STUDIO_CANVAS_ZOOM_FACTOR, type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import { ClemexMosaicCanvasListenersType } from '@clemex/mosaic-canvas'
import { useEffect } from 'react'
import { EVENTS_ID } from '@app/constants'
import { useStageStore } from '@app/stores/stage'
import { useUserSettingsCanvas } from '@app/api/hooks'

export const useCanvasZoom = (clemexMosaicCanvas: ClemexStudioMosaicCanvas): void => {
  const { data: userSettingsCanvas } = useUserSettingsCanvas()

  const setZoom = useStageStore((state) => state.setZoom)
  const setResolution = useStageStore((state) => state.setResolution)

  // Bind bi-derectional zoom behavior
  useEffect(() => {
    const zoomToFit = (): void => {
      clemexMosaicCanvas.resetView()
    }
    const zoomIn = (): void => {
      clemexMosaicCanvas.zoomIn()
    }
    const zoomOut = (): void => {
      clemexMosaicCanvas.zoomOut()
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.ZOOM_CHANGED, ({ zoom, resolution }) => {
      setZoom(zoom)
      setResolution(resolution)
    })
    window.addEventListener(EVENTS_ID.ZOOM_TO_FIT, zoomToFit)
    window.addEventListener(EVENTS_ID.ZOOM_IN, zoomIn)
    window.addEventListener(EVENTS_ID.ZOOM_OUT, zoomOut)
    return () => {
      deleteListenerCB()
      window.removeEventListener(EVENTS_ID.ZOOM_TO_FIT, zoomToFit)
      window.removeEventListener(EVENTS_ID.ZOOM_IN, zoomIn)
      window.removeEventListener(EVENTS_ID.ZOOM_OUT, zoomOut)
    }
  }, [clemexMosaicCanvas, setResolution, setZoom])

  useEffect(() => {
    clemexMosaicCanvas.setZoomFactor(userSettingsCanvas?.zoomFactor ?? CLEMEX_STUDIO_CANVAS_ZOOM_FACTOR)
  }, [clemexMosaicCanvas, userSettingsCanvas?.zoomFactor])
}
