import type * as React from 'react'
import styles from './styles/rate-user-experience.module.scss'
import { Popover } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

interface ContactSalesProps {
  isVisible: boolean
  children: React.ReactNode
}
export const ContactSales: React.FC<ContactSalesProps> = ({ isVisible, children }) => {
  const intl = useIntl()

  const mailToContactSales = (): string => {
    const mailSubject = intl.formatMessage({
      id: 'contact_sales.mail.template.subject',
      defaultMessage: 'Request access to a plugin download on Clemex Studio',
    })
    const mailBody = intl.formatMessage({
      id: 'contact_sales.mail.template.body',
      defaultMessage: "Hi,\n\nI'd like to activate the plugin download to the following project: \n{url}\n\nCan you assist me with that? ",
    }, { url: window.location.href })
    return `mailto:clemex-sales@clemex.com?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`
  }

  return <Popover
    open={isVisible}
    content={
      <div className={styles.title}>
        <FormattedMessage id="contact-sales.content" defaultMessage="To enable it, please contact the sales: "/>
        <a href={mailToContactSales()}>&nbsp;clemex-sales@clemex.com</a>
      </div>
    }
    title={
      <div className={styles.title}>
        <FormattedMessage id="contact-sales.title" defaultMessage="Downloading a plugin is a premium feature"/>
      </div>
    }
  >
    {children}
  </Popover>
}
