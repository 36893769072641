import { ClassAnnotationObjectOverview } from '@app/pages/editor-page/object-overview/class-annotation-object-overview'
import { DetectedObjectOverview } from '@app/pages/editor-page/object-overview/detected-object-overview'
import { useDataBrowserStore } from '@app/stores/databrowser'
import { useEditorStore } from '@app/stores/editor'
import { DraggableModal } from '@components/modals/draggable-modal'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const ObjectOverviewModal: FC = () => {
  const { isOpen: dataBrowserIsOpen } = useDataBrowserStore((state) => ({
    toggleOpenClose: state.toggleOpenClose,
    isOpen: state.isOpen,
  }))

  const [
    classAnnotationSelectedId,
    setClassAnnotationSelectedIds,
    detectedObjectSelectedId,
    setDetectedObjectSelectedIds,
  ] = useEditorStore((state) => [
    state.getClassAnnotationSelectedId(),
    state.setClassAnnotationSelectedIds,
    state.getDetectedObjectSelectedId(),
    state.setDetectedObjectSelectedIds,
  ])

  const isObjectOverviewOpen = (classAnnotationSelectedId !== undefined || detectedObjectSelectedId !== undefined) && !dataBrowserIsOpen

  const onCloseObjectOverview = (): void => {
    setClassAnnotationSelectedIds({})
    setDetectedObjectSelectedIds({})
  }

  return <DraggableModal
    isOpen={isObjectOverviewOpen}
    onClose={onCloseObjectOverview}
    className='!w-[300px]'
    title={
      <FormattedMessage
        id="object-overview-modal.title"
        defaultMessage="Object Overview"
      />
    }
  >
    {
      classAnnotationSelectedId !== undefined
        ? <ClassAnnotationObjectOverview />
        : detectedObjectSelectedId !== undefined && <DetectedObjectOverview />
    }
  </DraggableModal>
}
