/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProjectDatasetContext = {
    Validation: 'validation',
    Training: 'training',
    Deleted: 'deleted',
    Uploaded: 'uploaded'
} as const;
export type ProjectDatasetContext = typeof ProjectDatasetContext[keyof typeof ProjectDatasetContext];


export function ProjectDatasetContextFromJSON(json: any): ProjectDatasetContext {
    return ProjectDatasetContextFromJSONTyped(json, false);
}

export function ProjectDatasetContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectDatasetContext {
    return json as ProjectDatasetContext;
}

export function ProjectDatasetContextToJSON(value?: ProjectDatasetContext | null): any {
    return value as any;
}

