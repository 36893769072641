/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Polygon } from './Polygon';
import {
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
} from './Polygon';

/**
 * 
 * @export
 * @interface ClassAnnotationGeoJSON
 */
export interface ClassAnnotationGeoJSON {
    /**
     * 
     * @type {Date}
     * @memberof ClassAnnotationGeoJSON
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassAnnotationGeoJSON
     */
    modificationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationGeoJSON
     */
    classAnnotationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationGeoJSON
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ClassAnnotationGeoJSON
     */
    imageId: string;
    /**
     * 
     * @type {number}
     * @memberof ClassAnnotationGeoJSON
     */
    colorIndex: number;
    /**
     * 
     * @type {Polygon}
     * @memberof ClassAnnotationGeoJSON
     */
    geometry: Polygon;
}

/**
 * Check if a given object implements the ClassAnnotationGeoJSON interface.
 */
export function instanceOfClassAnnotationGeoJSON(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "imageId" in value;
    isInstance = isInstance && "colorIndex" in value;
    isInstance = isInstance && "geometry" in value;

    return isInstance;
}

export function ClassAnnotationGeoJSONFromJSON(json: any): ClassAnnotationGeoJSON {
    return ClassAnnotationGeoJSONFromJSONTyped(json, false);
}

export function ClassAnnotationGeoJSONFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassAnnotationGeoJSON {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationDate': !exists(json, 'creation_date') ? undefined : (new Date(json['creation_date'])),
        'modificationDate': !exists(json, 'modification_date') ? undefined : (new Date(json['modification_date'])),
        'classAnnotationId': !exists(json, 'class_annotation_id') ? undefined : json['class_annotation_id'],
        'projectId': json['project_id'],
        'imageId': json['image_id'],
        'colorIndex': json['color_index'],
        'geometry': PolygonFromJSON(json['geometry']),
    };
}

export function ClassAnnotationGeoJSONToJSON(value?: ClassAnnotationGeoJSON | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creation_date': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modification_date': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'class_annotation_id': value.classAnnotationId,
        'project_id': value.projectId,
        'image_id': value.imageId,
        'color_index': value.colorIndex,
        'geometry': PolygonToJSON(value.geometry),
    };
}

