/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bbox } from './Bbox';
import {
    BboxFromJSON,
    BboxFromJSONTyped,
    BboxToJSON,
} from './Bbox';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';
import type { LineString } from './LineString';
import {
    LineStringFromJSON,
    LineStringFromJSONTyped,
    LineStringToJSON,
} from './LineString';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';
import type { Polygon } from './Polygon';
import {
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
} from './Polygon';

/**
 * 
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * 
     * @type {Bbox}
     * @memberof Geometry
     */
    bbox?: Bbox | null;
    /**
     * 
     * @type {string}
     * @memberof Geometry
     */
    type: GeometryTypeEnum;
    /**
     * 
     * @type {Coordinates}
     * @memberof Geometry
     */
    coordinates: Coordinates;
}


/**
 * @export
 */
export const GeometryTypeEnum = {
    Polygon: 'Polygon',
    LineString: 'LineString',
    Point: 'Point'
} as const;
export type GeometryTypeEnum = typeof GeometryTypeEnum[keyof typeof GeometryTypeEnum];


/**
 * Check if a given object implements the Geometry interface.
 */
export function instanceOfGeometry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "coordinates" in value;

    return isInstance;
}

export function GeometryFromJSON(json: any): Geometry {
    return GeometryFromJSONTyped(json, false);
}

export function GeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Geometry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bbox': !exists(json, 'bbox') ? undefined : BboxFromJSON(json['bbox']),
        'type': json['type'],
        'coordinates': CoordinatesFromJSON(json['coordinates']),
    };
}

export function GeometryToJSON(value?: Geometry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bbox': BboxToJSON(value.bbox),
        'type': value.type,
        'coordinates': CoordinatesToJSON(value.coordinates),
    };
}

