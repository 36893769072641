import { EVENTS_ID } from '@app/constants'
import { type Polygon } from 'geojson'

export const EVENT_DISPATCHER = {
  dispatchEditorCanvasFocusObject: (
    imageId: string,
    bbox: [number, number, number, number],
  ) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.EDITOR_CANVAS_FOCUS_OBJECT, {
        detail: {
          imageId,
          bbox,
        },
      }),
    )
  },
  dispatchProjectReseted: () => {
    window.dispatchEvent(new CustomEvent(EVENTS_ID.PROJECT_RESET_COMPLETED))
  },
  dispatchEditorDataBrowserClassAnnotationFocusRow: (
    classAnnotationId: string,
  ) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.EDITOR_DATABROWSER_CLASS_ANNOTATION_FOCUS_ROW, {
        detail: {
          classAnnotationId,
        },
      },
    ))
  },
  dispatchEditorDataBrowserDetectedObjectFocusRow: (
    detectedObjectId: string,
  ) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.EDITOR_DATABROWSER_DETECTED_OBJECT_FOCUS_ROW, {
        detail: {
          detectedObjectId,
        },
      },
    ))
  },
  dispatchEditorDataBrowserDirectMeasureFocusRow: (
    directMeasureId: string,
  ) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.EDITOR_DATABROWSER_DIRECT_MEASURE_FOCUS_ROW, {
        detail: {
          directMeasureId,
        },
      },
    ))
  },
  dispatchEditorDataBrowserMetadataAnnotationFocusRow: (
    metadataAnnotationId: string,
  ) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.EDITOR_DATABROWSER_METADATA_ANNOTATION_FOCUS_ROW, {
        detail: {
          metadataAnnotationId,
        },
      },
    ))
  },
  dispatchAddAnnotationToCanvas: ({ geometry, colorIndex }: {
    geometry: Polygon
    colorIndex: number
  }) => {
    window.dispatchEvent(new CustomEvent(
      EVENTS_ID.CANVAS_ADD_ANNOTATION, {
        detail: {
          geometry,
          colorIndex,
        },
      },
    ))
  },
} as const
