import { Flex, Button, Slider } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/editor-settings.module.scss'
import { useUserSettingsCanvas } from '@app/api/hooks'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { SettingRow, SettingsSection } from '@components/modals/project-settings/settings'

export const EditorSettings: React.FC = () => {
  const { data: userSettingsCanvas, isLoading, updateUserSettingsCanvas, resetUserSettingsCanvas } = useUserSettingsCanvas()
  const [localZoomFactor, setLocalZoomFactor] = useState<number>(1.2)
  const onChangeZoomFactor = useCallback((zoomFactor: number | null): void => {
    if (zoomFactor === null) {
      return
    }
    void updateUserSettingsCanvas({ zoomFactor })
  }, [updateUserSettingsCanvas])

  useEffect(() => {
    if (userSettingsCanvas?.zoomFactor === undefined) {
      return
    }
    setLocalZoomFactor(userSettingsCanvas?.zoomFactor)
  }, [userSettingsCanvas])

  if (isLoading || userSettingsCanvas === undefined) {
    return <FormattedMessage id="global-settings.modal.tab.editor.loading" defaultMessage={'Loading...'} />
  }

  return (
    <Flex vertical className={styles.root}>
      <SettingsSection
        title={
          <FormattedMessage id="global-settings.modal.section.editor.section.canvas" defaultMessage={'Canvas'} />
        }
      >
        <SettingRow
          title={
            <FormattedMessage id="global-settings.modal.section.editor.canvas.zoom-factor" defaultMessage={'Zoom Speed'} />
          }
        >
          <FormattedMessage id="global-settings.modal.section.editor.canvas.zoom-factor.slider.min" defaultMessage={'Slow'} />
          <Slider
            className={styles.zoomFactorSlider}
            min={1.01}
            max={1.5}
            step={0.01}
            value={localZoomFactor}
            onChange={setLocalZoomFactor}
            onChangeComplete={onChangeZoomFactor}
          />
          <FormattedMessage id="global-settings.modal.section.editor.canvas.zoom-factor.slider.max" defaultMessage={'Fast'} />
        </SettingRow>
      </SettingsSection>
      <Button type='primary' onClick={resetUserSettingsCanvas} className={styles.resetSettings}>
        <FormattedMessage id="global-settings.modal.section.editor.canvas.reset" defaultMessage={'Reset to default canvas settings'} />
      </Button>
    </Flex>
  )
}
