import { ConfigProvider } from 'antd'

export const AntDConfigProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <ConfigProvider
    theme={
      {
        token: {
          colorPrimary: '#3f3f3f',
          colorInfo: '#3f3f3f',
          colorSuccess: '#27a234',
          colorLink: '#009ee2',
          colorWarning: '#e1b43a',
          fontWeightStrong: 500,
          colorError: '#B92525',
          colorPrimaryBorder: '#D9D9D9',
          borderRadius: 8,
          fontFamily: 'Rubik, sans-serif',
          fontFamilyCode: 'Rubik, sans-serif',
          borderRadiusLG: 8,
          borderRadiusSM: 8,
        },
        components: {
          Button: {
            controlHeight: 33,
            controlHeightLG: 33,
            controlHeightSM: 33,
            primaryShadow: '0 0 0',
          },
          Collapse: {
            headerPadding: '8px',
          },
          Input: {
            controlHeight: 35,
            controlHeightLG: 35,
            controlHeightSM: 35,
            colorBgContainer: '#E6E4E3',
            colorTextPlaceholder: '#595958',
          },
          Layout: {
            headerBg: '#FFFFFF',
            headerPadding: '0',
          },
          List: {
            colorBgContainer: '#D9D8D71E',
          },
          Menu: {
            itemActiveBg: '#595958',
            colorFillAlter: '#FFFFFF',
            itemColor: '#595958',
          },
          Progress: {
            defaultColor: '#595958',
            colorSuccess: '#595958',
          },
          Select: {
            selectorBg: '#E6E4E3',
            optionSelectedFontWeight: 500,
            optionSelectedBg: '#D9D8D7',
          },
          Slider: {
            handleColor: '#595958',
            handleActiveColor: '#707070',
            dotBorderColor: '#595958',
            dotActiveBorderColor: '#333332',
            railBg: '#D9D8D7',
            railHoverBg: '#B3B2B1',
            trackBg: '#595958',
            trackHoverBg: '#333332',
          },
          Switch: {
            colorPrimary: '#595958',
            handleBg: '#FFFFFF',
          },
          Table: {
            colorBgBase: '#D9D8D71E',
          },
          Tabs: {
            inkBarColor: '#595958',
            itemSelectedColor: '#595958',
          },
          Typography: {
            titleMarginBottom: '0',
            titleMarginTop: '0',
          },
        },
      }}
  >
    {children}
  </ConfigProvider>
)
