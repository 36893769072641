import { type DirectMeasureInput, DirectMeasureType, type GeometryTypeEnum, type MetadataAnnotationType } from '@app/api/openapi'
import { Feature } from 'ol'
import { LineString, Polygon, Point } from 'ol/geom'
import { type Polygon as GeoJSONPolygon, type Point as GeoJSONPoint, type LineString as GeoJSONLineString, type Feature as GeoJSONFeature } from 'geojson'
import { swapLineStringCoordinates, swapPointCoordinates, swapPolygonCoordinates } from '@openlayer/helper'
import { type ChangePatch, type CircleGeometryProperties, ClemexMosaicCanvasListenersType, type EllipseGeometryProperties, FEATURE_TYPE } from '@clemex/mosaic-canvas'
import { useDirectMeasure } from '@app/api/direct-measure'
import { useEffect, useState } from 'react'
import { EVENTS_ID } from '@app/constants'
import { useDebouncedCallback } from 'use-debounce'
import { groupBy } from 'lodash'
import { type ClemexStudioMosaicCanvas } from '@app/pages/editor-page/canvas/hooks/clemex-mosaic-canvas-context'
import type GeoJSON from 'ol/format/GeoJSON'
import { useProjectId, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'

interface StudioDirectMeasureFeatureProperties<T extends MetadataAnnotationType = MetadataAnnotationType> {
  [FEATURE_TYPE]: T
  id: string
}

const directMeasureFeatureToGeoJSON = (feature: Feature, geojsonParser: GeoJSON): Omit<DirectMeasureInput, 'projectId' | 'imageId'> => {
  const geoJSONFeature = geojsonParser.writeFeatureObject(feature) as unknown as GeoJSONFeature<GeoJSONLineString | GeoJSONPolygon | GeoJSONPoint, StudioDirectMeasureFeatureProperties>
  let coordinates = geoJSONFeature.geometry.coordinates
  const type = geoJSONFeature.geometry.type
  switch (type) {
    case 'Point':
      coordinates = swapPointCoordinates(coordinates as number[])
      break
    case 'LineString':
      coordinates = swapLineStringCoordinates(coordinates as number[][])
      break
    case 'Polygon':
      coordinates = swapPolygonCoordinates(coordinates as number[][][])
      break
    default:
      throw new Error(`Unknown type: ${type as unknown as string}`)
  }
  return {
    type: geoJSONFeature.properties[FEATURE_TYPE] as DirectMeasureType,
    directMeasureId: geoJSONFeature.properties.id,
    geometry: {
      type: type as GeometryTypeEnum,
      coordinates,
    },
    geometryProperties: feature.getGeometry()?.getProperties() as CircleGeometryProperties & EllipseGeometryProperties,
  }
}

const directMeasureGeoJSONToFeature = (annotation: DirectMeasureInput): Feature<LineString | Polygon | Point> => {
  const properties = {
    [FEATURE_TYPE]: annotation.type,
    id: annotation.directMeasureId,
  }
  let geometry: LineString | Polygon | Point
  switch (annotation.geometry.type) {
    case 'Point':
      geometry = new Point(swapPointCoordinates(annotation.geometry.coordinates as number[]))
      break
    case 'LineString':
      geometry = new LineString(swapLineStringCoordinates(annotation.geometry.coordinates as number[][]))
      break
    case 'Polygon':
      geometry = new Polygon(swapPolygonCoordinates(annotation.geometry.coordinates as number[][][]))
      break
    default:
      throw new Error(`Unknown type: ${annotation.geometry.type as unknown as string}`)
  }
  if (annotation.geometryProperties != null) {
    geometry.setProperties(annotation.geometryProperties)
  }
  return new Feature({
    geometry,
    ...properties,
  })
}

export const useCanvasDirectMeasures = (clemexMosaicCanvas: ClemexStudioMosaicCanvas, geojsonParser: GeoJSON): void => {
  const projectId = useProjectId()
  const selectedImageId = useSelectedImageId()
  const directMeasure = useDirectMeasure(projectId, selectedImageId)

  const [loadedDirectMeasureId, setLoadedDirectMeasureId] = useState<string | undefined>(undefined)

  // When the image changes, we reset direct measures.
  useEffect(() => {
    clemexMosaicCanvas.setDirectMeasureDistance([])
    clemexMosaicCanvas.setDirectMeasureAngle([])
    clemexMosaicCanvas.setDirectMeasureArea([])
    clemexMosaicCanvas.setPerimeterMeasurements([])
    clemexMosaicCanvas.setDirectMeasureArc([])
    clemexMosaicCanvas.setDirectMeasureRectangle([])
  }, [clemexMosaicCanvas, selectedImageId])

  useEffect(() => {
    const onProjectResetCompleted = (): void => {
      setLoadedDirectMeasureId(undefined)
    }
    window.addEventListener(EVENTS_ID.PROJECT_RESET_COMPLETED, onProjectResetCompleted, {})
    return () => {
      window.removeEventListener(EVENTS_ID.PROJECT_RESET_COMPLETED, onProjectResetCompleted, {})
    }
  })

  // When the direct measure are loaded.
  // Load them inside the ClemexMosaicCanvas.
  useEffect(() => {
    if (clemexMosaicCanvas === null || directMeasure.data === undefined) {
      return
    }
    const loadId = `${projectId}-${selectedImageId}`
    if (loadedDirectMeasureId !== loadId && !directMeasure.isValidating) {
      const typeToSetter = {
        [DirectMeasureType.Distance]: clemexMosaicCanvas.setDirectMeasureDistance as (annotations: Feature[]) => void,
        [DirectMeasureType.Angle]: clemexMosaicCanvas.setDirectMeasureAngle as (annotations: Feature[]) => void,
        [DirectMeasureType.Area]: clemexMosaicCanvas.setDirectMeasureArea as (annotations: Feature[]) => void,
        [DirectMeasureType.Perimeter]: clemexMosaicCanvas.setPerimeterMeasurements as (annotations: Feature[]) => void,
        [DirectMeasureType.Arc]: clemexMosaicCanvas.setDirectMeasureArc as (annotations: Feature[]) => void,
        [DirectMeasureType.Rectangle]: clemexMosaicCanvas.setDirectMeasureRectangle as (annotations: Feature[]) => void,
        [DirectMeasureType.Ellipse]: clemexMosaicCanvas.setDirectMeasureEllipse as (annotations: Feature[]) => void,
        [DirectMeasureType.Circle]: clemexMosaicCanvas.setDirectMeasureCircle as (annotations: Feature[]) => void,
      } as const
      const directMeasureGroupByType = {
        [DirectMeasureType.Distance]: [],
        [DirectMeasureType.Angle]: [],
        [DirectMeasureType.Area]: [],
        [DirectMeasureType.Perimeter]: [],
        [DirectMeasureType.Arc]: [],
        [DirectMeasureType.Rectangle]: [],
        [DirectMeasureType.Ellipse]: [],
        [DirectMeasureType.Circle]: [],
        ...groupBy(directMeasure.data, (annotation) => annotation.type),
      }
      Object.entries(directMeasureGroupByType).forEach(([type, annotations]) => {
        if (typeToSetter[type as DirectMeasureType] === undefined) {
          console.error(`Unknown direct measure type: ${type}`)
        } else {
          typeToSetter[type as DirectMeasureType](annotations.map((annotation) => directMeasureGeoJSONToFeature(annotation)))
        }
      })
      setLoadedDirectMeasureId(loadId)
    }
  }, [clemexMosaicCanvas, directMeasure.data, directMeasure.isValidating, loadedDirectMeasureId, projectId, selectedImageId])

  const debouncedSaveDirectMeasurePatches = useDebouncedCallback(directMeasure.savePatches, 300)
  // When the direct measure change, save them.
  useEffect(() => {
    const onDirectMeasureChange = (directMeasureChangePatch: ChangePatch<Feature>): void => {
      if (selectedImageId === undefined || projectId === undefined) {
        return
      }
      directMeasure.patch({
        add: directMeasureChangePatch.add?.map((addPatch): DirectMeasureInput => {
          return { ...directMeasureFeatureToGeoJSON(addPatch.data, geojsonParser), projectId, imageId: selectedImageId }
        }),
        remove: directMeasureChangePatch.remove?.map((removePatch): DirectMeasureInput => {
          return { ...directMeasureFeatureToGeoJSON(removePatch.data, geojsonParser), projectId, imageId: selectedImageId }
        }),
        update: directMeasureChangePatch.update?.map((updatePatch): DirectMeasureInput => {
          return { ...directMeasureFeatureToGeoJSON(updatePatch.data, geojsonParser), projectId, imageId: selectedImageId }
        }),
      })
      void debouncedSaveDirectMeasurePatches()
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.DIRECT_MEASURE_CHANGED, onDirectMeasureChange)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, projectId, selectedImageId, directMeasure, debouncedSaveDirectMeasurePatches, geojsonParser])

  // When the selectedImageId change, force saving the direct measure for the previous image.
  useEffect(() => {
    if (selectedImageId === undefined) {
      return
    }
    return () => {
      void debouncedSaveDirectMeasurePatches.flush()
    }
  }, [selectedImageId, debouncedSaveDirectMeasurePatches])
}
