import * as React from 'react'
import styles from '../styles/editor-page.module.scss'
import { RFPage, RFHeader, RFContent } from '@components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import {
  useFeatureFlags,
  useProjectClasses,
  useProjectImages,
} from '@app/api/hooks'
import { WorkflowSteps } from '@components/workflow-steps'
import { WebUIRoutes } from '@app/routes'
import { EditorLeftPanel } from '@app/pages/editor-page/left-panel'
import {
  KeyboardShortcutsModal,
  useListenToKeyboardBindings,
} from '@components/modals/keyboard-shortcuts-modal'
import { CreateProjectModal } from '@components/modals/create-project-modal'
import { EVENTS_ID, ONBOARDING_TOUR_CONTEXT, TUTORIAL_ENABLED } from '@app/constants'
import { EditorRightPanel } from '@app/pages/editor-page/right-panel'
import { ProjectDatasetContext } from '@app/api/openapi'
import { useImageNavigationEventHandler } from '@app/pages/editor-page/hooks/event-handlers/image-navigation'
import { NotAuthorized } from '@app/pages/not-authorized'
import { ErrorPage } from '@app/pages/error-page'
import { ClemexMosaicCanvasViewer } from '@app/pages/editor-page/canvas/clemex-mosaic-canvas'
import { UploadFirstImagesOfProject } from '@app/pages/editor-page/upload-first-images'
import { useBindExportProjectShortcut } from '@app/pages/editor-page/hooks/editor-shortcut'
import { MenuContext } from '@components/layout/navigation'
import { EditorStatusBar } from '@app/pages/editor-page/status-bar'
import { ProjectSettingsModal, ProjectSettingsPageContext } from '@components/modals/project-settings/project-settings-modal'
import { AnnotationEditorTour } from '@components/tutorial/annotation-editor-tour'
import { ValidationTour } from '@components/tutorial/validation-editor-tour'
import { GlobalSettingsModal } from '@components/modals/global-settings/global-settings-modal'
import { ProjectSharingModal } from '@components/modals/project-sharing-modal'
import * as RFAPI from '@app/api/api'
import { notification } from 'antd'
import { useProjectTrainingErrorNotitificationMonitoring, useRevalidateResourceWhenProjectStatusChange } from '@app/pages/editor-page/hooks/project-training-snapshot'
import { PanelBar } from '@app/pages/editor-page/panel-bar/panel-bar'
import { DataBrowserTable } from '@app/pages/editor-page/databrowser/databrowser-table'
import { useDataBrowserStore } from '@app/stores/databrowser'
import { useEditorStore } from '@app/stores/editor'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@shadcn-ui/components/ui/resizable'
import { useImageFilterStore } from '@app/stores/image-filter'
import { ObjectOverviewModal } from '@app/pages/editor-page/object-overview/object-overview-modal'
import { useLocation, useNavigate } from 'react-router'
import { useCurrentProject, useDatasetContext, useProjectId, useProjectSlug, useSelectedImageId, useSetDatasetContext, useSetSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'
import { useSearchParams } from 'react-router-dom'

const useBindProjectSlugChangeEvent = (): void => {
  const navigate = useNavigate()
  const datasetContext = useDatasetContext()

  React.useEffect(() => {
    const onProjectSlugChange = (event: Event): void => {
      const customEvent = event as CustomEvent<{ slug: string }>
      const route = datasetContext === ProjectDatasetContext.Training
        ? WebUIRoutes.annotateProject(customEvent.detail.slug)
        : WebUIRoutes.validateProject(customEvent.detail.slug)
      navigate(route.path, { replace: true })
    }
    window.addEventListener(EVENTS_ID.PROJECT_SLUG_CHANGED, onProjectSlugChange)
    return () => {
      window.removeEventListener(EVENTS_ID.PROJECT_SLUG_CHANGED, onProjectSlugChange)
    }
  }, [datasetContext, navigate])
}

const useInitializeSelectedImage = (): void => {
  const [searchParams] = useSearchParams()
  const imageSlugFromURL = searchParams.get('image') ?? undefined
  const projectId = useProjectId()
  const datasetContext = useDatasetContext()
  const selectedImageId = useSelectedImageId()
  const setSelectedImageId = useSetSelectedImageId()
  const { data: images, isLoading: isImagesLoading, isValidating: isImagesValidating } = useProjectImages(projectId, datasetContext)

  // if the selected image id is undefined
  // or if the id is not part of the images list
  // then we need to select another image
  const shouldSelectAnotherImage = React.useMemo(() => {
    return selectedImageId === undefined || images?.find((image) => image.id === selectedImageId) === undefined
  }, [images, selectedImageId])

  React.useEffect(() => {
    if (!shouldSelectAnotherImage) {
      return
    }
    if (images === undefined || images.length === 0) {
      return
    }
    if (imageSlugFromURL !== null) {
      const image = images.find((image) => image.slug === imageSlugFromURL)
      if (image !== undefined) {
        setSelectedImageId(image.id)
      } else {
        // The image slug is unknown, we select the first image
        setSelectedImageId(images[0]?.id ?? undefined)
      }
    } else {
      // The image slug is not set, we select the first image
      setSelectedImageId(images[0]?.id ?? undefined)
    }
  }, [images, setSelectedImageId, isImagesLoading, isImagesValidating, imageSlugFromURL, shouldSelectAnotherImage])
}

const useUpdateSelectedImageSlug = (): void => {
  const selectedImageId = useSelectedImageId()
  const projectId = useProjectId()
  const datasetContext = useDatasetContext()
  const { data: images, isLoading: isImagesLoading, isValidating: isImagesValidating } = useProjectImages(projectId, datasetContext)
  const [, setSearchParams] = useSearchParams()

  React.useEffect(() => {
    const image = (isImagesLoading || isImagesValidating) ? undefined : images?.find((image) => image.id === selectedImageId)

    if (image !== undefined) {
      setSearchParams((previousParams) => {
        return {
          ...previousParams,
          image: image.slug,
        }
      }, {
        replace: true,
      })
    }
  }, [images, isImagesLoading, isImagesValidating, selectedImageId, setSearchParams])
}

const useUpdateDatasetContextFromURL = (): void => {
  const projectSlug = useProjectSlug()
  const datasetContext = useDatasetContext()
  const setDatasetContext = useSetDatasetContext()
  const location = useLocation()
  const datasetContextFromURL = React.useMemo(() => {
    return projectSlug === undefined
      ? undefined
      : location.pathname.includes(`/${projectSlug}/annotate`) ? ProjectDatasetContext.Training : ProjectDatasetContext.Validation
  }, [projectSlug, location])

  React.useEffect(() => {
    if (datasetContextFromURL !== datasetContext) {
      setDatasetContext(datasetContextFromURL)
    }
  }, [datasetContext, datasetContextFromURL, setDatasetContext])
}

const useProjectPageReset = (): void => {
  // When unmounting the editor page, reset the databrowser state
  const resetDatabrowserState = useDataBrowserStore((state) => state.reset)
  React.useEffect(() => {
    return resetDatabrowserState
  }, [resetDatabrowserState])

  const resetImageFilterStore = useImageFilterStore((state) => state.reset)
  React.useEffect(() => {
    return resetImageFilterStore
  }, [resetImageFilterStore])

  const resetEditorStore = useEditorStore((state) => state.reset)
  React.useEffect(() => {
    return resetEditorStore
  }, [resetEditorStore])

  React.useEffect(() => {
    const onProjectReseted = (): void => {
      resetEditorStore()
      resetImageFilterStore()
    }
    window.addEventListener(EVENTS_ID.PROJECT_RESET_COMPLETED, onProjectReseted, {})
    return () => {
      window.removeEventListener(EVENTS_ID.PROJECT_RESET_COMPLETED, onProjectReseted, {})
    }
  }, [resetEditorStore, resetImageFilterStore])
}

const ResizableCanvasPanel: React.FC = () => {
  const { isOpen } = useDataBrowserStore((state) => ({
    isOpen: state.isOpen,
  }))

  return (
    <ResizablePanelGroup direction='horizontal'>
      {
        isOpen &&
        <>
          <ResizablePanel id="databrowser" order={1} collapsible collapsedSize={20} minSize={20} className='flex'>
            <DataBrowserTable />
          </ResizablePanel>
          <ResizableHandle withHandle className='w-0' />
        </>
      }
      <ResizablePanel id="canvas" order={2} minSize={20}>
        <ClemexMosaicCanvasViewer />
      </ResizablePanel>
    </ResizablePanelGroup>
  )
}

export const EditorPage: React.FC = () => {
  const intl = useIntl()
  const datasetContext = useDatasetContext()
  const projectSlug = useProjectSlug()
  const { data: project, isLoading: isProjectLoading, error: projectLoadingError } = useCurrentProject()
  const { data: flags, isLoading: isFeatureFlagLoading } = useFeatureFlags()
  const { data: images, isLoading: isLoadingImages } = useProjectImages(project?.id, datasetContext)
  const { isLoading: isProjectClassesLoading } = useProjectClasses(project?.id)
  const [showShareProject, setShowShareProject] = React.useState(false)
  const [projectSharingUrl, setProjectSharingUrl] = React.useState('')
  const [notificationApi, notificationContext] = notification.useNotification()
  const onboardingTourContext: ONBOARDING_TOUR_CONTEXT = (datasetContext === ProjectDatasetContext.Training) ? ONBOARDING_TOUR_CONTEXT.ANNOTATION : ONBOARDING_TOUR_CONTEXT.VALIDATION

  useProjectPageReset()

  const [isLeftPanelOpen, setIsLeftPanelOpen] = React.useState(true)
  const openProjectSharingModal = React.useCallback(async (): Promise<void> => {
    if (project?.id !== undefined) {
      try {
        const sharingToken = (await RFAPI.requestProjectSharingToken(project.id)).sharingToken
        setProjectSharingUrl(window.origin + WebUIRoutes.projectList().path + '?sharedProjectToken=' + sharingToken)
      } catch {
        notificationApi.error({
          message: <FormattedMessage
            id='project.sharing.link.generate.error.description'
            defaultMessage='There was an error generating the project sharing link. Please try again' />,
        })
      }
      setShowShareProject(true)
    }
  }, [project?.id, notificationApi])

  React.useEffect(() => {
    window.addEventListener(EVENTS_ID.PROJECT_SHARE_MODAL, openProjectSharingModal)
    return () => {
      window.removeEventListener(
        EVENTS_ID.PROJECT_SHARE_MODAL,
        openProjectSharingModal,
      )
    }
  }, [openProjectSharingModal])

  useRevalidateResourceWhenProjectStatusChange(project?.id)
  useProjectTrainingErrorNotitificationMonitoring(project?.id)

  useBindExportProjectShortcut(project?.id)

  useImageNavigationEventHandler(project?.id, datasetContext)

  useListenToKeyboardBindings()

  useBindProjectSlugChangeEvent()

  useUpdateSelectedImageSlug()
  useInitializeSelectedImage()
  useInitializeSelectedImage()

  useUpdateDatasetContextFromURL()

  if (projectLoadingError !== undefined) {
    if (projectLoadingError.response?.status === 403) {
      return <NotAuthorized />
    } else {
      return <ErrorPage />
    }
  }

  return (
    <RFPage
      title={intl.formatMessage(
        {
          id: 'page.title.editor',
          defaultMessage: '{project}',
        },
        {
          project: project?.name,
        },
      )}
    >
      <RFHeader
        showProfileMenu={!(flags?.enableLocalUser ?? false)}
        breadcrumbItem={project?.name}
        projectId={project?.id}
        navigationContext={MenuContext.EDITOR}
        onboardingTourContext={onboardingTourContext}
      >
        <WorkflowSteps datasetContext={datasetContext} projectSlug={projectSlug} />
      </RFHeader>
      <RFContent
        isLoading={isLoadingImages || isProjectLoading || isFeatureFlagLoading || isProjectClassesLoading}
      >
        {TUTORIAL_ENABLED && datasetContext === ProjectDatasetContext.Training && <AnnotationEditorTour onboardingTourContext={onboardingTourContext} />}
        {TUTORIAL_ENABLED && datasetContext === ProjectDatasetContext.Validation && <ValidationTour onboardingTourContext={onboardingTourContext} />}
        <GlobalSettingsModal />
        <CreateProjectModal />
        <ProjectSettingsModal projectId={project?.id} pageContext={ProjectSettingsPageContext.PROJECT_EDITOR} />
        <KeyboardShortcutsModal />
        <ProjectSharingModal isOpen={showShareProject} onClose={() => { setShowShareProject(false) }} sharingUrl={projectSharingUrl} projectName={project?.name ?? ''} />
        <ObjectOverviewModal />
        {notificationContext}
        <div className={styles.content}>
          <PanelBar
            onClickLeftPanel={() => {
              setIsLeftPanelOpen((prev) => {
                return !prev
              })
            }}
            isLeftPanelActive={isLeftPanelOpen}
          />
          {/* I used a style to hide the div, because we are unsetting the selected image ID when unmounted */}
          {/* This is a workaround until we refactor the left panel to remove all the selected image logic */}
          <div className={clsx(styles.panel, styles.leftPanel, { [styles.hide]: !isLeftPanelOpen })}>
            {project !== undefined && datasetContext !== undefined && (
              <EditorLeftPanel
                projectId={project.id}
                projectSlug={project.slug}
                context={datasetContext}
              />
            )}
          </div>
          <div className="flex flex-col grow max-w-full max-h-full overflow-auto">
            <div className="flex flex-row grow max-w-full overflow-auto">
              {(images !== undefined) && images.length === 0 && (
                <UploadFirstImagesOfProject
                  projectId={project?.id}
                  context={datasetContext}
                />
              )}
              {project !== undefined && images !== undefined && images.length !== 0 && (
                <ResizableCanvasPanel />
              )}
            </div>
            <div className=''>
              <EditorStatusBar />
            </div>
          </div>
          <div className={clsx(styles.panel, styles.rightPanel)}>
            {project !== undefined && (
              <EditorRightPanel />
            )}
          </div>
        </div>
      </RFContent>
    </RFPage >
  )
}
