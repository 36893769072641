/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AngleGeometryProperties } from './AngleGeometryProperties';
import {
    instanceOfAngleGeometryProperties,
    AngleGeometryPropertiesFromJSON,
    AngleGeometryPropertiesFromJSONTyped,
    AngleGeometryPropertiesToJSON,
} from './AngleGeometryProperties';
import type { ArcGeometryProperties } from './ArcGeometryProperties';
import {
    instanceOfArcGeometryProperties,
    ArcGeometryPropertiesFromJSON,
    ArcGeometryPropertiesFromJSONTyped,
    ArcGeometryPropertiesToJSON,
} from './ArcGeometryProperties';
import type { CircleGeometryProperties } from './CircleGeometryProperties';
import {
    instanceOfCircleGeometryProperties,
    CircleGeometryPropertiesFromJSON,
    CircleGeometryPropertiesFromJSONTyped,
    CircleGeometryPropertiesToJSON,
} from './CircleGeometryProperties';
import type { DistanceGeometryProperties } from './DistanceGeometryProperties';
import {
    instanceOfDistanceGeometryProperties,
    DistanceGeometryPropertiesFromJSON,
    DistanceGeometryPropertiesFromJSONTyped,
    DistanceGeometryPropertiesToJSON,
} from './DistanceGeometryProperties';
import type { EllipseGeometryProperties } from './EllipseGeometryProperties';
import {
    instanceOfEllipseGeometryProperties,
    EllipseGeometryPropertiesFromJSON,
    EllipseGeometryPropertiesFromJSONTyped,
    EllipseGeometryPropertiesToJSON,
} from './EllipseGeometryProperties';
import type { LineGeometryProperties } from './LineGeometryProperties';
import {
    instanceOfLineGeometryProperties,
    LineGeometryPropertiesFromJSON,
    LineGeometryPropertiesFromJSONTyped,
    LineGeometryPropertiesToJSON,
} from './LineGeometryProperties';
import type { PolygonGeometryProperties } from './PolygonGeometryProperties';
import {
    instanceOfPolygonGeometryProperties,
    PolygonGeometryPropertiesFromJSON,
    PolygonGeometryPropertiesFromJSONTyped,
    PolygonGeometryPropertiesToJSON,
} from './PolygonGeometryProperties';
import type { RectangleGeometryProperties } from './RectangleGeometryProperties';
import {
    instanceOfRectangleGeometryProperties,
    RectangleGeometryPropertiesFromJSON,
    RectangleGeometryPropertiesFromJSONTyped,
    RectangleGeometryPropertiesToJSON,
} from './RectangleGeometryProperties';

/**
 * @type GeometryProperties
 * 
 * @export
 */
export type GeometryProperties = { geometryType: 'ANGLE' } & AngleGeometryProperties | { geometryType: 'ARC' } & ArcGeometryProperties | { geometryType: 'CIRCLE' } & CircleGeometryProperties | { geometryType: 'DISTANCE' } & DistanceGeometryProperties | { geometryType: 'ELLIPSE' } & EllipseGeometryProperties | { geometryType: 'LINE' } & LineGeometryProperties | { geometryType: 'POLYGON' } & PolygonGeometryProperties | { geometryType: 'RECTANGLE' } & RectangleGeometryProperties;

export function GeometryPropertiesFromJSON(json: any): GeometryProperties {
    return GeometryPropertiesFromJSONTyped(json, false);
}

export function GeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeometryProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['geometry_type']) {
        case 'ANGLE':
            return {...AngleGeometryPropertiesFromJSONTyped(json, true), geometryType: 'ANGLE'};
        case 'ARC':
            return {...ArcGeometryPropertiesFromJSONTyped(json, true), geometryType: 'ARC'};
        case 'CIRCLE':
            return {...CircleGeometryPropertiesFromJSONTyped(json, true), geometryType: 'CIRCLE'};
        case 'DISTANCE':
            return {...DistanceGeometryPropertiesFromJSONTyped(json, true), geometryType: 'DISTANCE'};
        case 'ELLIPSE':
            return {...EllipseGeometryPropertiesFromJSONTyped(json, true), geometryType: 'ELLIPSE'};
        case 'LINE':
            return {...LineGeometryPropertiesFromJSONTyped(json, true), geometryType: 'LINE'};
        case 'POLYGON':
            return {...PolygonGeometryPropertiesFromJSONTyped(json, true), geometryType: 'POLYGON'};
        case 'RECTANGLE':
            return {...RectangleGeometryPropertiesFromJSONTyped(json, true), geometryType: 'RECTANGLE'};
        default:
            throw new Error(`No variant of GeometryProperties exists with 'geometryType=${json['geometryType']}'`);
    }
}

export function GeometryPropertiesToJSON(value?: GeometryProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['geometryType']) {
        case 'ANGLE':
            return AngleGeometryPropertiesToJSON(value);
        case 'ARC':
            return ArcGeometryPropertiesToJSON(value);
        case 'CIRCLE':
            return CircleGeometryPropertiesToJSON(value);
        case 'DISTANCE':
            return DistanceGeometryPropertiesToJSON(value);
        case 'ELLIPSE':
            return EllipseGeometryPropertiesToJSON(value);
        case 'LINE':
            return LineGeometryPropertiesToJSON(value);
        case 'POLYGON':
            return PolygonGeometryPropertiesToJSON(value);
        case 'RECTANGLE':
            return RectangleGeometryPropertiesToJSON(value);
        default:
            throw new Error(`No variant of GeometryProperties exists with 'geometryType=${value['geometryType']}'`);
    }

}

