import type * as React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface LoadingIconProps {
  isLoading: boolean
  className?: string
}

export const LoadingIcon: React.FC<LoadingIconProps> = (props: LoadingIconProps) => {
  const { isLoading, className } = props
  return <Spin className={className} spinning={isLoading} indicator={<LoadingOutlined/>}/>
}
