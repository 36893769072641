import { type FC, type HTMLAttributes } from 'react'
import styles from './styles/licensing-tag.module.scss'
import clsx from 'clsx'
import { Typography } from 'antd'
const { Text } = Typography
interface LicensingIconProps {
  icon: React.ReactNode
}
const LicensingIcon: FC<LicensingIconProps> = ({ icon }) => {
  return <div
    className={styles.licensingIcon}
  >
    {icon}
  </div>
}
interface LicensingTagProps extends HTMLAttributes<HTMLDivElement> {
  tagIcon: React.ReactNode
  tagText: string
}
export const LicensingTag: FC<LicensingTagProps> = ({ tagIcon, tagText, ...divProps }) => {
  return <div
    {...divProps}
    className={clsx(
      divProps.className,
      styles.licensingTag,
    )}
  >
    <LicensingIcon icon={tagIcon}/>
    <Text className={styles.licensingText}>{ tagText }</Text>
  </div>
}
interface LicensingTagWithExpirationProps extends LicensingTagProps {
  expirationText: string
  expirationClassName: string
}
export const LicensingTagWithExpiration: FC<LicensingTagWithExpirationProps> = ({ tagIcon, tagText, expirationClassName, expirationText, ...divProps }) => {
  return <div
    { ...divProps }
    className={
      clsx(
        divProps.className,
        styles.licensingTagWithExpiration)}
  >
    <LicensingIcon icon={tagIcon}/>
    <Text className={styles.licensingText}>{ tagText }</Text>
    <Text className={clsx(styles.expirationInformation, expirationClassName)}>{ expirationText }</Text>
  </div>
}
