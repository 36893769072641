import { type FeatureLike } from 'ol/Feature'
import Style, { type StyleFunction } from 'ol/style/Style'
import { FEATURE_TYPE, FeatureType, type ShapeStyle, type TransformInteractionStyle } from './common'
import ColorJS from 'colorjs.io'
import { Circle, type Polygon, Point } from 'ol/geom'
import { Fill, RegularShape, Stroke, Text } from 'ol/style'
import { fromCircle } from 'ol/geom/Polygon'

export interface EllipseGeometryProperties {
  geometryType: 'ELLIPSE'
  center: [number, number]
  radiusX: number
  radiusY: number
  angle: number
  filled: boolean
  pinnedResolution: number
}

export const buildEllipseStyle = (
  selection: Set<string>,
  pixelSizeCalibration: number | undefined,
  style: ShapeStyle,
): StyleFunction => {
  return (feature: FeatureLike, resolution: number) => {
    const geometry = feature.getGeometry()
    if (geometry === undefined) {
      return []
    }

    const { center, angle, radiusX, radiusY, filled, pinnedResolution } = geometry.getProperties() as EllipseGeometryProperties
    const selected = selection.has(feature.getProperties().id as string)
    const fillColor = new ColorJS(selected ? style.mainSelectedColor : style.mainColor)
    fillColor.alpha = style.fillOpacity

    const stroke = new Stroke({
      color: selected ? style.mainSelectedColor : style.mainColor,
      width: style.strokeWidth,
    })
    const fill = new Fill({
      color: filled ? fillColor.toString() : 'rgba(0, 0, 0, 0)',
    })

    const ellipse = buildEllipsePolygon(center, radiusX, radiusY, angle)

    const styles = []
    styles.push(new Style({
      stroke,
      fill,
      geometry: ellipse,
    }))

    if (feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_ELLIPSE) {
      const ellipseArea = Math.PI * radiusX * radiusY
      const font = `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`

      const measurementText = pixelSizeCalibration === undefined
        ? `${ellipseArea.toFixed(1)} px²`
        : `${(ellipseArea * pixelSizeCalibration).toFixed(2)} µm²`
      const measurementTextHeight = style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2
      const measurementTextsBoxHeight = measurementTextHeight +
        style.measurementTextFontStrokeWidth * 2 +
        style.measurementTextBackgroundPadding * 2

      const textOffsetX = 0
      const textOffsetY = (measurementTextsBoxHeight / 2) + style.measurementTextBackgroundDistanceShape

      const simpliedCoordinates = (ellipse.simplify(0) as Polygon).getCoordinates()
      let top = simpliedCoordinates[0][0]
      let left = simpliedCoordinates[0][0]
      let bottom = simpliedCoordinates[0][0]
      let right = simpliedCoordinates[0][0]
      for (const point of simpliedCoordinates[0]) {
        if (point[0] < left[0]) {
          left = point
        }
        if (point[0] > right[0]) {
          right = point
        }
        if (point[1] < bottom[1]) {
          bottom = point
        }
        if (point[1] > top[1]) {
          top = point
        }
      }
      styles.push(
        new Style({
          text: new Text({
            text: measurementText,
            font,
            padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
            backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
            backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
            fill: new Fill({
              color: style.measurementTextFontFillColor,
            }),
            stroke: new Stroke({
              color: style.measurementTextFontStrokeColor,
              width: style.measurementTextFontStrokeWidth,
            }),
            offsetY: textOffsetY * pinnedResolution / resolution,
            offsetX: textOffsetX * pinnedResolution / resolution,
            scale: pinnedResolution / resolution,
          }),
          geometry: () => {
            return new Point([
              (left[0] + right[0]) / 2,
              bottom[1],
            ])
          },
        }),
      )
    }
    return styles
  }
}

export const buildEllipsePolygon = (center: [number, number], radiusX: number, radiusY: number, angle: number): Polygon => {
  const circle = new Circle([0, 0], 1)
  const polygon = fromCircle(circle, 64)
  polygon.scale(radiusX, radiusY, [0, 0])
  polygon.rotate(angle, [0, 0])
  polygon.translate(center[0], center[1])
  return polygon
}

export const buildEllipseDirectMesureTransformInteractionStyle = (): TransformInteractionStyle => {
  const stroke = new Stroke({
    color: 'rgba(255, 255, 255, 1)',
    width: 1,
  })
  const fill = new Fill({
    color: 'rgba(255, 255, 255, 0.5)',
  })
  const bigHandle = new Style({
    image: new RegularShape({
      fill,
      stroke,
      radius: 8,
      points: 4,
      angle: Math.PI / 4,
    }),
    fill,
    stroke,
  })
  const smallHandle = new Style({
    image: new RegularShape({
      fill,
      stroke,
      radius: 6,
      points: 4,
      angle: Math.PI / 4,
    }),
    fill,
    stroke,
  })
  const rotateHandle = new Style({
    image: new RegularShape({
      fill,
      stroke,
      radius: 6,
      points: 15,
      displacement: [12, -12],
    }),
    fill,
    stroke,
  })
  const rotate0Handle = new Style({
    image: new RegularShape({
      fill,
      stroke,
      radius: 8,
      points: 16,
      angle: Math.PI / 4,
    }),
    fill,
    stroke,
  })

  return {
    default: [],
    rotate: rotateHandle,
    rotate0: rotate0Handle,
    scale: bigHandle,
    scale1: bigHandle,
    scale2: bigHandle,
    scale3: bigHandle,
    scalev: smallHandle,
    scaleh1: smallHandle,
    scalev2: smallHandle,
    scaleh3: smallHandle,
  }
}
