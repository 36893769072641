import type * as React from 'react'
import { Flex } from 'antd'
import { useIntl } from 'react-intl'

interface MadeWithLoveProps {
  city?: boolean
  className?: string
}

export const MadeWithLove: React.FC<MadeWithLoveProps> = ({ city = false, className }) => {
  const intl = useIntl()
  const file = city ? 'made.with.love.in.montreal.canada.city.v1.0.svg' : 'made.with.love.in.montreal.canada.v1.0.svg'
  return <Flex className={className} justify='center' align='center'>
    <img src={`/images/assets/${file}`} alt={intl.formatMessage({ id: 'made-with-love.logo.alt', defaultMessage: 'Made with ❤️ by Clemex' })} />
  </Flex>
}
