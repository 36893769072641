import * as React from 'react'
import { EVENTS_ID } from '@app/constants'
import { useLicence } from '@app/api/hooks'
import { useExportProgressStore } from '@app/stores/export-progress'

export const useEditorShortcutHook = ({ undo, redo }: { undo: () => void, redo: () => void }): void => {
  React.useEffect(() => {
    const undoEventHandler = (): void => {
      undo()
    }
    const redoEventHandler = (): void => {
      redo()
    }

    window.addEventListener(EVENTS_ID.EDITOR_UNDO, undoEventHandler)
    window.addEventListener(EVENTS_ID.EDITOR_REDO, redoEventHandler)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_UNDO, undoEventHandler)
      window.removeEventListener(EVENTS_ID.EDITOR_REDO, redoEventHandler)
    }
  }, [undo, redo])
}

export const useBindExportProjectShortcut = (projectId?: string): void => {
  const { data: licence, isLoading: isLicenceLoading } = useLicence()
  const [setIsExporting, setProgress] = useExportProgressStore((state) => [state.setIsExporting, state.setProgress])
  React.useEffect(() => {
    if (projectId === undefined) {
      return
    }
    if (isLicenceLoading) {
      return
    }
    const exportProjectEventHandler = (): void => {
      if (!(licence?.enableExportProject ?? false)) {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.PROJECT_EXPORT_SETTINGS))
        return
      }
      setIsExporting(true)
      const xmlHTTP = new XMLHttpRequest()
      xmlHTTP.open('GET', `/api/projects/${projectId}/export`, true)
      xmlHTTP.responseType = 'blob'
      xmlHTTP.onprogress = (progress) => {
        setProgress(progress.loaded / progress.total)
      }
      xmlHTTP.onloadend = () => {
        try {
          const linkElement = document.createElement('a')
          document.body.appendChild(linkElement)
          linkElement.style.display = 'none'
          const objectURL = window.URL.createObjectURL(xmlHTTP.response as Blob)
          const header = xmlHTTP.getResponseHeader('Content-Disposition')
          const fileName = header?.split('filename=')[1].replace(/"/g, '')
          linkElement.download = fileName ?? 'export.zip'
          linkElement.href = objectURL
          linkElement.target = '_blank'
          linkElement.click()
          window.URL.revokeObjectURL(objectURL)
          document.body.removeChild(linkElement)
        } finally {
          setIsExporting(false)
        }
      }
      xmlHTTP.onerror = () => {
        setIsExporting(false)
      }
      xmlHTTP.send()
    }
    window.addEventListener(EVENTS_ID.EXPORT_PROJECT, exportProjectEventHandler)
    return () => {
      window.removeEventListener(EVENTS_ID.EXPORT_PROJECT, exportProjectEventHandler)
    }
  }, [isLicenceLoading, licence?.enableExportProject, projectId, setIsExporting, setProgress])
}
