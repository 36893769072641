import Style, { type StyleFunction } from 'ol/style/Style'
import { DEFAULT_CLEMEX_VISION_MASK_COLOR } from '../color'
import Stroke from 'ol/style/Stroke'

export interface DetectedObjectStyle {
  defaultMaskColor: Record<number, [number, number, number]>
  opacity: number
}

export interface DetectedObjectProperties {
  id: string
}

export const DEFAULT_CLASS_ANNOTATION_STYLE: DetectedObjectStyle = {
  defaultMaskColor: DEFAULT_CLEMEX_VISION_MASK_COLOR,
  opacity: 1,
}

const DETECTED_OBJECT_Z_INDEX = 100

export const buildDetectedObjectStyle = (
  style: DetectedObjectStyle,
  selectedClassAnnotationIds: Set<string>,
): StyleFunction => {
  return (feature) => {
    const styles = []
    const featureProperties = feature.getProperties() as DetectedObjectProperties
    const selected = selectedClassAnnotationIds.has(featureProperties.id)

    if (selected) {
      styles.push(new Style({
        stroke: new Stroke({
          color: 'black',
          width: 2,
          lineDash: [10, 10],
        }),
        zIndex: DETECTED_OBJECT_Z_INDEX + 1,
      }))
      styles.push(new Style({
        stroke: new Stroke({
          color: 'white',
          width: 2,
          lineDash: [10, 10],
          lineDashOffset: 10,
        }),
        zIndex: DETECTED_OBJECT_Z_INDEX + 1,
      }))
    }
    return styles
  }
}
