import { type DirectMeasurePatchRequest, type DirectMeasureInput } from '@app/api/openapi'
import useSWR, { mutate as globalMutate } from 'swr'
import { api } from './api'
import { CACHE_KEYS } from '@app/api/cache-keys'
import { useCallback, useState } from 'react'

const MAX_RETRY = 3

const listDirectMeasure = async (projectId: string, imageId: string): Promise<DirectMeasureInput[]> => {
  const response = await api.listDirectMeasureApiProjectsProjectIdImagesImageIdDirectMeasureGet({ projectId, imageId })
  return response
}

const patchProjectImageDirectMeasures = async (
  projectId: string,
  imageId: string,
  patch: DirectMeasurePatchRequest,
): Promise<void> => {
  await api.patchProjectImageDirectMeasuresApiProjectsProjectIdImagesImageIdDirectMeasurePatch({
    projectId,
    imageId,
    directMeasurePatchRequest: patch,
  })
}

interface UseProjectImageDirectMeasure {
  data: DirectMeasureInput[] | undefined
  isLoading: boolean
  isValidating: boolean
  error: unknown
  patch: (patch: DirectMeasurePatchRequest) => void
  savePatches: () => Promise<void>
}
export const useDirectMeasure = (projectId: string | undefined, imageId: string | undefined): UseProjectImageDirectMeasure => {
  const cacheKey = CACHE_KEYS.PROJECT_IMAGE_DIRECT_MEASURE(projectId, imageId)
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    cacheKey,
    async ({ data }) => {
      return await listDirectMeasure(data.projectId, data.imageId)
    },
  )

  const [cumulativePatches, setCumulativePatches] = useState<DirectMeasurePatchRequest[]>([])

  const patch = (changes: DirectMeasurePatchRequest): void => {
    setCumulativePatches((prev) => {
      return [
        ...prev,
        changes,
      ]
    })
  }

  const savePatches = useCallback(async (): Promise<void> => {
    if (projectId === undefined || imageId === undefined) {
      return
    }
    setCumulativePatches([])
    let retry = MAX_RETRY
    let success = false
    while (!success && retry > 0) {
      try {
        for (const patch of cumulativePatches) {
          await patchProjectImageDirectMeasures(projectId, imageId, patch)
        }
        success = true
      } catch (e) {
        retry -= 1
        console.error(`Failed to save direct measure changes (retry ${MAX_RETRY - retry} / ${MAX_RETRY})`, e)
        if (retry === 0) {
          throw e
        }
      } finally {
        // After saving the patches, we need to refresh the data
        // In case of failure, we also need to refresh the data to get the latest state
        await mutate()
        await globalMutate(CACHE_KEYS.ANALYTIC_DATA_DIRECT_MEASURE(imageId))
      }
    }
  }, [cumulativePatches, imageId, projectId, mutate])

  return {
    data,
    isLoading,
    isValidating,
    error,
    patch,
    savePatches,
  }
}
