import { api } from '@app/api/api'
import { CACHE_KEYS } from '@app/api/cache-keys'
import { type AnalyticDataClassAnnotations, type AnalyticDataDirectMeasures, type AnalyticDataInstanceSegmentation, TaskStatus } from '@app/api/openapi'
import { usePredictionMaskAnalyticDetectedObjectStatus } from '@app/api/resource-status'
import useSWR, { type SWRResponse } from 'swr'

export const useAnalyticDataInstanceSegmentation = (imageId: string | undefined, projectTrainingSnapshotId: string | undefined): SWRResponse<AnalyticDataInstanceSegmentation | null> => {
  const {
    data: predictionMaskAnalyticDetectedObjectStatus,
  } = usePredictionMaskAnalyticDetectedObjectStatus(imageId, projectTrainingSnapshotId)
  return useSWR(
    CACHE_KEYS.ANALYTIC_DATA_INSTANCE_SEGMENTATION(imageId, projectTrainingSnapshotId, predictionMaskAnalyticDetectedObjectStatus === TaskStatus.Completed),
    async ({ data }) => {
      if (predictionMaskAnalyticDetectedObjectStatus !== TaskStatus.Completed) {
        return null
      }
      if (data.projectTrainingSnapshotId === undefined) {
        throw new Error('projectTrainingSnapshotId is undefined')
      }
      return await api.getInstanceSegmentationApiAnalyticDataImageIdProjectTrainingSnapshotIdPredictionDetectionObjectsGet({
        imageId: data.imageId,
        projectTrainingSnapshotId: data.projectTrainingSnapshotId,
      })
    },
  )
}

export const useAnalyticDataDirectMeasures = (imageId: string | undefined): SWRResponse<AnalyticDataDirectMeasures> => {
  return useSWR(
    CACHE_KEYS.ANALYTIC_DATA_DIRECT_MEASURE(imageId),
    async ({ data }) => {
      return await api.getDirectMeasureWithStatsApiAnalyticDataImageIdDirectMeasuresGet(data)
    },
  )
}

export const useAnalyticDataClassAnnotations = (imageId: string | undefined): SWRResponse<AnalyticDataClassAnnotations> => {
  return useSWR(
    CACHE_KEYS.ANALYTIC_DATA_CLASS_ANNOTATIONS(imageId),
    async ({ data }) => {
      return await api.getClassAnnotationWithStatsApiAnalyticDataImageIdClassAnnotationsGet(data)
    },
  )
}

export const createAnalyticDirectMeasureDownloadCSVURL = (imageId: string): string => {
  return `/api/analytic-data/${imageId}/direct_measures.csv`
}

export const createAnalyticMetadataAnnotationDownloadCSVURL = (imageId: string): string => {
  return `/api/analytic-data/${imageId}/metadata_annotations.csv`
}

export const createAnalyticPredictionDetectedObjectsDownloadCSVURL = (imageId: string, projectTrainingSnapshotId: string): string => {
  return `/api/analytic-data/${imageId}/${projectTrainingSnapshotId}/prediction_detection_objects.csv`
}

export const createAnalyticClassAnnotationsDownloadCSVURL = (imageId: string): string => {
  return `/api/analytic-data/${imageId}/class_annotations.csv`
}
