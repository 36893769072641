import * as React from 'react'
import styles from './styles/projects-list.module.scss'
import { RFPage, RFHeader, RFContent } from '@components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Button, notification } from 'antd'
import AddCircle from '@material-design-icons/svg/filled/add_circle.svg'
import {
  useProjects,
  useFeatureFlags,
} from '@app/api/hooks'
import * as RFAPI from '@app/api/api'
import { ProjectInfoCard } from '@components/project-info-card'
import { WebUIRoutes } from '@app/routes'
import { useNavigate } from 'react-router'
import { E2E_REQUIRED_CLASSES, EVENTS_ID, ONBOARDING_TOUR_CONTEXT, TUTORIAL_ENABLED } from '@app/constants'
import { type ProjectResponse } from '@app/api/openapi'
import { ContextualMenu } from '@components/contextual-menu'
import { ProjectSettingsModal, ProjectSettingsPageContext } from '@components/modals/project-settings/project-settings-modal'
import { CreateProjectModal } from '@components/modals/create-project-modal'
import { KeyboardShortcutsModal } from '@components/modals/keyboard-shortcuts-modal'
import { NotAuthorized } from '@app/pages/not-authorized'
import { ErrorPage } from '@app/pages/error-page'
import { MenuContext } from '@components/layout/navigation'
import { ProjectListTour } from '@components/tutorial/project-list-tour'
import { GlobalSettingsModal } from '@components/modals/global-settings/global-settings-modal'
import { SharedProjectModal } from '@components/modals/project-sharing-modal'
import { useSearchParams } from 'react-router-dom'

const ProjectInfoCards: React.FC = (): React.ReactElement => {
  const { data, projects, mutate: mutateProjects } = useProjects()
  const intl = useIntl()
  const navigate = useNavigate()
  const [duplicateNotification, duplicateContextHolder] = notification.useNotification()

  const [selectedProjectId, setSelectedProjectId] = React.useState<string | undefined>(undefined)
  const [isEditProjectSettingsOpen, setIsEditProjectSettingsOpen] = React.useState<boolean>(false)
  const [duplicatingProject, setDuplicatingProject] = React.useState<string | undefined>(undefined)

  const onClickEditProject = React.useCallback((projectId: string): void => {
    setSelectedProjectId(projectId)
    setIsEditProjectSettingsOpen(true)
  }, [setSelectedProjectId, setIsEditProjectSettingsOpen])

  const onCloseProjectSettings = React.useCallback(async (): Promise<void> => {
    setIsEditProjectSettingsOpen(false)
  }, [setIsEditProjectSettingsOpen])

  const onDuplicate = React.useCallback(async (projectId: string): Promise<ProjectResponse> => {
    try {
      const duplicatedProject = await RFAPI.duplicateProject(projectId)
      if (data !== undefined) {
        const updatedData = { data: [...data.data, duplicatedProject] }
        await mutateProjects(updatedData)
      }
      return duplicatedProject
    } catch (error) {
      duplicateNotification.error({
        message: intl.formatMessage({
          id: 'project.action.duplicateAndNavigate.fail.notification.message',
          defaultMessage: 'Failed to duplicate project',
        }),
        description: intl.formatMessage({
          id: 'project.action.duplicateAndNavigate.fail.notification.description',
          defaultMessage: 'Please try again later',
        }),
      })
      throw error
    }
  }, [data, mutateProjects, duplicateNotification, intl])

  React.useEffect(() => {
    const onDuplicateEventHandler = async (event: Event): Promise<void> => {
      const projectId = (event as CustomEvent).detail.projectId as string
      setDuplicatingProject(projectId)
      void (await onDuplicate(projectId))
      setDuplicatingProject(undefined)
    }
    const onDuplicateAndNavigateEventHandler = async (
      event: Event,
    ): Promise<void> => {
      const projectId = (event as CustomEvent).detail.projectId as string
      setDuplicatingProject(projectId)
      const newProject = await onDuplicate(projectId)
      navigate(WebUIRoutes.annotateProject(newProject.slug).path)
      setDuplicatingProject(undefined)
    }

    window.addEventListener(EVENTS_ID.DUPLICATE_PROJECT, onDuplicateEventHandler, {})
    window.addEventListener(
      EVENTS_ID.DUPLICATE_PROJECT_AND_NAVIGATE, onDuplicateAndNavigateEventHandler, {})
    return () => {
      window.removeEventListener(EVENTS_ID.DUPLICATE_PROJECT, onDuplicateEventHandler, {})
      window.removeEventListener(EVENTS_ID.DUPLICATE_PROJECT_AND_NAVIGATE, onDuplicateAndNavigateEventHandler, {})
    }
  }, [navigate, onDuplicate])

  return (
    <>
      {duplicateContextHolder}
      <ProjectSettingsModal
        projectId={selectedProjectId}
        pageContext={ProjectSettingsPageContext.PROJECT_LIST}
        isOpen={isEditProjectSettingsOpen}
        onClose={onCloseProjectSettings}
      />
      <GlobalSettingsModal />
      {projects?.map((project) => (
        <ContextualMenu
          key={project.id}
          menuItems={{
            [EVENTS_ID.DUPLICATE_PROJECT]: { projectId: project.id },
            [EVENTS_ID.DUPLICATE_PROJECT_AND_NAVIGATE]: {
              projectId: project.id,
            },
          }}
          className={E2E_REQUIRED_CLASSES.PROJECT_CARD}
        >
          <ProjectInfoCard
            posterImage={project.posterImage}
            onDuplicateAndNavigate={async () => {
              // Do nto allow to duplicate the project if it is already duplicating
              if (duplicatingProject !== undefined) {
                return
              }
              const newProject = await onDuplicate(project.id)
              navigate(WebUIRoutes.annotateProject(newProject.slug).path)
            }}
            onOpen={() => {
              navigate(WebUIRoutes.annotateProject(project.slug).path)
            }}
            onClickEditProject={() => { onClickEditProject(project.id) }}
            projectName={project.name}
            lastModifiedDate={project.lastModifiedDate.toISOString()}
            description={project.description}
            cardCoverChildren={null}
            isDuplicating={duplicatingProject === project.id}
          />
        </ContextualMenu>
      ))}
    </>
  )
}

export const ProjectsListPage: React.FC = () => {
  const { data: flags } = useFeatureFlags()
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
    React.useState(false)

  const intl = useIntl()
  const { mutate: mutateProjectList, isLoading: isProjectListLoading, error: useProjectsError } = useProjects()
  const [searchParams] = useSearchParams()

  const sharedProjectToken = searchParams.get('sharedProjectToken')
  const [isSharedProjectModalOpen, setIsSharedModalOpen] = React.useState(sharedProjectToken !== null)
  // Touring for project list have different tour steps
  const projectOnboardingTourContext = flags?.enableLocalUser ?? false ? ONBOARDING_TOUR_CONTEXT.PROJECT_LIST_LOCAL_USER : ONBOARDING_TOUR_CONTEXT.PROJECT_LIST

  if (useProjectsError !== undefined) {
    if (useProjectsError.response?.status === 403) {
      return <NotAuthorized />
    } else {
      return <ErrorPage />
    }
  }

  return (
    <>
      {TUTORIAL_ENABLED && <ProjectListTour onboardingTourContext={projectOnboardingTourContext} />}
      <RFPage
        title={intl.formatMessage({
          id: 'page.title.project list',
          defaultMessage: 'Projects',
        })}
      >
        <RFHeader
          leftChildren={
            <Button
              className={styles.createProjectButton}
              icon={<AddCircle />}
              size={'middle'}
              onClick={() => {
                setIsCreateProjectModalOpen(true)
              }}
              type="primary"
              id="button-create-project"
            >
              <span>
                <FormattedMessage
                  id="projects-list.button.new-project.label"
                  defaultMessage={'new project'}
                />
              </span>
            </Button>
          }
          showProfileMenu={!(flags?.enableLocalUser ?? false)}
          navigationContext={MenuContext.HOME}
          onboardingTourContext={projectOnboardingTourContext}
        />
        <RFContent isLoading={isProjectListLoading}>
          <CreateProjectModal
            isOpen={isCreateProjectModalOpen}
            onClose={async () => {
              setIsCreateProjectModalOpen(false)
            }}
          />
          <KeyboardShortcutsModal />
          {sharedProjectToken !== null && <SharedProjectModal isOpen={isSharedProjectModalOpen} sharedProjectToken={sharedProjectToken} onClose={() => {
            setIsSharedModalOpen(false)
            void mutateProjectList()
          }} />}
          <div className={styles.projectsListContainer}>
            <Row className={styles.projectsList}>
              <ProjectInfoCards />
            </Row>
          </div>
        </RFContent>
      </RFPage>
    </>
  )
}
