import { Draw, Translate } from 'ol/interaction'
import { FEATURE_TYPE, type TextFeatureType } from '../shapes/common'
import { type ChangePatch } from './common'
import { type MapBrowserEvent, type Collection, type Feature, type Map } from 'ol'
import { type Point } from 'ol/geom'
import type BaseEvent from 'ol/events/Event'
import { v4 as uuidV4 } from 'uuid'
import { type DrawEvent } from 'ol/interaction/Draw'
import EventType from 'ol/events/EventType'
import { type TextGeometryProperties } from '../shapes/text'
import { type Layer } from 'ol/layer'
import { doubleClick, primaryAction } from 'ol/events/condition'
import { type TranslateEvent } from 'ol/interaction/Translate'
import PointerInteraction from 'ol/interaction/Pointer'
import { type Coordinate } from 'ol/coordinate'
import { type FeatureLike } from 'ol/Feature'

export class DrawTextInteraction extends Draw {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Point>>) => void
  private _isInteracting = false
  private readonly _drawingFeatureType: TextFeatureType
  private readonly _initialText: string = ''

  constructor (
    destinationCollection: Collection<Feature<Point>>,
    onEndInteraction: (patch: ChangePatch<Feature<Point>>) => void,
    drawingFeatureType: TextFeatureType,
  ) {
    super({
      type: 'Point',
      style: [], // Remove default style for drawing point
      condition: primaryAction,
    })
    this._onEndInteraction = onEndInteraction
    this._drawingFeatureType = drawingFeatureType

    this.addEventListener('drawstart', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      const id = uuidV4()
      drawEndEvent.feature.setProperties({
        [FEATURE_TYPE]: this._drawingFeatureType,
        id,
      })
      this._isInteracting = true
    })
    this.addEventListener('drawabort', () => {
      this._isInteracting = false
    })
    this.addEventListener('drawend', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      this._isInteracting = false
      const feature = drawEndEvent.feature as Feature<Point>
      const geom = feature.getGeometry()
      geom?.setProperties({
        geometryType: 'TEXT',
        text: this._initialText,
        pinnedResolution: this.getMap()?.getView().getResolution() ?? 1,
      } satisfies TextGeometryProperties)
      destinationCollection.push(feature)
      this._onEndInteraction({
        add: [
          {
            id: feature.getProperties().id,
            data: feature,
          },
        ],
      })
    })
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortDrawing()
        return false
      }
      if (key === 'Enter') {
        this.finishDrawing()
        return false
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }
}

interface TranslateTextInteractionConstructor {
  modifiableFeatures: Collection<Feature>
  layers: Layer[]
  onEndInteraction: (patch: ChangePatch<Feature<Point>>) => void
  pixelTolerance?: number
}

export class TranslateTextInteraction extends Translate {
  private readonly _onEndInteraction: (patch: ChangePatch<Feature<Point>>) => void
  private readonly _layers: Layer[]
  private readonly _pixelTolerance: number
  private readonly _modifiableFeatures: Collection<Feature>

  constructor ({
    modifiableFeatures,
    layers,
    onEndInteraction,
    pixelTolerance = 16,
  }: TranslateTextInteractionConstructor) {
    super({
      layers,
      condition: (e: MapBrowserEvent<UIEvent>) => {
        return primaryAction(e) && this.isCursorAbleToModify(e)
      },
      hitTolerance: pixelTolerance,
    })
    this._onEndInteraction = onEndInteraction
    this._modifiableFeatures = modifiableFeatures
    this._pixelTolerance = pixelTolerance
    this._layers = layers;
    (this as unknown as { featuresAtPixel_: TranslateTextInteraction['_featuresAtPixel_'] }).featuresAtPixel_ = this._featuresAtPixel_

    this.on('translateend', (translateEndEvent: TranslateEvent) => {
      this._onEndInteraction({
        update: translateEndEvent.features.getArray().map((feature) => {
          return {
            id: feature.getProperties().id,
            data: feature as Feature<Point>,
          }
        }),
      })
    })
  }

  // XXX: reimplementation of the private method featuresAtPixel_ from ol/interaction/Translate
  //      The original implementation is buggy as `feature` frin the callback of forEachFeatureAtPixel
  //      can be `FeatureLike` instead of `Feature<Geometry>`.
  private readonly _featuresAtPixel_ = (pixel: Coordinate, map: Map): Feature | undefined => {
    return map.forEachFeatureAtPixel(
      pixel,
      (feature, layer) => {
        if (!(this as unknown as { filter_: (f: FeatureLike, l: Layer) => boolean }).filter_(feature, layer)) {
          return undefined
        }
        const features = (this as unknown as { features_: Collection<Feature> | null }).features_
        if ((features != null) && !features.getArray().some((f) => f.getId() === feature.getId())) {
          return undefined
        }
        return feature
      },
      {
        layerFilter: (layer) => this._layers.includes(layer),
        hitTolerance: this._pixelTolerance,
      },
    ) as Feature | undefined
  }

  public readonly isCursorAbleToModify = (evt: MapBrowserEvent<UIEvent>): boolean => {
    if (!this.getActive()) {
      return false
    }

    const feature = evt.map.forEachFeatureAtPixel(evt.pixel, (feature) => {
      return this._modifiableFeatures.getArray().find((f) => f.getProperties().id === feature.getProperties().id)
    }, {
      hitTolerance: this._pixelTolerance,
      layerFilter: (layer) => this._layers.includes(layer),
    })

    return feature !== undefined
  }
}

interface EditTextInteractionConstructor {
  modifiableFeatures: Collection<Feature>
  layers: Layer[]
  onEndInteraction: (patch: ChangePatch<Feature>) => void
  onStartEditing: (feature: Feature) => void
}
export class EditTextInteraction extends PointerInteraction {
  private readonly modifiableFeatures: Collection<Feature>
  private selectedTextFeature?: Feature = undefined
  private readonly onEndInteraction: (patch: ChangePatch<Feature>) => void
  private readonly onStartEditing: (feature: Feature) => void
  private _isInteracting = false

  constructor ({
    modifiableFeatures,
    layers,
    onEndInteraction,
    onStartEditing,
  }: EditTextInteractionConstructor) {
    super({
      handleEvent: (evt: MapBrowserEvent<UIEvent>): boolean => {
        if (doubleClick(evt)) {
          const feature = evt.map.forEachFeatureAtPixel(evt.pixel, (feature) => feature, {
            layerFilter: (layer) => {
              return layers.includes(layer)
            },
          })
          if (feature !== undefined) {
            const featureToModify = this.modifiableFeatures.getArray()
              .find((f) => f.getProperties().id === feature.getProperties().id)
            if (featureToModify !== undefined) {
              this.startEditing(featureToModify)
              return false
            }
          }
        }
        if (this._isInteracting) {
          this.endEditing()
          return false
        }
        return true
      },
    })
    this.modifiableFeatures = modifiableFeatures
    this.onEndInteraction = onEndInteraction
    this.onStartEditing = onStartEditing
    this.selectedTextFeature = undefined
  }

  public readonly startEditing = (feature: Feature): void => {
    this.selectedTextFeature = feature
    const geom = feature.getGeometry() as Point
    this._isInteracting = true
    const { text } = geom.getProperties() as TextGeometryProperties
    const overlay = document.getElementById('ol-overlay-text-edit')
    if (overlay !== null) {
      // move overlay to the feature
      const pixel = this.getMap()?.getPixelFromCoordinate(geom.getCoordinates())
      if (pixel !== undefined) {
        overlay.style.left = `${pixel[0].toFixed(0)}px`
        overlay.style.top = `${pixel[1].toFixed(0)}px`
      }
      // Change visibility of overlay
      overlay.style.visibility = 'visible'
      const input = overlay.children[0] as HTMLTextAreaElement
      input.value = text
      document.addEventListener('mousedown', this._onClickCheckEndEditing, {})
      document.addEventListener('keydown', this._onKeyDownCheckEscapeToExit, {})
      input.addEventListener('input', this._onTextChange, {})
      input.focus()
      this.onStartEditing(feature)
    }
  }

  public readonly endEditing = (): void => {
    if (!this._isInteracting) {
      return
    }
    this._isInteracting = false
    const modifiedFeature = this.selectedTextFeature
    this.selectedTextFeature = undefined
    const overlay = document.getElementById('ol-overlay-text-edit')
    if (overlay !== null) {
      overlay.style.visibility = 'hidden'
      const input = overlay.children[0] as HTMLTextAreaElement
      document.removeEventListener('mousedown', this._onClickCheckEndEditing, {})
      document.removeEventListener('keypress', this._onKeyDownCheckEscapeToExit, {})
      input.removeEventListener('input', this._onTextChange, {})
    }
    if (modifiedFeature !== undefined) {
      // if the text content is empty, then delete the feature
      // otherwise, update the feature
      const geom = modifiedFeature.getGeometry()
      if (geom !== undefined) {
        const { text } = geom.getProperties() as TextGeometryProperties
        if (text === '') {
          this.onEndInteraction({
            remove: [
              {
                id: modifiedFeature.getProperties().id,
                data: modifiedFeature,
              },
            ],
          })
        } else {
          this.onEndInteraction({
            update: [
              {
                id: modifiedFeature.getProperties().id,
                data: modifiedFeature,
              },
            ],
          })
        }
      }
    }
  }

  private readonly _onTextChange = (e: Event): void => {
    const input = e.target as HTMLTextAreaElement
    if (this.selectedTextFeature !== undefined) {
      const geom = this.selectedTextFeature.getGeometry()
      if (geom !== undefined) {
        geom.setProperties({
          ...geom.getProperties() as TextGeometryProperties,
          text: input.value,
        })
        this.selectedTextFeature.changed()
        geom.changed()
      }
    }
  }

  private readonly _onClickCheckEndEditing = (evt: MouseEvent): void => {
    // Check if the click is outside the overlay
    const overlay = document.getElementById('ol-overlay-text-edit')
    if (overlay !== null) {
      if (!overlay.contains(evt.target as Node)) {
        this.endEditing()
      }
    }
  }

  private readonly _onKeyDownCheckEscapeToExit = (evt: KeyboardEvent): void => {
    if (evt.key === 'Escape') {
      this.endEditing()
    }
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }
}
