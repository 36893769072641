import { createWithEqualityFn as create } from 'zustand/traditional'

interface ExportProgressStoreState {
  isExporting: boolean
  progress: number
}

interface ExportProgressStoreAction {
  setIsExporting: (value: boolean) => void
  setProgress: (value: number) => void
}

export const useExportProgressStore = create<ExportProgressStoreState & ExportProgressStoreAction>((set) => ({
  isExporting: false,
  progress: 0,
  setIsExporting: (value: boolean) => { set({ isExporting: value, progress: 0 }) },
  setProgress: (value: number) => { set({ progress: value }) },
}))
