import { useIsDirectMeasureAreaToolFreehand, useIsDirectMeasurePerimeterToolFreehand, useSelectedTool } from '@app/pages/editor-page/hooks/editor-page'
import { Flex, Typography, Switch } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/tool-parameters.module.scss'
import { useCallback } from 'react'
import { Tool } from '@app/constants'
import { useEditorStore } from '@app/stores/editor'

const { Title } = Typography

export const ToolParameters: React.FC = () => {
  const [isAreaMeasurementFreehand, setIsAreaMeasurementFreehand] = useIsDirectMeasureAreaToolFreehand()
  const [isPerimeterMeasurementFreehand, setIsPerimeterMeasurementFreehand] = useIsDirectMeasurePerimeterToolFreehand()
  const {
    isMetadataAnnotationLineToolFreehand,
    isMetadataAnnotationPolygonToolFreehand,
    setIsMetadataAnnotationLineToolFreehand,
    setIsMetadataAnnotationPolygonToolFreehand,
  } = useEditorStore(state => {
    return {
      isMetadataAnnotationLineToolFreehand: state.isMetadataAnnotationLineToolFreehand,
      isMetadataAnnotationPolygonToolFreehand: state.isMetadataAnnotationPolygonToolFreehand,
      setIsMetadataAnnotationLineToolFreehand: state.setIsMetadataAnnotationLineToolFreehand,
      setIsMetadataAnnotationPolygonToolFreehand: state.setIsMetadataAnnotationPolygonToolFreehand,
    }
  })
  const [selectedTool] = useSelectedTool()

  const onToggleDirectMeasureAreaFreehand = useCallback(() => {
    setIsAreaMeasurementFreehand(!isAreaMeasurementFreehand)
  }, [isAreaMeasurementFreehand, setIsAreaMeasurementFreehand])

  const onToggleDirectMeasurePerimeterFreehand = useCallback(() => {
    setIsPerimeterMeasurementFreehand(!isPerimeterMeasurementFreehand)
  }, [isPerimeterMeasurementFreehand, setIsPerimeterMeasurementFreehand])

  const onToggleMetadataAnnotationPolygonFreehand = useCallback(() => {
    setIsMetadataAnnotationPolygonToolFreehand(!isMetadataAnnotationPolygonToolFreehand)
  }, [isMetadataAnnotationPolygonToolFreehand, setIsMetadataAnnotationPolygonToolFreehand])

  const onToggleMetadataAnnotationLineFreehand = useCallback(() => {
    setIsMetadataAnnotationLineToolFreehand(!isMetadataAnnotationLineToolFreehand)
  }, [isMetadataAnnotationLineToolFreehand, setIsMetadataAnnotationLineToolFreehand])

  const selectedToolToFreehand: Partial<Record<Tool, boolean>> = {
    [Tool.DIRECT_MEASURE_AREA]: isAreaMeasurementFreehand,
    [Tool.DIRECT_MEASURE_PERIMETER]: isPerimeterMeasurementFreehand,
    [Tool.METADATA_ANNOTATION_LINE]: isMetadataAnnotationLineToolFreehand,
    [Tool.METADATA_ANNOTATION_POLYGON]: isMetadataAnnotationPolygonToolFreehand,
  }
  const selectedToolToToggleFreehand: Partial<Record<Tool, () => void>> = {
    [Tool.DIRECT_MEASURE_AREA]: onToggleDirectMeasureAreaFreehand,
    [Tool.DIRECT_MEASURE_PERIMETER]: onToggleDirectMeasurePerimeterFreehand,
    [Tool.METADATA_ANNOTATION_LINE]: onToggleMetadataAnnotationLineFreehand,
    [Tool.METADATA_ANNOTATION_POLYGON]: onToggleMetadataAnnotationPolygonFreehand,
  }

  const hasFreehand = selectedToolToFreehand[selectedTool] !== undefined
  const isFreehand = selectedToolToFreehand[selectedTool]
  const onToggleFreehand = selectedToolToToggleFreehand[selectedTool]

  return <div className={styles.root}>
    <Title level={5} className={styles.title}>
      <FormattedMessage id="tool.parameters.title" defaultMessage={'Tool Parameters'} />
    </Title>
    <div className={styles.content}>
      {
        hasFreehand &&
        <Flex justify='space-between' align='center' flex={1}>
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="tool.parameters.freehand.label" defaultMessage={'Freehand'} />
          </Title>
          <Switch checked={isFreehand} onClick={onToggleFreehand}/>
        </Flex>
      }
    </div>
  </div>
}
