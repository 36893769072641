import type * as React from 'react'
import styles from './styles/slider-opacity.module.scss'
import { Slider as AntdSlider } from 'antd'
import clsx from 'clsx'

interface SliderProps {
  value: number
  min: number
  max: number
  prediction?: boolean
  onChange: (value: number) => void
  onClickMin: () => void
  onClickMax: () => void
}
export const SliderOpacity: React.FC<SliderProps> = ({ value, max, min, prediction = false, onChange, onClickMax, onClickMin }: SliderProps) => {
  return <div className={clsx(styles.sliderOpacity, { [styles.prediction]: prediction })}>
    <div className={clsx(styles.sliderEdge, styles.min, { [styles.prediction]: prediction })} onClick={onClickMin}/>
    <AntdSlider className={clsx(styles.slider, { [styles.prediction]: prediction })} min={min} max={max} value={value} onChange={onChange} onAfterChange={() => {
      const element = document.activeElement as HTMLElement
      element.blur()
    }}/>
    <div className={clsx(styles.sliderEdge, styles.max, { [styles.prediction]: prediction })} onClick={onClickMax}/>
  </div>
}
