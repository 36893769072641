/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CircleGeometryProperties
 */
export interface CircleGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof CircleGeometryProperties
     */
    geometryType: CircleGeometryPropertiesGeometryTypeEnum;
    /**
     * 
     * @type {Array}
     * @memberof CircleGeometryProperties
     */
    center: Array;
    /**
     * 
     * @type {number}
     * @memberof CircleGeometryProperties
     */
    radius: number;
    /**
     * 
     * @type {boolean}
     * @memberof CircleGeometryProperties
     */
    filled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CircleGeometryProperties
     */
    pinnedResolution: number;
}


/**
 * @export
 */
export const CircleGeometryPropertiesGeometryTypeEnum = {
    Circle: 'CIRCLE'
} as const;
export type CircleGeometryPropertiesGeometryTypeEnum = typeof CircleGeometryPropertiesGeometryTypeEnum[keyof typeof CircleGeometryPropertiesGeometryTypeEnum];


/**
 * Check if a given object implements the CircleGeometryProperties interface.
 */
export function instanceOfCircleGeometryProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "geometryType" in value;
    isInstance = isInstance && "center" in value;
    isInstance = isInstance && "radius" in value;
    isInstance = isInstance && "filled" in value;
    isInstance = isInstance && "pinnedResolution" in value;

    return isInstance;
}

export function CircleGeometryPropertiesFromJSON(json: any): CircleGeometryProperties {
    return CircleGeometryPropertiesFromJSONTyped(json, false);
}

export function CircleGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CircleGeometryProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'center': json['center'],
        'radius': json['radius'],
        'filled': json['filled'],
        'pinnedResolution': json['pinned_resolution'],
    };
}

export function CircleGeometryPropertiesToJSON(value?: CircleGeometryProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry_type': value.geometryType,
        'center': value.center,
        'radius': value.radius,
        'filled': value.filled,
        'pinned_resolution': value.pinnedResolution,
    };
}

