import { EVENTS_ID } from '@app/constants'
import { api } from './api'
import { mutate as globalMutate } from 'swr'
import { CACHE_KEYS, type CacheKey } from '@app/api/cache-keys'
import { type AlgoClass, type ClassAnnotationGeoJSON } from '@app/api/openapi'

export const addProjectClass = async (projectId: string): Promise<{ name: string, colorIndex: number }> => {
  const newClass = await api.createProjectClassApiProjectsProjectIdClassPost({
    projectId,
  })
  await globalMutate(CACHE_KEYS.PROJECT_CLASSES(projectId))
  window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_CLASSES_ADD))
  return newClass
}

export const updateProjectClass = async (projectId: string, updatedName: string, colorIndex: number): Promise<void> => {
  await api.updateProjectClassApiProjectsProjectIdClassColorIndexPut({
    projectId,
    colorIndex,
    projectClassUpdateRequest: {
      name: updatedName,
    },
  })
  await globalMutate(CACHE_KEYS.PROJECT_CLASSES(projectId))
}

export const deleteProjectClass = async (projectId: string, colorIndex: number): Promise<void> => {
  // 1) Make optimistic mutation about the side effects of deleting a class
  //    - remove the class with `colorIndex` from the project classes cache
  //    - remove all the annotations with `colorIndex` from the project image annotations cache
  //    - reset the undo/redo history
  // This early mutation is needed to avoid letting the user interact with objects that will be deleted
  // This works because we can reproduce the side effects of deleting a class without calling the API
  // This is not ideal because it duplicate the logics of the server API into the Web UI
  // We only invalidate CACHE_KEYS that might induce interactions with the deleted class or annotations
  // There might be some transition states between CACHE_KEYS that we don't invalidate early,
  // but it should not be a problem because the user should not be able to interact with them.
  //
  // 2) Call the API to delete the class
  //    After the API call, the server will have the latest state of the project
  //    And we are certain that the class is deleted and the side-effects are applied
  //
  // 3) Invalidate the cache, including side effects, so SWR will revalidate the cache
  //    This time, it should not be optimistic. We want to make sure we get the latest data from the server.

  void globalMutate(CACHE_KEYS.PROJECT_CLASSES(projectId), (currentProjectClasses: AlgoClass[] | undefined) => {
    return (currentProjectClasses ?? []).filter((projectClass) => projectClass.colorIndex !== colorIndex)
  }, { revalidate: false })
  void globalMutate((key) => {
    // Invalidate all the project image annotations cache where the projectId matches
    if (typeof key === 'object') {
      const cacheKey = key as CacheKey<unknown>
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS.name) {
        const projectImageAnnotationsCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationsCacheKey.data.projectId === projectId) {
          return true
        }
      }
    }
    return false
  }, (currentProjectImageAnnotations: ClassAnnotationGeoJSON[] | undefined) => {
    return (currentProjectImageAnnotations ?? []).filter((annotation) => annotation.colorIndex !== colorIndex)
  }, { revalidate: false })
  window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET))

  await api.deleteProjectClassApiProjectsProjectIdClassColorIndexDelete({
    projectId,
    colorIndex,
  })

  window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET))
  void globalMutate(CACHE_KEYS.PROJECT_CLASSES(projectId))
  void globalMutate((key) => {
    // Invalidate all the project image annotations cache where the projectId matches
    if (typeof key === 'object') {
      const cacheKey = key as CacheKey<unknown>
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS.name) {
        const projectImageAnnotationsCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationsCacheKey.data.projectId === projectId) {
          return true
        }
      }
      if (cacheKey.keyId === CACHE_KEYS.PROJECT_IMAGE_ANNOTATION_CLASSES_DISTRIBUTION.name) {
        const projectImageAnnotationClassDistributionCacheKey = cacheKey as CacheKey<{ projectId: string, imageId: string }>
        if (projectImageAnnotationClassDistributionCacheKey.data.projectId === projectId) {
          return true
        }
      }
    }
    return false
  })
  void globalMutate(CACHE_KEYS.PROJECT_TRAINING_STATE(projectId))
  void globalMutate(CACHE_KEYS.PROJECT_TRAINING_SNAPSHOT(projectId))
}
