import Fill from 'ol/style/Fill'
import Style, { type StyleFunction } from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import CircleStyle from 'ol/style/Circle'
import { type Color } from '../color'
import { type Feature } from 'ol'
import { type Point } from 'ol/geom'

const pointCircleStyle = (fillColor: Color, borderColor: Color = [0, 0, 0]): Style => {
  const pointStyle = new Style({
    image: new CircleStyle({
      radius: 8,
      fill: new Fill({
        color: fillColor,
      }),
      stroke: new Stroke({
        color: borderColor,
        width: 3,
      }),
    }),
  })
  return pointStyle
}

export const buildSmartAnnotationStyle = (
): StyleFunction => {
  return (feature) => {
    const color: Color = feature.get('color')
    return pointCircleStyle(color)
  }
}

export const getAnimatedSmartAnnotationStyle = (feature: Feature<Point>, state: number): Style => {
  const color: Color = feature.get('color')
  return pointCircleStyle(color, state === 1 ? [50, 50, 50] : [200, 200, 200])
}
