/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AngleUnit } from './AngleUnit';
import {
    AngleUnitFromJSON,
    AngleUnitFromJSONTyped,
    AngleUnitToJSON,
} from './AngleUnit';
import type { DirectMeasureTextPosition } from './DirectMeasureTextPosition';
import {
    DirectMeasureTextPositionFromJSON,
    DirectMeasureTextPositionFromJSONTyped,
    DirectMeasureTextPositionToJSON,
} from './DirectMeasureTextPosition';
import type { FontWeight } from './FontWeight';
import {
    FontWeightFromJSON,
    FontWeightFromJSONTyped,
    FontWeightToJSON,
} from './FontWeight';

/**
 * Configuration for the direct measure settings.
 * 
 * This model include the parameters that the user can configure.
 * @export
 * @interface DirectMeasureSettingsValueObject
 */
export interface DirectMeasureSettingsValueObject {
    /**
     * The main color of the direct measure tools
     * @type {string}
     * @memberof DirectMeasureSettingsValueObject
     */
    mainColor: string;
    /**
     * The color of the measuring line
     * @type {string}
     * @memberof DirectMeasureSettingsValueObject
     */
    measuringLineColor: string;
    /**
     * The color of the measuring point
     * @type {string}
     * @memberof DirectMeasureSettingsValueObject
     */
    measuringPointColor: string;
    /**
     * The font family of the direct measure text
     * @type {string}
     * @memberof DirectMeasureSettingsValueObject
     */
    fontFamily: string;
    /**
     * The font size of the direct measure text
     * @type {number}
     * @memberof DirectMeasureSettingsValueObject
     */
    fontSize: number;
    /**
     * 
     * @type {FontWeight}
     * @memberof DirectMeasureSettingsValueObject
     */
    fontWeight: FontWeight;
    /**
     * The color of the direct measure text
     * @type {string}
     * @memberof DirectMeasureSettingsValueObject
     */
    fontColor: string;
    /**
     * The font color of the outline of the direct measure text
     * @type {string}
     * @memberof DirectMeasureSettingsValueObject
     */
    fontOutlineColor: string;
    /**
     * 
     * @type {AngleUnit}
     * @memberof DirectMeasureSettingsValueObject
     */
    angleUnit: AngleUnit;
    /**
     * 
     * @type {DirectMeasureTextPosition}
     * @memberof DirectMeasureSettingsValueObject
     */
    directMeasureTextPosition: DirectMeasureTextPosition;
}

/**
 * Check if a given object implements the DirectMeasureSettingsValueObject interface.
 */
export function instanceOfDirectMeasureSettingsValueObject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mainColor" in value;
    isInstance = isInstance && "measuringLineColor" in value;
    isInstance = isInstance && "measuringPointColor" in value;
    isInstance = isInstance && "fontFamily" in value;
    isInstance = isInstance && "fontSize" in value;
    isInstance = isInstance && "fontWeight" in value;
    isInstance = isInstance && "fontColor" in value;
    isInstance = isInstance && "fontOutlineColor" in value;
    isInstance = isInstance && "angleUnit" in value;
    isInstance = isInstance && "directMeasureTextPosition" in value;

    return isInstance;
}

export function DirectMeasureSettingsValueObjectFromJSON(json: any): DirectMeasureSettingsValueObject {
    return DirectMeasureSettingsValueObjectFromJSONTyped(json, false);
}

export function DirectMeasureSettingsValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasureSettingsValueObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mainColor': json['main_color'],
        'measuringLineColor': json['measuring_line_color'],
        'measuringPointColor': json['measuring_point_color'],
        'fontFamily': json['font_family'],
        'fontSize': json['font_size'],
        'fontWeight': FontWeightFromJSON(json['font_weight']),
        'fontColor': json['font_color'],
        'fontOutlineColor': json['font_outline_color'],
        'angleUnit': AngleUnitFromJSON(json['angle_unit']),
        'directMeasureTextPosition': DirectMeasureTextPositionFromJSON(json['direct_measure_text_position']),
    };
}

export function DirectMeasureSettingsValueObjectToJSON(value?: DirectMeasureSettingsValueObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'main_color': value.mainColor,
        'measuring_line_color': value.measuringLineColor,
        'measuring_point_color': value.measuringPointColor,
        'font_family': value.fontFamily,
        'font_size': value.fontSize,
        'font_weight': FontWeightToJSON(value.fontWeight),
        'font_color': value.fontColor,
        'font_outline_color': value.fontOutlineColor,
        'angle_unit': AngleUnitToJSON(value.angleUnit),
        'direct_measure_text_position': DirectMeasureTextPositionToJSON(value.directMeasureTextPosition),
    };
}

