import EventType from 'ol/events/EventType'
import type BaseEvent from 'ol/events/Event'
import type RenderEvent from 'ol/render/Event'
import type VectorLayer from 'ol/layer/Vector'
import type VectorSource from 'ol/source/Vector'
import { Draw } from 'ol/interaction'
import { FEATURE_TYPE, FeatureType } from '../shapes/common'
import { getAnimatedSmartAnnotationStyle } from '../shapes/smart-annotation'
import { getVectorContext } from 'ol/render'
import { primaryAction } from 'ol/events/condition'
import { round } from 'mathjs'
import { type Color } from '../color'
import { type DrawEvent } from 'ol/interaction/Draw'
import { type MapBrowserEvent, type Collection, type Feature } from 'ol'
import { type Point } from 'ol/geom'
import { v4 as uuidV4 } from 'uuid'
import { type StyleFunction } from 'ol/style/Style'

interface smartAnnotationParameters {
  color: Color
}

export class SmartAnnotationInteraction extends Draw {
  private _smartAnnotationParameters: smartAnnotationParameters
  private readonly _onEndInteraction: (smartAnnotationSelection: Feature<Point>) => void
  private readonly _onStopAnnotation: () => void
  private _isInteracting = false
  private readonly _destinationLayer: VectorLayer<VectorSource<Feature<Point>>>
  private readonly _collection: Collection<Feature<Point>>

  constructor (
    destinationLayer: VectorLayer<VectorSource<Feature<Point>>>,
    collection: Collection<Feature<Point>>,
    smartAnnotationParameters: smartAnnotationParameters,
    onEndInteraction: (smartAnnotationSelection: Feature<Point>) => void,
    onStopAnnotation: () => void,
  ) {
    // Call the super constructor
    super({
      type: 'Point',
      minPoints: 1,
      maxPoints: 1,
      condition: primaryAction,
      style: [],
    })

    this._smartAnnotationParameters = smartAnnotationParameters
    this._onEndInteraction = onEndInteraction
    this._onStopAnnotation = onStopAnnotation
    this._destinationLayer = destinationLayer
    this._collection = collection

    // this.addEventListener('drawstart', onDrawStart)
    this.addEventListener('drawend', (e: Event | BaseEvent) => {
      const drawEndEvent = e as DrawEvent
      this._isInteracting = false
      const feature = drawEndEvent.feature as Feature<Point>
      feature.setGeometry(feature.getGeometry()?.simplify(1) as Point)
      const id = uuidV4()
      feature.setProperties({
        [FEATURE_TYPE]: FeatureType.SMART_ANNOTATION,
        color: this._smartAnnotationParameters.color,
        id,
      })

      collection.push(feature)

      this._onEndInteraction(feature)

      this._animateFeature(feature)
    })
  }

  private _animateFeature (feature: Feature<Point>): void {
    // Animate the style of the selected feature
    const interval = 500
    const start = Date.now()
    const flashGeomOri = feature.getGeometry()

    if (flashGeomOri === null || flashGeomOri === undefined) {
      return
    }
    const flashGeom = flashGeomOri.clone()

    const animate = (event: RenderEvent): void => {
      const hasNoFeature = this._collection.getLength() === 0
      const featureNotInCollection = !this._collection.getArray().includes(feature)

      // Last condition needs to be directly written in the if in order to allow typescript to understand the type guard
      if (hasNoFeature || featureNotInCollection || event.frameState === null || event.frameState === undefined) {
        this._destinationLayer.un('postrender', animate)
        return
      }

      const elapsed = event.frameState?.time - start

      const vectorContext = getVectorContext(event)
      vectorContext.setStyle(getAnimatedSmartAnnotationStyle(feature, round(elapsed / interval) % 2))
      vectorContext.drawGeometry(flashGeom)

      // tell OpenLayers to continue postrender animation
      this.getMap()?.render()
    }
    this._destinationLayer.on('postrender', animate)
  }

  public readonly setStyle = (style: StyleFunction): void => {
    this.getOverlay().setStyle(style)
  }

  public readonly isInteracting = (): boolean => {
    return this._isInteracting
  }

  public readonly setBrushParameters = (smartAnnotationParameters: smartAnnotationParameters): void => {
    this._smartAnnotationParameters = smartAnnotationParameters
  }

  // XXX: This is a hack to fix some issue with aborting/finishing the drawing when switching between tools
  public readonly abortDrawing = (): void => {
    super.abortDrawing()
    this.handlingDownUpSequence = false
  }

  public readonly finishDrawingDrawing = (): void => {
    super.abortDrawing()
    this.handlingDownUpSequence = false
  }

  public readonly abortSmartAnnotation = (featureCompleted: Feature<Point>): void => {
    this._collection.remove(featureCompleted)

    if (this._collection.getLength() === 0) {
      this.abortAllSmartAnnotation()
    }
    this.handlingDownUpSequence = false
  }

  public readonly abortAllSmartAnnotation = (): void => {
    super.abortDrawing()
    this._collection.clear()
    this._onStopAnnotation()
    this.handlingDownUpSequence = false
  }

  public readonly handleEvent = (mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean => {
    if (mapBrowserEvent.type === EventType.KEYDOWN) {
      const keyEvent = mapBrowserEvent.originalEvent as KeyboardEvent
      const key = keyEvent.key
      if (key === 'Escape') {
        this.abortAllSmartAnnotation()
        return false
      }
    }
    return super.handleEvent(mapBrowserEvent)
  }
}
