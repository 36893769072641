import { useThicknessMeasure } from '@app/api/image-object/thickness-measure'
import { useProjectId, useSelectedImageId } from '@app/pages/editor-page/hooks/editor-page'
import { useEditorStore } from '@app/stores/editor'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@shadcn-ui/components/ui/table'
import { type FC, type ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

interface StatTitleProps {
  title: ReactNode
}
export const StatTitle: FC<StatTitleProps> = ({ title }) => {
  return (
    <TableHead className="text-xs text-clemex-offDarkGray h-auto w-[150px]">{title}</TableHead>
  )
}

interface StatNumberValueProps {
  value: number
  pixelSizeUm?: number
  decimal?: number
}
export const StatNumberValue: FC<StatNumberValueProps> = ({ value, pixelSizeUm, decimal = 2 }) => {
  return (
    <TableCell className="text-xs text-clemex-offDarkGray p-0">{(value * (pixelSizeUm ?? 1)).toFixed(decimal)}</TableCell>
  )
}

interface StatStringValueProps {
  value: string
}
export const StatStringValue: FC<StatStringValueProps> = ({ value }) => {
  return (
    <TableCell className="text-xs text-clemex-offDarkGray p-0">{value}</TableCell>
  )
}

interface ObjectStatsContainerProps {
  children: ReactNode
}
export const ObjectStatsContainer: FC<ObjectStatsContainerProps> = ({ children }) => {
  return (
    <TableRow className="">
      {children}
    </TableRow>
  )
}

interface ThicknessMeasureTableProps {
  type: 'annotation' | 'prediction'
}
export const ThicknessMeasureTable: FC<ThicknessMeasureTableProps> = ({ type }) => {
  const projectId = useProjectId()
  const selectedImageId = useSelectedImageId()
  const thicknessMeasureObjectId = useEditorStore((state) => state.thicknessMeasureObjectId)

  const { data: thicknessMeasure, isLoading } = useThicknessMeasure({
    projectId,
    imageId: selectedImageId,
    measure: 'thickness',
    objectId: thicknessMeasureObjectId,
    type,
  })

  if (isLoading || thicknessMeasure === undefined) {
    return <div>
      <FormattedMessage
        id="editor-page.object-overview.class-annotation-object-overview.measure.loading"
        defaultMessage={'Loading...'}
      />
    </div>
  }

  return <Table>
    <TableBody>
      <ObjectStatsContainer>
        <StatTitle title={
          <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.thickness_measure.min" defaultMessage={'Thickness min'} />
        } />
        <StatNumberValue value={thicknessMeasure.thicknessMin} />
      </ObjectStatsContainer>
      <ObjectStatsContainer>
        <StatTitle title={
          <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.thickness_measure.max" defaultMessage={'Thickness max'} />
        } />
        <StatNumberValue value={thicknessMeasure.thicknessMax} />
      </ObjectStatsContainer>
      <ObjectStatsContainer>
        <StatTitle title={
          <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.thickness_measure.mean" defaultMessage={'Thickness mean'} />
        } />
        <StatNumberValue value={thicknessMeasure.thicknessMean} />
      </ObjectStatsContainer>
      <ObjectStatsContainer>
        <StatTitle title={
          <FormattedMessage id="editor-page.object-overview.class-annotation-object-overview.thickness_measure.median" defaultMessage={'Thickness median'} />
        } />
        <StatNumberValue value={thicknessMeasure.thicknessMedian} />
      </ObjectStatsContainer>
    </TableBody>
  </Table>
}
