import type * as React from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/authentication-providers.module.scss'
import clsx from 'clsx'

interface AuthenticationProvidersProps {
  register?: boolean
  className?: string
}

export const AuthenticationProviders: React.FC<AuthenticationProvidersProps> = ({ register = false, className }) => {
  return <div className={clsx(styles.authenticationProviders, className)}>
    <a className={clsx(styles.provider, { [styles.register]: register })} href="/api/microsoft-login">
      <Button className={styles.button}
        icon={
          <img width="32" height="32" src="/images/login/ms_logo.svg" />
        }
      >
        {register && <FormattedMessage id="signUp.withMicrosoft" defaultMessage={'Sign Up using your Microsoft account'} />}
        {!register && <FormattedMessage id="login.withMicrosoft" defaultMessage={'Sign in with your Microsoft account'} />}
      </Button>
    </a>
  </div>
}
