import type * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Form, Typography, Checkbox, notification, Button, Flex, Space, Alert } from 'antd'
import { useFeatureFlags, useProfile } from '@app/api/hooks'
import styles from './styles/request-assistance-modal.module.scss'
import * as RFAPI from '@app/api/api'
import { UserAssistanceType } from '@app/api/openapi'
import { EVENTS_ID } from '@app/constants'
const { Text, Title, Paragraph } = Typography

interface RequestAssistanceModalProps {
  isOpen: boolean
  close: () => void
}
interface FormResults {
  assistances: UserAssistanceType[]
}
export const RequestAssistanceModal: React.FC<RequestAssistanceModalProps> = ({ isOpen, close }) => {
  const { data: flags } = useFeatureFlags()
  const { data: userProfile } = useProfile()
  const [form] = Form.useForm()
  const [notificationApi, notificationContext] = notification.useNotification({
    placement: 'topRight',
  })
  const submitForm = async (values: FormResults): Promise<void> => {
    close()
    form.resetFields()
    void RFAPI.requestAssistance(values.assistances).then(() => {
      notificationApi.success({
        message: <FormattedMessage id='request-assistance.notification.success.contact-me.title' defaultMessage='Request submitted'/>,
        description: <FormattedMessage
          id='request-assistance.notification.success.contact-me.description'
          defaultMessage='Your request for assistance has been submitted to clemex team. Expect to be contacted within two business days'/>,
        placement: 'topRight',
        duration: 7,
      })
    }).catch(() => {
      notificationApi.error({
        message: <FormattedMessage id='request-assistance.notification.error.contact-me.title' defaultMessage='Request failed'/>,
        description: <FormattedMessage
          id='request-assistance.notification.error.contact-me.description'
          defaultMessage='Your request for assistance Could not be submitted to Clemex. Please try again'/>,
        placement: 'topRight',
        duration: 7,
      })
    })
  }

  return <Modal
    footer={null}
    closable={false}
    title={<Title level={4}>
      <FormattedMessage id='request-assistance.modal.title' defaultMessage='Request Assistance'/>
    </Title>}
    open={isOpen}
    className={styles.modal}
  >
    { notificationContext }
    { flags !== undefined && !flags.enableLocalUser
      ? <>
          <Form form={form} layout="vertical" initialValues={{ assistances: [] }} onFinish={submitForm}>
            <Form.Item name="assistances" rules={[
              {
                required: true,
                message: <FormattedMessage id='request-assistance.modal.assistance.error.required' defaultMessage='Please select at least one option'/>,
              },

            ]}>
              <Checkbox.Group className={styles.helpChoices}>
                <Checkbox value={UserAssistanceType.Help}>
                  <FormattedMessage id='request-assistance.modal.choice.help' defaultMessage='I need help with Studio'/>
                </Checkbox>
                <Checkbox value={UserAssistanceType.LicenceAcquisition}>
                  <FormattedMessage id='request-assistance.modal.choice.licence' defaultMessage='I want to buy a licence'/>
                </Checkbox>
                <Checkbox value={UserAssistanceType.ProblemReport}>
                  <FormattedMessage id='request-assistance.modal.choice.problem' defaultMessage='Something is not working for me'/>
                </Checkbox>
                <Checkbox value={UserAssistanceType.FeatureRequest}>
                  <FormattedMessage id='request-assistance.modal.choice.suggestion' defaultMessage='I have a suggestion'/>
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Paragraph>
              <FormattedMessage id='request-assistance.modal.response.handling.description.part1' defaultMessage='Just a heads up, a real live human will be reading your comment!'/>
              <br/>
              <FormattedMessage id='request-assistance.modal.response.handling.description.part2' defaultMessage="We're all ears and ready to dish out some genuine human-powered responses."/>
            </Paragraph>
            <Paragraph>
              <Alert description={<>
                <FormattedMessage id='request-assistance.modal.response.handling.description.part3'
                  defaultMessage='You will be contacted at the following email address:'
                />
                <Text ellipsis={{ tooltip: true }} className={styles.assistanceModalAlert}>
                  { userProfile?.email }
                </Text>
              </>
              }
              type="info"
              showIcon
              />
            </Paragraph>
            <Flex className={styles.footer}>
              <Form.Item>
                <Button type='default' className={styles.assistanceModalButtons} onClick={() => {
                  close()
                  form.resetFields()
                }}
                >
                  <FormattedMessage id='request-assistance.modal.Cancel' defaultMessage='Cancel'/>
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType="submit" className={styles.assistanceModalButtons}>
                  <FormattedMessage id='request-assistance.modal.contact-me' defaultMessage='Contact me'/>
                </Button>
              </Form.Item>
            </Flex>
          </Form>

        </>
      : <Space direction='vertical' size='middle'>
          <Text>
            <FormattedMessage
              id='request-assistance.modal.offlie.description'
              defaultMessage='To contact Clemex you can send an email at : {salesEmail}, {supportEmail}'
              values={{
                salesEmail: <a href="mailto:clemex-sales@clemex.com">clemex-sales@clemex.com</a>,
                supportEmail: <a href="mailto:support@clemex.com">support@clemex.com</a>,
              }} />
            <br/>
            <FormattedMessage
              id='request-assistance.modal.offlie.description2'
              defaultMessage='or by phone at: {salesPhoneNumber}'
              values={{
                salesPhoneNumber: '1-888-651-6573',

              }}
            />
          </Text>
          <Flex className={styles.footer}>
            <Button type='default' className={styles.assistanceModalButtons} onClick={() => window.dispatchEvent(new CustomEvent(EVENTS_ID.DOWNLOAD_LOGS))}>
              <FormattedMessage id='request-assistance.modal.button.download-logs' defaultMessage='Get application Logs' />
            </Button>
            <Button type='primary' className={styles.assistanceModalButtons} onClick={close}>
              <FormattedMessage id='request-assistance.modal.button.ok' defaultMessage='Ok'/>
            </Button>
          </Flex>
        </Space>

    }
  </Modal>
}
