/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Project Training Error
 * @export
 * @interface ProjectTrainingError
 */
export interface ProjectTrainingError {
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingError
     */
    projectTrainingSnapshotId: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectTrainingError
     */
    errorDatetime: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectTrainingError
     */
    errorMessage: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectTrainingError
     */
    acknowledged: boolean;
}

/**
 * Check if a given object implements the ProjectTrainingError interface.
 */
export function instanceOfProjectTrainingError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectTrainingSnapshotId" in value;
    isInstance = isInstance && "errorDatetime" in value;
    isInstance = isInstance && "errorMessage" in value;
    isInstance = isInstance && "acknowledged" in value;

    return isInstance;
}

export function ProjectTrainingErrorFromJSON(json: any): ProjectTrainingError {
    return ProjectTrainingErrorFromJSONTyped(json, false);
}

export function ProjectTrainingErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTrainingError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectTrainingSnapshotId': json['project_training_snapshot_id'],
        'errorDatetime': (new Date(json['error_datetime'])),
        'errorMessage': json['error_message'],
        'acknowledged': json['acknowledged'],
    };
}

export function ProjectTrainingErrorToJSON(value?: ProjectTrainingError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_training_snapshot_id': value.projectTrainingSnapshotId,
        'error_datetime': (value.errorDatetime.toISOString()),
        'error_message': value.errorMessage,
        'acknowledged': value.acknowledged,
    };
}

