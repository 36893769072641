/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ArrowGeometryProperties } from './ArrowGeometryProperties';
import {
    instanceOfArrowGeometryProperties,
    ArrowGeometryPropertiesFromJSON,
    ArrowGeometryPropertiesFromJSONTyped,
    ArrowGeometryPropertiesToJSON,
} from './ArrowGeometryProperties';
import type { CircleGeometryProperties } from './CircleGeometryProperties';
import {
    instanceOfCircleGeometryProperties,
    CircleGeometryPropertiesFromJSON,
    CircleGeometryPropertiesFromJSONTyped,
    CircleGeometryPropertiesToJSON,
} from './CircleGeometryProperties';
import type { EllipseGeometryProperties } from './EllipseGeometryProperties';
import {
    instanceOfEllipseGeometryProperties,
    EllipseGeometryPropertiesFromJSON,
    EllipseGeometryPropertiesFromJSONTyped,
    EllipseGeometryPropertiesToJSON,
} from './EllipseGeometryProperties';
import type { LineGeometryProperties } from './LineGeometryProperties';
import {
    instanceOfLineGeometryProperties,
    LineGeometryPropertiesFromJSON,
    LineGeometryPropertiesFromJSONTyped,
    LineGeometryPropertiesToJSON,
} from './LineGeometryProperties';
import type { PolygonGeometryProperties } from './PolygonGeometryProperties';
import {
    instanceOfPolygonGeometryProperties,
    PolygonGeometryPropertiesFromJSON,
    PolygonGeometryPropertiesFromJSONTyped,
    PolygonGeometryPropertiesToJSON,
} from './PolygonGeometryProperties';
import type { RectangleGeometryProperties } from './RectangleGeometryProperties';
import {
    instanceOfRectangleGeometryProperties,
    RectangleGeometryPropertiesFromJSON,
    RectangleGeometryPropertiesFromJSONTyped,
    RectangleGeometryPropertiesToJSON,
} from './RectangleGeometryProperties';
import type { TextGeometryProperties } from './TextGeometryProperties';
import {
    instanceOfTextGeometryProperties,
    TextGeometryPropertiesFromJSON,
    TextGeometryPropertiesFromJSONTyped,
    TextGeometryPropertiesToJSON,
} from './TextGeometryProperties';

/**
 * @type GeometryProperties1
 * 
 * @export
 */
export type GeometryProperties1 = { geometryType: 'ARROW' } & ArrowGeometryProperties | { geometryType: 'CIRCLE' } & CircleGeometryProperties | { geometryType: 'ELLIPSE' } & EllipseGeometryProperties | { geometryType: 'LINE' } & LineGeometryProperties | { geometryType: 'POLYGON' } & PolygonGeometryProperties | { geometryType: 'RECTANGLE' } & RectangleGeometryProperties | { geometryType: 'TEXT' } & TextGeometryProperties;

export function GeometryProperties1FromJSON(json: any): GeometryProperties1 {
    return GeometryProperties1FromJSONTyped(json, false);
}

export function GeometryProperties1FromJSONTyped(json: any, ignoreDiscriminator: boolean): GeometryProperties1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['geometry_type']) {
        case 'ARROW':
            return {...ArrowGeometryPropertiesFromJSONTyped(json, true), geometryType: 'ARROW'};
        case 'CIRCLE':
            return {...CircleGeometryPropertiesFromJSONTyped(json, true), geometryType: 'CIRCLE'};
        case 'ELLIPSE':
            return {...EllipseGeometryPropertiesFromJSONTyped(json, true), geometryType: 'ELLIPSE'};
        case 'LINE':
            return {...LineGeometryPropertiesFromJSONTyped(json, true), geometryType: 'LINE'};
        case 'POLYGON':
            return {...PolygonGeometryPropertiesFromJSONTyped(json, true), geometryType: 'POLYGON'};
        case 'RECTANGLE':
            return {...RectangleGeometryPropertiesFromJSONTyped(json, true), geometryType: 'RECTANGLE'};
        case 'TEXT':
            return {...TextGeometryPropertiesFromJSONTyped(json, true), geometryType: 'TEXT'};
        default:
            throw new Error(`No variant of GeometryProperties1 exists with 'geometryType=${json['geometryType']}'`);
    }
}

export function GeometryProperties1ToJSON(value?: GeometryProperties1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['geometryType']) {
        case 'ARROW':
            return ArrowGeometryPropertiesToJSON(value);
        case 'CIRCLE':
            return CircleGeometryPropertiesToJSON(value);
        case 'ELLIPSE':
            return EllipseGeometryPropertiesToJSON(value);
        case 'LINE':
            return LineGeometryPropertiesToJSON(value);
        case 'POLYGON':
            return PolygonGeometryPropertiesToJSON(value);
        case 'RECTANGLE':
            return RectangleGeometryPropertiesToJSON(value);
        case 'TEXT':
            return TextGeometryPropertiesToJSON(value);
        default:
            throw new Error(`No variant of GeometryProperties1 exists with 'geometryType=${value['geometryType']}'`);
    }

}

