import type * as React from 'react'
import styles from './styles/jumping-dots.module.scss'

export const JumpingDots: React.FC = () => {
  return <>
    <span className={styles.dot}>.</span>
    <span className={styles.dot}>.</span>
    <span className={styles.dot}>.</span>
  </>
}
