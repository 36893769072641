import type * as React from 'react'
import styles from './styles/request-reset-password-form.module.scss'
import { Button, Form, Input, Space } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import AccountCircle from '@material-design-icons/svg/filled/account_circle.svg'
import { WebUIRoutes } from '@app/routes'
import clsx from 'clsx'
import { AuthenticationFormLayout, type AuthenticationFormLayoutResult } from '@components/authentication/authentication-form-layout'
import { useNavigate } from 'react-router'

export interface RequestResetPasswordFormResult {
  email: string
  password: string
  remember: boolean
}

export const RequestResetPasswordForm: React.FC<AuthenticationFormLayoutResult<RequestResetPasswordFormResult>> = (props) => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <AuthenticationFormLayout
      onSubmit={props.onSubmit}
      title={
        <FormattedMessage id="request-reset-password.title" defaultMessage={'Request Reset Password'} />
      }
    >
      <Space direction="vertical" size="small" className={styles.spaceSeparation}>
        <Form.Item
          name="email"
          rules={[{
            required: true,
            type: 'email',
            message: <FormattedMessage id="login.form.error.required.email" defaultMessage={'Please input a valid email.'} />,
          }]}
        >
          <Input prefix={<AccountCircle />} placeholder={intl.formatMessage({
            id: 'request-reset-password.form.email.placeholder',
            defaultMessage: 'Email',
          })} />
        </Form.Item>
        <Form.Item>
          <div className={styles.flex_end}>
            <Button className={styles.floatRight} type="primary" htmlType="submit">
              <FormattedMessage id="login.form.resetPassword.submit" defaultMessage={'Submit'} />
            </Button>
            <Button className={clsx(styles.floatRight, styles.marginRight)} onClick={() => { navigate(WebUIRoutes.authentication().path) }}>
              <FormattedMessage id="login.form.resetPassword.back" defaultMessage={'Go Back'} />
            </Button>
          </div>
        </Form.Item>
      </Space>
    </AuthenticationFormLayout>
  )
}
