/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PluginStatus } from './PluginStatus';
import {
    PluginStatusFromJSON,
    PluginStatusFromJSONTyped,
    PluginStatusToJSON,
} from './PluginStatus';

/**
 * ProjectBundleStatusResponse is used to know the status of the plugin bundle of a project.
 * @export
 * @interface ProjectBundleStatusResponse
 */
export interface ProjectBundleStatusResponse {
    /**
     * 
     * @type {PluginStatus}
     * @memberof ProjectBundleStatusResponse
     */
    status: PluginStatus;
}

/**
 * Check if a given object implements the ProjectBundleStatusResponse interface.
 */
export function instanceOfProjectBundleStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function ProjectBundleStatusResponseFromJSON(json: any): ProjectBundleStatusResponse {
    return ProjectBundleStatusResponseFromJSONTyped(json, false);
}

export function ProjectBundleStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectBundleStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': PluginStatusFromJSON(json['status']),
    };
}

export function ProjectBundleStatusResponseToJSON(value?: ProjectBundleStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': PluginStatusToJSON(value.status),
    };
}

