import { Polygon } from 'ol/geom'

export const makePolygonValid = (polygon: Polygon, minArea = 0.01): Polygon | undefined => {
  const externalRing = polygon.getLinearRing(0)
  if (externalRing === null || externalRing === undefined) {
    console.error('Failed to get external ring of polygon geometry')
    return
  }
  const hasAtLeast4Coordinates = externalRing.getCoordinates().length >= 4
  const areaIsBiggerThanThreshold = Math.abs(externalRing.getArea()) > 0.1
  if (hasAtLeast4Coordinates && areaIsBiggerThanThreshold) {
    const validInternalLinearRings = polygon.getLinearRings().slice(1).filter((linearRing) => {
      const hasAtLeast4Coordinates = linearRing.getCoordinates().length >= 4
      const areaIsBiggerThanThreshold = Math.abs(linearRing.getArea()) > minArea
      return hasAtLeast4Coordinates && areaIsBiggerThanThreshold
    })
    return new Polygon([
      externalRing.getCoordinates(),
      ...validInternalLinearRings.map(linearRing => linearRing.getCoordinates()),
    ])
  } else {
    if (!hasAtLeast4Coordinates) {
      console.error('Failed to get at least 4 coordinates in the external ring')
    }
  }
}
