import type * as React from 'react'
import stylesFromEditor from './styles/class-editor.module.scss'
import { Typography, Tag, Tooltip } from 'antd'
import { type AnnotationClass } from './class-editor'
import { FormattedMessage } from 'react-intl'
import { getColor, TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'
import PredictionShowIcon from '@material-design-icons/svg/filled/tonality.svg'
import PredictionHideIcon from '@material-design-icons/svg/filled/hide_source.svg'

const { Title, Text } = Typography

interface PredictionClassesProps {
  classes: AnnotationClass[]
  onTogglePredictionClassVisibility: (classIndex: number) => void
  globalPredictionVisibility: boolean
  onToggleGlobalPredictionVisibility: () => void

}
export const PredictionClasses: React.FC<PredictionClassesProps> = (props) => {
  const { classes, onTogglePredictionClassVisibility, globalPredictionVisibility, onToggleGlobalPredictionVisibility } = props

  return <div className={stylesFromEditor.classEditor}>
    <div className={stylesFromEditor.headerContainer}>
      <Title level={5} className={stylesFromEditor.title}>
        <FormattedMessage id="prediction-classes.title" defaultMessage={'Classes'} />
      </Title>
      <div className={stylesFromEditor.actions} id='prediction-class-show-hide'>
        <Tooltip
          placement="topRight"
          title={
            globalPredictionVisibility
              ? <FormattedMessage id="predictions-classes.tooltip.hide-predictions" defaultMessage="Hide all predictions in the viewer" />
              : <FormattedMessage id="predictions-classes.tooltip.show-predictions" defaultMessage="Show all predictions in the viewer" />
          }
          mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
        >
          <button
            className={stylesFromEditor.action}
            onClick={(e) => {
              // Remove focus on the button
              e.currentTarget.blur()
              onToggleGlobalPredictionVisibility()
            }}
          >
            {globalPredictionVisibility ? <PredictionShowIcon /> : <PredictionHideIcon />}
          </button>
        </Tooltip>
      </div>
    </div>
    <div className={stylesFromEditor.content}>
      {
        classes.map((classIterate) => {
          return <div className={stylesFromEditor.classDetail} key={classIterate.colorIndex}>
            <div className={stylesFromEditor.classDetailTag}>
              <Tag color={getColor(classIterate.colorIndex)} className={stylesFromEditor.classDetailColor} />
            </div>
            <div className={stylesFromEditor.classDetailName}>
              <Text ellipsis title={classIterate.name}>
                {classIterate.name}
              </Text>
            </div>
            <div className={stylesFromEditor.classDetailActions}>
              <Tooltip
                title={
                  classIterate.predictionVisible && globalPredictionVisibility
                    ? <FormattedMessage id={'prediction-classes.class.button.visibility.hide'} defaultMessage={'Hide the prediction mask of this class on the image'} />
                    : <FormattedMessage id={'prediction-classes.class.button.visibility.show'} defaultMessage={'Show the prediction mask of this class on the image'} />
                }
                placement="topRight"
                mouseLeaveDelay={0}
                mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
              >
                <button
                  className={stylesFromEditor.classDetailAction}
                  onClick={(e) => {
                    // Remove focus on the button
                    e.currentTarget.blur()
                    onTogglePredictionClassVisibility(classIterate.colorIndex)
                  }}
                >
                  {classIterate.predictionVisible && globalPredictionVisibility ? <PredictionShowIcon /> : <PredictionHideIcon />}
                </button>
              </Tooltip>
            </div>
          </div>
        })
      }
    </div>
  </div>
}
