import { useImageFilterStore } from '@app/stores/image-filter'
import { Button, Slider as AntdSlider, Flex } from 'antd'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'
import CloseIcon from '@material-design-icons/svg/outlined/close.svg'

interface FilterSliderProps {
  min?: number
  max?: number
  step?: number
  title: React.ReactNode
  value: number
  onChange: (value: number) => void
}
const FilterSlider: FC<FilterSliderProps> = ({ title, value, onChange, min, max, step }) => {
  return <div className="flex flex-col px-[8px]">
    <div className="clemex-title">
      { title }
    </div>
    <div>
      <AntdSlider
        min={min ?? 0}
        max={max ?? 100}
        step={step ?? 0.01}
        value={value}
        onChange={onChange}
        onChangeComplete={() => {
          const element = document.activeElement as HTMLElement
          element.blur()
        }}
      />
    </div>
  </div>
}

export const ImageParameters: React.FC = () => {
  const [setIsOpen] = useImageFilterStore((state) => [state.setIsOpen])
  const [contrast, setContrast] = useImageFilterStore((state) => [state.contrast, state.setContrast])
  const [saturation, setSaturation] = useImageFilterStore((state) => [state.saturation, state.setSaturation])
  const [gamma, setGamma] = useImageFilterStore((state) => [state.gamma, state.setGamma])
  const [exposure, setExposure] = useImageFilterStore((state) => [state.exposure, state.setExposure])

  const reset = useImageFilterStore((state) => state.reset)

  return <Flex vertical className="grow p-[8px] pl-[16px]">
    <Flex justify='space-between' align='center' className="clemex-title pb-[12px]">
      <FormattedMessage id="image-filter.title" defaultMessage={'Image filters'} />
      <button onClick={() => { setIsOpen(false) }}>
        <CloseIcon />
      </button>
    </Flex>
    <Flex vertical>
      <FilterSlider
        title={<FormattedMessage id="image-filter.saturation.title" defaultMessage={'Saturation'} />}
        value={saturation}
        onChange={setSaturation}
        min={-1}
        max={1}
      />
      <FilterSlider
        title={<FormattedMessage id="image-filter.gamma.title" defaultMessage={'Gamma'} />}
        value={gamma}
        onChange={setGamma}
        min={0.1}
        max={10}
      />
      <FilterSlider
        title={<FormattedMessage id="image-filter.contrast.title" defaultMessage={'Contrast'} />}
        value={contrast}
        onChange={setContrast}
        min={-1}
        max={1}
      />
      <FilterSlider
        title={<FormattedMessage id="image-filter.exposure.title" defaultMessage={'Exposure'} />}
        value={exposure}
        onChange={setExposure}
        min={-1}
        max={1}
      />
      <Flex justify='flex-end'>
        <Button
          type='primary'
          onClick={reset}
        >
          <FormattedMessage id="image-filter.reset" defaultMessage={'Reset'} />
        </Button>
      </Flex>
    </Flex>
  </Flex>
}
