/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectUpdateRequest
 */
export interface ProjectUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateRequest
     */
    description?: string | null;
}

/**
 * Check if a given object implements the ProjectUpdateRequest interface.
 */
export function instanceOfProjectUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectUpdateRequestFromJSON(json: any): ProjectUpdateRequest {
    return ProjectUpdateRequestFromJSONTyped(json, false);
}

export function ProjectUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ProjectUpdateRequestToJSON(value?: ProjectUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

