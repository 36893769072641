/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * RedeemShareProjectResponse is used to know if a project can be trained.
 * @export
 * @interface RedeemShareProjectResponse
 */
export interface RedeemShareProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof RedeemShareProjectResponse
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemShareProjectResponse
     */
    slug: string;
}

/**
 * Check if a given object implements the RedeemShareProjectResponse interface.
 */
export function instanceOfRedeemShareProjectResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "slug" in value;

    return isInstance;
}

export function RedeemShareProjectResponseFromJSON(json: any): RedeemShareProjectResponse {
    return RedeemShareProjectResponseFromJSONTyped(json, false);
}

export function RedeemShareProjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedeemShareProjectResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'slug': json['slug'],
    };
}

export function RedeemShareProjectResponseToJSON(value?: RedeemShareProjectResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'slug': value.slug,
    };
}

