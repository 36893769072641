import { EVENTS_ID } from '@app/constants'
import { Modal, Tabs, type TabsProps } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './styles/project-settings-modal.module.scss'
import { DirectMeasureSettings } from '@components/modals/project-settings/direct-measure-settings'
import { ProjectMetadataSettings } from '@components/modals/project-settings/project-metadata-settings'
import type { DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable'
import Draggable from 'react-draggable'
import { useProjectSettings, useLicence } from '@app/api/hooks'
import { AlgorithmSelector, AlgorithmSettings } from '@components/modals/project-settings/algorithm-settings'
import { ExportSettings, ExportFeatureExplanation } from '@components/modals/project-settings/export-settings'
import { MeasurementSetting } from '@components/modals/project-settings/measurement-settings'

export enum ProjectSettingsPageContext {
  PROJECT_LIST = 'PROJECT_LIST',
  PROJECT_EDITOR = 'PROJECT_EDITOR',
}

enum ProjectSettingsModalTabsKeys {
  PROJECT_METADATA = 'PROJECT_METADATA',
  ALGORITHM = 'ALGORITHM',
  DIRECT_MEASURE = 'DIRECT_MEASURE',
  EXPORT = 'EXPORT',
  MEASUREMENT = 'MEASUREMENT',
}

interface ProjectSettingsModalProps {
  projectId?: string
  isOpen?: boolean
  onClose?: () => void
  pageContext: ProjectSettingsPageContext
}
export const ProjectSettingsModal: React.FC<ProjectSettingsModalProps> = ({ projectId, ...props }) => {
  const { data: projectSettings } = useProjectSettings(projectId)
  const { data: licence } = useLicence()
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false)
  const [activeTab, setActiveTab] = useState<ProjectSettingsModalTabsKeys>(ProjectSettingsModalTabsKeys.PROJECT_METADATA)

  useEffect(() => {
    if (props.isOpen !== undefined) {
      setIsOpen(props.isOpen)
    }
  }, [props.isOpen])

  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
    props.onClose?.()
  }

  useEffect(() => {
    const openProjectExportSettings = (): void => {
      setActiveTab(ProjectSettingsModalTabsKeys.EXPORT)
      openModal()
    }
    const openProjectSettings = (): void => {
      setActiveTab(ProjectSettingsModalTabsKeys.PROJECT_METADATA)
      openModal()
    }
    const openAlgoSettings = (): void => {
      setActiveTab(ProjectSettingsModalTabsKeys.ALGORITHM)
      openModal()
    }
    window.addEventListener(EVENTS_ID.PROJECT_SETTING_OPEN, openProjectSettings)
    window.addEventListener(EVENTS_ID.ALGO_SETTING_OPEN, openAlgoSettings)
    window.addEventListener(EVENTS_ID.PROJECT_EXPORT_SETTINGS, openProjectExportSettings)
    return () => {
      window.removeEventListener(EVENTS_ID.PROJECT_SETTING_OPEN, openProjectSettings)
      window.removeEventListener(EVENTS_ID.ALGO_SETTING_OPEN, openAlgoSettings)
      window.removeEventListener(EVENTS_ID.PROJECT_EXPORT_SETTINGS, openProjectExportSettings)
    }
  }, [])
  const draggleRef = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const [disabled, setDisabled] = useState(true)
  const onStart: DraggableEventHandler = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (targetRect === undefined) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  if (projectId === undefined) {
    return null
  }

  const projectMetadataSettingsItem = {
    key: ProjectSettingsModalTabsKeys.PROJECT_METADATA,
    label: <FormattedMessage id="project-settings.modal.tab.project-metadata.title" defaultMessage={'General'} />,
    children: <ProjectMetadataSettings
      projectId={projectId}
      canArchiveProject={props.pageContext === ProjectSettingsPageContext.PROJECT_LIST}
      onArchiveCompleted={closeModal}
    />,
  }
  const directMeasureSettings = {
    key: ProjectSettingsModalTabsKeys.DIRECT_MEASURE,
    label: <FormattedMessage id="project-settings.modal.tab.direct-measure.title" defaultMessage={'Direct Measure'} />,
    children: <DirectMeasureSettings projectId={projectId} />,
  }
  const algorithmSettings = {
    key: ProjectSettingsModalTabsKeys.ALGORITHM,
    label: <FormattedMessage id="project-settings.modal.tab.algorithm.title" defaultMessage={'Algorithm'} />,
    children: <>
      <AlgorithmSelector projectId={projectId}/>
      <AlgorithmSettings
        projectId={projectId}
        algorithmId={projectSettings?.algorithmId}
      />
    </>,
  }
  const exportTab = {
    key: ProjectSettingsModalTabsKeys.EXPORT,
    label: <FormattedMessage id="project-settings.modal.tab.export.title" defaultMessage={'Export'} />,
    children: <>
      {licence?.enableExportProject
        ? <ExportSettings projectId={projectId} />
        : <ExportFeatureExplanation />}
    </>,
  }
  const measurementSettings = {
    key: ProjectSettingsModalTabsKeys.MEASUREMENT,
    label: <FormattedMessage id="project-settings.modal.tab.measurement.title" defaultMessage={'Measurement'} />,
    children: <MeasurementSetting projectId={projectId} />,
  }

  const tabsItems: TabsProps['items'] = [projectMetadataSettingsItem]
  if (props.pageContext === ProjectSettingsPageContext.PROJECT_EDITOR) {
    tabsItems.push(exportTab)
    tabsItems.push(measurementSettings)
    tabsItems.push(directMeasureSettings)
    tabsItems.push(algorithmSettings)
  }

  return <Modal
    className={styles.projectSettingsModal}
    open={isOpen}
    title={
      <div
        className={styles.projectSettingsModalTitle}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false)
          }
        }}
        onMouseOut={() => {
          setDisabled(true)
        }}
      >
        <FormattedMessage id="project-settings.modal.title" defaultMessage={'Project Settings'} />
      </div>
    }
    closable={true}
    onCancel={closeModal}
    footer={null}
    onOk={closeModal}
    width={650}
    mask={false}
    maskClosable={false}
    wrapClassName={styles.projectSettingsModalWrapper}

    modalRender={(modal) => (
      <Draggable
        disabled={disabled}
        bounds={bounds}
        nodeRef={draggleRef}
        onStart={(event, uiData) => { onStart(event, uiData) }}
      >
        <div ref={draggleRef}>{modal}</div>
      </Draggable>
    )}
  >
    <Tabs
      className={styles.projectSettingsTabs}
      activeKey={activeTab}
      items={tabsItems}
      onChange={(activeKey: string) => { setActiveTab(activeKey as ProjectSettingsModalTabsKeys) }}
    />
  </Modal>
}
