import { api } from '@app/api/api'
import { CACHE_KEYS } from '@app/api/cache-keys'
import { type ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetMeasureEnum, type ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetTypeEnum, type ThicknessMeasureImageObject } from '@app/api/openapi'
import useSWR, { type SWRResponse } from 'swr'

interface ThicknessMeasureParameters {
  imageId: string | undefined
  projectId: string | undefined
  type: ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetTypeEnum | undefined
  objectId: string | undefined
  measure: ObjectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGetMeasureEnum | undefined
}
export const useThicknessMeasure = ({
  imageId,
  projectId,
  type,
  objectId,
  measure,
}: ThicknessMeasureParameters): SWRResponse<ThicknessMeasureImageObject> => {
  return useSWR(
    CACHE_KEYS.IMAGE_OBJECT(
      projectId,
      imageId,
      type,
      objectId,
      measure,
    ),
    async ({ data }) => {
      return await api.objectMeasureApiProjectsProjectIdImagesImageIdTypeObjectIdObjectMeasureMeasureGet(data)
    },
    {
      keepPreviousData: false,
    },
  )
}
