import { type LineString, Point, Polygon } from 'ol/geom'
import Style, { type StyleFunction } from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import type Feature from 'ol/Feature'
import { type FeatureLike } from 'ol/Feature'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import { AngleUnitValues, DEFAULT_SHAPE_STYLE, FEATURE_TYPE, FeatureType, type ShapeStyle } from './common'
import CircleStyle from 'ol/style/Circle'

const buildMeasuringPoint = (measuringPointRadius: number, resolution: number): Polygon => {
  //
  //  A---B
  //  | I |
  //  D---C
  //
  // I is the origin of the measuring point of the tool anchor (0, 0)
  //
  // A = [-measuringPointRadius, measuringPointRadius]
  // B = [measuringPointRadius, measuringPointRadius]
  // C = [measuringPointRadius, -measuringPointRadius]
  // D = [-measuringPointRadius, -measuringPointRadius]
  //

  const measuringLine = new Polygon([[
    [-measuringPointRadius, measuringPointRadius],
    [measuringPointRadius, measuringPointRadius],
    [measuringPointRadius, -measuringPointRadius],
    [-measuringPointRadius, -measuringPointRadius],
    [-measuringPointRadius, measuringPointRadius],
  ]])
  measuringLine.scale(resolution, resolution, [0, 0])
  return measuringLine
}

export interface AngleGeometryProperties {
  geometryType: 'ANGLE'
  pinnedResolution: number
}

export const buildAngleStyle = (
  selection: Set<string>,
  style: ShapeStyle = DEFAULT_SHAPE_STYLE,
): StyleFunction => {
  const angleStyle: StyleFunction = (feature: FeatureLike, resolution: number): Style[] => {
    const geom = feature.getGeometry()
    if (geom?.getType() !== 'LineString') {
      return []
    }
    const lineStringGeom = geom as LineString

    const { pinnedResolution } = geom.getProperties() as AngleGeometryProperties
    const selected = selection.has(feature.getProperties().id as string)

    if (lineStringGeom.getCoordinates().length === 2) {
      return [
        new Style({
          stroke: new Stroke({
            color: selected ? style.mainSelectedColor : style.mainColor,
            width: style.measuringLineThickness,
          }),
          zIndex: style.zIndexOffSet,
        }),
      ]
    } else if (lineStringGeom.getCoordinates().length === 3) {
      const A = lineStringGeom.getCoordinates()[0]
      const B = lineStringGeom.getCoordinates()[1]
      const C = lineStringGeom.getCoordinates()[2]

      const styles = [
        new Style({
          stroke: new Stroke({
            color: selected ? style.mainSelectedColor : style.mainColor,
            width: style.measuringLineThickness,
          }),
          zIndex: style.zIndexOffSet + (selected ? style.zIndexSelectionOffset : 0),
        }),
        ...[A, B, C].map((point) => {
          return new Style({
            stroke: new Stroke({
              color: style.measuringPointColor,
              width: 0.0001,
            }),
            fill: new Fill({
              color: style.measuringPointColor,
            }),
            geometry: () => {
              const measuringPoint = buildMeasuringPoint(style.measuringPointRadius, resolution)
              measuringPoint.translate(point[0], point[1])
              return measuringPoint
            },
            zIndex: style.zIndexOffSet + 1 + (selected ? style.zIndexSelectionOffset : 0),
          })
        }),
      ]
      if (feature.get(FEATURE_TYPE) === FeatureType.DIRECT_MEASURE_ANGLE) {
        const [x1, y1] = A
        const [x2, y2] = B
        const [x3, y3] = C
        const AB = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
        const BC = Math.sqrt(Math.pow(x3 - x2, 2) + Math.pow(y3 - y2, 2))
        const CA = Math.sqrt(Math.pow(x1 - x3, 2) + Math.pow(y1 - y3, 2))
        const measuredABCAngle = Math.acos((Math.pow(AB, 2) + Math.pow(BC, 2) - Math.pow(CA, 2)) / (2 * AB * BC))
        const measureText = style.angleUnit === AngleUnitValues.DEGREE
          ? `${(measuredABCAngle * 180 / Math.PI).toFixed(1)} °`
          : `${measuredABCAngle.toFixed(1)} rad`
        const measureTextHeight = style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2 + style.measurementTextBackgroundPadding * 2
        // Note: it is not easy to compute the width of the text in pixel. Openlayers does not provide any API to do so
        //       We could compute the width of the text in pixel using the canvas API but it would be too slow
        //       The best we can do is to approximate it by using the number of characters
        const measureTextWidth = 0.6 * measureText.length * style.measurementTextFontSizePx + style.measurementTextFontStrokeWidth * 2 + style.measurementTextBackgroundPadding * 2

        styles.push(
          new Style({
            text: new Text({
              font: `${style.measurementTextFontWeight} ${style.measurementTextFontSizePx}px ${style.measurementTextFontFamily}`,
              rotation: 0,
              justify: 'center',
              textAlign: 'center',
              text: measureText,
              padding: [style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding, style.measurementTextBackgroundPadding],
              fill: new Fill({ color: style.measurementTextFontFillColor }),
              stroke: new Stroke({ color: style.measurementTextFontStrokeColor, width: style.measurementTextFontStrokeWidth }),
              backgroundFill: new Fill({ color: style.measurementTextBackgroundFillColor }),
              backgroundStroke: new Stroke({ color: style.measurementTextBackgroundStrokeColor, width: style.measurementTextBackgroundStrokeWidth }),
              scale: pinnedResolution / resolution,
            }),
            geometry: () => {
              const pos = new Point([x2, y2])
              let rotationAngle = Math.PI + Math.atan2((BC * (y2 - y1) + AB * (y3 - y2)) / (AB * BC), (BC * (x2 - x1) + AB * (x3 - x2)) / (AB * BC))
              // If the sin(ABC) is negative, we need to rotate the text background by 180°
              if ((x1 - x3) * (y2 - y3) - (y1 - y3) * (x2 - x3) < 0) {
                rotationAngle -= Math.PI
              }
              pos.translate(
                Math.sign(Math.sin(-rotationAngle)) * (style.measurementTextBackgroundDistanceShape + ((measureTextWidth / 2))) * resolution,
                Math.sign(Math.cos(-rotationAngle)) * (style.measurementTextBackgroundDistanceShape + ((measureTextHeight / 2))) * resolution,
              )
              return pos
            },
            zIndex: style.zIndexOffSet + 2 + (selected ? style.zIndexSelectionOffset : 0),
          }),
        )
      }
      return styles
    }
    return []
  }
  return angleStyle
}

export const buildAngleModifyInteractionStyle = (style: ShapeStyle): StyleFunction => {
  return (feature: FeatureLike) => {
    const hitPoint = feature.getGeometry() as Point
    const targetFeature = feature.get('features')[0] as Feature | undefined
    if (targetFeature === undefined) {
      return undefined
    }
    const targetModifyGeometry = targetFeature.getGeometry()

    if (targetModifyGeometry !== undefined) {
      if (targetModifyGeometry.getType() === 'LineString') {
        const targetLineCoordinates = (targetModifyGeometry as LineString).getCoordinates() as [[number, number], [number, number], [number, number]]
        const hitPointCoordinates = hitPoint.getCoordinates() as [number, number]

        if (
          (targetLineCoordinates[0][0] === hitPointCoordinates[0] &&
          targetLineCoordinates[0][1] === hitPointCoordinates[1]) ||
          (targetLineCoordinates[1][0] === hitPointCoordinates[0] &&
          targetLineCoordinates[1][1] === hitPointCoordinates[1]) ||
          (targetLineCoordinates[2][0] === hitPointCoordinates[0] &&
          targetLineCoordinates[2][1] === hitPointCoordinates[1])
        ) {
          return [
            new Style({
              image: new CircleStyle({
                radius: style.hitDetectionToleranceRadius / 2,
                fill: new Fill({
                  color: 'rgba(255, 255, 255, 0.5)',
                }),
                stroke: new Stroke({
                  color: 'rgba(255, 255, 255, 0.8)',
                  width: 2,
                }),
              }),
              zIndex: style.zIndexOffSet + 4,
            }),
          ]
        }
      }
    }
  }
}
