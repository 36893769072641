/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FontWeight = {
    Normal: 'normal',
    Bold: 'bold'
} as const;
export type FontWeight = typeof FontWeight[keyof typeof FontWeight];


export function FontWeightFromJSON(json: any): FontWeight {
    return FontWeightFromJSONTyped(json, false);
}

export function FontWeightFromJSONTyped(json: any, ignoreDiscriminator: boolean): FontWeight {
    return json as FontWeight;
}

export function FontWeightToJSON(value?: FontWeight | null): any {
    return value as any;
}

